import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { AnalystEntityType } from "app/shared/types/AnalyticsTypes";
import { AnalistTaskInstanceListTabs, ANALYST_ENTITY_TYPE, TaskInstanceStatus } from "app/shared/Constants";
import { Icon } from "app/components_v2/Icon/Icon";
import { faSliders } from "@fortawesome/pro-regular-svg-icons";

type AnalistDetailBodyProps = {
    entityType: AnalystEntityType;
    selectedTaskStatus?: TaskInstanceStatus;
    donut?: ReactNode;
    donutList?: ReactNode;
    stars?: ReactNode;
    taskList?: ReactNode;
    barChart?: ReactNode;
    selectedTab?: AnalistTaskInstanceListTabs;
    onSecondaryFilterClick?: () => void;
};

export const AnalistDetailBody: FC<AnalistDetailBodyProps> = ({
    entityType,
    selectedTaskStatus,
    donut,
    donutList,
    stars,
    taskList,
    barChart,
    selectedTab,
    onSecondaryFilterClick,
}) => {
    const { t } = useTranslation();
    const barChartTitle: Record<AnalystEntityType, string> = {
        COMPANY: TranslationKeys.COMPANY,
        DEPARTMENT: TranslationKeys.DEPARTMENT,
        USER: TranslationKeys.EMPLOYEE,
    };

    const donutListTitle: Record<string, string> = {
        [TaskInstanceStatus.SHARED_TIMED_OUT]: TranslationKeys.ANALIST_SHARED_TIMED_OUT,
        [TaskInstanceStatus.TIMED_OUT]: TranslationKeys.ANALIST_TIMED_OUT,
        [TaskInstanceStatus.OUT_OF_TIME]: TranslationKeys.ANALIST_OUT_OF_TIME,
        [TaskInstanceStatus.IN_TIME]: TranslationKeys.ANALIST_IN_TIME,
    };

    const taskListTitle: Record<AnalistTaskInstanceListTabs, string> = {
        FEEDBACK: TranslationKeys.WITH_REVIEWS,
        COMMENTS: TranslationKeys.WITH_COMMENTS,
        NOREVIEW: TranslationKeys.WITH_NO_REVIEWS,
    };

    return (
        <div className="analistDetailBody">
            {donut && (
                <div className="analistDetailBody__box">
                    <p className="analistDetailBody__box__title">{t(TranslationKeys.ANALIST_TITLE_DONUT)}</p>
                    {donut}
                </div>
            )}
            {stars && (
                <div className="analistDetailBody__stars">
                    <div className="analistDetailBody__box--heightFitContent">
                        <p className="analistDetailBody__box__title">{t(TranslationKeys.ANALIST_TITLE_STARS)}</p>
                        {stars}
                    </div>
                    <div className="analistDetailBody__box--heightFitContent">
                        <p className="analistDetailBody__box__title">
                            {t(TranslationKeys.ANALIST_TITLE_LINECHART).replace("{1}", t(barChartTitle[entityType]))}
                        </p>
                        {barChart && barChart}
                    </div>
                </div>
            )}
            {donutList && entityType !== ANALYST_ENTITY_TYPE.USER && (
                <div className="analistDetailBody__box--heightFitContent">
                    {selectedTaskStatus && (
                        <p className="analistDetailBody__box__title">
                            {entityType === ANALYST_ENTITY_TYPE.DEPARTMENT
                                ? t(TranslationKeys.ANALIST_TITLE_DONUT_USERLIST).replace(
                                      "{1}",
                                      t(donutListTitle[selectedTaskStatus])
                                  )
                                : t(TranslationKeys.ANALIST_TITLE_DONUT_DEPARTMENTLIST).replace(
                                      "{1}",
                                      t(donutListTitle[selectedTaskStatus])
                                  )}
                        </p>
                    )}
                    {donutList}
                </div>
            )}
            {taskList && (
                <div
                    className={`analistDetailBody__box--heightFitContent ${
                        entityType === ANALYST_ENTITY_TYPE.USER ? "analistDetailBody__box--fullwidth" : ""
                    }`}
                >
                    {selectedTab && selectedTaskStatus && (
                        <div className="analistDetailBody__box__header">
                            <p className="analistDetailBody__box__title">
                                {t(TranslationKeys.ANALIST_TITLE_TASKLIST)
                                    .replace("{0}", t(donutListTitle[selectedTaskStatus]))
                                    .replace("{1}", t(taskListTitle[selectedTab]))}
                            </p>
                            <Icon icon={faSliders} theme="light" onClick={onSecondaryFilterClick} />
                        </div>
                    )}
                    {taskList}
                </div>
            )}
        </div>
    );
};
