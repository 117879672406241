import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { TaskHisotryGridSfModel, TaskHistoryGridContext } from "../../state/context/taskHistoryGridContext";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { useToast } from "app/hooks/Toast/useToast";
import { fillTaskHistoryExtraParams } from "../utilities/fillTaskHistoryExtraParams";
import { TaskInstanceService } from "app/services";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { PaginationDefaults, TASK_TYPE } from "app/shared/Constants";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import {
    INITIAL_TASK_GRID_SORT_DIRECTION,
    INITIAL_TASK_GRID_SORT_FIELD,
} from "app/pages/02-TAR/07-TAR-CRUD/constants/taskGridConstants";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useSession } from "app/hooks";

type THGridFetchModel = {
    pi?: number;
    sortF?: string;
    sortD?: SortedTypeModel;
    taskHistorySF?: TaskHisotryGridSfModel;
    company?: string;
    q?: string;
};

export const useTaskHistoryGrid = () => {
    const { t } = useTranslation();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const { handleToast } = useToast();
    const [searchParams] = useSearchParams();
    const session = useSession();

    const {
        secondaryFilterValues,
        pageIndex,
        sortDirection,
        sortField,
        selectCompany,
        query,
        onQueryChange,
        onChangePageIndex,
        onSortChange,
        onCompanyChange,
        onSFChange,
    } = useContext(TaskHistoryGridContext);

    const [data, setData] = useState<TaskInstanceModel[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showSecondaryFilter, setShowSecondaryFilter] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [taskInstanceId, setTaskInstanceId] = useState<number>();
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [isExportModalVisible, setIsExportModalVisible] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);

    const title = `${t(TranslationTitles.TASK_HISTORY_PAGE_TITLE)} ${
        selectCompany.company.value === "-1" ? `(${selectCompany.company.label})` : ""
    }`;
    const subtitle = `${total} ${t(TranslationKeys.TASKS)}`;

    const handleExport = async (csvParams?: IDownLoadCsvReturn) => {
        setIsExporting(true);
        setIsExportModalVisible(false);
        const selectedCompanyFetch = selectCompany.company.value;

        const extraParams = fillTaskHistoryExtraParams({
            companyId: selectedCompanyFetch,
            ...secondaryFilterValues,
        });
        await TaskInstanceService.GetDataExport({
            extraParams,
            query,
            sortDirection: sortDirection === "default" ? INITIAL_TASK_GRID_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_TASK_GRID_SORT_FIELD : sortField,
            ...csvParams,
            pageIndex: csvParams?.pageIndex !== undefined ? csvParams?.pageIndex - 1 : 0,
        });
        setIsExporting(false);
    };

    const getTaskInstances = async ({ pi, company, sortD, sortF, taskHistorySF, q }: THGridFetchModel) => {
        setIsLoading(true);
        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;
        const filterData = taskHistorySF ?? secondaryFilterValues;
        const selectedCompanyFetch = company ?? selectCompany.company.value;
        const queryFetch = q !== undefined ? q : query;

        const extraParams = fillTaskHistoryExtraParams({
            companyId: selectedCompanyFetch,
            ...filterData,
        });

        const {
            data: taskInstanceData,
            error,
            status,
        } = await TaskInstanceService.GetTaskHistoryData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexFetch,
            extraParams,
            query: queryFetch,
            sortDirection: sortD || sortDirection,
            sortField: sortF || sortField,
        });

        if (!status()) {
            console.error(error);
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED), variant: "danger" });
            setIsLoading(false);
            return;
        }

        setData(taskInstanceData.list);
        setTotal(taskInstanceData.count);
        setIsLoading(false);
    };

    const handleCleanFetch = () => {
        setShowSearchInput(false);
        getTaskInstances({ q: "", pi: 0 });
        onQueryChange("");
        onChangePageIndex(1);
    };

    const onQuerySearch = () => {
        if (isLoading) return;
        if (!!query.length) {
            getTaskInstances({ pi: 0 });
            onChangePageIndex(1);
            return;
        }
        handleCleanFetch();
    };

    const handleSortChange = (sortF: string, sortD: SortedTypeModel) => {
        onSortChange(sortF, sortD);
        getTaskInstances({ sortF, sortD });
    };

    const handleSaveSecondaryFilter = (values: TaskHisotryGridSfModel) => {
        onSFChange(values);
        setShowSecondaryFilter(false);
        getTaskInstances({ pi: 0, taskHistorySF: values });
        onChangePageIndex(1);
    };

    const onSelectCompany = ({ label, value }: OptionsSearch) => {
        if (value !== "-1") changeWorkingCompanyByCompanyId(Number(value));

        onCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        onSFChange({ departmentId: "-1", userId: "-1", subDepartmentId: "-1" });
        onChangePageIndex(1);
        getTaskInstances({
            pi: 0,
            company: value,
            taskHistorySF: { ...secondaryFilterValues, departmentId: "-1", userId: "-1", subDepartmentId: "-1" },
        });
    };

    const openTask = (id: number) => {
        setIsModalOpened(true);
        setTaskInstanceId(id);
    };

    const handleChangePageIndex = (pi: number) => {
        onChangePageIndex(pi);
        getTaskInstances({ pi: pi - 1 });
    };

    const onChangeCurrentTab = (tab: number) => {
        if (tab === currentTab) return;
        if (isLoading) return;
        setCurrentTab(tab);
    };

    useEffect(() => {
        let taskHistoryParams: TaskHisotryGridSfModel = secondaryFilterValues;
        let companyIdParams = selectCompany.company.value;

        if (!!searchParams.size) {
            const deadlineDate = searchParams.get("DeadlineDate");
            const deadlineDate1 = searchParams.get("DeadlineDate1");
            const sporadicTasks = Boolean(searchParams.get("SporadicTasks"));
            const inTime = Boolean(searchParams.get("InTime"));
            const outOfTime = Boolean(searchParams.get("OutOfTime"));
            const companyId = searchParams.get("CompanyId");
            const taskId = searchParams.get("TaskId") ?? "";

            taskHistoryParams = {
                ...secondaryFilterValues,
                startDate: !!deadlineDate?.length ? new Date(deadlineDate) : null,
                endDate: !!deadlineDate1?.length ? new Date(deadlineDate1) : null,
                taskType: sporadicTasks ? TASK_TYPE.SPORADIC : "-1",
                inTime,
                outOfTime,
                taskId,
            };
            onSFChange(taskHistoryParams);
            if (!!companyId) {
                const selectedCompanyParams = session?.user.companies?.find((c) => c.companyId === Number(companyId));
                if (selectedCompanyParams) {
                    companyIdParams = String(selectedCompanyParams.companyId);
                    onCompanyChange({
                        company: {
                            label: selectedCompanyParams.companyName,
                            value: String(selectedCompanyParams.companyId),
                        },
                    });
                }
            }
        }
        getTaskInstances({ taskHistorySF: taskHistoryParams, company: companyIdParams });
    }, [searchParams]);

    return {
        data,
        handleCleanFetch,
        handleSaveSecondaryFilter,
        handleSortChange,
        isLoading,
        isModalOpened,
        onCompanyChange,
        onQueryChange,
        onQuerySearch,
        pageIndex,
        query,
        secondaryFilterValues,
        selectCompany,
        setIsModalOpened,
        showSearchInput,
        showSecondaryFilter,
        sortDirection,
        sortField,
        taskInstanceId,
        total,
        setShowSecondaryFilter,
        setShowSearchInput,
        onSelectCompany,
        openTask,
        title,
        subtitle,
        handleChangePageIndex,
        handleExport,
        currentTab,
        onChangeCurrentTab,
        isExportModalVisible,
        setIsExportModalVisible,
        isExporting,
    };
};
