import { FC } from "react";
import { ProviderProps } from "./types";
import { IssueModalProvider } from "./IssueModalProvider/IssueModalProvider";
import { IssueAssetsModalProvider } from "./IssueAssetsModalProvider/IssueAssetsModalProvider";
import { HeaderTitleProvider } from "./HeaderTitleProvider/HeaderTitleProvider";
import { PreviousPathNameProvider } from "./PreviousPathNameProvider/PreviousPathNameProvider";
import { I18nextProvider, useTranslation } from "react-i18next";
import { ToastProvider } from "./ToastProvider/ToastProvider";
import { Provider } from "react-redux";
import { store } from "../reducer";
import { WizardTaskProvider } from "./WizardTaskProvider/WizardTaskProvider";
import { CloseCompanyModalProvider } from "./CloseCompanyModalProvider/CloseCompanyModalProvider";
import { PatchNoteModalProvider } from "./PatchNoteModalProvider/PatchNoteModalProvider";

export const MainProvider: FC<ProviderProps> = ({ children }) => {
    const { i18n } = useTranslation();
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <ToastProvider>
                    <PatchNoteModalProvider>
                        <CloseCompanyModalProvider>
                            <IssueModalProvider>
                                <IssueAssetsModalProvider>
                                    <HeaderTitleProvider>
                                        <WizardTaskProvider>
                                            <PreviousPathNameProvider>{children}</PreviousPathNameProvider>
                                        </WizardTaskProvider>
                                    </HeaderTitleProvider>
                                </IssueAssetsModalProvider>
                            </IssueModalProvider>
                        </CloseCompanyModalProvider>
                    </PatchNoteModalProvider>
                </ToastProvider>
            </I18nextProvider>
        </Provider>
    );
};
