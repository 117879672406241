import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellPhoto } from "app/components_v2/Table/CellPhoto/CellPhoto";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType, SortedTypeModel } from "app/components_v2/Table/types";
import { CompanyModel } from "app/models/01-SEG/Company/CompanyModels";
import { CompanyService, GroupCompanyService } from "app/services";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { faBuildings, faCirclePlus, faMagnifyingGlass, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FC, FormEvent, useEffect, useState } from "react";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GroupCompanyErrorFormValues, GroupCompanyFormValues } from "./GroupCompanyModal/types";
import { GroupCompanyModal } from "./GroupCompanyModal/GroupCompanyModal";
import { GroupCompanyModel } from "app/models/01-SEG/GroupCompany/GroupCompanyModel";
import {
    hasPermission,
    hasPermissionToAdd,
    hasPermissionToDelete,
    hasPermissionToEdit,
} from "app/routes/HelperRoleBasedAccess";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { INITIAL_GROUP_COMPANY_ERROR_FORM_VALUES } from "../constants/groupCompanyConstants";
import { MobileCompanyGridBody } from "./Company/MobileCompanyGridBody/MobileCompanyGridBody";
import { PaginationDefaults, RegexPatterns, SecScreen } from "app/shared/Constants";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import {
    CompanyTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useNavigate } from "react-router-dom";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import CompanyWizard from "app/components_v2/wizards/Company/CompanyWizard";
import { Badge } from "app/components_v2/Badge/Badge";
import { getDateFormattedSelects } from "app/helpers";

type Props = {
    model: GroupCompanyModel;
    afterDelete: () => void;
    showOpened: boolean;
};

const INITIAL_SORT_FIELD = "name";
const INITIAL_SORT_DIRECTION: SortedTypeModel | undefined = "asc";

export const GroupCompanyCollapse: FC<Props> = ({ model, afterDelete, showOpened }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const nav = useNavigate();
    const { getErrorMessage } = useFetchErrors();

    const [group, setGroup] = useState<GroupCompanyModel>(model);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<GroupCompanyFormValues>({ name: model.name, nif: model.nif });
    const [groupErrorMessages, setGroupErrorMessages] = useState<GroupCompanyErrorFormValues>(
        INITIAL_GROUP_COMPANY_ERROR_FORM_VALUES
    );
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isOpen, setisOpen] = useState<boolean>(showOpened);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [wizardCompanyOpen, setWizardCompanyOpen] = useState(false);
    const [data, setData] = useState<CompanyModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const [sortField, setSortField] = useState<string>(INITIAL_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel | undefined>(INITIAL_SORT_DIRECTION);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const { name, id, isDeletable } = group;

    const columns: ColumnsType<CompanyModel>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "name",
            render: ({ logoFileURL, name }) => <CellPhoto name={name} img={logoFileURL} />,
            sortedType: "asc",
        },
        {
            label: t(TranslationKeys.LABEL_ADDRESS),
            dataIndex: "address",
            render: ({ address }) => <CellTitle title={address} />,
            sortedType: "default",
        },
        {
            label: t(CompanyTranslations.COMPANY_STATUS),
            dataIndex: "Status",
            render: ({ closeCompanies }) => {
                const isClosed =
                    closeCompanies?.length &&
                    closeCompanies[0].closeDate != null &&
                    getDateFormattedSelects(new Date(closeCompanies[0].closeDate)) ===
                        getDateFormattedSelects(new Date());
                return (
                    <Badge
                        title={isClosed ? t(TranslationCommon.CLOSED) : t(TranslationCommon.OPEN)}
                        variant={isClosed ? "red" : "green"}
                    />
                );
            },
            sortedType: "default",
        },
        {
            label: t(TranslationCommon.EDIT),
            dataIndex: "edit",
            hidden: !hasPermission(SecScreen.CENTERS_MANAGEMENT),
            className: "companyGrid__actions",
            render: (record) => (
                <CellIcons
                    icons={[{ icon: faPen, onClick: () => nav(`edit/${record.id}`), title: t(TranslationCommon.EDIT) }]}
                />
            ),
            alignCenter: true,
        },
    ];

    const handleInputChange = (values: Partial<GroupCompanyFormValues>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (values: Partial<GroupCompanyErrorFormValues>) => {
        setGroupErrorMessages((prev) => ({ ...prev, ...values }));
    };

    const onDelete = async (groupCompanyId: number) => {
        setIsDeleting(true);
        const delSr = await GroupCompanyService.Delete(groupCompanyId);

        if (delSr.status()) {
            handleToast({
                title: t(TranslationModals.SUCCESS_DELETE),
                type: "alert",
                variant: "success",
            });
            afterDelete();
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
        }
        setIsDeleting(false);
    };

    const confirmDelete = () => {
        isDeletable ? setShowConfirmModal(true) : setShowDeleteModal(true);
    };

    const getCompanies = async (pageOne?: boolean) => {
        setIsLoading(true);
        pageOne && setPageIndex(1);

        const extraparams = `GroupCompanyId=${id}`;

        const {
            data: companieData,
            error,
            status,
        } = await CompanyService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageOne ? 0 : pageIndex - 1,
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
        });

        firstLoad && setFirstLoad(false);

        if (!status()) {
            console.error(error);
            handleToast({ title: error, variant: "danger" });
            setIsLoading(false);
            return;
        }

        setData(companieData.list);
        setTotal(companieData.count);
        setIsLoading(false);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const extraparams = `GroupCompanyId=${id}`;

        return CompanyService.GetDataExport({
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
            ...params,
        });
    };

    const handleCleanFetch = () => {
        setQuery("");
        setShowSearchInput(false);
        getCompanies(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (validate()) return;

        setIsEditing(true);
        const groupEdited: GroupCompanyModel = {
            ...group,
            ...formValues,
        };
        const { status, getParsedError } = await GroupCompanyService.Edit(groupEdited, id);

        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), type: "alert", variant: "danger" });
            setIsEditing(false);
            setIsEditModalOpen(false);
            return;
        }

        setGroup((prev) => ({ ...prev, ...formValues }));
        setIsEditing(false);
        setIsEditModalOpen(false);
    };

    const validate = () => {
        let isErrored = false;
        handleErrorChange(INITIAL_GROUP_COMPANY_ERROR_FORM_VALUES);

        if (!formValues.name.length) {
            handleErrorChange({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isErrored = true;
        }
        if (!formValues.nif.length) {
            handleErrorChange({ errorNif: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isErrored = true;
        }

        if (!!formValues.nif.length && !RegexPatterns.nifAndNie.test(formValues.nif)) {
            handleErrorChange({ errorNif: t(TranslationCommon.INVALID_NIF) });
            isErrored = true;
        }

        return isErrored;
    };

    const onCloseEditModal = () => {
        setFormValues({ name: group.name, nif: group.nif });
        setIsEditModalOpen(false);
        setGroupErrorMessages(INITIAL_GROUP_COMPANY_ERROR_FORM_VALUES);
    };

    useEffect(() => {
        getCompanies();
    }, [pageIndex, sortField, sortDirection]);

    return (
        <div>
            {showDeleteModal && (
                <ConfirmModal
                    onConfirm={() => setShowDeleteModal(false)}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    title={t(TranslationCommon.ACTION_CANNOT_BE_COMPLETED)}
                    description={t(TranslationCommon.ENTITY_HAS_CHILDREN)
                        .replace("{0}", t(TranslationTitles.GROUP_COMPANY_TITLE))
                        .replace("{1}", t(TranslationKeys.COMPANIES))}
                />
            )}
            {showConfirmModal && (
                <ConfirmModal
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={() => onDelete(id)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={`${t(TranslationKeys.GROUP_COMPANY_DELETE_TITLE)} ${name}`}
                    isLoading={isDeleting}
                />
            )}
            {isEditModalOpen && (
                <GroupCompanyModal
                    values={formValues}
                    onSubmit={handleSubmit}
                    isLoading={isEditing}
                    errorMesages={groupErrorMessages}
                    onChange={handleInputChange}
                    onClose={onCloseEditModal}
                    isEdit={true}
                />
            )}
            {wizardCompanyOpen && (
                <GenericModal
                    header={{
                        title: t(TranslationKeys.NEW_COMPANY),
                        onClose: () => setWizardCompanyOpen(false),
                    }}
                    size="lg"
                    hideChildrenPadding
                >
                    <CompanyWizard
                        closeModal={() => {
                            setWizardCompanyOpen(false);
                            getCompanies(true);
                        }}
                        selectedCompanyGroup={{ value: String(id), label: name }}
                    />
                </GenericModal>
            )}
            <TableCollapsable
                title={name}
                onClickCollapsable={() => setisOpen(!isOpen)}
                actionButtons={[
                    {
                        icon: faCirclePlus,
                        onClick: () => setWizardCompanyOpen(true),
                        hidden: !hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT),
                        title: t(TranslationCommon.CREATE),
                    },
                    {
                        icon: faPen,
                        onClick: () => {
                            setIsEditModalOpen(true);
                        },
                        hidden: !hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT),
                        title: t(TranslationCommon.EDIT),
                    },
                    {
                        icon: faTrash,
                        onClick: confirmDelete,
                        hidden: !hasPermissionToDelete(SecScreen.CENTERS_MANAGEMENT),
                        title: t(TranslationCommon.DELETE),
                    },
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <TableInputText
                                    onChange={setQuery}
                                    value={query}
                                    fetch={() => getCompanies(true)}
                                    cleanFunction={handleCleanFetch}
                                />
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                ]}
                isOpen={isOpen}
                cols={columns}
                data={data}
                placeholder={<TableError icon={faBuildings} />}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={setPageIndex}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                    setSortField(sortFieldParam);
                    setSortDirection(sortedTypeParam);
                }}
                onClickExportCsv={handleExport}
                onDoubleClick={(record) => nav(`edit/${record.id}`)}
                mobileBody={(row) => <MobileCompanyGridBody key={row.id} company={row} />}
                fitHeight={(firstLoad && !isLoading) || (!firstLoad && total < 10)}
            />
        </div>
    );
};
