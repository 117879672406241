import { FC } from "react";
import { TaskPillComponentDesktopProps } from "../type";
import { TaskPillBadge } from "../TaskPillBadge/TaskPillBadge";
import { TaskPillPhoto } from "../../TaskPillPhoto/TaskPillPhoto";
import { TaskPillIcons } from "../TaskPillIcons/TaskPillIcons";
import { TaskReview } from "../../TaskReview/TaskReview";
import { TaskPillPercentage } from "../TaskPillPercentage/TaskPillPercentage";
import { TaskPillBodyDesktop } from "../TaskPillBodyDesktop/TaskPillBodyDesktop";

type TaskPillDesktopProps = TaskPillComponentDesktopProps;

export const TaskPillDesktop: FC<TaskPillDesktopProps> = ({
    badgetitle,
    borderStyle,
    comment,
    isPhotoExample,
    stars,
    state,
    title,
    user,
    asignedToWorkingPositionId,
    audioUrl,
    carrouselBadge,
    carrouselPhotos,
    clampComment,
    completedDate,
    date,
    description,
    disabled,
    displayOnly,
    editStarRating,
    hasComents,
    hasFields,
    hasPhoto,
    hideMobilePhotos,
    hidePhotos,
    isCritical,
    isDisabledRange,
    isMyFeedback,
    isSporadic,
    isSupervisor,
    nameInitials,
    oneColumn,
    showMagnifyGlass,
    starColor,
    starSize,
    startHour,
    taskInstanceCheckListCount,
    taskInstanceCheckListMaxCount,
    showTaskReview,
    onPillClick,
    onStarsChange,
    onSlideChange,
    onClickMagnifyGlass,
}) => {
    return (
        <div
            className={`taskPill${displayOnly ? "--displayOnly" : ""} ${oneColumn ? "oneColumn" : ""} ${
                disabled ? "disabled" : ""
            } taskPIllDsk__container  taskPill--${borderStyle}`}
            onClick={(e) => {
                e.stopPropagation();
                onPillClick && onPillClick();
            }}
        >
            {!hidePhotos && !!carrouselPhotos?.length && (
                <div
                    className={`taskPill__carrousel__wrapper${oneColumn ? "--oneColumn" : ""} ${
                        hideMobilePhotos ? "displayNoneMobile" : ""
                    }`}
                >
                    <div className="taskPill__carrousel taskPIllDsk__container__carrousel">
                        <TaskPillPhoto
                            taskInstancePhotos={carrouselPhotos}
                            onSlideChange={(index) => {
                                onSlideChange({
                                    image: carrouselPhotos[index],
                                    index,
                                });
                            }}
                            showZoom={showMagnifyGlass}
                            onClickMagnifyGlass={onClickMagnifyGlass}
                            isTaskDetail={oneColumn}
                            isPhotoExample={isPhotoExample}
                            badgeTitle={carrouselBadge}
                        />
                    </div>
                </div>
            )}
            <TaskPillBodyDesktop
                user={user}
                nameInitials={nameInitials}
                title={title}
                description={description}
                date={date}
                isDisabledRange={isDisabledRange}
                completedDate={completedDate}
                startHour={startHour}
            />

            <div className="taskPIllDsk__container__section--right">
                <TaskPillBadge title={badgetitle} state={state} disabled={disabled} isSporadic={isSporadic} />
                {showTaskReview && (
                    <div className="taskPill__section__review">
                        <TaskReview
                            onStarsChange={onStarsChange}
                            isSupervisor={isSupervisor}
                            stars={stars}
                            comment={comment}
                            starColor={starColor}
                            starSize={starSize}
                            audioUrl={audioUrl}
                            isMyFeedback={isMyFeedback}
                            editStarRating={editStarRating}
                            clampComment={clampComment}
                        />
                    </div>
                )}
                <div className="taskPill__section__container">
                    <div className="taskPill__section__container__line--dsk">
                        {!!taskInstanceCheckListMaxCount && (
                            <TaskPillPercentage
                                listMaxCount={taskInstanceCheckListMaxCount}
                                listCount={taskInstanceCheckListCount}
                            />
                        )}
                    </div>
                    <TaskPillIcons
                        hasComents={hasComents}
                        hasPhoto={hasPhoto}
                        hasFields={hasFields}
                        isCritical={isCritical}
                        asignedToWorkingPositionId={asignedToWorkingPositionId}
                    />
                </div>
            </div>
        </div>
    );
};
