import { useEffect, useState } from "react";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { ErrorConstants } from "app/shared/errorConstants";
import { AssetTypeModel } from "app/models/05-QUA/AssetModels/AssetTypeModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { getInitials } from "app/helpers/getInitials";
import AssetTypeService from "app/services/05-QUA/AssetTypeService";
import { AssetFieldInsertModel } from "app/models/05-QUA/AssetModels/AssetFieldModel";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { AssetModelFormValues } from "app/models/05-QUA/AssetModels";

type IUseAssetFormInfo = {
    isEditPage: boolean;
    onInputChange: (fields: Partial<AssetModelFormValues>) => void;
    assetFields: AssetFieldInsertModel[];
};

export const useAssetFormInfo = ({ assetFields, isEditPage, onInputChange }: IUseAssetFormInfo) => {
    const session = useSession();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { t } = useTranslation();

    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [iscompanyLoading, setIscompanyLoading] = useState<boolean>(true);

    const [assetTypeOptions, setAssetTypeOptions] = useState<AssetTypeModel[]>([]);
    const [isAssetTypeLoading, setIsAssetTypeLoading] = useState<boolean>(true);

    const [planAPPCCOptions, setPlanAPPCCOptions] = useState<OptionModel[]>([]);
    const [isPlanLoading, setIsPlanLoading] = useState<boolean>(true);

    const getCompany = async () => {
        setIscompanyLoading(true);
        const { status, data } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });

        if (!status()) {
            setCompanyOptions([]);
            handleToast({
                title: getErrorMessage(ErrorConstants.COMPANIES_FAILED_LOAD_DATA),
                type: "alert",
                variant: "danger",
            });
            setIscompanyLoading(false);
            return;
        }

        setCompanyOptions(data.map(({ label, value }) => ({ label, value, initials: getInitials(label) })));
        setIscompanyLoading(false);
    };

    const getAssetType = async () => {
        setIsAssetTypeLoading(true);
        const { data, status, getParsedError } = await AssetTypeService.GetList();
        if (!status()) {
            setAssetTypeOptions([]);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsAssetTypeLoading(false);
            return;
        }
        setAssetTypeOptions(data);
        setIsAssetTypeLoading(false);
    };

    const getPlans = async () => {
        setIsPlanLoading(true);
        const { data, status, getParsedError } = await QuaSelectorService.GetPlansAPPCC();
        if (!status()) {
            setAssetTypeOptions([]);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsPlanLoading(false);
            return;
        }
        setPlanAPPCCOptions(data);
        setIsPlanLoading(false);
    };

    const onChangeAssetType = ({ value }: OptionModel) => {
        const newAssetTypeId = Number(value);

        const selectedAssetType = assetTypeOptions.find(({ id }) => id === newAssetTypeId);

        if (!selectedAssetType) return;

        const { type, max, min, description } = selectedAssetType;
        const assetFieldToAdd: AssetFieldInsertModel = {
            assetDynamicField: type === "STRING" ? "TEXT" : "NUMBER",
            assetFieldRange: { fK_MeasurementUnit: 0, id: 0, max: max || 0, min: min || 0, unit: "" },
            label: t(description),
            id: v4(),
            isDeleted: false,
            dbId: 0,
        };

        onInputChange({
            fK_AssetType: newAssetTypeId,
            assetFields: !!assetFields.length
                ? assetFields.map((assetField, key) => {
                      const { label, assetFieldRange } = assetField;
                      if (
                          key === 0 &&
                          !isEditPage &&
                          assetTypeOptions.map(({ description }) => t(description)).includes(label) &&
                          assetFieldRange?.min &&
                          assetTypeOptions.map(({ min }) => min).includes(assetFieldRange?.min) &&
                          assetFieldRange?.max &&
                          assetTypeOptions.map(({ max }) => max).includes(max)
                      ) {
                          return {
                              ...assetField,
                              assetDynamicField: type === "STRING" ? "TEXT" : "NUMBER",
                              assetFieldRange: { fK_MeasurementUnit: 0, id: 0, max: max || 0, min: min || 0, unit: "" },
                              label: t(description),
                          };
                      }

                      return assetField;
                  })
                : [assetFieldToAdd],
        });
    };

    useEffect(() => {
        getCompany();
        getAssetType();
        getPlans();
    }, []);

    return {
        companyOptions,
        iscompanyLoading,
        assetTypeOptions,
        isAssetTypeLoading,
        planAPPCCOptions,
        isPlanLoading,
        onChangeAssetType,
    };
};
