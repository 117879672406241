import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import {
    AuditTranslation,
    BaseAuditGroupCheckListItemTypeTranslations,
    BaseTaskTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { getDayMonthFormatedWithYearNumbers } from "app/helpers";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { useTitle } from "app/hooks";
import { Tabs } from "app/components_v2/Tabs/Tabs";
import { useEditAudit } from "./hook/useEditAudit";
import { CrudHeaderProps } from "app/components_v2/Layout/CrudHeader/CrudHeader";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { faPrint, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { AuditReportFilter } from "app/components_v2/__filters/AuditReportFilter/AuditReportFilter";
import { INITIAL_AUDIT_REPORT_FILTER_DATA } from "./tabs/AuditReport/constants/AuditReportConstants";
import { TableActionButtons } from "app/components_v2/Table/TableActionButtons/TableActionButtons";
import { EditAuditHeader } from "./EditAuditHeader/EditAuditHeader";

export const EditAudit = () => {
    const { id: auditId } = useParams();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const actualStatus: AuditInstanceStatus = pathname.includes("completed")
        ? "COMPLETED"
        : pathname.includes("reviewed")
        ? "REVIEWED"
        : pathname.includes("closed")
        ? "CLOSED"
        : "IN_PROGRESS";

    useTitle(
        t(
            actualStatus === "IN_PROGRESS"
                ? TranslationTitles.RESOLVE_AUDIT_PAGE_TITLE
                : actualStatus === "COMPLETED"
                ? TranslationTitles.COMPLETED_AUDIT_PAGE_TITLE
                : actualStatus === "REVIEWED"
                ? TranslationTitles.REVIEWED_AUDIT_PAGE_TITLE
                : actualStatus === "CLOSED"
                ? TranslationTitles.REVIEW_AUDIT_PAGE_TITLE
                : TranslationTitles.NEW_AUDIT_PAGE_TITLE
        )
    );

    const {
        auditInstance,
        confirmAuditIssuesModalSubmit,
        confirmAuditStartModalSubmit,
        currentTab,
        editAuditHeaderData,
        filterData,
        footers,
        handleCloseAudit,
        handlePrintAuditReport,
        isAuditIssuesConfirmModalOpen,
        isAuditStartConfirmModalOpen,
        isCloseAuditModalOpen,
        isEditAuditHeaderLoading,
        isSFOpen,
        onSFChange,
        onSFOpen,
        renderAuditStars,
        setAuditIssuesIsConfirmModalOpen,
        setAuditStartIsConfirmModalOpen,
        setIsCloseAuditModalOpen,
        tabs,
    } = useEditAudit({
        auditInstanceId: Number(auditId),
        actualStatus,
    });

    const headerOptions: CrudHeaderProps = {
        type: "delete",
        customHeader: (
            <EditAuditHeader
                actualStatus={actualStatus}
                isEditAuditHeaderLoading={isEditAuditHeaderLoading}
                editAuditHeaderData={editAuditHeaderData}
                currentTab={currentTab}
                classificationLabel={filterData.baseAuditGroupCheckListItemTypeLabel}
                stars={renderAuditStars(auditInstance?.grade ?? null, "white")}
            />
        ),
        variant: "appcc",
        isLoading: isEditAuditHeaderLoading,
        isActive: actualStatus !== "IN_PROGRESS",
        tabsOptions: {
            tabs: tabs.some((tab) => !tab.hidden) ? (
                <>
                    <Tabs tabs={tabs} currentTab={currentTab} hideChildren />
                    {currentTab === 2 && (
                        <TableActionButtons
                            actionButtons={[
                                {
                                    icon: faSliders,
                                    onClick: () => onSFOpen(true),
                                    showMarkableIcon: !isEqual(INITIAL_AUDIT_REPORT_FILTER_DATA, filterData),
                                },
                            ]}
                        />
                    )}
                </>
            ) : undefined,
        },
        topNode: currentTab === 2 && (
            <NewButton
                text={t(TranslationCommon.PRINT)}
                handleClickButton={handlePrintAuditReport}
                iconLeft={faPrint}
                buttonType={"emptyWhite"}
            />
        ),

        leftNodeOneColumn: true,
    };

    return (
        <>
            {isAuditStartConfirmModalOpen && (
                <ConfirmModal
                    onConfirm={confirmAuditStartModalSubmit}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setAuditStartIsConfirmModalOpen(false)}
                    title={t(TranslationKeys.AUDIT_FINISH_CONFIRM_MODAL_TITLE)}
                    description={t(TranslationKeys.AUDIT_FINISH_CONFIRM_MODAL_SUBTITLE)}
                    portal
                    variant="appcc"
                />
            )}
            {isAuditIssuesConfirmModalOpen && (
                <ConfirmModal
                    onConfirm={confirmAuditIssuesModalSubmit}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setAuditIssuesIsConfirmModalOpen(false)}
                    title={t(AuditTranslation.AUDIT_FINISH_MODAL_TITLE)}
                    description={t(AuditTranslation.AUDIT_FINISH_MODAL_DESCRIPTION)}
                    portal
                    variant="appcc"
                />
            )}
            {isCloseAuditModalOpen && (
                <ConfirmModal
                    onConfirm={handleCloseAudit}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setIsCloseAuditModalOpen(false)}
                    title={t(AuditTranslation.AUDIT_CLOSE_MODAL_TITLE)}
                    description={t(AuditTranslation.AUDIT_CLOSE_MODAL_DESCRIPTION)}
                    portal
                    variant="appcc"
                />
            )}
            {isSFOpen && (
                <AuditReportFilter
                    isOpen={isSFOpen}
                    onCancel={() => onSFOpen(false)}
                    onSave={onSFChange}
                    initialFilterData={INITIAL_AUDIT_REPORT_FILTER_DATA}
                    filterData={filterData}
                />
            )}
            <FormLayout
                headerOptions={headerOptions}
                isLoading={false}
                footer={footers[currentTab] && footers[currentTab].show && footers[currentTab].footer}
            >
                {tabs[currentTab].children}
            </FormLayout>
        </>
    );
};
