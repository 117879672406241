import { useGoBack, useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useUserType } from "app/hooks/useUserType";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DashboardTotalCounters } from "../NewDashboard/NewDashboard";
import { RoundedFilterButtonModel } from "app/components_v2/RoundedFilterButtonGroup/types";
import { faCheck, faClock } from "@fortawesome/pro-regular-svg-icons";
import { DashboardFilterData } from "app/components_v2/__filters/DashboardFilter/types";
import { initialFilterState } from "../constants/DashboardResumeContext";
import { taskInstancePillExtraParams } from "app/helpers/ExtraParams/Dashboard/DashboardExtraparams";
import { DashboardService, TaskInstanceService } from "app/services";
import { PaginationDefaults, PrivatePaths } from "app/shared/Constants";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { DashboardFilterDataMapper } from "app/mappers/DashboardFilterDataMapper";
import { SporadicTaskExtraparams } from "app/helpers/ExtraParams/Dashboard/SporadicTaskExtraparams";
import { useDashboardUserInfo } from "./useDashboardUserInfo";

type IGetSporadicTask = {
    pi?: number;
};

type IGetTasks = {
    pi?: number;
    tab?: number;
    actualFilterButton?: number;
    sfData?: DashboardFilterData;
};

type IGetCounters = {
    actualFilterButton?: number;
    actualTab?: number;
    sfData?: DashboardFilterData;
    dashboadFocused?: boolean;
};

const time_to_refresh = 15 * 60 * 1000;

export const useDashbaord = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const location = useLocation();
    const session = useSession();
    const { realParentGoBack, goBackToNotificationPage, removeUrlFromPathnames } = useGoBack();
    const { handleToast } = useToast();
    const { isSupervisorOrAnalist } = useUserType();
    const [searchParams] = useSearchParams();

    const isSharedViewSelected = searchParams.get("view") ? 1 : 0;

    const [myTasks, setMyTasks] = useState<TaskInstanceModel[]>([]);
    const [myTasksCount, setMyTasksCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState(1);
    const [currentTab, setCurrentTab] = useState<number>(isSharedViewSelected);
    const [isSecondaryFilterOpen, setIsSecondaryFilterOpen] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [counters, setCounters] = useState<DashboardTotalCounters>({
        myTasks: 0,
        sharedTasks: 0,
        sporadicTasks: 0,
    });
    const [filterButtonCounters, setFilterButtonCounters] = useState({ pending: 0, completed: 0 });
    const [currentFilterButton, setCurrentFilterButton] = useState(1);
    const { fetchHeaderInfo, isUserInfoLoading, userInfo } = useDashboardUserInfo();

    const initFilterData: DashboardFilterData = {
        ...initialFilterState,
        company: `${session?.workingCompany?.companyId}`,
        user: session?.user.id !== undefined ? `${session?.user.id}` : "-1",
        department: isSupervisorOrAnalist
            ? "-1"
            : session?.workingDepartment?.departmentId !== undefined
            ? `${session?.workingDepartment?.departmentId}`
            : "-1",
        subdepartment: isSupervisorOrAnalist
            ? "-1"
            : session?.workingSubDepartment?.subdepartmentId !== undefined
            ? `${session?.workingSubDepartment?.subdepartmentId}`
            : "-1",
        myTasksUserId: session?.user.id,
        isAllChecked: false,
        isAssignedToQR: false,
    };
    const [filterData, setFilterData] = useState<DashboardFilterData>(initFilterData);

    const items: RoundedFilterButtonModel[] = [
        {
            icon: faClock,
            id: 1,
            number: filterButtonCounters.pending,
            title: t(TranslationKeys.PENDING),
        },
        {
            icon: faCheck,
            id: 2,
            number: filterButtonCounters.completed,
            title: t(TranslationKeys.DONE_TASKI),
        },
    ];

    const fetchCounters = async ({ actualFilterButton, actualTab, sfData }: IGetCounters = {}) => {
        const filterDataFetch = sfData || filterData;
        const userType = session?.user.userType || "WORKER";
        const filterButtonFetch = actualFilterButton ?? currentFilterButton;
        const tabFetch = actualTab ?? currentTab;
        const isDashboardFocus = isDashboardFocused(filterDataFetch);
        const extraParams = taskInstancePillExtraParams(filterDataFetch, "counters", false, userType);

        const { data, status, error } = await DashboardService.GetCounters({ extraParams });

        if (!status()) {
            console.error(error);
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setFirstLoad(false);
            return;
        }

        const { depCompleted, depPending, myCompleted, myPending, sporadicPending } = data;
        if (filterButtonFetch === 1)
            setCounters({
                myTasks: myPending,
                sharedTasks: depPending,
                sporadicTasks: sporadicPending,
            });
        else
            setCounters({
                myTasks: myCompleted,
                sharedTasks: depCompleted,
                sporadicTasks: sporadicPending,
            });
        setFilterButtonCounters({
            completed: isDashboardFocus ? (tabFetch === 0 ? myCompleted : depCompleted) : myCompleted + depCompleted,
            pending: isDashboardFocus ? (tabFetch === 0 ? myPending : depPending) : myPending + depPending,
        });
        setFirstLoad(false);
    };

    const getTasks = async ({ actualFilterButton, pi, tab, sfData }: IGetTasks = {}) => {
        setIsLoading(true);
        const filterDataFetch = sfData || filterData;
        let filterDataCopy = DashboardFilterDataMapper(filterDataFetch, actualFilterButton ?? currentFilterButton);

        if (
            filterDataFetch.user === "-1" &&
            filterDataFetch.department === "-1" &&
            filterDataFetch.subdepartment === "-1"
        ) {
            filterDataCopy.myTasksUserId = session?.user?.id;
        } else filterDataCopy.myTasksUserId = undefined;
        filterDataCopy.firstLoad = firstLoad;

        const userType = session?.user.userType || "WORKER";

        const piFetch = pi !== undefined ? pi : pageIndex - 1;
        const tabFetch = tab !== undefined ? tab : currentTab;

        const type = tabFetch === 1 ? "shared" : "myTask";
        const { data, status } = await TaskInstanceService.GetData({
            extraParams: taskInstancePillExtraParams(filterDataCopy, type, !!isSharedViewSelected, userType),
            pageIndex: piFetch,
            pageSize: PaginationDefaults.PAGE_SIZE,
            sortDirection: "asc",
        });

        if (!status()) {
            setIsLoading(false);
            setPageIndex(1);
            setMyTasks([]);
            return;
        }
        const exceedsPageLimit = data.count / PaginationDefaults.PAGE_SIZE <= piFetch;
        if (exceedsPageLimit) setPageIndex(1);

        setMyTasks(data.list);
        setMyTasksCount(data.count);
        if (firstLoad && !data.myTasks) onFirstTabIsEmpty(true);
        setIsLoading(false);
    };

    const getSporadicTasks = async ({ pi }: IGetSporadicTask = {}) => {
        setIsLoading(true);
        const companyId = String(session?.workingCompany?.companyId);
        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;
        const departmentId = session?.user.userType === "WORKER" ? session.workingDepartment?.departmentId : undefined;
        const extraParams = SporadicTaskExtraparams({ companyId, deadlineDate: new Date(), departmentId });

        const { data, status } = await DashboardService.GetSporadicTasks({
            extraParams,
            pageIndex: pageIndexFetch,
            pageSize: 10,
        });
        if (!status()) {
            setIsLoading(false);
            setPageIndex(1);
            setMyTasks([]);
            return;
        }

        const exceedsPageLimit = data.count / PaginationDefaults.PAGE_SIZE <= pageIndexFetch;
        if (exceedsPageLimit) setPageIndex(1);

        setMyTasks(data.list);
        setMyTasksCount(data.count);
        setIsLoading(false);
    };

    const isDashboardFocused = (sfData: DashboardFilterData) => {
        const filterDataDashboard = sfData || filterData;
        return (
            (filterDataDashboard.user !== String(session?.user.id) ||
                filterDataDashboard.department !== "-1" ||
                filterDataDashboard.subdepartment !== "-1") &&
            filterDataDashboard.isAllChecked &&
            isSupervisorOrAnalist
        );
    };

    const onFirstTabIsEmpty = (isShared: boolean) => {
        if (!isShared) return;
        setCurrentTab(1);
    };

    const onChangeFilterButton = (value: number) => {
        if (value === currentFilterButton || isLoading) return;

        const actualTab = currentTab === 1 ? 1 : 0;
        setCurrentFilterButton(value);
        fetchCounters({ actualFilterButton: value, actualTab });
        onChangePageIndex(1, actualTab, value);
        setCurrentTab(actualTab);
    };

    const onCompleteSporadicTask = async () => {
        nav(`/${PrivatePaths.DASHBOARD}${location.search}`);
        await getSporadicTasks();
        fetchCounters();
    };

    const onCloseTaskDetail = async () => {
        if (currentTab === 2) {
            onCompleteSporadicTask();
            return;
        }

        if (goBackToNotificationPage()) {
            removeUrlFromPathnames(location.pathname);
            return;
        }
        realParentGoBack();
        await getTasks();
        fetchCounters();
    };

    const onDetailClick = (id: number) => {
        if (currentTab == 2) {
            nav(`/${PrivatePaths.DASHBOARD}/sporadic/${id}${location.search}`);
            return;
        }
        nav(`/${PrivatePaths.DASHBOARD}/${id}${location.search}`);
    };

    const onChangePageIndex = async (pi: number, tab?: number, actualFilterButton?: number) => {
        const actualTab = tab ?? currentTab;
        setPageIndex(pi);
        if (actualTab === 2) {
            await getSporadicTasks({ pi: pi - 1 });
            fetchCounters({ actualFilterButton, actualTab });
            return;
        }

        await getTasks({ pi: pi - 1, actualFilterButton });
        fetchCounters({ actualFilterButton, actualTab });
    };

    const onGoBack = async () => {
        const value: DashboardFilterData = { ...filterData };
        // Reset filterData values
        value.user = session?.user.id ? String(session?.user.id) : "-1";
        value.department = "-1";
        value.subdepartment = "-1";
        value.isAllChecked = false;
        setFilterData(value);
        fetchHeaderInfo(value, currentTab);
        setPageIndex(1);
        await getTasks({ pi: 0, sfData: value });
        fetchCounters({ sfData: value });
    };

    const onFilterSave = async (value: DashboardFilterData) => {
        setFilterData(value);
        setIsSecondaryFilterOpen(false);
        fetchHeaderInfo(value, currentTab);

        setPageIndex(1);
        await getTasks({ pi: 0, sfData: value });
        fetchCounters({ sfData: value });
    };

    const onComplete = async () => {
        realParentGoBack();
        await getTasks();
        fetchCounters();
    };

    const onChangeTab = async (tabIndex: number) => {
        if (currentTab === tabIndex) return;
        nav("");
        setCurrentTab(tabIndex);
        setPageIndex(1);

        if (tabIndex === 0 || tabIndex === 1) {
            await getTasks({ pi: 0, tab: tabIndex });
            fetchCounters();
            return;
        }

        await getSporadicTasks({ pi: 0 });
        fetchCounters();
        return;
    };

    useEffect(() => {
        getTasks();
        fetchCounters();
        fetchHeaderInfo(filterData, currentTab);
    }, []);

    useEffect(() => {
        const value = filterData;
        if (currentTab === 0 && !value.isAllChecked) {
            value.user = value.user || session?.user.id ? String(session?.user.id) : "0";
        }
        setFilterData(value);
    }, [currentTab]);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (currentTab === 2) {
                await getSporadicTasks({ pi: pageIndex });
                fetchCounters();
                return;
            }
            await getTasks();
            fetchCounters();
        }, time_to_refresh);

        return () => clearInterval(intervalId);
    }, [pageIndex, currentTab]);

    return {
        dashboardListProps: {
            isLoading,
            filterData,
            getTasks,
            onDetailClick,
            myTasks,
            myTasksCount,
            onChangePageIndex,
            pageIndex,
            getSporadicTasks,
        },
        currentTab,
        isSecondaryFilterOpen,
        userInfo,
        counters,
        currentFilterButton,
        onChangeFilterButton,
        setIsSecondaryFilterOpen,
        isUserInfoLoading,
        onGoBack,
        onFilterSave,
        onComplete,
        items,
        filterData,
        isDashboardFocus: isDashboardFocused(filterData),
        initFilterData,
        onCompleteSporadicTask,
        onCloseTaskDetail,
        onChangeTab,
    };
};
