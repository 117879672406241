import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "app/components_v2/__inputs";
import { CustomerInstanceModel } from "app/models/01-SEG/CustomerInstance/CustomerInstanceModel";
import { TranslationKeys } from "app/translation/translationKeys";

type Props = {
    model: CustomerInstanceModel;
    onChange: (values: Partial<CustomerInstanceModel>) => void;
};

const LicenseForm: FC<Props> = ({ model, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className="assetForm__container">
            <Input
                value={model.name}
                onChange={(name) => onChange({ name })}
                label={t(TranslationKeys.INSTANCE_FORM_LABEL_NAME)}
                placeholder={t(TranslationKeys.INSTANCE_FORM_PLACEHOLDER_NAME)}
            />
            <Input
                value={model.contactName}
                onChange={(contactName) => onChange({ contactName })}
                label={t(TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_NAME)}
                placeholder={t(TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_NAME)}
            />
            <Input
                value={model.email}
                onChange={(email) => onChange({ email })}
                label={t(TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_EMAIL)}
                placeholder={t(TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_EMAIL)}
            />
            <Input
                value={model.phoneNumber}
                onChange={(phoneNumber) => onChange({ phoneNumber })}
                label={t(TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_PHONE)}
                placeholder={t(TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_PHONE)}
            />
        </div>
    );
};

export default LicenseForm;
