import { FC } from "react";
import { TaskPillMobileComponent } from "../type";
import { TaskPillAvatar } from "../TaskPillAvatar/TaskPillAvatar";
import { TaskPillBadge } from "../TaskPillBadge/TaskPillBadge";
import { TaskPillPhoto } from "../../TaskPillPhoto/TaskPillPhoto";
import { TaskPillDate } from "../TaskPillDate/TaskPillDate";
import { TaskPillIcons } from "../TaskPillIcons/TaskPillIcons";
import { TaskReview } from "../../TaskReview/TaskReview";
import { TaskPillPercentage } from "../TaskPillPercentage/TaskPillPercentage";

type TaskPillMobileProps = TaskPillMobileComponent;

export const TaskPillMobile: FC<TaskPillMobileProps> = ({
    displayOnly,
    oneColumn,
    disabled,
    borderStyle,
    hidePadding,
    title,
    description,
    carrouselPhotos,
    hidePhotos,
    hideMobilePhotos,
    showMagnifyGlass,
    isPhotoExample,
    carrouselBadge,
    showTaskPillDateOrIcons,
    taskType,
    completedDate,
    date,
    isDisabledRange,
    startHour,
    taskInstanceCheckListMaxCount,
    asignedToWorkingPositionId,
    hasComents,
    hasFields,
    hasPhoto,
    isCritical,
    taskInstanceCheckListCount,
    showTaskReview,
    comment,
    stars,
    audioUrl,
    clampComment,
    editStarRating,
    isMyFeedback,
    isSupervisor,
    starColor,
    starSize,
    user,
    nameInitials,
    badgetitle,
    state,
    isSporadic,
    onStarsChange,
    onPillClick,
    onSlideChange,
    onClickMagnifyGlass,
}) => {
    return (
        <>
            <div
                className={`taskPill${displayOnly ? "--displayOnly" : ""} ${oneColumn ? "oneColumn" : ""} ${
                    disabled ? "disabled" : ""
                } taskPill--${borderStyle} ${hidePadding ? "taskPill--hidePadding" : ""}`}
                onClick={(e) => {
                    e.stopPropagation();
                    onPillClick && onPillClick();
                }}
            >
                <div className="taskPill__section__wrapper">
                    <div className="taskPill__section">
                        <TaskPillAvatar user={user} nameInitials={nameInitials} />
                        <TaskPillBadge title={badgetitle} state={state} disabled={disabled} isSporadic={isSporadic} />
                    </div>
                    <div className="taskPill__section">
                        <p className="taskPill__section__title text_clamp">{title}</p>
                    </div>

                    {!!description?.length && <p className="taskPill__section__taskDescription">{description}</p>}
                    {!hidePhotos && carrouselPhotos && (
                        <div
                            className={`taskPill__carrousel__wrapper${oneColumn ? "--oneColumn" : ""} ${
                                hideMobilePhotos ? "displayNoneMobile" : ""
                            }`}
                        >
                            <div className="taskPill__carrousel taskPill__mobile__img">
                                <TaskPillPhoto
                                    taskInstancePhotos={carrouselPhotos}
                                    onSlideChange={(index) => {
                                        onSlideChange({
                                            image: carrouselPhotos[index],
                                            index,
                                        });
                                    }}
                                    showZoom={showMagnifyGlass}
                                    onClickMagnifyGlass={onClickMagnifyGlass}
                                    isTaskDetail={oneColumn}
                                    isPhotoExample={isPhotoExample}
                                    badgeTitle={carrouselBadge}
                                />
                            </div>
                        </div>
                    )}

                    {showTaskPillDateOrIcons && (
                        <div className="taskPill__section--last">
                            {taskType !== "SPORADIC" && (
                                <TaskPillDate
                                    isDisabledRange={isDisabledRange}
                                    date={date}
                                    completedDate={completedDate}
                                    startHour={startHour}
                                />
                            )}
                            {!taskInstanceCheckListMaxCount && (
                                <TaskPillIcons
                                    hasComents={hasComents}
                                    hasPhoto={hasPhoto}
                                    hasFields={hasFields}
                                    isCritical={isCritical}
                                    asignedToWorkingPositionId={asignedToWorkingPositionId}
                                />
                            )}
                        </div>
                    )}
                    {!!taskInstanceCheckListMaxCount && (
                        <div className="taskPill__section__container">
                            <div className="taskPill__section__container__line">
                                <TaskPillPercentage
                                    listMaxCount={taskInstanceCheckListMaxCount}
                                    listCount={taskInstanceCheckListCount}
                                />
                            </div>

                            <TaskPillIcons
                                hasComents={hasComents}
                                hasPhoto={hasPhoto}
                                hasFields={hasFields}
                                isCritical={isCritical}
                                asignedToWorkingPositionId={asignedToWorkingPositionId}
                            />
                        </div>
                    )}
                </div>

                {showTaskReview && (
                    <div className="taskPill__section__review">
                        <TaskReview
                            onStarsChange={onStarsChange}
                            isSupervisor={isSupervisor}
                            stars={stars}
                            comment={comment}
                            starColor={starColor}
                            starSize={starSize}
                            audioUrl={audioUrl}
                            isMyFeedback={isMyFeedback}
                            editStarRating={editStarRating}
                            clampComment={clampComment}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
