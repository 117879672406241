import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { CompanyWizardModel } from "app/models/01-SEG/Company/CompanyWizardModel";
import { GroupCompanyModel } from "app/models/01-SEG/GroupCompany/GroupCompanyModel";
import { GroupCompanyService } from "app/services";
import { TranslationErrors, TranslationModals } from "app/translation/translationKeys";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ERROR_INITIAL_VALUES, Step1ErrorsModel } from "../types";
import { OptionModel } from "app/models/02-TAR/OptionModel";

export const useStep1 = (data: CompanyWizardModel, next: () => void) => {
    const session = useSession();
    const { handleToast } = useToast();
    const { t } = useTranslation();

    const [groupCompany, setGroupCompany] = useState<OptionModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<Step1ErrorsModel>(ERROR_INITIAL_VALUES);

    const getCompanyTypeAndGroups = async () => {
        setLoading(true);
        const { status, data } = await GroupCompanyService.GetData({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}`,
        });

        if (!status()) {
            handleToast({
                title: t(TranslationModals.TOAST_ERROR_COMPANY_GROUP),
                type: "alert",
                variant: "danger",
            });
            return Promise.reject(t(TranslationModals.TOAST_ERROR_COMPANY_GROUP));
        }

        setGroupCompany(
            data.list.map(({ name, id }: GroupCompanyModel) => ({
                label: name,
                value: String(id),
            }))
        );
        setLoading(false);
    };

    useEffect(() => {
        getCompanyTypeAndGroups();
    }, []);

    const onChangeErrors = (values: Partial<Step1ErrorsModel>) => setErrors((prev) => ({ ...prev, ...values }));

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let valid = true;

        if (!data.company.name.length) {
            onChangeErrors({ nameError: t(TranslationErrors.WIZARD_COMPANY_ERROR_NAME) });
            valid = false;
        }

        if (valid) next();
    };

    return {
        groupCompany,
        loading,
        errors,
        setErrors,
        validate,
    };
};
