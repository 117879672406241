import { v4 } from "uuid";
import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";
import { PatchNoteFormValues } from "../models/PatchNoteFormValues";

export const mapPatchNoteToFormValues = ({
    id,
    name,
    title,
    customerInstances,
    patchNotes,
}: PatchNoteVersionModel): PatchNoteFormValues => ({
    id,
    name,
    title,
    patchNotes: patchNotes.map(({ id, fK_PatchNoteVersion, note }) => ({
        dbId: id,
        id: v4(),
        fK_PatchNoteVersion,
        note,
    })),
    customerInstances,
});

export const mapFormValuesToPatchNote = ({
    id,
    name,
    title,
    patchNotes,
    customerInstances,
}: PatchNoteFormValues): PatchNoteVersionModel => ({
    id,
    name,
    title,
    patchNotes: patchNotes.map(({ dbId, fK_PatchNoteVersion, note }) => ({ id: dbId, fK_PatchNoteVersion, note })),
    customerInstances,
    publishDate: new Date(),
    customerInstanceCount: 0,
});
