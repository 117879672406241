import { AssetModelFormValues } from "app/models/05-QUA/AssetModels";
import { TranslationCommon, TranslationErrors } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { AssetFormValidationsModel, ErrorDynamicAssetModel } from "../models/AssetFormModel";

type IValidateAsset = {
    validations: AssetFormValidationsModel;
    data: AssetModelFormValues;
};

type IValidationReturn = {
    validations: AssetFormValidationsModel;
    isValid: boolean;
};

export const useAssetValidate = () => {
    const { t } = useTranslation();

    const validate = ({ data, validations }: IValidateAsset): IValidationReturn => {
        let check = true;
        const aux = { ...validations };

        const {
            name,
            fK_AssetType,
            fK_Company,
            assetFields,
            fK_PlanAPPCC,
            allowSensorSerialNumber,
            sensorSerialNumber,
        } = data;

        aux.errorName = "";

        if (!name.length) {
            aux.errorName = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        aux.errorCompany = "";
        if (!fK_Company) {
            aux.errorCompany = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        aux.errorType = "";
        if (!fK_AssetType) {
            aux.errorType = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        // aux.errorDynamicAsset.errorMessage = "";
        const assetFiltersToValidate = assetFields.filter((x) => !x.isDeleted);
        // if (!assetFiltersToValidate.length) {
        //     aux.errorDynamicAsset.errorMessage = t(TranslationCommon.INPUT_NOT_EMPTY);
        //     check = false;
        // }

        aux.errorPlanAPPCC = "";
        if (!fK_PlanAPPCC) {
            aux.errorPlanAPPCC = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        aux.errorDynamicAsset.dynamicAssets = [];
        assetFiltersToValidate.forEach(({ assetDynamicField, label, assetFieldRange, id }) => {
            const error: ErrorDynamicAssetModel = {
                errorLabel: "",
                errorMax: "",
                errorMin: "",
                errorUnit: "",
                id,
            };
            let checkAssetFields = true;

            if (!label.length) {
                error.errorLabel = t(TranslationCommon.INPUT_NOT_EMPTY);
                checkAssetFields = false;
            }

            if (assetDynamicField === "TEXT") {
                if (checkAssetFields) return;
                aux.errorDynamicAsset.dynamicAssets.push(error);
                check = false;
                return;
            }

            if (!assetFieldRange) {
                aux.errorDynamicAsset.dynamicAssets.push(error);
                check = false;
                return;
            }

            const { unit, max, min } = assetFieldRange;
            if (min === undefined) {
                error.errorMin = t(TranslationCommon.INPUT_NOT_EMPTY);
                checkAssetFields = false;
            }

            if (max === undefined) {
                error.errorMax = t(TranslationCommon.INPUT_NOT_EMPTY);
                checkAssetFields = false;
            }

            if (!unit?.length) {
                error.errorUnit = t(TranslationCommon.INPUT_NOT_EMPTY);
                checkAssetFields = false;
            }

            if (!!min && !!max && min > max) {
                error.errorMin = t(TranslationErrors.ASSET_MIN_TEMP_BIGGER_THAN_MAX);
                checkAssetFields = false;
            }

            if (!checkAssetFields) {
                aux.errorDynamicAsset.dynamicAssets.push(error);
                check = false;
            }
        });

        aux.errorSerialNumber = "";
        if (allowSensorSerialNumber && (!sensorSerialNumber.length || sensorSerialNumber.length !== 8)) {
            aux.errorSerialNumber = t(TranslationErrors.ASSET_MIN_MAX_LENGTH_SENSOR_SERIAL_NUMBER);
            check = false;
        }

        if (!check) return { isValid: false, validations: aux };

        return { isValid: true, validations: aux };
    };

    return { validate };
};
