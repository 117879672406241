import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { FC } from "react";
import { TableTabHeaderActions } from "../TableTabHeaderActions/TableTabHeaderActions";
import { ActionButtonsModel } from "../TableTabHeader/TableTabHeader";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type TableTabOneRowHeaderProps = {
    title?: string;
    tabs: TabPropsReduced[];
    currentTab?: number;
    actionButtons?: ActionButtonsModel[];
    onClickCollapsable?: () => void;
    isOpen?: boolean;
    isLoading?: boolean;
    variant?: TabHeaderVariants;
};
export const TableTabOneRowHeader: FC<TableTabOneRowHeaderProps> = ({
    tabs,
    currentTab,
    actionButtons,
    onClickCollapsable,
    isOpen = true,
    isLoading = false,
    variant = "primary",
}) => {
    const { width } = useWindowSize();
    return (
        <div
            className={`tableTabOneRowHeader tableTabOneRowHeader--${variant}`}
            onClick={(e) => {
                e.stopPropagation();
                onClickCollapsable && onClickCollapsable();
            }}
        >
            <Tabs
                tabs={tabs}
                currentTab={currentTab}
                hideChildren
                isLoading={isLoading}
                height={width >= screenSize.TABLET ? 50 : 40}
            />
            <TableTabHeaderActions
                actionButtons={actionButtons}
                onClickCollapsable={onClickCollapsable}
                oneRow={!actionButtons}
                isOpen={isOpen}
            />
        </div>
    );
};
