import { OnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingModel";
import { PrivatePaths } from "app/shared/Constants";

export const ON_BOARDING_STEPS_PATHS: Record<string, string> = {
    ONBOARDING_CREATE_COMPANY: `/${PrivatePaths.CENTERS_MANAGEMENT}`,
    ONBOARDING_CREATE_ROLES: `/${PrivatePaths.ROLE_MANAGEMENT}`,
    ONBOARDING_CREATE_USERS: `/${PrivatePaths.USER_MANAGEMENT}`,
    ONBOARDING_ASSIGN_USERS_TO_TEAMS: `/${PrivatePaths.CENTERS_MANAGEMENT}`,
    ONBOARDING_CREATE_PLANS: `/${PrivatePaths.PLAN_APPCC}`,
    ONBOARDING_CREATE_CORRECTIVE_MEASURES: `/${PrivatePaths.CORR_MEASURE_MANAGER}`,
    ONBOARDING_CREATE_ASSETS: `/${PrivatePaths.ASSETS}`,
    ONBOARDING_CREATE_BASETASK: `/${PrivatePaths.BASE_TASK_MANAGEMENT}`,
    ONBOARDING_CREATE_APPCC_BASETASK: `/${PrivatePaths.BASE_TASK_APPCC_MANAGEMENT}`,
    ONBOARDING_CREATE_QR: `/${PrivatePaths.QR}`,
    ONBOARDING_CREATE_TASK: `/${PrivatePaths.TASK_PAGE}`,
    ONBOARDING_CREATE_APPCC_TASK: `/${PrivatePaths.REGISTRY_MANAGEMENT}`,
    ONBOARDING_CREATE_BASE_AUDIT_ITEMS: `/${PrivatePaths.BASE_AUDIT_GROUP_CHECKLIST_ITEM}`,
    ONBOARDING_CREATE_AUDIT_TEMPLATE: `/${PrivatePaths.AUDIT_MANAGEMENT}`,
};

export const ON_BOARDING_STATUS: Record<OnBoardingStatus, OnBoardingStatus> = {
    BLOCKED: "BLOCKED",
    DONE: "DONE",
    IN_PROGRESS: "IN_PROGRESS",
    REVIEW: "REVIEW",
};
