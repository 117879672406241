import { getDateFormatted } from "../Date.utilities";
import { DateRange } from "app/models/utilities";

export const hasOverlappingRanges = (closeCompanies: DateRange[]): boolean => {
    // First make all dates have same structure and sort by closeDate
    const closeCompaniesDates = closeCompanies
        .map(({ "0": closeDate, "1": openDate }) => {
            if (!closeDate || !openDate) return;
            return {
                closeDate: getDateFormatted(new Date(closeDate)),
                openDate: getDateFormatted(new Date(openDate)),
            };
        })
        .sort((a, b) => (!!a && !!b ? new Date(a.closeDate).getTime() - new Date(b.closeDate).getTime() : 0));

    // Check overlapment
    for (let i = 0; i < closeCompaniesDates.length - 1; i++) {
        const currentRange = closeCompaniesDates[i];
        const nextRange = closeCompaniesDates[i + 1];

        // If has overlapment
        if (!!currentRange?.openDate && !!nextRange?.closeDate && currentRange.openDate >= nextRange.closeDate)
            return true;
    }

    return false;
};
