import { FC } from "react";
import { RecurringTaskCheckbox } from "../RecurringTaskCheckbox";
import {
    CustomMonthlyOption2,
    RecurrentTaskModel,
    RecurrentTaskOption2,
    Step4,
    taskEnds,
} from "app/models/02-TAR/TaskWizard";
import { SelectRecurringTask } from "../SelectRecurringTask";
import { CustomRepeatEvery } from "../CustomRepeatEvery";
import { CustomDaysBubles } from "../CustomDaysBubles";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { CustomSelectOptionsEveryMonth } from "../CustomSelectOptionsEveryMonth";
import { Label } from "app/components_v2/__inputs";
import { TaskEndsNever } from "../TaskEndsNever";
import { TaskEndsSpecificDay } from "../TaskEndsSpecificDay";
import { TaskEndsTimes } from "../TaskEndsTimes";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type Validations = {
    customReps: string;
    customDaysBubles: string;
    customMonth: string;
    finishEl: string;
    finishResp: string;
};

type TaskRecurringProps = {
    taskStart: string;
    recurringTask: RecurrentTaskModel;
    recurentTaskSelectorValues: RecurrentTaskOption2[];
    validations: Validations;
    customRepeatEveryOptions: OptionsSearch[];
    weekOptions: CustomMonthlyOption2[];
    finish: taskEnds;
    onChange: (fields: Partial<Step4>) => void;
};

export const TaskRecurring: FC<TaskRecurringProps> = ({
    taskStart,
    recurringTask,
    recurentTaskSelectorValues,
    validations,
    customRepeatEveryOptions,
    weekOptions,
    finish,
    onChange,
}) => {
    const { t } = useTranslation();
    return (
        <>
            {taskStart !== "" && (
                <>
                    <RecurringTaskCheckbox onChange={onChange} recurringTask={recurringTask} taskStart={taskStart} />

                    {recurringTask.isRecurrent && (
                        <SelectRecurringTask
                            onChange={onChange}
                            recurentTaskSelectorValues={recurentTaskSelectorValues}
                            recurringTask={recurringTask}
                        />
                    )}
                </>
            )}

            {recurringTask && recurringTask.isRecurrent && (
                <>
                    {recurringTask.value === "custom" && recurringTask.custom && (
                        <>
                            <div className="step_four_custom_select">
                                <CustomRepeatEvery
                                    recurringTask={recurringTask}
                                    onChange={onChange}
                                    customError={validations.customReps}
                                    isWizardMode
                                    className="step_four_custom_select__inputNumber"
                                    options={customRepeatEveryOptions}
                                />
                                {recurringTask.custom.customValue === "week" && (
                                    <>
                                        <div className="wizard_custom_day_bubles__wrapper">
                                            {recurringTask.custom.days.map(({ isActive, value, id }, i) => (
                                                <CustomDaysBubles
                                                    recurringTask={recurringTask}
                                                    isActive={isActive}
                                                    onChange={onChange}
                                                    value={value}
                                                    key={id}
                                                    id={id}
                                                />
                                            ))}
                                        </div>
                                        <>
                                            {validations.customDaysBubles &&
                                                validations.customDaysBubles.length !== 0 && (
                                                    <ErrorMessage errorMessage={validations.customDaysBubles} />
                                                )}
                                        </>
                                    </>
                                )}
                            </div>

                            {recurringTask.custom.customValue === "everyMonth" && (
                                <CustomSelectOptionsEveryMonth
                                    recurringTask={recurringTask}
                                    onChange={onChange}
                                    weekOptions={weekOptions}
                                    customError={validations.customMonth}
                                />
                            )}
                        </>
                    )}
                </>
            )}
            {taskStart !== "" && recurringTask && recurringTask.isRecurrent && (
                <div className="stepFourRadioButtons__wrapper">
                    <Label label={t(TranslationKeys.TASK_ENDS)} />
                    <TaskEndsNever finish={finish} onChange={onChange} />
                    <TaskEndsSpecificDay
                        finish={finish}
                        onChange={onChange}
                        customError={validations.finishEl}
                        min={taskStart}
                    />
                    <TaskEndsTimes finish={finish} onChange={onChange} customError={validations.finishResp} />
                </div>
            )}
        </>
    );
};
