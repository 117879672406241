import { useEffectAfterFirstRender } from "app/hooks/useEffectAfterFirstRender";
import BaseTaskService from "app/services/02-TAR/BaseTaskService";
import { useState } from "react";
import { useDebounceValue } from "usehooks-ts";

type IuseInputTaskData = {
    hasPlanAPPCC: boolean;
    onChange: (inputValue: string) => void;
    value: string;
    id?: number;
};

export const useInputTaskData = ({ onChange, value, hasPlanAPPCC, id }: IuseInputTaskData) => {
    const [options, setOptions] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBaseTaskPopoverVisible, setIsBaseTaskPopoverVisible] = useState<boolean>(false);

    const [debouncedValue, setDebounceValue] = useDebounceValue("", 300);

    const onChangeInputValue = (inputValue: string) => {
        if (!inputValue.length) setOptions([]);
        else setIsLoading(true);

        setDebounceValue(inputValue);
        onChange(inputValue);
        setIsBaseTaskPopoverVisible(!!inputValue.length);
    };

    const onFocusInputValue = () => {
        if (!debouncedValue.length) return;
        setIsLoading(true);
        setIsBaseTaskPopoverVisible(true);
        getOptions();
    };

    const resetState = () => {
        setOptions([]);
        setIsBaseTaskPopoverVisible(false);
        setIsLoading(false);
    };
    const getOptions = async () => {
        if (!value.length) return;
        setIsLoading(true);

        const tabName = hasPlanAPPCC ? "APPCC" : "NO_APPCC";
        const extraParams = new URLSearchParams();
        extraParams.append("tab", tabName);
        if (!!id) extraParams.append("id", String(id));

        const { data, status } = await BaseTaskService.GetTitles({
            pageIndex: 0,
            pageSize: 5,
            query: value,
            extraParams: extraParams.toString(),
        });

        if (!status()) {
            resetState();
            return;
        }

        setOptions(data.list);
        setIsBaseTaskPopoverVisible(!!data.list.length);
        setIsLoading(false);
    };

    useEffectAfterFirstRender(() => {
        if (!value.length) {
            resetState();
            return;
        }

        getOptions();
    }, [debouncedValue]);

    return {
        baseTaskOptions: options,
        isBaseTaskPopoverLoading: isLoading,
        onChangeInputValue,
        isBaseTaskPopoverVisible,
        setIsBaseTaskPopoverVisible,
        onFocusInputValue,
    };
};
