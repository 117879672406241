import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { OptionsSearch } from "app/models/FormComponentsModel";
import CustomerInstanceWorkingPositionService from "app/services/02-TAR/CustomerInstanceWorkingPositionService";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import WorkingPositionService from "app/services/02-TAR/WorkingPositionService";
import { PrivatePaths } from "app/shared/Constants";
import { ErrorConstants } from "app/shared/errorConstants";
import { TranslationCommon, TranslationModals } from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { WorkingPositionFormValuesModel } from "../types";
import { useValidate } from "./useValidate";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { getInitials } from "app/helpers/getInitials";

const INITIAL_FORM_VALUES: WorkingPositionFormValuesModel = {
    fK_QrCode: "",
    name: "",
    companyId: -1,
};

export const useWorkingPosition = () => {
    const { t } = useTranslation();

    const { handleToast } = useToast();
    const { workingPositionId } = useParams();
    const isEditPage = !!workingPositionId;
    const nav = useNavigate();
    const session = useSession();
    const { getErrorMessage } = useFetchErrors();

    const [formvalues, setFormvalues] = useState<WorkingPositionFormValuesModel>(INITIAL_FORM_VALUES);
    const { fK_QrCode, name, description, companyId } = formvalues;
    const [formValuesCopy, setFormValuesCopy] = useState<WorkingPositionFormValuesModel>(INITIAL_FORM_VALUES);
    const [workingPositionOptions, setWorkingPositionOptions] = useState<OptionModel[]>([]);

    const { errorFormValues, validate } = useValidate(formvalues);

    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [iscompanyLoading, setIscompanyLoading] = useState<boolean>(true);

    const [isLoading, setIsLoading] = useState<boolean>(isEditPage);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleInputChange = (values: Partial<WorkingPositionFormValuesModel>) =>
        setFormvalues((prev) => ({ ...prev, ...values }));

    const getWorkingPosition = async () => {
        if (!workingPositionId) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        const { data, status } = await WorkingPositionService.GetOne(workingPositionId);

        if (!status()) {
            setFormvalues(INITIAL_FORM_VALUES);
            setFormValuesCopy(INITIAL_FORM_VALUES);
            setIsLoading(false);

            handleToast({
                title: t(TranslationCommon.FAILED_DATA_LOADED),
                type: "alert",
                variant: "danger",
            });
            nav(PrivatePaths.QR);
            return;
        }

        setFormvalues({
            ...data,
            description: data.description || "",
            fK_QrCode: data.customerInstanceWorkingPosition?.id ? String(data.customerInstanceWorkingPosition?.id) : "",
        });

        setFormValuesCopy({
            ...data,
            description: data.description || "",
            fK_QrCode: data.customerInstanceWorkingPosition?.id ? String(data.customerInstanceWorkingPosition?.id) : "",
        });

        if (data.fK_QrCode)
            setWorkingPositionOptions((prev) => [
                ...prev,
                {
                    label: String(data.customerInstanceWorkingPosition?.qrCode),
                    value: String(data.customerInstanceWorkingPosition?.id),
                },
            ]);
        setIsActive(data.isDeleted !== undefined ? !data.isDeleted : true);
        setIsLoading(false);
    };

    const getCompany = async () => {
        setIscompanyLoading(true);
        const { status, data } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });

        if (!status()) {
            setCompanyOptions([]);
            handleToast({
                title: getErrorMessage(ErrorConstants.COMPANIES_FAILED_LOAD_DATA),
                type: "alert",
                variant: "danger",
            });
            setIscompanyLoading(false);
            return;
        }

        setCompanyOptions(data.map(({ label, value, profilePictureURL }) => ({ label, value, initials: getInitials(label), profilePictureURL })));
        setIscompanyLoading(false);
    };
    const getWorkingPositionOptions = async () => {
        const { data, status } = await CustomerInstanceWorkingPositionService.GetWorkingPositionsFromCustomerInstance(
            session?.user.customerInstanceId || 0,
            false
        );
        if (!status()) {
            setWorkingPositionOptions([]);
            handleToast({
                title: getErrorMessage(ErrorConstants.WORKING_POSITION_FAILED_LOAD_DATA),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setWorkingPositionOptions((prev) => [...prev, ...data.list]);
    };

    const createWorkingPosition = async () => {
        setIsSaving(true);
        const { status, getParsedError } = await WorkingPositionService.Save({
            fK_QrCode: Number(fK_QrCode),
            id: 0,
            name,
            description: description ?? null,
            companyId,
        });

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsSaving(false);
            return;
        }
        setIsSaving(false);
        handleToast({
            title: t(TranslationModals.SUCCESS_SAVE),
            type: "alert",
            variant: "success",
        });
        nav(PrivatePaths.QR);
    };

    const editWorkingPosition = async (id: number) => {
        setIsSaving(true);
        const { status, getParsedError } = await WorkingPositionService.Edit({
            fK_QrCode: Number(fK_QrCode),
            id: id,
            name,
            description: description ?? null,
            companyId,
        });

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsSaving(false);
            return;
        }
        setIsSaving(false);
        handleToast({
            title: t(TranslationModals.SUCCESS_EDIT),
            type: "alert",
            variant: "success",
        });
        setFormValuesCopy(formvalues);
    };

    const handleSubmit = async () => {
        if (validate()) return;
        if (isEditPage && workingPositionId) {
            await editWorkingPosition(Number(workingPositionId));
            return;
        }
        await createWorkingPosition();
    };

    useEffect(() => {
        if (isEditPage) getWorkingPosition();
        getCompany();
        getWorkingPositionOptions();
    }, []);

    return {
        formvalues,
        formValuesCopy,
        isLoading,
        isActive,
        handleInputChange,
        setIsActive,
        errorFormValues,
        isSaving,
        handleSubmit,
        companyOptions,
        iscompanyLoading,
        workingPositionOptions,
    };
};
