import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TaskTranslations } from "app/translation/translationKeys";
import { TaskStart } from "./components";
import { CustomErrorTaskTemporalityModel, EditDataModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TaskTemporalityRecurrent } from "./components/TaskTemporalityRecurrent";
import { Switch } from "app/components_v2/__inputs";
import { days } from "../../utilities/initialValues";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";

type TaskTemporalityProps = EditDataModel<TaskTemporalityModel, CustomErrorTaskTemporalityModel> & { min: Date };

export const TaskTemporality: FC<TaskTemporalityProps> = ({
    onChange,
    taskStart,
    taskHours,
    recurringTask,
    finish,
    customError,
    isDisabled,
    taskType,
    min,
    variant = "primary",
}) => {
    const { t } = useTranslation();

    const handleTaskTypeChange = (isChecked: boolean) => {
        if (!isChecked) {
            onChange({ taskType: "NORMAL" });
            return;
        }
        onChange({
            taskType: "SPORADIC",
            taskHours: [],
            finish: {
                checked: "never",
                value: null,
            },
            recurringTask: {
                isRecurrent: false,
                options: [],
                value: "",
                custom: {
                    customValue: "day",
                    days,
                    repeatEvery: 1,
                    selectedOptions: { text: "", value: "" },
                },
                text: "",
            },
        });

        return;
    };

    return (
        <div className="taskTemporality">
            <WhiteBoxCollapsable
                whiteBoxOptions={{ fullWidth: true }}
                collapsableOptions={{ title: t(TaskTranslations.TASK_DATE_TITLE), border: "none", variant }}
            >
                <div className="taskTemporality__container">
                    <div className="taskTemporality__taskStart">
                        <TaskStart
                            onChange={onChange}
                            taskStart={taskStart}
                            customError={customError.taskStart}
                            disabled={isDisabled}
                            min={min}
                        />
                    </div>
                    {taskType !== "AUDIT" && (
                        <Switch
                            checked={taskType === "SPORADIC"}
                            onChange={handleTaskTypeChange}
                            label={t(TaskTranslations.IS_TASK_SPORADIC)}
                            disabled={isDisabled}
                        />
                    )}
                    <TaskTemporalityRecurrent
                        customError={customError}
                        finish={finish}
                        taskStart={taskStart}
                        taskHours={taskHours}
                        recurringTask={recurringTask}
                        onChange={onChange}
                        isDisabled={isDisabled}
                        taskType={taskType}
                    />
                </div>
            </WhiteBoxCollapsable>
        </div>
    );
};
