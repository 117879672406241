import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";
import { PatchNoteVersionErrorsModel } from "../models/PatchNoteVersionGridModel";

export const INITIAL_PATCH_NOTE: PatchNoteVersionModel = {
    id: 0,
    name: "",
    title: "",
    publishDate: new Date(),
    patchNotes: [],
    customerInstances: [],
    customerInstanceCount: 0,
};

export const INITIAL_PATCH_NOTE_ERRORS: PatchNoteVersionErrorsModel = {
    errorName: "",
    errorTitle: "",
    errorPatchNotes: "",
    errorCustomerInstances: "",
};
