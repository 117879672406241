import { v4 } from "uuid";
import { BaseTaskFieldModel } from "app/models/02-TAR/BaseTask/BaseTaskFieldModel";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { AllSteps } from "app/models/02-TAR/TaskWizard";

export const baseTaskToAllSteps = (task: AllSteps, baseTask: BaseTaskModel): AllSteps => {
    const {
        name,
        description,
        fotoExample,
        isPhotoRequired,
        isCritical,
        fotoExampleId,
        baseTaskCheckList,
        baseTaskFields,
        id,
        reportType,
    } = baseTask;
    const taskFieldsValidated: BaseTaskFieldModel[] = baseTaskFields || [];

    const hasChecklist = !!baseTaskCheckList?.length && reportType === "CHECKLIST";

    return {
        ...task,
        taskTitle: name ?? task.taskTitle,
        taskDesc: description ?? task.taskDesc,
        somethingToReportCheckBoxImage: fotoExample && fotoExample.length !== 0 ? true : false,
        somethingToReportImage: fotoExample && fotoExample.length !== 0 ? fotoExample : "",
        fotoExampleId: fotoExampleId ? Number(fotoExampleId) : undefined,
        isPhotoRequired,
        criticalTask: isCritical,
        fK_BaseTask: id,
        fK_PlanAPPCC: baseTask.fK_PlanAPPCC !== undefined ? String(baseTask.fK_PlanAPPCC) : "-1",
        checkList: hasChecklist
            ? baseTaskCheckList.map(({ id, name, isDeleted }) => ({
                  id: v4(),
                  dbId: Number(id),
                  name,
                  isDeleted,
              }))
            : [],
        reportType,
        dynamicFields: taskFieldsValidated.map(
            ({ dynamicFieldType, id, isRequired, label, baseTaskFieldOptions, isDeleted }) => ({
                dbId: id,
                dynamicFieldsType: dynamicFieldType || "DATE",
                id: v4(),
                isDeleted,
                isRequired: !!isRequired,
                label: label || "",
                taskFieldOptions:
                    baseTaskFieldOptions?.map((taskfield) => ({
                        label: taskfield.label,
                        id: v4(),
                    })) || [],
            })
        ),
    };
};
