import { SortedTypeModel } from "app/components_v2/Table/types";
import { TaskHisotryGridSfModel } from "../state/context/taskHistoryGridContext";

export const INITIAL_TH_SF_VALUES: TaskHisotryGridSfModel = {
    userId: "-1",
    departmentId: "-1",
    subDepartmentId: "-1",
    taskType: "-1",
    pending: false,
    nearEnd: false,
    timedOut: false,
    inTime: false,
    outOfTime: false,
    isAssignedToQR: false,
    oneStar: false,
    twoStar: false,
    threeStar: false,
    fourStar: false,
    fiveStar: false,
    isCritical: false,
    isDataReportRequired: false,
    isPhotoRequired: false,
    startDate: null,
    endDate: null,
    hasComments: false,
    taskId: "",
};

export const INITIAL_TH_SORT_DIRECTION: SortedTypeModel = "desc";
export const INITIAL_TH_SORT_FIELD: string = "DeadlineDate";
