import { useContext } from "react";
import { CompanyService } from "app/services";
import { CloseCompanyModalContext } from "app/state/context/CloseCompanyModalContext/CloseCompanyModalContext";

export const useCloseCompaniesAndDepartmentsModal = () => {
    const { open, reset } = useContext(CloseCompanyModalContext);

    const getClosedOpenCompaniesAndDepartments = async () => {
        const { data, status } = await CompanyService.GetClosedCompaniesAndDepartments();
        if (
            status() &&
            (!!data.closedCompanies.length ||
                !!data.openCompanies.length ||
                !!data.closedDepartments.length ||
                !!data.closedDepartments.length)
        ) {
            open(data);
            return;
        }
        reset();
    };

    return { getClosedOpenCompaniesAndDepartments };
};
