import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { FC, ReactNode } from "react";
import { screenSize } from "app/shared/Constants";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { TableActionButtons } from "app/components_v2/Table/TableActionButtons/TableActionButtons";
import { TaskHisotryGridSfModel } from "../../../state/context/taskHistoryGridContext";
import { TaskHistoryFilter } from "app/components_v2/__filters/TaskHistoryFilter/TaskHistoryFilter";
import { useWindowSize } from "usehooks-ts";

type TaskHistoryHeaderProps = {
    title: string;
    secondayFilter: {
        filterData: TaskHisotryGridSfModel;
        initialFilterData: TaskHisotryGridSfModel;
        showSecondaryFilter: boolean;
        onCancel?: () => void;
        onSave?: (values: TaskHisotryGridSfModel) => void;
        companyId: string;
    };
    actionButtons: ActionButtonsModel[];
    tabs?: ReactNode;
};

export const TaskHistoryHeader: FC<TaskHistoryHeaderProps> = ({ title, secondayFilter, actionButtons, tabs }) => {
    const { width } = useWindowSize();

    return (
        <>
            <TabHeader
                paddingSize="sm"
                classNameToScrollTop=".customContainer"
                variant="primary"
                tabs={tabs}
                tabHeight={width >= screenSize.MOBILE ? 50 : 40}
            >
                <div className="taskHistoryHeader">
                    <h1 className="taskHistoryHeader__title">{title}</h1>
                    {width < screenSize.MOBILE && (
                        <div className="taskHistoryHeader__icons">
                            <TableActionButtons actionButtons={actionButtons} />
                        </div>
                    )}
                </div>
            </TabHeader>
            <TaskHistoryFilter {...secondayFilter} />
        </>
    );
};
