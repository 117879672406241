import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { Switch } from "app/components_v2/__inputs";
import { Step4, RecurrentTaskModel } from "app/models/02-TAR/TaskWizard";

type RecurringTaskCheckboxProps = {
    onChange: (fields: Partial<Step4>) => void;
    recurringTask: RecurrentTaskModel | null;
    taskStart: string;
    disabled?: boolean;
    inverted?: boolean;
};

export const RecurringTaskCheckbox: FC<RecurringTaskCheckboxProps> = ({
    onChange,
    recurringTask,
    taskStart,
    disabled,
    inverted,
}) => {
    const { t } = useTranslation();

    if (!recurringTask) return null;

    return (
        <Switch
            checked={recurringTask.isRecurrent}
            onChange={(checked) =>
                onChange({
                    recurringTask: {
                        ...recurringTask,
                        isRecurrent: checked,
                        value: "everyDay",
                        text: `${t(TranslationKeys.EVERY_DAY)}`,
                    },
                    finish: {
                        checked: "never",
                        value: null,
                    },
                })
            }
            label={t(TranslationKeys.RECURRENT_TASK_CHECKBOX)}
            disabled={!disabled ? taskStart === "" : true}
            inverted={inverted}
        />
    );
};
