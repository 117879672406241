import { BaseTaskModelPaginationParams } from "app/models/02-TAR/PaginationParamsModel";

export const fillExtraparams = ({ isAppcc, taskType }: Partial<BaseTaskModelPaginationParams>) => {
    const extraparams = new URLSearchParams();
    extraparams.append("IsDeleted", "false");
    if (isAppcc != null) extraparams.append("IsAppcc", String(isAppcc));
    if (taskType != null && taskType !== "-1") extraparams.append("TaskType", String(taskType));

    return extraparams.toString();
};
