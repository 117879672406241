import { faArrowAltCircleDown, faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { Section } from "../Section/Section";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { FC, useState } from "react";
import { ButtonGroupSection } from "../ButtonGroupSection/ButtonGroupSection";
import { Button } from "app/components_v2/__buttons/Button/Button";

type Props = {
    show?: boolean;
};

export const ButtonsSection: FC<Props> = ({ show }) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    return (
        <Section title="Buttons" show={show}>
            <h1>New Buttons</h1>
            <h2>Primary</h2>
            <h3>Contained</h3>
            <>
                <Button text="Hola que tal" />
                <Button text="Hola que tal" iconLeft={faStarSharp} />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} />
                <Button iconRight={faArrowAltCircleDown} /> <Button iconRight={faArrowAltCircleDown} isLoading />
                <Button text="Hola que tal" iconLeft={faStarSharp} isLoading />
            </>
            <h3>Secondary</h3>
            <>
                <Button text="Hola que tal" type="secondary" />
                <Button text="Hola que tal" iconLeft={faStarSharp} type="secondary" />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button iconRight={faArrowAltCircleDown} type="secondary" />{" "}
                <Button iconRight={faArrowAltCircleDown} type="secondary" isLoading />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" isLoading />
            </>
            <h3>Tertiary</h3>
            <>
                <Button text="Hola que tal" type="tertiary" />
                <Button text="Hola que tal" iconLeft={faStarSharp} type="tertiary" />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button iconRight={faArrowAltCircleDown} type="tertiary" />{" "}
                <Button iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
            </>
            <h3>Link</h3>
            <>
                <Button text="Hola que tal" type="link" />
                <Button text="Hola que tal" iconLeft={faStarSharp} type="link" />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" />
                <Button iconRight={faArrowAltCircleDown} type="link" />{" "}
                <Button iconRight={faArrowAltCircleDown} type="link" isLoading />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" isLoading />
            </>
            <h2>Danger</h2>
            <h3>Contained</h3>
            <>
                <Button variant="danger" text="Hola que tal" />
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} />
                <Button variant="danger" iconRight={faArrowAltCircleDown} />
                <Button variant="danger" iconRight={faArrowAltCircleDown} isLoading />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} isLoading />
            </>
            <h3>Secondary</h3>
            <>
                <Button variant="danger" text="Hola que tal" type="secondary" />
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} type="secondary" />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="secondary" isLoading />
                <Button
                    variant="danger"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="secondary"
                    isLoading
                />
            </>
            <h3>Tertiary</h3>
            <>
                <Button variant="danger" text="Hola que tal" type="tertiary" />
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
                <Button
                    variant="danger"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="tertiary"
                    isLoading
                />
            </>
            <h3>Link</h3>
            <>
                <Button variant="danger" text="Hola que tal" type="link" />
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} type="link" />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="link" isLoading />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" isLoading />
            </>
            <h2>Purple</h2>
            <h3>Contained</h3>
            <>
                <Button variant="purple" text="Hola que tal" />
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} />
                <Button variant="purple" iconRight={faArrowAltCircleDown} />
                <Button variant="purple" iconRight={faArrowAltCircleDown} isLoading />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} isLoading />
            </>
            <h3>Secondary</h3>
            <>
                <Button variant="purple" text="Hola que tal" type="secondary" />
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} type="secondary" />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="secondary" isLoading />
                <Button
                    variant="purple"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="secondary"
                    isLoading
                />
            </>
            <h3>Tertiary</h3>
            <>
                <Button variant="purple" text="Hola que tal" type="tertiary" />
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
                <Button
                    variant="purple"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="tertiary"
                    isLoading
                />
            </>
            <h3>Link</h3>
            <>
                <Button variant="purple" text="Hola que tal" type="link" />
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} type="link" />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="link" isLoading />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" isLoading />
            </>
            <h3>Disabled</h3>
            <>
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="contained" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="secondary" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="link" isDisabled />
            </>
            <h2>appcc</h2>
            <h3>Contained</h3>
            <>
                <Button variant="appcc" text="Hola que tal" />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} isLoading />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} isLoading />
            </>
            <h3>Secondary</h3>
            <>
                <Button variant="appcc" text="Hola que tal" type="secondary" />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="secondary" />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="secondary" isLoading />
                <Button
                    variant="appcc"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="secondary"
                    isLoading
                />
            </>
            <h3>Tertiary</h3>
            <>
                <Button variant="appcc" text="Hola que tal" type="tertiary" />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
                <Button
                    variant="appcc"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="tertiary"
                    isLoading
                />
            </>
            <h3>Link</h3>
            <>
                <Button variant="appcc" text="Hola que tal" type="link" />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="link" />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="link" isLoading />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" isLoading />
            </>
            <h3>Disabled</h3>
            <>
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="contained" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="secondary" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="link" isDisabled />
            </>
            <>
                <p className="designSys__section__element__title">Default button</p>
                <NewButton
                    text="Label"
                    iconLeft={faStarSharp}
                    iconRight={faArrowAltCircleDown}
                    handleClickButton={() => console.log("click")}
                />
                <NewButton
                    handleClickButton={() => console.log("click")}
                    iconRight={faArrowAltCircleDown}
                    onlyIcon={true}
                />
            </>
            <>
                <p className="designSys__section__element__title">Danger button</p>
                <NewButton
                    handleClickButton={() => console.log("click")}
                    text="Label"
                    iconLeft={faStarSharp}
                    iconRight={faArrowAltCircleDown}
                    danger={true}
                />
                <NewButton
                    handleClickButton={() => console.log("click")}
                    danger={true}
                    iconRight={faArrowAltCircleDown}
                    onlyIcon={true}
                />
            </>
            <>
                <p className="designSys__section__element__title">SecondaryButton </p>
                <NewButton
                    handleClickButton={() => console.log("click")}
                    text="Label"
                    iconLeft={faStarSharp}
                    iconRight={faArrowAltCircleDown}
                    buttonType={"secondary"}
                />
                <NewButton
                    handleClickButton={() => console.log("click")}
                    iconRight={faArrowAltCircleDown}
                    onlyIcon={true}
                    buttonType={"secondary"}
                />
            </>
            <>
                <p className="designSys__section__element__title">SecondaryButton Danger:</p>
                <NewButton
                    handleClickButton={() => console.log("click")}
                    text="un botón largo"
                    iconLeft={faStarSharp}
                    iconRight={faArrowAltCircleDown}
                    buttonType={"secondary"}
                    danger={true}
                />
                <NewButton
                    handleClickButton={() => console.log("click")}
                    danger={true}
                    iconRight={faArrowAltCircleDown}
                    onlyIcon={true}
                    buttonType={"secondary"}
                />
            </>
            <>
                <p className="designSys__section__element__title">TertiaryButton:</p>
                <NewButton
                    handleClickButton={() => console.log("click")}
                    text="un botón largo"
                    iconLeft={faStarSharp}
                    iconRight={faArrowAltCircleDown}
                    buttonType={"tertiary"}
                />
                <NewButton
                    handleClickButton={() => console.log("click")}
                    iconRight={faArrowAltCircleDown}
                    onlyIcon={true}
                    buttonType={"tertiary"}
                />
            </>
            <>
                <p className="designSys__section__element__title">TertiaryButton Danger:</p>
                <NewButton
                    handleClickButton={() => console.log("click")}
                    text="un botón largo"
                    iconLeft={faStarSharp}
                    iconRight={faArrowAltCircleDown}
                    buttonType={"tertiary"}
                    danger={true}
                />
                <NewButton
                    handleClickButton={() => console.log("click")}
                    danger={true}
                    iconRight={faArrowAltCircleDown}
                    onlyIcon={true}
                    buttonType={"tertiary"}
                />
            </>
            <>
                <p className="designSys__section__element__title">Disabled button:</p>
                <NewButton text="Label" iconLeft={faStarSharp} iconRight={faArrowAltCircleDown} disabled={true} />
                <NewButton danger={true} iconRight={faArrowAltCircleDown} disabled={true} onlyIcon={true} />
            </>
            <>
                <p className="designSys__section__element__title">Loading button:</p>
                <NewButton text="Loading" loading={buttonLoading} handleClickButton={() => setButtonLoading(true)} />
                <button
                    onClick={() => {
                        setButtonLoading(false);
                    }}
                >
                    click
                </button>
                <NewButton
                    onlyIcon={true}
                    iconRight={faArrowAltCircleDown}
                    loading={buttonLoading}
                    handleClickButton={() => setButtonLoading(true)}
                />
            </>
            <>
                <p className="designSys__section__element__title">Empty button:</p>
                <NewButton
                    handleClickButton={() => console.log("click")}
                    text="Emtpy button"
                    iconLeft={faStarSharp}
                    iconRight={faArrowAltCircleDown}
                    buttonType={"empty"}
                    danger={false}
                />

                <NewButton
                    handleClickButton={() => console.log("click")}
                    danger={false}
                    iconRight={faArrowAltCircleDown}
                    onlyIcon={true}
                    buttonType={"empty"}
                />
            </>
            <>
                <p className="designSys__section__element__title">Empty button white:</p>

                <div style={{ backgroundColor: "red" }}>
                    <NewButton
                        handleClickButton={() => console.log("click")}
                        text="Emtpy button white"
                        iconLeft={faStarSharp}
                        iconRight={faArrowAltCircleDown}
                        buttonType={"emptyWhite"}
                        danger={false}
                    />
                </div>
            </>
            <>
                <p className="designSys__section__element__title">Empty button danger:</p>
                <NewButton
                    handleClickButton={() => console.log("click")}
                    text="Emtpy button"
                    iconLeft={faStarSharp}
                    iconRight={faArrowAltCircleDown}
                    buttonType={"empty"}
                    danger={true}
                />

                <NewButton
                    handleClickButton={() => console.log("click")}
                    danger={true}
                    iconRight={faArrowAltCircleDown}
                    onlyIcon={true}
                    buttonType={"empty"}
                />
            </>
            <>
                <p className="designSys__section__element__title">Empty button disabled:</p>
                <NewButton
                    handleClickButton={() => console.log("click")}
                    text="Emtpy button"
                    iconLeft={faStarSharp}
                    iconRight={faArrowAltCircleDown}
                    buttonType={"empty"}
                    danger={false}
                    disabled
                />

                <NewButton
                    handleClickButton={() => console.log("click")}
                    danger={false}
                    disabled
                    iconRight={faArrowAltCircleDown}
                    onlyIcon={true}
                    buttonType={"empty"}
                />
            </>
            <ButtonGroupSection show />
        </Section>
    );
};
