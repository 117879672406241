import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { OnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingModel";
import { OnBoardingStepsTranslation, TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { OnBoardingModalType } from "../../types";
import { getOnBoardingStatusInfo } from "../../helpers/getOnBoardingStatusInfo";

type OnBoardingStatusModalProps = {
    name: string;
    status: OnBoardingStatus;
    type: OnBoardingModalType;
    onComplete: () => void;
};

export const OnBoardingStatusModal: FC<OnBoardingStatusModalProps> = ({ name, status, onComplete, type }) => {
    const { t } = useTranslation();

    const { title } = getOnBoardingStatusInfo(status);

    const stepDescription: Record<OnBoardingStatus, string> = {
        BLOCKED: t(OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_BLOCKED),
        DONE: t(OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_DONE),
        IN_PROGRESS: t(OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_IN_PROGRESS),
        REVIEW: t(OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_REVIEW),
    };
    const subStepDescription: Record<OnBoardingStatus, string> = {
        BLOCKED: t(OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_BLOCKED),
        DONE: t(OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_DONE),
        IN_PROGRESS: t(OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_IN_PROGRESS),
        REVIEW: t(OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_REVIEW),
    };

    return (
        <ConfirmModal
            onConfirm={onComplete}
            onConfirmText={t(TranslationKeys.CLOSE)}
            title={`${t(name)} - ${t(title)}`}
            description={type === "step" ? stepDescription[status] : subStepDescription[status]}
        />
    );
};
