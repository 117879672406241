import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys, CloseCompanyTranslations } from "app/translation/translationKeys";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { CloseCompanyModel } from "app/models/01-SEG/CloseCompany/CloseCompanyModel";
import { Label, Switch } from "app/components_v2/__inputs";
import { CloseRangeDates } from "app/components_v2/CloseRangeDates/CloseRangeDates";

type CloseCompaniesProps = {
    closeCompany?: boolean;
    closeCompanies: CloseCompanyModel[] | null;
    onSwitchClick: (switchValue: boolean) => void;
    onClosingDates: (dates: Date[] | undefined[], index: number) => void;
    onAddNewRangeHour: () => void;
    onDeleteNewRangeHour: (index: number) => void;
    errorMessage: string;
};

export const CloseCompanyDates: FC<CloseCompaniesProps> = ({
    closeCompany,
    closeCompanies,
    onSwitchClick,
    onClosingDates,
    onAddNewRangeHour,
    onDeleteNewRangeHour,
    errorMessage,
}) => {
    const { t } = useTranslation();

    return (
        <div className="closeCompanyDates">
            <div className="closeCompanyDates__dates">
                <div className="closeCompanyDates__dates__switch">
                    <Switch
                        onChange={onSwitchClick}
                        checked={!!closeCompany}
                        disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                    />
                    <Label
                        label={`${t(CloseCompanyTranslations.CLOSE_COMPANY)} (${t(TranslationKeys.INPUT_OPTIONAL)})`}
                    />
                </div>
                {closeCompany && (
                    <CloseRangeDates
                        closeDates={closeCompanies?.map(({ closeDate, openDate }) => [closeDate, openDate])}
                        onClosingDates={onClosingDates}
                        onAddNewRangeHour={onAddNewRangeHour}
                        onDeleteNewRangeHour={onDeleteNewRangeHour}
                        errorMessage={errorMessage}
                    />
                )}
            </div>
        </div>
    );
};
