import { DistributionListFilterType } from "../../models/EmailDistributionListGridModels";
import {
    EmailDistributionListErrors,
    EmailDistributionListFormValues,
} from "../../models/EmailDistributionListFormValues";
import {
    EmailDistributionListTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
} from "app/translation/translationKeys";
import { FC, FormEvent, useEffect, useState } from "react";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { getInitials } from "app/helpers/getInitials";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import {
    INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS,
    INITIAL_EMAIL_DISTRIBUTION_LIST_FORM_VALUES,
} from "../../constants/EmailDistributionListConstants";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionsOutsideSelect, SelectOptions } from "app/components_v2/__inputs";
import { SecScreen } from "app/shared/Constants";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import Spinner from "app/components_v2/Spinner/Spinner";

type CreateEmailDistributionListUsersModalProps = {
    title: string;
    onClose: () => void;
    onSubmit: (values: EmailDistributionListFormValues) => Promise<void>;
};

export const CreateEmailDistributionListUsersModal: FC<CreateEmailDistributionListUsersModalProps> = ({
    title,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const { handleErrorManager } = useErrorManager();
    const { getErrorMessage } = useFetchErrors();

    const [usersOptions, setUsersOptions] = useState<OptionModel[]>([]);
    const [distributionListTypes, setDistributionListTypes] = useState<OptionModel[]>([]);
    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    const [formValues, setFormValues] = useState<EmailDistributionListFormValues>(
        INITIAL_EMAIL_DISTRIBUTION_LIST_FORM_VALUES
    );
    const [emailDistributionListErrors, setEmailDistributionListErrors] = useState<EmailDistributionListErrors>(
        INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS
    );

    const { errorFunctionality, errorCompany, errorUserList } = emailDistributionListErrors;
    const { companyList, fK_DistributionList, userList } = formValues;

    const handleInputChange = (values: Partial<EmailDistributionListFormValues>) => {
        setEmailDistributionListErrors(INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS);
        setFormValues((prev) => ({ ...prev, ...values }));
    };
    const handleErrorChange = (values: Partial<EmailDistributionListErrors>) => {
        setEmailDistributionListErrors((prev) => ({ ...prev, ...values }));
    };

    const distributionListFilter: Record<number, DistributionListFilterType> = {
        1: "SporadicTasksPermission",
        2: "AuditReportPermission",
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate()) return;
        setIsLoadingSave(true);
        await onSubmit(formValues);
        setIsLoadingSave(false);
    };

    const validate = () => {
        let isValid = true;
        setEmailDistributionListErrors(INITIAL_EMAIL_DISTRIBUTION_LIST_ERRORS);

        if (!fK_DistributionList.length) {
            handleErrorChange({ errorFunctionality: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }

        if (!companyList.length && fK_DistributionList !== "3") {
            handleErrorChange({ errorCompany: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }

        if (!userList.length) {
            handleErrorChange({ errorUserList: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }

        return isValid;
    };

    const getDistributionListTypes = async () => {
        const { data, status, getParsedError } = await SegSelectorService.GetDistributionListTypes();

        const distributionErrorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), distributionErrorMessage)) return;

        if (!data.length) {
            setDistributionListTypes([]);
            return;
        }
        setDistributionListTypes(data.map((option) => ({ ...option, label: t(option.label) })));
    };

    const getUsers = async (companiesId: string, distributionListId: number) => {
        const supervisorParams = new URLSearchParams();
        if (session?.user.id) supervisorParams.append("userId", session?.user.id.toString());
        supervisorParams.append("includeMyself", "true");

        if (!!companiesId.length) supervisorParams.append("CompaniesId", companiesId);

        if (distributionListId == 1 || distributionListId == 2)
            supervisorParams.append(distributionListFilter[distributionListId], "true");

        const supervisorAndAnalistUsersPromise = SegSelectorService.GetDistributionListUsers({
            extraParams: supervisorParams.toString(),
        });

        return supervisorAndAnalistUsersPromise;
    };

    const handleChangeDistributionList = async ({ value }: OptionModel) => {
        setIsLoading(true);
        handleInputChange({ fK_DistributionList: value });

        if (value === "3") {
            await handleChangeCompany([], Number(value));
            setIsLoading(false);
            return;
        }

        const companyOptionsPromise = SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `supervisorId=${session?.user.id}`,
        });

        const { data, status, getParsedError } = await companyOptionsPromise;
        const companyErrorMessage = getErrorMessage(getParsedError());
        setFormValues((prev) => ({ ...prev, userList: [], companyList: [] }));
        if (handleErrorManager(status(), companyErrorMessage)) return;

        setCompanyOptions(
            data.map(({ label, value, profilePictureURL }) => ({
                label,
                value,
                profilePictureURL,
                initials: getInitials(label),
            }))
        );
        setIsLoading(false);
    };

    const handleChangeCompany = async (values: OptionModel[], distributionListId: number) => {
        const companiesId = values.map(({ value }) => value).join(";");
        handleInputChange({ companyList: values });
        const { data, status } = await getUsers(companiesId, distributionListId);
        if (!status()) {
            setUsersOptions([]);
            setIsLoading(false);
            return;
        }

        handleInputChange({ userList: userList.filter(({ value }) => data.some((x) => x.value === value)) });
        setUsersOptions(data);
    };

    const handleDeleteComapny = async (id: string) => {
        const selectedCompany = companyList.find(({ value }) => value === id);

        if (!selectedCompany) return;

        if (companyList.length === 1) {
            handleInputChange({ companyList: [], userList: [] });
            return;
        }

        await handleChangeCompany(
            companyList.filter(({ value }) => value !== id),
            Number(fK_DistributionList)
        );
    };

    const handleDelteUser = (id: string) => {
        const { userList } = formValues;
        const selectedItem = userList.find(({ value }) => value === id);
        if (!selectedItem) return;

        handleInputChange({ userList: userList.filter(({ value }) => value !== id) });
    };

    useEffect(() => {
        getDistributionListTypes();
    }, []);

    const SelectUserList: FC = () => (
        <OptionsOutsideSelect
            onChange={(values) => handleInputChange({ userList: values })}
            options={usersOptions}
            onDeleteItem={handleDelteUser}
            values={userList}
            hidden={!hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST)}
            placeholder={t(TranslationKeys.SELECT_USERS)}
            label={t(TranslationKeys.USERS)}
            isLoading={isLoading}
            isMulti
            errorMessage={errorUserList}
            autoInitials
        />
    );

    return (
        <GenericModal
            header={{ title, onClose }}
            size="md"
            footer={
                hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST) && (
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.SAVE),
                            form: "emailDistributionListUsers",
                            type: "submit",
                            disabled: !distributionListTypes.length,
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            buttonType: "tertiary",
                            onClick: onClose,
                        }}
                        loading={isLoadingSave}
                    />
                )
            }
        >
            <form onSubmit={handleSubmit} className="addUsersModal" id="emailDistributionListUsers">
                <div className="emailDistributionList__modal">
                    <SelectOptions
                        isMulti={false}
                        onChange={handleChangeDistributionList}
                        options={distributionListTypes}
                        label={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_TYPE_LABEL)}
                        selectedValue={fK_DistributionList}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                        isLoading={isLoading}
                        errorMessage={errorFunctionality}
                    />
                    {!!fK_DistributionList.length &&
                        fK_DistributionList !== "3" &&
                        (isLoading ? (
                            <div className="emailDistributionList__modal__spinner">
                                <Spinner />
                            </div>
                        ) : (
                            <OptionsOutsideSelect
                                onChange={(values) => handleChangeCompany(values, Number(fK_DistributionList))}
                                options={companyOptions}
                                label={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_COMPANY_LABEL)}
                                values={companyList}
                                placeholder={t(TranslationCommon.SELECT_OPTION)}
                                isLoading={isLoading}
                                errorMessage={errorCompany}
                                onDeleteItem={handleDeleteComapny}
                            />
                        ))}

                    {(!!formValues.companyList.length || fK_DistributionList === "3") && <SelectUserList />}
                </div>
            </form>
        </GenericModal>
    );
};
