import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { DateRangePicker, Input, OptionsOutsideSelect, SelectOptions, Switch } from "app/components_v2/__inputs";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { useTitle } from "app/hooks";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import {
    NewUserTabsTranslation,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UserInfoJobDescriptionModal } from "./Components/UserInfoFormModals/UserInfoJobDescriptionModal";
import { useJobDescriptionUserInfoForm } from "./hooks/useJobDescriptionUserInfoForm";
import { useUserInfoForm } from "./hooks/useUserInfoForm";
import { ErrorUserPageFormValues, UserPageFormValues } from "./types";
import { Label } from "app/components_v2/__inputs/Label";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { OptionModel } from "app/models/02-TAR/OptionModel";

export type UserInfoFormProps = {
    userFormValues: UserPageFormValues;
    activeScreensOptions: OptionModel[];
    roleOptions: OptionModel[];
    companyOptions: OptionModel[];
    isEditable?: boolean;
    errorFormValues: ErrorUserPageFormValues;
    isActive: boolean;
    userId?: string;
    onInputChange: (values: Partial<UserPageFormValues>) => void;
    onSubmit: () => void;
    onRoleChange: (rolesId: number[]) => void;
};

const MIN_ACTIVATION_DATE = new Date();
MIN_ACTIVATION_DATE.setDate(MIN_ACTIVATION_DATE.getDate() + 1);

export const UserInfoForm: FC<UserInfoFormProps> = ({
    userFormValues,
    isEditable,
    activeScreensOptions,
    roleOptions,
    companyOptions,
    errorFormValues,
    isActive,
    userId,
    onInputChange,
    onSubmit,
    onRoleChange,
}) => {
    const { t } = useTranslation();
    useTitle(t(userId !== undefined ? TranslationTitles.EDIT_USER_PAGE_TITLE : TranslationTitles.NEW_USER_PAGE_TITLE));

    const {
        userCode,
        email,
        initialScreen,
        lastName,
        name,
        username,
        userRoleModelList,
        userType,
        fk_JobDescription,
        automaticActivationDate,
        isAutomaticActivationOpen,
    } = userFormValues;

    const {
        erroruserCode,
        errorEmail,
        errorInitialScreen,
        errorLastName,
        errorName,
        errorUsername,
        errorUserType,
        errorUserCompanyList,
        errorRoleLis,
    } = errorFormValues;

    const {
        fillCompanyOptionsMultiSelect,
        fillCompanyValues,
        fillOptions,
        fillOptionsMultiSelect,
        fillValues,
        isLoadingResetPassword,
        isResetPasswordModalOpen,
        onChangeInitialView,
        onChangeRole,
        onChnageSingleRole,
        onConfirmModal,
        onDeleteCompany,
        onDeleteRole,
        showActivationDate,
        userTypeOptions,
        disabled,
        setIsResetPasswordModalOpen,
    } = useUserInfoForm({
        userFormValues,
        onInputChange,
        onSubmit,
        isEditable,
        activeScreensOptions,
        roleOptions,
        companyOptions,
        errorFormValues,
        isActive,
        userId,
        onRoleChange,
    });

    const {
        addNewJobDescription,
        closeDeleteModal,
        deleteJobDescriptionState,
        isDeleteModalLoading,
        isJobDescriptionModalVisible,
        jobDescriptionOptions,
        lastItems,
        onCancel,
        onDeleteJobDescription,
        onEditJobDescription,
        selectActions,
        selectedJobDescription,
    } = useJobDescriptionUserInfoForm({ onInputChange, fk_JobDescription });

    return (
        <>
            {isResetPasswordModalOpen && (
                <ConfirmModal
                    onClose={() => setIsResetPasswordModalOpen(false)}
                    onConfirm={onConfirmModal}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={t(TranslationModals.EDIT_FORM_USER_TITLE_MODAL)}
                    description={t(TranslationModals.EDIT_FORM_USER_DESCRIPTION_MODAL)}
                    isLoading={isLoadingResetPassword}
                />
            )}
            {deleteJobDescriptionState.isOpen && (
                <ConfirmModal
                    onClose={closeDeleteModal}
                    onConfirm={onDeleteJobDescription}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={t(TranslationModals.JOB_DESCRIPTION_DELETE_MODAL_TITLE)}
                    description={t(TranslationModals.JOB_DESCRIPTION_DELETE_MODAL_DESCRIPTION).replace(
                        "{0}",
                        deleteJobDescriptionState.jobDescription?.description || ""
                    )}
                    isLoading={isDeleteModalLoading}
                    type="delete"
                />
            )}
            {isJobDescriptionModalVisible && (
                <UserInfoJobDescriptionModal
                    onAddNew={addNewJobDescription}
                    onCancel={onCancel}
                    selectedJobDescription={selectedJobDescription}
                    onEdit={onEditJobDescription}
                />
            )}
            <div className="userInfoForm">
                <div className="userPage">
                    {userId && hasPermissionToEdit(SecScreen.USER_MANAGEMENT) ? (
                        <div className="userPage__button">
                            <NewButton
                                text={t(NewUserTabsTranslation.RESET_PASSWORD_USER)}
                                iconRight={faArrowsRotate}
                                handleClickButton={() => setIsResetPasswordModalOpen(true)}
                            />
                        </div>
                    ) : null}
                    <form
                        className="userInfoForm__container"
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                        id="userInfoForm"
                    >
                        <>
                            <WhiteBox>
                                <Collapsable border="none" title={t(NewUserTabsTranslation.USER_DATA_TITLE)}>
                                    <div className="userInfoForm__personalInfo">
                                        <Input
                                            label={t(TranslationKeys.LABEL_USERNAME)}
                                            placeholder={t(TranslationKeys.PLACEHOLDER_USERNAME)}
                                            value={username}
                                            onChange={(value) => onInputChange({ username: value })}
                                            disabled={isEditable || !isActive}
                                            errorMessage={errorUsername}
                                        />
                                        <Input
                                            label={t(TranslationKeys.LABEL_USER_CODE)}
                                            placeholder={t(TranslationKeys.PLACEHOLDER_USER_CODE)}
                                            value={userCode}
                                            onChange={(value) => onInputChange({ userCode: value })}
                                            disabled={disabled}
                                            errorMessage={erroruserCode}
                                            hint={t(TranslationKeys.IF_NOT_USERCODE_USE_DNI)}
                                        />
                                        <Input
                                            label={t(TranslationKeys.LABEL_FIRSTNAME)}
                                            placeholder={t(TranslationKeys.PLACEHOLDER_FIRSTNAME)}
                                            value={name}
                                            onChange={(value) => onInputChange({ name: value })}
                                            disabled={disabled}
                                            errorMessage={errorName}
                                        />
                                        <Input
                                            label={t(TranslationKeys.LABEL_LASTNAME)}
                                            placeholder={t(TranslationKeys.PLACEHOLDER_LASTNAME)}
                                            value={lastName}
                                            onChange={(value) => onInputChange({ lastName: value })}
                                            disabled={disabled}
                                            errorMessage={errorLastName}
                                        />
                                        <Input
                                            label={t(TranslationKeys.LABEL_EMAIL)}
                                            placeholder={t(TranslationKeys.PLACEHOLDER_EMAIL)}
                                            value={email}
                                            onChange={(value) => onInputChange({ email: value })}
                                            disabled={isEditable || !isActive}
                                            errorMessage={errorEmail}
                                        />
                                        <SelectWithActions
                                            actions={selectActions}
                                            lastItems={lastItems}
                                            onChange={({ value }) =>
                                                onInputChange({
                                                    fk_JobDescription: value,
                                                })
                                            }
                                            options={jobDescriptionOptions}
                                            label={`${t(TranslationKeys.LABEL_JOB_DESCRIPTION)} (${t(
                                                TranslationKeys.INPUT_OPTIONAL
                                            )})`}
                                            placeholder={t(TranslationKeys.LABEL_JOB_DESCRIPTION)}
                                            selectedValue={fk_JobDescription !== "-1" ? fk_JobDescription : undefined}
                                            disabled={disabled}
                                        />
                                        <SelectOptions
                                            label={t(TranslationKeys.LABEL_USER_TYPE)}
                                            placeholder={t(TranslationKeys.LABEL_USER_TYPE)}
                                            options={userTypeOptions}
                                            selectedValue={userType !== "-1" ? userType : undefined}
                                            onChange={({ value }) => onInputChange({ userType: value })}
                                            isMulti={false}
                                            disabled={disabled}
                                            errorMessage={errorUserType}
                                        />
                                        {showActivationDate() && (
                                            <div className="userInfoForm__container__activationDate">
                                                <Label
                                                    label={t(TranslationKeys.LABEL_COMPANY_AUTOMATIC_ACTIVATION)}
                                                    isOptional
                                                />
                                                <div
                                                    className={`userInfoForm__container__activationDate__inputs${
                                                        disabled ? "--disabled" : ""
                                                    }`}
                                                >
                                                    <Switch
                                                        checked={!!isAutomaticActivationOpen}
                                                        onChange={(checked) =>
                                                            onInputChange({
                                                                isAutomaticActivationOpen: checked,
                                                            })
                                                        }
                                                        disabled={disabled}
                                                    />
                                                    {isAutomaticActivationOpen && (
                                                        <DateRangePicker
                                                            onChange={(date) => {
                                                                onInputChange({
                                                                    automaticActivationDate: date[0],
                                                                });
                                                            }}
                                                            startDate={
                                                                automaticActivationDate
                                                                    ? new Date(automaticActivationDate)
                                                                    : undefined
                                                            }
                                                            disabled={disabled}
                                                            min={MIN_ACTIVATION_DATE}
                                                            range={false}
                                                            showMoreOptions={false}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Collapsable>
                            </WhiteBox>
                            <div className="userInfoForm__rolesAndCompanies">
                                <WhiteBox fullWidth>
                                    <Collapsable border="none" title={t(NewUserTabsTranslation.USER_PERMISSIONS_TITLE)}>
                                        {isEditable ? (
                                            <OptionsOutsideSelect
                                                label={t(TranslationKeys.LABEL_ROLE_SELECTION)}
                                                placeholder={t(TranslationKeys.LABEL_ROLE_SELECTION)}
                                                onChange={onChangeRole}
                                                onDeleteItem={onDeleteRole}
                                                options={fillOptionsMultiSelect()}
                                                values={fillValues()}
                                                disabled={disabled}
                                                hidden={disabled}
                                                isMulti
                                                errorMessage={errorRoleLis}
                                            />
                                        ) : (
                                            <SelectOptions
                                                label={t(TranslationKeys.LABEL_ROLE_SELECTION)}
                                                placeholder={t(TranslationKeys.LABEL_ROLE_SELECTION)}
                                                onChange={onChnageSingleRole}
                                                selectedValue={
                                                    userRoleModelList[0]?.roleId
                                                        ? String(userRoleModelList[0].roleId)
                                                        : undefined
                                                }
                                                options={fillOptions()}
                                                isMulti={false}
                                                errorMessage={errorRoleLis}
                                            />
                                        )}
                                        {isEditable && (
                                            <SelectOptions
                                                label={t(TranslationKeys.LABEL_INITIAL_VIEW)}
                                                placeholder={t(TranslationKeys.LABEL_INITIAL_VIEW)}
                                                options={activeScreensOptions}
                                                selectedValue={initialScreen}
                                                onChange={onChangeInitialView}
                                                isMulti={false}
                                                disabled={disabled}
                                                errorMessage={errorInitialScreen}
                                            />
                                        )}
                                    </Collapsable>
                                </WhiteBox>

                                <WhiteBox fullWidth>
                                    <Collapsable
                                        border="none"
                                        title={t(NewUserTabsTranslation.ASSIGNED_COMPANIES__TITLE)}
                                    >
                                        <OptionsOutsideSelect
                                            label={t(TranslationKeys.LABEL_COMPANY_SELECTION)}
                                            placeholder={t(TranslationKeys.LABEL_COMPANY_SELECTION)}
                                            onChange={(value) => onInputChange({ userCompanyList: value })}
                                            onDeleteItem={onDeleteCompany}
                                            options={fillCompanyOptionsMultiSelect()}
                                            values={fillCompanyValues()}
                                            disabled={disabled}
                                            hidden={disabled}
                                            errorMessage={errorUserCompanyList}
                                            isMulti
                                        />
                                    </Collapsable>
                                </WhiteBox>
                            </div>
                        </>
                    </form>
                </div>
            </div>
        </>
    );
};
