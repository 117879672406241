import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useFilterSelectors } from "app/hooks/useFilterSelectors";
import { DateRangePicker, OptionsOutsideSelect, SelectOptions } from "app/components_v2/__inputs";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { FilterStars } from "../DashboardFilter/components/FilterStars/FilterStars";
import { isEqual } from "lodash";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { TaskHisotryGridSfModel } from "app/pages/02-TAR/09-TAR-Historic/state/context/taskHistoryGridContext";
import { TASK_TYPE } from "app/shared/Constants";

type TaskHistoryFilterProps = {
    filterData: TaskHisotryGridSfModel;
    initialFilterData: TaskHisotryGridSfModel;
    showSecondaryFilter: boolean;
    companyId: string;
    onCancel?: () => void;
    onSave?: (values: TaskHisotryGridSfModel) => void;
};

const maxDate = new Date();

export const TaskHistoryFilter: FC<TaskHistoryFilterProps> = ({
    filterData,
    initialFilterData,
    showSecondaryFilter,
    companyId,
    onCancel,
    onSave,
}) => {
    const { t } = useTranslation();
    const { users, departments, taskStatus, mapStatusOptions } = useFilterSelectors(showSecondaryFilter, companyId);

    const [internalFilterData, setInternalFilterData] = useState(filterData);
    const [statusOptions, setStatusOptions] = useState<OptionsSearch[]>(mapStatusOptions(filterData));

    const taskTypeOptions: OptionsSearch[] = [
        { label: t(TranslationCommon.SELECT_OPTION), value: "-1" },
        { label: t(TaskTranslations.TASK_TYPE_NORMAL), value: TASK_TYPE.NORMAL },
        { label: t(TaskTranslations.TASK_TYPE_SPORADIC), value: TASK_TYPE.SPORADIC },
        { label: t(TaskTranslations.TASK_TYPE_AUDIT), value: TASK_TYPE.AUDIT },
    ];

    const handleInputChange = (values: Partial<TaskHisotryGridSfModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleSelectStateChange = (values: OptionsSearch[]) => {
        setStatusOptions(values);
        values.forEach(({ value }) => handleInputChange({ [value]: true }));
    };

    const onDeleteStatusItem = (valueToDelete: string) => {
        setStatusOptions((prev) => prev.filter(({ value }) => value !== valueToDelete));
        handleInputChange({ [valueToDelete]: false });
    };

    const setOptions = (value: OptionsSearch[]): OptionsSearch[] => {
        const defaultOption = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: String(-1),
        };
        return [defaultOption, ...value];
    };

    const onReset = () => {
        setStatusOptions([]);
        handleInputChange(initialFilterData);
    };

    const handleLeaveFilter = () => {
        setStatusOptions(mapStatusOptions(filterData));
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    return (
        <SecondaryFilter
            isOpen={showSecondaryFilter}
            onClickOutside={handleLeaveFilter}
            onCancel={handleLeaveFilter}
            onReset={onReset}
            onSave={() => onSave && onSave(internalFilterData)}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
        >
            <DateRangePicker
                startDate={internalFilterData.startDate ?? undefined}
                endDate={internalFilterData.endDate ?? undefined}
                onChange={([startDate, endDate]) => handleInputChange({ startDate, endDate })}
                label={t(TranslationKeys.REQUEST_DATE)}
                placeholder={t(TranslationKeys.ALL_TIME_DATES)}
                showMoreOptions
                max={maxDate}
            />
            <OptionsOutsideSelect
                label={t(TranslationKeys.TASK_FILTER_STATUS)}
                options={taskStatus}
                onChange={handleSelectStateChange}
                placeholder={t(TranslationKeys.STATUS_CODE)}
                values={statusOptions}
                onDeleteItem={onDeleteStatusItem}
                isMulti
            />
            {users && (
                <SelectOptions
                    label={t(TranslationKeys.TASK_FILTER_USER)}
                    options={setOptions(users)}
                    selectedValue={internalFilterData.userId !== "-1" ? internalFilterData.userId : undefined}
                    onChange={({ value }) => handleInputChange({ userId: value })}
                    isSearchable
                    isMulti={false}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            )}
            {departments && (
                <SelectOptions
                    label={t(TranslationKeys.TASK_FILTER_DEPARTMENT)}
                    options={setOptions(departments)}
                    selectedValue={
                        internalFilterData.departmentId !== "-1" ? internalFilterData.departmentId : undefined
                    }
                    onChange={({ value }) => handleInputChange({ departmentId: value })}
                    isSearchable
                    isMulti={false}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            )}
            {/* TODO: --SUBDEPARTMENT-- */}
            {/* {subdepartments && (
                <SelectOptions
                    label={t(TranslationKeys.TASK_FILTER_SUBDEPARTMENT)}
                    options={setOptions(subdepartments)}
                    selectedValue={
                        internalFilterData.subDepartmentId !== "-1" ? internalFilterData.subDepartmentId : undefined
                    }
                    onChange={({ value }) => handleInputChange({ subDepartmentId: value })}
                    isSearchable
                    isMulti={false}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            )} */}

            <SelectOptions
                label={t(TaskTranslations.TASK_TYPE_TITLE)}
                options={taskTypeOptions}
                selectedValue={internalFilterData.taskType !== "-1" ? internalFilterData.taskType : undefined}
                onChange={({ value }) => handleInputChange({ taskType: value })}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                isSearchable
                isMulti={false}
            />
            <div className="dashboardFilter__checkboxes">
                <FilterStars
                    filterData={internalFilterData}
                    onChange={({ star, state }) => handleInputChange({ [star]: state })}
                />
                <div className="dashboardFilter__checkboxes__container">
                    <CheckBox
                        label={t(TranslationKeys.PIC_ASSOCIATED_TASK)}
                        htmlFor={TranslationKeys.PIC_ASSOCIATED_TASK}
                        onChange={(checked) => handleInputChange({ isPhotoRequired: checked })}
                        checked={!!internalFilterData.isPhotoRequired}
                    />
                    <CheckBox
                        label={t(TranslationKeys.REPORTED_DATA_TASK)}
                        htmlFor={TranslationKeys.REPORTED_DATA_TASK}
                        onChange={(checked) => handleInputChange({ isDataReportRequired: checked })}
                        checked={!!internalFilterData.isDataReportRequired}
                    />
                    <CheckBox
                        label={t(TranslationKeys.CRITICAL_CHECKBOX)}
                        htmlFor={TranslationKeys.CRITICAL_CHECKBOX}
                        onChange={(checked) => handleInputChange({ isCritical: checked })}
                        checked={!!internalFilterData.isCritical}
                    />
                    <CheckBox
                        label={t(TranslationKeys.QR_CHECKBOX)}
                        htmlFor={TranslationKeys.QR_CHECKBOX}
                        onChange={(checked) => handleInputChange({ isAssignedToQR: checked })}
                        checked={!!internalFilterData.isAssignedToQR}
                    />
                    <CheckBox
                        label={t(TranslationKeys.TASK_COMMENTS_TITLE)}
                        htmlFor={TranslationKeys.TASK_COMMENTS_TITLE}
                        onChange={(checked) => handleInputChange({ hasComments: checked })}
                        checked={!!internalFilterData.hasComments}
                    />
                </div>
            </div>
        </SecondaryFilter>
    );
};
