import { FC } from "react";
import { IssueHistoryPill } from "../IssueHistoryPill/IssueHistoryPill";
import { IssueStatusModel } from "app/models/05-QUA/IssueModels/IssueStatusModels";
import { IssueCorrectiveMeasureModel } from "app/models/05-QUA/IssueModels/IssueCorrectiveMeasureModel";

type IssueHistoryPillListProps = {
    issueStatus: IssueStatusModel[];
    issueCorrectiveMeasures: IssueCorrectiveMeasureModel[] | null;
};

export const IssueHistoryPillList: FC<IssueHistoryPillListProps> = ({ issueStatus, issueCorrectiveMeasures }) => {
    return (
        <div className="issueHistoryPillList">
            {issueStatus.map((status) => (
                <IssueHistoryPill
                    key={status.id}
                    issueStatus={status}
                    issueCorrectiveMeasures={issueCorrectiveMeasures ?? []}
                />
            ))}
        </div>
    );
};
