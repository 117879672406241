import { FC } from "react";
import { AddTaskHourButtons, MultiTaskHour } from "app/components_v2/WizardTask";
import { Step4, taskHoursModel } from "app/models/02-TAR/TaskWizard";
import { TaskHourValidation } from "../../models";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";

type TaskHourProps = {
    hours: taskHoursModel[];
    validation: TaskHourValidation;
    onDeleteHour: (id: string) => void;
    onChange: (fields: Partial<Step4>) => void;
    onAddNewHour: () => void;
    onAddNewRangeHour: () => void;
};

export const TaskHour: FC<TaskHourProps> = ({
    hours,
    validation,
    onDeleteHour,
    onChange,
    onAddNewHour,
    onAddNewRangeHour,
}) => {
    const { t } = useTranslation();

    const taskHoursNoRange = hours.filter(({ type }) => type === "hour");
    const taskHoursRange = hours.filter(({ type }) => type === "range");

    return (
        <div className="stepFourInput__wrapper">
            {taskHoursNoRange.map(({ hour, id, maxHour, type }) => (
                <div className="stepFourInput__wrapper__taskHour" key={id}>
                    <MultiTaskHour
                        hour={hour}
                        id={id}
                        maxHour={type === "range" ? maxHour : undefined}
                        type={type!}
                        multi={type === "range"}
                        onDeleteHour={onDeleteHour}
                        errorMessage={validation.errors.find((el) => el.id === id)?.value}
                        onChangeHour={(value) =>
                            onChange({
                                taskHours: hours.map((el) =>
                                    el.id === id
                                        ? {
                                              ...el,
                                              hour: value,
                                          }
                                        : el
                                ),
                            })
                        }
                        onChangeMaxHour={
                            type === "range"
                                ? (value) =>
                                      onChange({
                                          taskHours: hours.map((el) =>
                                              el.id === id
                                                  ? {
                                                        ...el,
                                                        maxHour: value,
                                                    }
                                                  : el
                                          ),
                                      })
                                : undefined
                        }
                    />
                </div>
            ))}
            {hours.length < 3 && <AddNewButton onClick={onAddNewHour}>{t(TranslationKeys.ADD_HOUR)}</AddNewButton>}

            {taskHoursRange.map(({ hour, id, maxHour, type }) => (
                <div className="stepFourInput__wrapper__taskHour" key={id}>
                    <MultiTaskHour
                        hour={hour}
                        id={id}
                        maxHour={type === "range" ? maxHour : undefined}
                        type={type!}
                        multi={type === "range"}
                        onDeleteHour={onDeleteHour}
                        errorMessage={validation.errors.find((el) => el.id === id)?.value}
                        onChangeHour={(value) =>
                            onChange({
                                taskHours: hours.map((el) =>
                                    el.id === id
                                        ? {
                                              ...el,
                                              hour: value,
                                          }
                                        : el
                                ),
                            })
                        }
                        onChangeMaxHour={
                            type === "range"
                                ? (value) =>
                                      onChange({
                                          taskHours: hours.map((el) =>
                                              el.id === id
                                                  ? {
                                                        ...el,
                                                        maxHour: value,
                                                    }
                                                  : el
                                          ),
                                      })
                                : undefined
                        }
                    />
                </div>
            ))}

            {hours.length < 3 && (
                <AddNewButton onClick={onAddNewRangeHour}>{t(TranslationKeys.ADD_RANGE_HOUR)}</AddNewButton>
            )}

            {!!validation.value.length && <ErrorMessage errorMessage={validation.value} />}
        </div>
    );
};
