export const ErrorConstants = {
    // Taskinstance
    TASK_INSTANCE_ALREADY_COMPLETED: "TASK_INSTANCE_ALREADY_COMPLETED",
    TASK_INSTANCE_ALREADY_REOPENED: "TASK_INSTANCE_ALREADY_REOPENED",
    TASK_INSTANCE_NOT_YOURS: "TASK_INSTANCE_NOT_YOURS",
    TASK_INSTANCE_NOT_EXIST: "TASK_INSTANCE_NOT_EXIST",

    WORKING_POSITION_ALREADY_EXIST: "WORKING_POSITION_ALREADY_EXIST",
    WORKING_POSITION_ENTITY_NOT_FOUND: "WORKING_POSITION_ENTITY_NOT_FOUND",

    LOGIN_INVALID_CREDENTIALS: "LOGIN_INVALID_CREDENTIALS",
    ACCESS_DENIED: "ACCESS_DENIED",
    WORKING_POSITION_TASKS_EMPTY: "WORKING_POSITION_TASKS_EMPTY",
    DEPARTMENT_NOT_EXIST: "DEPARTMENT_NOT_EXIST",
    TASKS_ALREADY_IMPORTED: "TASKS_ALREADY_IMPORTED",

    // Company
    COMPANIES_FAILED_LOAD_DATA: "COMPANIES_FAILED_LOAD_DATA",

    // WorkingPosition
    WORKING_POSITION_FAILED_LOAD_DATA: "WORKING_POSITION_FAILED_LOAD_DATA",
    WORKING_POSITION_ALREADY_SELECTED: "WORKING_POSITION_ALREADY_SELECTED",

    // ResetPassword
    PASSWORD_CONTAINS_ASTERISK: "PASSWORD_CONTAINS_ASTERISK",

    // TaskinstanceChecklist
    TASK_INSTANCE_CHECKLIST_NOT_YOURS: "TASK_INSTANCE_CHECKLIST_NOT_YOURS",
    TASK_INSTANCE_CHECKLIST_FAILED: "TASK_INSTANCE_CHECKLIST_FAILED",

    // TaskinstancePhotos
    TASK_INSTANCE_PHOTOS_NULL_OR_EMPTY: "TASK_INSTANCE_PHOTOS_NULL_OR_EMPTY",
    TASK_INSTANCE_PHOTOS_NOT_FOUND: "TASK_INSTANCE_PHOTOS_NOT_FOUND",
    TASK_INSTANCE_PHOTOS_MAX_FILES: "TASK_INSTANCE_PHOTOS_MAX_FILES",
    TASK_INSTANCE_PHOTOS_NO_PHOTOS: "TASK_INSTANCE_PHOTOS_NO_PHOTOS",
    TASK_INSTANCE_PHOTOS_NOT_MINE: "TASK_INSTANCE_PHOTOS_NOT_MINE",
    TASK_INSTANCE_PHOTOS_TASK_ALREADY_COMPLETED: "TASK_INSTANCE_PHOTOS_TASK_ALREADY_COMPLETED",
    TASK_INSTANCE_PHOTOS_FAILED_UPLOADING: "TASK_INSTANCE_PHOTOS_FAILED_UPLOADING",
    TASK_INSTANCE_PHOTOS_NOT_EXIST: "TASK_INSTANCE_PHOTOS_NOT_EXIST",
    TASK_INSTANCE_NO_PHOTOS: "TASK_INSTANCE_NO_PHOTOS",
};
