import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuditGridContext, AuditGridSFModel } from "../../state/context/auditGridContext";
import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import {
    INITIAL_AUDIT_GRID_COUNTERS,
    INITIAL_AUDIT_GRID_SORT_DIRECTION,
    INITIAL_AUDIT_GRID_SORT_FIELD,
} from "../../constants/auditGridContants";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { fillExtraparams } from "../../helpers/fillExtraparams";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { useToast } from "app/hooks/Toast/useToast";
import { AuditInstanceCountersDto } from "app/dtos/05-QUA/AuditInstance/AuditInstanceCountersDto";
import { PaginationDefaults } from "app/shared/Constants";
import { fillCounterExtraparams } from "../../helpers/fillCounterExtraparams";
import { AuditHeaderButtons } from "../components/AuditHeader/types";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useSession } from "app/hooks";

export type AduitGridFetchModel = {
    pi?: number;
    tab?: number;
    sortF?: string;
    sortD?: SortedTypeModel;
    auditSf?: AuditGridSFModel;
    selectedCompany?: string;
};

export type AduitGridCountersFetchModel = {
    auditSf?: AuditGridSFModel;
    selectedCompany?: string;
};

export const useAuditGrid = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const { handleToast } = useToast();
    const session = useSession();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();

    const {
        currentTab,
        onChangeCurrentTab,
        onChangePageIndex,
        pageIndex,
        onSortChange,
        sortDirection,
        sortField,
        onCompanyChange,
        selectCompany,
        secondaryFilterValues,
        onSFChange,
        currentFilterButton,
        onChangeFilterButton,
    } = useContext(AuditGridContext);

    const [createNewModalIsOpen, setCreateNewModalIsOpen] = useState<boolean>(false);
    const [data, setData] = useState<AuditInstanceModel[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [counters, setCounters] = useState<AuditInstanceCountersDto>(INITIAL_AUDIT_GRID_COUNTERS);
    const [isSecondaryFilterOpen, setIsSecondaryFilterOpen] = useState<boolean>(false);

    const handleNavigate = (audit: AuditInstanceModel) => {
        changeWorkingCompanyByCompanyId(audit.fK_Company);
        if (audit.status === "COMPLETED") {
            nav(`${audit.id}/completed`);
            return;
        }
        if (audit.status === "REVIEWED") {
            nav(`${audit.id}/reviewed`);
            return;
        }
        if (audit.status === "CLOSED") {
            nav(`${audit.id}/closed`);
            return;
        }
        nav(`${audit.id}`);
        return;
    };

    const handleCurrentTabChange = (tab: number) => {
        if (isLoading) return;
        if (tab === currentTab) return;
        const auditSf = { ...secondaryFilterValues, closedDate: undefined, closedDate2: undefined };
        getData({ pi: 0, tab, auditSf });
        getCounters({ auditSf });
        onSFChange(auditSf);
        onChangePageIndex(1);
        onChangeCurrentTab(tab);
        onSortChange(INITIAL_AUDIT_GRID_SORT_FIELD, INITIAL_AUDIT_GRID_SORT_DIRECTION);
    };

    const handlePageIndexChange = (pi: number) => {
        onChangePageIndex(pi);
        getData({ pi: pi - 1 });
        getCounters({});
    };

    const handleSaveSecondaryFilter = (values: AuditGridSFModel) => {
        const sfCompany = values.companyId ?? "-1";
        onSFChange(values);
        onCompanyChange({
            company: { label: values.companyName ?? t(TranslationKeys.ALL_COMPANIES), value: sfCompany },
            isOpen: false,
        });
        setIsSecondaryFilterOpen(false);
        getData({ pi: 0, selectedCompany: sfCompany, auditSf: values });
        getCounters({ selectedCompany: sfCompany, auditSf: values });
        onChangePageIndex(1);

        if (sfCompany != "-1") changeWorkingCompanyByCompanyId(Number(sfCompany));
    };

    const getData = async ({ pi, sortD, sortF, tab, selectedCompany, auditSf }: AduitGridFetchModel = {}) => {
        setIsLoading(true);
        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;
        const selectedCompanyFetch = selectedCompany !== undefined ? selectedCompany : selectCompany.company.value;
        const filterData = auditSf ? auditSf : secondaryFilterValues;

        const extraParams = fillExtraparams({
            currentTab: tab !== undefined ? tab : currentTab,
            companyId: selectedCompanyFetch,
            sortDirection: sortD || sortDirection,
            sortField: sortF || sortField,
            closedDate: filterData.closedDate,
            closedDate2: filterData.closedDate2,
            openDate: filterData.openDate,
            openDate2: filterData.openDate2,
        });

        const { status, data: fetchData } = await AuditInstanceService.GetData({
            pageIndex: pageIndexFetch,
            pageSize: PaginationDefaults.PAGE_SIZE,
            extraParams: extraParams,
            query: filterData.query,
        });

        if (!status()) {
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED), variant: "danger", type: "alert" });
            setIsLoading(false);
            setData([]);
            return;
        }

        setData(fetchData.list);
        setTotal(fetchData.count);
        setIsLoading(false);
    };

    const getCounters = async ({ auditSf, selectedCompany }: AduitGridCountersFetchModel = {}) => {
        const selectedCompanyFetch = selectedCompany !== undefined ? selectedCompany : selectCompany.company.value;
        const filterData = auditSf ? auditSf : secondaryFilterValues;

        const extraParams = fillCounterExtraparams({
            companyId: selectedCompanyFetch,
            closedDate: filterData.closedDate,
            closedDate2: filterData.closedDate2,
            openDate: filterData.openDate,
            openDate2: filterData.openDate2,
        });

        const { status, data: fetchData } = await AuditInstanceService.GetCounters({
            extraParams: extraParams,
            query: filterData.query,
        });

        if (!status()) {
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED), variant: "danger", type: "alert" });
            setCounters(INITIAL_AUDIT_GRID_COUNTERS);
            return;
        }
        setCounters(fetchData);
    };

    const handleChangeFilterButton = (filterButton: AuditHeaderButtons) => {
        if (filterButton === currentFilterButton) return;
        if (isLoading) return;

        onChangeFilterButton(filterButton);
        const tab = filterButton === "IN_PROGRESS" ? 0 : 1;
        handleCurrentTabChange(tab);
    };

    const onChangeOrderBy = (sortD: SortedTypeModel, sortF: string) => {
        onSortChange(sortF, sortD);
        getData({ sortD, sortF });
        getCounters();
    };

    const onCreateNewAudit = (auditId: number, companyId: number) => {
        const companyName = session?.user.companies?.find((company) => company.companyId === companyId)?.companyName;
        nav(`${auditId}`);
        onCompanyChange({ company: { value: String(companyId), label: companyName || "" } });
    };

    useEffect(() => {
        getData();
        getCounters();
    }, []);

    return {
        createNewModalIsOpen,
        currentTab,
        data,
        handleNavigate,
        handlePageIndexChange,
        isLoading,
        pageIndex,
        selectCompany,
        setCreateNewModalIsOpen,
        total,
        handleCurrentTabChange,
        counters,
        secondaryFilterValues,
        isSecondaryFilterOpen,
        setIsSecondaryFilterOpen,
        handleSaveSecondaryFilter,
        onChangeFilterButton: handleChangeFilterButton,
        currentFilterButton,
        sortDirection,
        sortField,
        onChangeOrderBy,
        onCreateNewAudit,
    };
};
