import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import {
    CustomMonthlyOption2,
    RecurrentTaskModel,
    RecurrentTaskOption2,
    taskHoursModel,
} from "app/models/02-TAR/TaskWizard";
import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { getDayOfWeek, getMonthName, getWeekDay } from "app/helpers/Date.utilities";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { useSession } from "app/hooks";

export const useTaskTemporality = (
    taskHours: taskHoursModel[],
    onChange: (fields: Partial<TaskTemporalityModel>) => void,
    taskStart: string,
    recurringTask: RecurrentTaskModel | null
) => {
    const { t } = useTranslation();
    const session = useSession();

    const recurringTaskValuesConst: RecurrentTaskOption2[] = [
        { value: "", label: t(TranslationCommon.SELECT_OPTION), hidden: true },
        { value: "everyDay", label: t(TranslationKeys.EVERY_DAY) },
        { value: "everyWeek", label: t(TranslationKeys.EVERY_WEEK_DAY) },
        { value: "everyMonth", label: t(TranslationKeys.EVERY_MONTH_DAY) },
        { value: "annually", label: t(TranslationKeys.EVERY_YEAR_DAY_MONTH) },
        {
            value: "mondayToFriday",
            label: t(TranslationKeys.EVERY_LABORAL_DAYS),
        },
        { value: "custom", label: t(TranslationKeys.WIZARD_SELECT_CUSTOM) },
    ];

    const weekTextOptions = [
        t(TranslationKeys.FIRST_DAY_WEEK),
        t(TranslationKeys.SECOND_DAY_WEEK),
        t(TranslationKeys.THIRD_DAY_WEEK),
        t(TranslationKeys.FOURTH_DAY_WEEK),
        t(TranslationKeys.FIFTH_DAY_WEEK),
    ];

    const weekOptions: CustomMonthlyOption2[] = [
        { value: "", label: t(TranslationCommon.SELECT_OPTION), hidden: true },
        {
            value: "everyMonthDayOfMonth",
            label: t(TranslationKeys.SPECIFIC_DAY),
        }, // 5
        {
            value: "everyMonthFirstDayOfWeek",
            label: weekTextOptions[getWeekDay(taskStart) - 1],
        }, // martes
    ];

    const options: OptionsSearch[] = [
        {
            value: "day",
            label:
                recurringTask && recurringTask.custom.repeatEvery > 1
                    ? t(TranslationKeys.DAYS)
                    : t(TranslationKeys.DAY),
        },
        {
            value: "week",
            label:
                recurringTask && recurringTask.custom.repeatEvery > 1
                    ? t(TranslationKeys.WEEKS)
                    : t(TranslationKeys.WEEK),
        },
        {
            value: "everyMonth",
            label:
                recurringTask && recurringTask.custom.repeatEvery > 1
                    ? t(TranslationKeys.MONTHS)
                    : t(TranslationKeys.MONTH),
        },
        {
            value: "everyYear",
            label:
                recurringTask && recurringTask.custom.repeatEvery > 1
                    ? t(TranslationKeys.YEARS)
                    : t(TranslationKeys.YEAR),
        },
    ];

    // Never -> No se acaba nunca
    // EL -> Dia que se acaba la tarea
    // After -> Repeticiones para acabar

    // Valores del select de la recurrencia
    const [recurentTaskSelectorValues, setRecurentTaskSelectorValues] =
        useState<RecurrentTaskOption2[]>(recurringTaskValuesConst);

    // Valores del select cuando seleccionas custom y el mes
    // -> custom
    //  -> Mes
    const [customMonthlySelectorValues, setCustomMonthlySelectorValues] = useState<CustomMonthlyOption2[]>(weekOptions);

    // Cuando añades la hora te comprueba si hay 3 o más
    // si la hay no te deja hacer nada
    // si no hay más de 2 te la añade

    const onAddNewHour = () => {
        if (taskHours.length >= 3) return;

        onChange({
            taskHours: [...taskHours, { hour: "", id: v4(), maxHour: "", type: "hour" }],
        });
    };

    const onAddNewRangeHour = () => {
        if (taskHours.length >= 3) return;
        onChange({
            taskHours: [...taskHours, { hour: "", id: v4(), maxHour: "", type: "range" }],
        });
    };

    const onDeleteHour = (id: string) => {
        onChange({
            taskHours: taskHours.filter((hour) => id !== hour.id),
        });
    };

    // Cuando borras o dejas sin valor el input de añadir horas
    // Si hay solo una hora pero vacía no hace nada
    // Si hay un input sin valor y dás click fuera te lo elimina
    const onBlur = () => {
        if (taskHours.length <= 1) return;
        return onChange({
            taskHours: taskHours.filter(({ hour }) => hour !== ""),
        });
    };

    useEffect(() => {
        // Si el dia seleccionado es jueves 5
        // te pone los valores del select acorde a la fecha estipulada
        // Select de tareas recurrentes
        if (taskStart !== "") {
            setRecurentTaskSelectorValues(
                recurringTaskValuesConst.map(({ label, value, hidden }) => {
                    return {
                        label:
                            value === "annually"
                                ? label
                                      .replace("{1}", `${new Date(taskStart).getDate()}`)
                                      .replace("{2}", getMonthName(taskStart))
                                : value === "everyMonth"
                                ? label?.replace(
                                      "{1}",
                                      weekTextOptions[getWeekDay(taskStart) - 1]
                                          ?.toLocaleLowerCase()
                                          ?.replace("{1}", getDayOfWeek(taskStart)?.toLocaleLowerCase())
                                  )
                                : label?.replace("{1}", getDayOfWeek(taskStart)),
                        value,
                        hidden,
                    };
                })
            );

            setCustomMonthlySelectorValues(
                customMonthlySelectorValues.map(({ value, hidden }, i) => ({
                    label:
                        value === "everyMonthDayOfMonth"
                            ? weekOptions[i].label?.replace("{1}", `${new Date(taskStart).getDate()}`)
                            : weekOptions[i].label?.replace("{1}", getDayOfWeek(taskStart)),
                    value,
                    hidden,
                }))
            );
        }
    }, [taskStart, session?.user.language]);

    return {
        onAddNewHour,
        onBlur,
        onAddNewRangeHour,
        onDeleteHour,
        recurentTaskSelectorValues,
        customOptionsValue: options,
        weekOptions: customMonthlySelectorValues,
    };
};
