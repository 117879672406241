import { FC } from "react";
import { ErrorFormValuesNotificationDepartmentModel, NotificationModalAssignedTo } from "../types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionsOutsideSelect, SelectOptions } from "app/components_v2/__inputs";
import { NotificationsTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type NotificationsDepartmentModalProps = {
    departmentList: number[];
    companyList: number[];
    usersList: number[];
    companyOptions: OptionModel[];
    departmentOptions: OptionModel[];
    userOptions: OptionModel[];
    errorFormValues: ErrorFormValuesNotificationDepartmentModel;
    onSelect: (values: Omit<NotificationModalAssignedTo, "qrList">, companyChanged: boolean) => void;
};

export const NotificationsDepartmentModal: FC<NotificationsDepartmentModalProps> = ({
    companyList,
    companyOptions,
    departmentList,
    departmentOptions,
    onSelect,
    userOptions,
    usersList,
    errorFormValues,
}) => {
    const { t } = useTranslation();
    const { errorCompany, errorDepartment, errorUser } = errorFormValues;

    const handleDeleteListItemsUsers = (userId: string) => {
        onSelect(
            {
                companyList,
                departmentList,
                usersList: usersList.filter((userListId) => userListId !== Number(userId)),
            },
            false
        );
    };

    const handleDeleteListItemsDepartments = (departmentId: string) => {
        onSelect(
            {
                companyList,
                departmentList: departmentList.filter((departmentListId) => departmentListId !== Number(departmentId)),
                usersList,
            },
            false
        );
    };

    return (
        <div className="notificationsDepartmentModal">
            <div className="notificationsDepartmentModal__company">
                <SelectOptions
                    label={t(NotificationsTranslations.NOTIFICATION_WHICH_COMPANY_LABEL)}
                    options={companyOptions}
                    onChange={({ value }) =>
                        onSelect({ companyList: [Number(value)], departmentList: [], usersList: [] }, true)
                    }
                    selectedValue={companyList[0] ? String(companyList[0]) : undefined}
                    isMulti={false}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    errorMessage={errorCompany}
                />
            </div>
            {!!companyList[0] && (
                <div className="notificationsDepartmentModal__body">
                    <OptionsOutsideSelect
                        label={t(NotificationsTranslations.NOTIFICATION_ASSIGN_USER)}
                        options={userOptions}
                        autoInitials
                        onChange={(users) =>
                            onSelect(
                                {
                                    companyList,
                                    departmentList,
                                    usersList: users.map(({ value }) => Number(value)),
                                },
                                false
                            )
                        }
                        values={userOptions ? userOptions.filter(({ value }) => usersList.includes(Number(value))) : []}
                        onDeleteItem={handleDeleteListItemsUsers}
                        isMulti
                        errorMessage={errorUser}
                    />
                    <OptionsOutsideSelect
                        label={t(NotificationsTranslations.NOTIFICATION_ASSIGN_DEPARTMENT)}
                        options={departmentOptions}
                        autoInitials
                        onChange={(departments) =>
                            onSelect(
                                {
                                    companyList,
                                    departmentList: departments.map(({ value }) => Number(value)),
                                    usersList,
                                },
                                false
                            )
                        }
                        values={
                            departmentOptions
                                ? departmentOptions.filter(({ value }) => departmentList.includes(Number(value)))
                                : []
                        }
                        onDeleteItem={handleDeleteListItemsDepartments}
                        isMulti
                        errorMessage={errorDepartment}
                    />
                </div>
            )}
        </div>
    );
};
