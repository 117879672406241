import { FC } from "react";
import { Input } from "app/components_v2/__inputs";
import { faClose, faSearch } from "@fortawesome/pro-regular-svg-icons";

type TableInputTextProps = {
    onChange: (value: string) => void;
    value: string;
    fetch: () => void;
    cleanFunction: () => void;
};

export const TableInputText: FC<TableInputTextProps> = ({ cleanFunction, fetch, onChange, value }) => {
    return (
        <Input
            focus
            onChange={onChange}
            iconRight={!!value.length ? faSearch : faClose}
            value={value}
            onClickIcon={() => {
                !!value.length ? fetch() : cleanFunction();
            }}
            onPressEnter={() => {
                !!value.length ? fetch() : cleanFunction();
            }}
            whiteInput
            transparent
            onClick={(e) => e.stopPropagation()}
        />
    );
};
