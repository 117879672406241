import { SortedTypeModel } from "app/components_v2/Table/types";
import { AssetGridSF } from "../types";
import { AssetTabCountersDto } from "app/dtos/05-QUA/Asset/AssetTabCountersDto";

export const INITIAL_ASSET_SORT_FIELD = "name";
export const INITIAL_ASSET_SORT_DIRECTION: SortedTypeModel = "asc";
export const INITIAL_ASSET_SF_VALUES: AssetGridSF = {
    hasConsecutiveIssues: "-1",
    hasSensorCode: "-1",
    isNotWorking: "-1",
};
export const INITIAL_ASSET_COUNTERS: AssetTabCountersDto = { withoutSensor: 0, withSensor: 0, deactivated: 0 };
