import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { validateStatus } from "../__validates/validateTaskpillStatus";
import { TranslationKeys } from "app/translation/translationKeys";

export const taskInstancePhotoBadge = (taskInstance: TaskInstanceModel, t: Function) => {
    const { fotoExample, taskInstancePhotos } = taskInstance;
    const isPhotoExample =
        validateStatus(taskInstance) !== "IN_TIME" && validateStatus(taskInstance) !== "OUT_OF_TIME" && fotoExample;
    if (isPhotoExample && !taskInstancePhotos) return t(TranslationKeys.TASK_DETAIL_PHOTO_EXAMPLE);

    if (!taskInstancePhotos?.length) return;

    const takenInRealTime = taskInstancePhotos[0].takenInRealTime;

    if (takenInRealTime === true) return t(TranslationKeys.TASK_DETAIL_PHOTO_GALERY);
    if (takenInRealTime === false) return t(TranslationKeys.TASK_DETAIL_PHOTO_INSTANT);

    return;
};
