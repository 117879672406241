import { TypeCodeTask } from "app/models/02-TAR/Task/TaskBody";
import { TranslationKeys } from "app/translation/translationKeys";
import { TFunction } from "react-i18next";

export const selectBadgeTitle = (typeCode: TypeCodeTask, t: TFunction) => {
    const translations: Record<TypeCodeTask, TranslationKeys> = {
        ONE_TIME: TranslationKeys.ONE_TIME,
        DAILY: TranslationKeys.DAILY,
        WEEKLY: TranslationKeys.WEEKLY,
        MONTHLY: TranslationKeys.MONTHLY,
        ANNUALY: TranslationKeys.ANNUALY,
    };

    return t(translations[typeCode]);
};
