import { BaseTaskGridFetchModel } from "../models/BaseTaskPageModels";
import { BaseTaskGridSFModel } from "../state/context/BaseTaskContext";

type BaseTaskExtraParamsModel = BaseTaskGridSFModel &
    BaseTaskGridFetchModel & {
        isAPPCC?: boolean;
    };

export const fillExtraParams = ({
    isAPPCC,
    baseTaskTypeId,
    isPhotoRequired,
    reportType,
    planId,
}: BaseTaskExtraParamsModel): string => {
    const extraParams = new URLSearchParams();
    const tabName = isAPPCC ? "APPCC" : "NO_APPCC";

    extraParams.append("Tab", tabName);
    baseTaskTypeId !== "-1" && extraParams.append("BaseTaskTypeId", baseTaskTypeId.toString());
    isPhotoRequired !== undefined && extraParams.append("IsPhotoRequired", isPhotoRequired.toString());
    reportType !== undefined && extraParams.append("ReportType", reportType.toString());
    planId !== "-1" && tabName === "APPCC" && extraParams.append("PlanId", planId.toString());

    return extraParams.toString();
};
