import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAssetFormInfo } from "./hooks/useAssetFormInfo";
import { useSession } from "app/hooks";
import {
    AssetTranslations,
    TaskTranslations,
    TranslationCommon,
    TranslationKeys,
} from "app/translation/translationKeys";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { AssetModelFormValues } from "app/models/05-QUA/AssetModels";
import { AssetFormProps, AssetFormValidationsModel } from "../models/AssetFormModel";
import { TextArea } from "app/components_v2/__inputs/TextArea/TextArea";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { Input } from "app/components_v2/__inputs/Input/Input";
import { AssetNotWorking } from "../AssetNotWorking/AssetNotWorking";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";
import { AssetSensorInfo } from "./AssetSensorInfo/AssetSensorInfo";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { AssetFieldInsertModel } from "app/models/05-QUA/AssetModels/AssetFieldModel";
import { v4 } from "uuid";

type AssetFormInfoProps = AssetFormProps<AssetModelFormValues, AssetFormValidationsModel> & {
    isEditPage: boolean;
};

export const AssetFormInfo: FC<AssetFormInfoProps> = ({
    assetId,
    description,
    fK_AssetType,
    fK_Company,
    isDisabled,
    name,
    onInputChange,
    customError,
    notWorking,
    fK_PlanAPPCC,
    id,
    allowSensorSerialNumber,
    sensorSerialNumber,
    battery,
    signalLevel,
    assetFields,
    isEditPage,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const hasPermissionsToViewSensors = hasPermissionToView(session, SecScreen.AUTOMATIC_RECORD);
    const isOnBoarding = session?.isOnBoarding;

    const { assetTypeOptions, companyOptions, planAPPCCOptions, onChangeAssetType } = useAssetFormInfo({
        assetFields,
        isEditPage,
        onInputChange,
    });

    const { errorCompany, errorName, errorType, errorPlanAPPCC, errorAssetId, errorSerialNumber } = customError;
    const showNotWorkingSwitch = !!id && hasPermissionToView(session, SecScreen.OPEN_MANUAL_ISSUE) && !isOnBoarding;

    return (
        <>
            <WhiteBoxCollapsable
                collapsableOptions={{
                    background: "transparent",
                    border: "none",
                    title: t(AssetTranslations.ASSET_INFO_TITLE),
                    variant: "appcc",
                }}
            >
                <div className="assetForm__info">
                    <div className="assetForm__container__assetName">
                        <Input
                            value={name}
                            onChange={(value) => onInputChange({ name: value })}
                            label={t(TranslationKeys.NAME)}
                            placeholder={t(TranslationKeys.ASSET_NAME_PLACEHOLDER)}
                            errorMessage={errorName}
                            disabled={isDisabled}
                        />
                        <Input
                            value={assetId}
                            onChange={(value) => {
                                onInputChange({ assetId: value });
                            }}
                            label={t(TranslationKeys.ASSET_ID)}
                            placeholder={t(TranslationKeys.ASSET_ID)}
                            disabled={isDisabled}
                            isOptional
                            errorMessage={errorAssetId}
                            max={8}
                        />
                    </div>
                    <div className="assetForm__container__assetDescription">
                        <TextArea
                            text={description}
                            onChange={(value) => onInputChange({ description: value })}
                            label={`${t(TranslationKeys.DESCRIPTION)} (${t(TranslationKeys.INPUT_OPTIONAL)})`}
                            placeholder={t(TranslationKeys.ASSET_DESCRIPTION_PLACEHOLDER)}
                            disabled={isDisabled}
                        />
                    </div>
                    <div className="assetForm__container__fields">
                        <div className="assetForm__container__fields">
                            <div className="assetForm__container__section">
                                <SelectOptions
                                    selectedValue={String(fK_Company)}
                                    onChange={({ value }) => onInputChange({ fK_Company: Number(value) })}
                                    options={companyOptions}
                                    label={t(TranslationKeys.WORKING_POSITION_COMPANY_LABEL)}
                                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                                    isMulti={false}
                                    disabled={isDisabled}
                                    errorMessage={errorCompany}
                                />
                                <SelectOptions
                                    label={t(TaskTranslations.TASK_APPCC_LABEL)}
                                    selectedValue={String(fK_PlanAPPCC)}
                                    onChange={({ value }) =>
                                        onInputChange({
                                            fK_PlanAPPCC: Number(value),
                                        })
                                    }
                                    options={planAPPCCOptions}
                                    errorMessage={errorPlanAPPCC}
                                    disabled={isDisabled}
                                    isMulti={false}
                                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                                />
                                <SelectOptions
                                    label={t(TranslationKeys.ASSET_TYPE)}
                                    selectedValue={assetTypeOptions
                                        .find((option) => option.id === fK_AssetType)
                                        ?.id?.toString()}
                                    onChange={onChangeAssetType}
                                    options={assetTypeOptions.map(({ id, name }) => ({
                                        label: t(name),
                                        value: String(id),
                                    }))}
                                    errorMessage={errorType}
                                    disabled={isDisabled}
                                    isMulti={false}
                                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                                />
                                {showNotWorkingSwitch && (
                                    <AssetNotWorking
                                        assetId={id}
                                        isWorking={!notWorking}
                                        onChange={(isNotWorking) => onInputChange({ notWorking: isNotWorking })}
                                        companyId={fK_Company}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </WhiteBoxCollapsable>
            {hasPermissionsToViewSensors && (
                <WhiteBoxCollapsable
                    collapsableOptions={{
                        background: "transparent",
                        border: "none",
                        title: t(AssetTranslations.ASSET_SENSOR_TITLE),
                        variant: "appcc",
                    }}
                >
                    <AssetSensorInfo
                        id={id}
                        allowSensorSerialNumber={allowSensorSerialNumber}
                        battery={battery}
                        errorSerialNumber={errorSerialNumber}
                        onInputChange={onInputChange}
                        sensorSerialNumber={sensorSerialNumber}
                        signalLevel={signalLevel}
                        isDisabled={isDisabled}
                    />
                </WhiteBoxCollapsable>
            )}
        </>
    );
};
