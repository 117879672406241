import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { RoleWizardFormValues } from "./types";
import Helper from "app/helpers/TranslationHelper";

type step4Props = {
    data: RoleWizardFormValues;
    onFinish: () => void;
    isTemporal: boolean;
};

const Step4: FC<step4Props> = ({ data, onFinish, isTemporal }) => {
    const { t } = useTranslation();

    return (
        <WizardForm
            validate={(e) => {
                e.preventDefault();
                onFinish();
            }}
        >
            <div>
                <p className="roleWizardStep4">
                    {t(TranslationKeys.WIZARD_ROLE_CONFIRM_CREATION)}
                    <span className="roleWizardStep4__roleName"> “{data.roleName}”</span>{" "}
                    {isTemporal
                        ? `${t(TranslationKeys.WIZARD_TEMPORARY_ROLE_FOR_USER)} ${data.userSelected.label}`
                        : ""}
                    . {t(TranslationKeys.WIZARD_TEMPORARY_ROLE_ACTIVE_PERMISIONS)}:
                </p>
                <div className="showScrollbar roleWizardStep4__permissions">
                    {data.permissions
                        .map((module) => ({
                            ...module,
                            screenPatents: module.screenPatents.filter(
                                (screen) => screen.patents && screen.patents.length
                            ),
                        }))
                        .filter((module) => !!module.screenPatents.length)
                        .map((permission) => {
                            return permission.screenPatents.map(({ screenCode }) => (
                                <p key={screenCode} className="">
                                    {Helper.translateRowTitle(screenCode, t)}
                                </p>
                            ));
                        })}
                </div>
            </div>
        </WizardForm>
    );
};

export default Step4;
