import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAssetDynamicFields } from "./hooks/useAssetDynamicFields";
import { v4 } from "uuid";
import { TranslationKeys } from "app/translation/translationKeys";
import { AssetFormProps, AssetFormValidationsModel, ErrorDynamicAssetModel } from "../models/AssetFormModel";
import { AssetDynamicFieldModel, AssetFormDynamicFieldsModel } from "app/models/05-QUA/AssetModels";
import { AssetFieldInsertModel } from "app/models/05-QUA/AssetModels/AssetFieldModel";
import { SelectOptions } from "app/components_v2/__inputs";
import { AssetDynamicField } from "../AssetDynamicField/AssetDynamicField";

const INITIAL_ERROR_MESSAGES: ErrorDynamicAssetModel = {
    errorLabel: "",
    errorMax: "",
    errorMin: "",
    errorUnit: "",
    id: "",
};

export const AssetDynamicFields: FC<AssetFormProps<AssetFormDynamicFieldsModel, AssetFormValidationsModel>> = ({
    assetFields,
    isDisabled,
    onInputChange,
    customError,
}) => {
    const { t } = useTranslation();
    const { assetDynamicFieldOptions, measurementTypeOptions, setMeasurementTypeOptions } = useAssetDynamicFields();

    const { errorDynamicAsset } = customError;

    const addNewDynamicfield = (type: AssetDynamicFieldModel) => {
        const assetFieldsFiltered = assetFields.filter((x) => !x.isDeleted);
        if (assetFieldsFiltered.length >= 20) return;
        if (type === "TEXT") {
            addNewDynamicFieldText();
            return;
        }

        addNewDynamicFieldNumber();
    };

    const addNewDynamicFieldText = () => {
        onInputChange({
            assetFields: [
                ...assetFields,
                {
                    assetDynamicField: "TEXT",
                    dbId: undefined,
                    id: v4(),
                    label: "",
                    isDeleted: false,
                    assetFieldRange: null,
                },
            ],
        });
    };

    const addNewDynamicFieldNumber = () => {
        onInputChange({
            assetFields: [
                ...assetFields,
                {
                    assetDynamicField: "NUMBER",
                    id: v4(),
                    label: "",
                    assetFieldRange: {
                        max: null,
                        min: null,
                        fK_MeasurementUnit: 0,
                        unit: "",
                        id: 0,
                    },
                    isDeleted: false,
                },
            ],
        });
    };

    const handleChangeDynamicfield = (values: AssetFieldInsertModel) => {
        const selectedAssetField = assetFields.find(({ id }) => id === values.id);
        if (!selectedAssetField) return;
        const assetFieldsToSave = assetFields.map((assetField) => (assetField.id === values.id ? values : assetField));
        onInputChange({ assetFields: assetFieldsToSave });
    };

    const handleDeleteAssetDynamicField = (id: string) => {
        const selectedAssetDynamicField = assetFields.find((field) => field.id === id);
        if (!selectedAssetDynamicField) return;
        if (selectedAssetDynamicField.dbId) {
            onInputChange({
                assetFields: assetFields.map((field) => (field.id === id ? { ...field, isDeleted: true } : field)),
            });
            return;
        }

        onInputChange({
            assetFields: assetFields.filter((field) => field.id !== id),
        });
    };

    return (
        <div className="assetDynamicFields">
            {!isDisabled ? (
                <div className="assetDynamicFields__assetField">
                    <SelectOptions
                        onChange={({ value }) => addNewDynamicfield(value as AssetDynamicFieldModel)}
                        options={assetDynamicFieldOptions}
                        placeholder={t(TranslationKeys.ASSET_ADD_DYNAMIC_FIELD_PLACEHOLDER)}
                        isMulti={false}
                        errorMessage={errorDynamicAsset.errorMessage}
                    />
                </div>
            ) : null}
            <div className="assetDynamicFields__assetDynamicField">
                {assetFields
                    .filter((x) => !x.isDeleted)
                    .map((assetfield) => (
                        <AssetDynamicField
                            data={assetfield}
                            onChange={handleChangeDynamicfield}
                            key={assetfield.id}
                            disabled={isDisabled}
                            onDelete={handleDeleteAssetDynamicField}
                            customerError={
                                errorDynamicAsset.dynamicAssets.find((errorField) => errorField.id === assetfield.id) ||
                                INITIAL_ERROR_MESSAGES
                            }
                            measurementTypeOptions={measurementTypeOptions}
                            onChangeMeasurementTypeOptions={setMeasurementTypeOptions}
                        />
                    ))}
            </div>
        </div>
    );
};
