import { useTranslation } from "react-i18next";
import { FC } from "react";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { OnBoardingStepsTranslation, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";

type OnBoardingSubStepModalProps = {
    name: string;
    isLoading: boolean;
    onComplete: () => void;
    onClose: () => void;
};

export const OnBoardingSubStepModal: FC<OnBoardingSubStepModalProps> = ({ name, onComplete, onClose, isLoading }) => {
    const { t } = useTranslation();

    return (
        <ConfirmModal
            onConfirm={onComplete}
            onConfirmText={t(TranslationCommon.COMPLETE)}
            title={t(OnBoardingStepsTranslation.ONBOARDING_COMPLETE_TITLE).replace("{0}", t(name))}
            description={t(OnBoardingStepsTranslation.ONBOARDING_COMPLETE_SUBSTEP_DESCRIPTION)}
            onClose={onClose}
            onCloseText={t(TranslationKeys.CLOSE)}
            isLoading={isLoading}
        />
    );
};
