import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import {
    faArrowDownToLine,
    faClose,
    faFilter,
    faMagnifyingGlass,
    faSearch,
    faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { INITIAL_TH_SF_VALUES, INITIAL_TH_SORT_DIRECTION } from "../constants/taskHistoryConstants";
import { Input } from "app/components_v2/__inputs";
import { isEqual } from "lodash";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { TaskHistoryHeader } from "./components/TaskHistoryHeader/TaskHistoryHeader";
import { TaskHistoryList } from "./components/TaskHistoryList/TaskHistoryList";
import { TaskHistoryTable } from "./components/TaskHistoryTable/TaskHistoryTable";
import { TaskHistoryTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTaskHistoryGrid } from "./hooks/useTaskHistoryGrid";
import { useTranslation } from "react-i18next";
import TaskDetailModal from "../../01-TAR-Dashboard/TaskDetail/TaskDetailModal";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { FloatingButton } from "app/components_v2/__buttons/FloatingButton/FloatingButton";
import { ExportCsvModal } from "app/components_v2/__modals/ExportCsvModal/ExportCsvModal";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";
import { TableActionButtons } from "app/components_v2/Table/TableActionButtons/TableActionButtons";

export const TaskHistoryGrid: FC = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    const {
        data,
        handleChangePageIndex,
        handleSaveSecondaryFilter,
        handleSortChange,
        isLoading,
        isModalOpened,
        onCompanyChange,
        onQueryChange,
        onQuerySearch,
        onSelectCompany,
        openTask,
        pageIndex,
        query,
        secondaryFilterValues,
        selectCompany,
        setIsModalOpened,
        setShowSearchInput,
        setShowSecondaryFilter,
        showSearchInput,
        showSecondaryFilter,
        sortDirection,
        sortField,
        taskInstanceId,
        title,
        total,
        handleExport,
        currentTab,
        onChangeCurrentTab,
        isExportModalVisible,
        setIsExportModalVisible,
        isExporting,
    } = useTaskHistoryGrid();

    const tabs: TabPropsReduced[] = [
        {
            text: t(TaskHistoryTranslations.TASK_HISTORY_LIST),
            onClick: onChangeCurrentTab,
            type: "dark",
            children: (
                <TaskHistoryList
                    data={data}
                    isLoading={isLoading}
                    onChangePageIndex={handleChangePageIndex}
                    onDetailClick={openTask}
                    pageIndex={pageIndex}
                    total={total}
                />
            ),
        },
        {
            text: t(TaskHistoryTranslations.TASK_HISTORY_TABLE),
            onClick: onChangeCurrentTab,
            type: "dark",
            children: (
                <TaskHistoryTable
                    data={data}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    total={total}
                    openTask={openTask}
                    onChangePageIndex={handleChangePageIndex}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                        handleSortChange(sortFieldParam, sortedTypeParam || INITIAL_TH_SORT_DIRECTION);
                    }}
                    onClickExportCsv={handleExport}
                />
            ),
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faMagnifyingGlass,
            onClick: () => {
                setShowSearchInput(true);
            },
            inputComponent: {
                component: (
                    <Input
                        focus
                        onChange={onQueryChange}
                        iconRight={!!query.length ? faSearch : faClose}
                        value={query}
                        onClickIcon={onQuerySearch}
                        onPressEnter={onQuerySearch}
                        whiteInput
                        transparent
                    />
                ),
                show: showSearchInput,
            },
            title: t(TranslationCommon.SEARCH),
        },
        {
            icon: faFilter,
            onClick: () => {
                onCompanyChange({ isOpen: true });
                setShowSearchInput(false);
            },
            inputComponent: {
                component: (
                    <TableSelectCompany
                        onChange={onSelectCompany}
                        value={selectCompany.company}
                        onClickOutside={() => onCompanyChange({ isOpen: false })}
                    />
                ),
                show: selectCompany.isOpen,
            },
            title: t(TranslationCommon.CHANGE_COMPANY),
        },
        {
            icon: faSliders,
            onClick: () => setShowSecondaryFilter(true),
            showMarkableIcon: !isEqual(secondaryFilterValues, INITIAL_TH_SF_VALUES),
            title: t(TranslationCommon.FILTER),
        },
    ];

    return (
        <>
            {isModalOpened && (
                <TaskDetailModal
                    onClose={() => setIsModalOpened(false)}
                    taskInstanceId={String(taskInstanceId)}
                    editTaskDetail={false}
                    isReviewPhotos
                    showValoratedBy
                    editStarRating={false}
                    allowOpenIssue={false}
                />
            )}
            {isExportModalVisible && (
                <ExportCsvModal
                    onClose={() => setIsExportModalVisible(false)}
                    onConfirm={handleExport}
                    title={t(TranslationKeys.CSV_EXPORT)}
                    pageIndex={pageIndex}
                    total={total}
                />
            )}
            <PageContainer
                header={
                    <TaskHistoryHeader
                        title={title}
                        secondayFilter={{
                            filterData: secondaryFilterValues,
                            initialFilterData: INITIAL_TH_SF_VALUES,
                            showSecondaryFilter: showSecondaryFilter,
                            onCancel: () => setShowSecondaryFilter(false),
                            onSave: handleSaveSecondaryFilter,
                            companyId: selectCompany.company.value,
                        }}
                        actionButtons={actionButtons}
                        tabs={
                            <>
                                <div className="taskInstanceGrid__tabsWithCounter">
                                    <Tabs
                                        tabs={tabs}
                                        currentTab={currentTab}
                                        hideChildren
                                        height={width >= screenSize.MOBILE ? 50 : 40}
                                    />
                                    {width < screenSize.MOBILE && (
                                        <p className="taskInstanceGrid__tabsWithCounter__counter">
                                            {total} {t(TranslationKeys.TASKS)}
                                        </p>
                                    )}
                                </div>
                                {width >= screenSize.MOBILE && (
                                    <div className="taskHistoryHeader__icons">
                                        <p className="taskInstanceGrid__tabsWithCounter__counter">
                                            {total} {t(TranslationKeys.TASKS)}
                                        </p>
                                        <TableActionButtons actionButtons={actionButtons} />
                                    </div>
                                )}
                            </>
                        }
                    />
                }
                fullHeight={!data.length && !isLoading}
            >
                {tabs[currentTab].children}
                {currentTab === 0 && (
                    <FloatingButton
                        iconRight={faArrowDownToLine}
                        onClick={() => setIsExportModalVisible(true)}
                        isLoading={isExporting}
                    />
                )}
            </PageContainer>
        </>
    );
};
