import { FC } from "react";
import { useStepFour } from "./hooks";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { StartTask } from "./components";
import { useTranslation } from "react-i18next";
import { TaskTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { StepFourProps } from "./models";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { TaskHour } from "./components/TaskHour/TaskHour";
import { Switch } from "app/components_v2/__inputs";
import { TaskRecurring } from "./components/TaskRecurring/TaskRecurring";
import { Divider } from "../../../../../components_v2/Divider/Divider";

export const StepFour: FC<StepFourProps> = ({
    taskStart,
    taskHours,
    recurringTask,
    finish,
    taskType,
    onChange,
    next,
    issueId,
}) => {
    const {
        onAddNewHour,
        recurentTaskSelectorValues,
        validations,
        validate,
        options,
        weekOptions,
        onDeleteHour,
        onAddNewRangeHour,
        onCloseModal,
        onConfirmModal,
        showModalHoursOutOfTime,
        onTaskTypeChange,
    } = useStepFour({
        onChange,
        recurringTask,
        taskHours,
        taskStart,
        finish,
        taskType,
        next,
        issueId,
    });

    const { t } = useTranslation();

    return (
        <WizardForm validate={validate}>
            <div className="stepFourTaskWizard">
                <StartTask
                    onChange={onChange}
                    taskStart={taskStart}
                    customError={validations.taskStart}
                    recurringTask={recurringTask}
                />
                {!issueId && (
                    <Switch
                        checked={taskType === "SPORADIC"}
                        onChange={onTaskTypeChange}
                        label={t(TaskTranslations.IS_TASK_SPORADIC)}
                    />
                )}
                {taskType !== "SPORADIC" && (
                    <TaskHour
                        hours={taskHours}
                        onAddNewHour={onAddNewHour}
                        onAddNewRangeHour={onAddNewRangeHour}
                        onChange={onChange}
                        onDeleteHour={onDeleteHour}
                        validation={validations.taskHours}
                    />
                )}
            </div>
            <Divider />
            {taskType !== "SPORADIC" && recurringTask && (
                <TaskRecurring
                    taskStart={taskStart}
                    recurringTask={recurringTask}
                    recurentTaskSelectorValues={recurentTaskSelectorValues}
                    validations={validations}
                    customRepeatEveryOptions={options}
                    weekOptions={weekOptions}
                    finish={finish}
                    onChange={onChange}
                />
            )}

            {showModalHoursOutOfTime && (
                <ConfirmModal
                    onConfirm={onConfirmModal}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={onCloseModal}
                    title={t(TranslationKeys.HOURS_OUT_OF_RANGE_TITLE)}
                    description={t(TranslationKeys.HOURS_OUT_OF_RANGE_DESCRIPTION)}
                    portal
                />
            )}
        </WizardForm>
    );
};
