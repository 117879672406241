import { TaskGridSecondaryFilterModel } from "../../models/taskGridSecondaryFilterModel";

export const fillTaskExtraParams = (
    {
        responsable,
        detail,
        isRecurrent,
        frequency,
        fromHour,
        toHour,
        isPhotoRequired,
        isDataRequired,
        isCritical,
        workingPositionDepartments,
        baseTask,
        taskType,
    }: TaskGridSecondaryFilterModel,
    planId: string | null,
    assignedTo?: string,
    initialRender?: boolean,
    companyId?: string
): string => {
    let params = `IsRecurrent=${isRecurrent}&`;
    if (companyId !== "-1") params += `CompanyId=${companyId}&`;
    if (assignedTo === "workingPosition") {
        params += `IsAssignedToQR=true&AssignedTo=WORKING_POSITION&workingPositionId=${responsable.value}&`;
        if (workingPositionDepartments.value !== "-1") params += `DepartmentId=${workingPositionDepartments.value}&`;
    } else {
        params += `AssignedTo=${assignedTo}&`;
    }

    if (responsable.assignedTo === "user" && responsable.value.length) params += `UserId=${responsable.value}&`;
    if (responsable.assignedTo === "department" && responsable.value.length)
        params += `DepartmentId=${responsable.value}&`;
    if (responsable.assignedTo === "subDepartment" && responsable.value.length)
        params += `SubDepartmentId=${responsable.value}&`;

    if (detail.value.length) params += `Detail=${detail.text}&`;
    if (frequency) params += `Frequency=${frequency}&`;
    if (fromHour) params += `FromHour=${fromHour}&`;
    if (toHour) params += `ToHour=${toHour}&`;
    if (isPhotoRequired) params += `IsPhotoRequired=${isPhotoRequired}&`;
    if (isDataRequired) params += `IsDataRequired=${isDataRequired}&`;
    if (isCritical) params += `IsCritical=${isCritical}&`;
    if (initialRender) params += `InitialRender=true&`;
    if (taskType && taskType !== "-1") params += `taskType=${taskType}&`;
    if (!!baseTask.length && baseTask !== "-1") params += `FK_BaseTask=${baseTask}&`;
    if (!!planId && !!planId.length) params += `FK_PlanAPPCC=${planId}`;
    return params;
};
