import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";

export type TaskPillDateProps = {
    date?: string;
    completedDate?: string;
    isDisabledRange?: boolean;
    startHour?: string;
};

export const TaskPillDate: FC<TaskPillDateProps> = ({ date, completedDate, isDisabledRange, startHour }) => {
    const { t } = useTranslation();
    return (
        <div className="taskPill__section--last__date">
            {isDisabledRange && <p> {` ${t(TranslationKeys.TASKINSTANCE_START_HOUR)} ${startHour}`}</p>}
            {date && <p> {` ${t(TranslationKeys.TASKINSTANCE_LIMIT_DATE)} ${date}`}</p>}

            {completedDate && (
                <p>{` ${t(TranslationKeys.TASKINSTANCE_COMPLETED_OUT_OF_TIME_TEXT)} ${completedDate}`}</p>
            )}
        </div>
    );
};
