import { FC } from "react";
import { TaskRangeHour } from "./TaskRangeHour";
import { CustomErrorTaskTemporalityModel, EditDataModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { AddTaskHourButtons } from "app/components_v2/WizardTask";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import {
    CustomRepeatEvery,
    CustomSelectOptionsEveryMonth,
    RecurringTaskCheckbox,
    SelectRecurringTask,
    TaskEndsNever,
    TaskEndsSpecificDay,
    TaskEndsTimes,
} from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step4/components";
import { CustomWeek } from "./CustomWeek";
import { Label } from "app/components_v2/__inputs";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { useTaskTemporality } from "../hooks";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { Divider } from "app/components_v2/Divider/Divider";

type TaskTemporalityRecurrentProps = EditDataModel<TaskTemporalityModel, CustomErrorTaskTemporalityModel>;

export const TaskTemporalityRecurrent: FC<TaskTemporalityRecurrentProps> = ({
    taskHours,
    customError,
    finish,
    isDisabled,
    onChange,
    recurringTask,
    taskStart,
    taskType,
}) => {
    const { t } = useTranslation();

    const {
        onAddNewHour,
        recurentTaskSelectorValues,
        customOptionsValue,
        weekOptions,
        onAddNewRangeHour,
        onDeleteHour,
    } = useTaskTemporality(taskHours, onChange, taskStart, recurringTask);

    if (!recurringTask || taskType === "SPORADIC") return null;

    const isCustom = recurringTask.value === "custom";

    const taskHoursNoRange = taskHours.filter(({ type }) => type === "hour");
    const taskHoursRange = taskHours.filter(({ type }) => type === "range");

    return (
        <div className="taskTemporalityRecurrent">
            <div className="taskTemporality__rangeHours">
                <div className="taskTemporality__hours">
                    {taskHoursNoRange.map(({ hour, id }) => (
                        <TaskRangeHour
                            hour={hour}
                            id={id}
                            onChange={onChange}
                            onDeleteHour={onDeleteHour}
                            taskHours={taskHours}
                            key={id}
                            type={"hour"}
                            errorMessage={customError.taskHours.errors.find((el) => el.id === id)?.value}
                            disabled={isDisabled}
                        />
                    ))}

                    {taskHours.length < 3 && !isDisabled && (
                        <AddNewButton onClick={onAddNewHour}>{t(TranslationKeys.ADD_HOUR)}</AddNewButton>
                    )}
                </div>

                <div className="taskTemporality__multiHours">
                    {taskHoursRange.map(({ hour, id, maxHour }) => (
                        <TaskRangeHour
                            hour={hour}
                            maxHour={maxHour}
                            id={id}
                            onChange={onChange}
                            onDeleteHour={onDeleteHour}
                            taskHours={taskHours}
                            key={id}
                            type={"range"}
                            errorMessage={customError.taskHours.errors.find((el) => el.id === id)?.value}
                            disabled={isDisabled}
                        />
                    ))}
                    {taskHours.length < 3 && !isDisabled && (
                        <AddNewButton onClick={onAddNewRangeHour}>{t(TranslationKeys.ADD_RANGE_HOUR)}</AddNewButton>
                    )}
                </div>

                {!!customError.taskHours.value.length && <ErrorMessage errorMessage={customError.taskHours.value} />}
            </div>
            <Divider />
            <div className="taskTemporalityRecurrent__recurrency">
                <RecurringTaskCheckbox
                    onChange={onChange}
                    recurringTask={recurringTask}
                    taskStart={taskStart}
                    disabled={isDisabled}
                />
                <div className="taskTemporalityRecurrent__recurrency__container">
                    {recurringTask && recurringTask.isRecurrent && (
                        <>
                            <div className="taskTemporalityRecurrent__recurrency__repeat">
                                <SelectRecurringTask
                                    onChange={onChange}
                                    recurentTaskSelectorValues={recurentTaskSelectorValues}
                                    recurringTask={recurringTask}
                                    disabled={isDisabled}
                                />

                                {isCustom && (
                                    <div className="taskTemporality__customRepeat">
                                        <CustomRepeatEvery
                                            recurringTask={recurringTask}
                                            onChange={onChange}
                                            disabled={isDisabled}
                                            options={customOptionsValue}
                                        />
                                    </div>
                                )}
                                {recurringTask?.custom?.customValue === "week" && isCustom && (
                                    <CustomWeek
                                        recurringTask={recurringTask}
                                        onChange={onChange}
                                        customError={customError.customDaysBubles}
                                        disabled={isDisabled}
                                        errorClassName="task__temp__errorMessage"
                                    />
                                )}

                                {recurringTask?.custom?.customValue === "everyMonth" && (
                                    <CustomSelectOptionsEveryMonth
                                        recurringTask={recurringTask}
                                        onChange={onChange}
                                        weekOptions={weekOptions}
                                        customError={customError.customMonth}
                                        disabled={isDisabled}
                                    />
                                )}
                            </div>

                            {!!taskStart.length && (
                                <div className="taskTemporalityRecurrent__recurrency__ends">
                                    <Label label={t(TranslationKeys.TASK_ENDS)} />

                                    <TaskEndsNever finish={finish} onChange={onChange} disabled={isDisabled} />
                                    <TaskEndsSpecificDay
                                        finish={finish}
                                        onChange={onChange}
                                        customError={customError.finishEl}
                                        min={taskStart}
                                        disabled={isDisabled}
                                        errorClassName="task__temp__errorMessage"
                                    />
                                    <TaskEndsTimes
                                        finish={finish}
                                        onChange={onChange}
                                        customError={customError.finishResp}
                                        disabled={isDisabled}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
