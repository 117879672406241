import { chain } from "lodash";
import { SecScreen } from "../shared/Constants";
import {
    IssueTranslation,
    TranslationKeys,
    TranslationModules,
    TranslationTitles,
} from "app/translation/translationKeys";
import { ModuleScreenPatents, SecModule, SecScreenPatent } from "../models/ModuleModels";

export default class MapModelsHelper {
    public static fromScreenPatentsToScreenPatentCode(values: SecScreenPatent[]): SecScreenPatent[] {
        if (values.length === 0) return [];

        let result: SecScreenPatent[] = [];

        values.forEach((screen) => {
            if (screen.patents && screen.patents.length) {
                result = result.concat(
                    screen.patents.map(
                        (p): SecScreenPatent => ({
                            screenCode: screen.screenCode,
                            patentCode: p,
                        })
                    )
                );
            } else
                result.push({
                    screenCode: screen.screenCode,
                    patentCode: screen.patentCode,
                });
        });

        return result.filter((s) => s.patentCode !== "");
    }

    public static fromScreenPatentCodeToScreenPatents(values: SecScreenPatent[]): SecScreenPatent[] {
        if (values.length === 0) return [];

        return chain(values)
            .groupBy("screenCode")
            .map(
                (value, key): SecScreenPatent => ({
                    screenCode: key,
                    patentCode: "",
                    patents: value.map((p) => p.patentCode),
                })
            )
            .value()
            .filter((x) => x.screenCode !== SecScreen.LICENSES);
    }

    public static fromSecModuleToModuleScreenPatents(values: SecModule[]): ModuleScreenPatents[] {
        if (values.length === 0) return [];

        return values.map((m) => {
            const group = chain(m.screenPatents)
                .groupBy("screenCode")
                .map((value, key) => ({
                    screenCode: key,
                    patents: value.map((p) => p.patentCode),
                }))
                .value();

            return {
                moduleCode: m.moduleCode,
                screens: group.filter((x) => x.screenCode !== SecScreen.LICENSES),
            };
        });
    }

    public static translateRowTitle(title: string, t: Function) {
        switch (title) {
            case "IMPERSONATE":
                title = t(TranslationKeys.IMPERSONATE);
                break;
            case "SYSTEM_NOTIFICATION":
                title = t(TranslationTitles.PERMISION_TITLE_SYSTEM_NOTIFICATION);
                break;
            case "ASSET_PAGE":
                title = t(TranslationTitles.PERMISION_TITLE_ASSET_PAGE_PERMISSION);
                break;
            case "TASK_PHOTO":
                title = t(TranslationTitles.PERMISION_TITLE_TASK_PHOTO);
                break;
            case "FEEDBACK_TASKS":
                title = t(TranslationTitles.PERMISION_TITLE_FEEDBACK_TASKS);
                break;
            case "TASK_HISTORY":
                title = t(TranslationTitles.PERMISION_TITLE_TASK_HISTORY);
                break;
            case "REPORT":
                title = t(TranslationModules.REPORT);
                break;
            case "TAR_PLAN":
                title = t(TranslationModules.TAR_PLAN);
                break;
            case "TAR_REGISTRY":
                title = t(TranslationModules.TAR_REGISTRY);
                break;
            case "TAR_REVISION":
                title = t(TranslationModules.TAR_REVISION);
                break;
            case "ANALYTICS":
                title = t(TranslationModules.ANALYTICS);
                break;
            case "COMPANY_RANKING":
                title = t(TranslationTitles.PERMISION_TITLE_COMPANY_RANKING);
                break;
            case "ADMIN":
                title = t(TranslationTitles.PERMISION_TITLE_ADMIN);
                break;
            case "ANALYST":
                title = t(TranslationTitles.PERMISION_TITLE_ANALYST);
                break;
            case "SUPERVISOR":
                title = t(TranslationTitles.PERMISION_TITLE_SUPERVISOR);
                break;
            case "EMPLOYEE":
                title = t(TranslationTitles.PERMISION_TITLE_EMPLOYEE);
                break;
            case "ALERTS_NOTIFICATIONS":
                title = t(TranslationTitles.PERMISION_TITLE_ALERTS_NOTIFICATIONS);
                break;
            case "TAR_MANAGEMENT":
                title = t(TranslationTitles.PERMISION_TITLE_TAR_MANAGEMENT);
                break;
            case "ISSUE_MANAGEMENT":
                title = t(TranslationTitles.PERMISION_TITLE_ISSUE_MANAGEMENT);
                break;
            // ADMIN
            case "ACCES_CONFIG":
                title = t(TranslationTitles.PERMISION_TITLE_ACCES_CONFIG);
                break;
            case "CENTERS_MANAGEMENT":
                title = t(TranslationTitles.PERMISION_TITLE_CENTERS_MANAGEMENT);
                break;
            case "ROLE_MANAGEMENT":
                title = t(TranslationTitles.PERMISION_TITLE_ROLE_MANAGEMENT);
                break;
            case "TEAM_MANAGEMENT":
                title = t(TranslationTitles.PERMISION_TITLE_TEAM_MANAGEMENT);
                break;
            case "TEMPORARY_ROLES":
                title = t(TranslationTitles.PERMISION_TITLE_TEMPORARY_ROLES);
                break;
            case "USER_MANAGEMENT":
                title = t(TranslationTitles.PERMISION_TITLE_USER_MANAGEMENT);
                break;
            case "BASE_TASK_MANAGEMENT":
                title = t(TranslationTitles.PERMISION_TITLE_BASE_TASK_MANAGEMENT);
                break;
            case "TASK_PAGE":
                title = t(TranslationTitles.PERMISION_TITLE_TASK);
                break;

            // SUPERVISOR
            case "TASK_REVIEW":
                title = t(TranslationTitles.PERMISION_TITLE_TASK_REVIEW);
                break;

            // EMPLOYEE
            case "MY_VALORATION":
                title = t(TranslationTitles.PERMISION_TITLE_MY_VALORATION);
                break;
            case "RESET_TASK":
                title = t(TranslationTitles.PERMISION_TITLE_RESET_TASK);
                break;

            // ALERTS_NOTIFICATIONS
            case "ALERTS":
                title = t(TranslationTitles.PERMISION_TITLE_ALERTS);
                break;
            case "SYSTEM_NOTIFICATION2":
                title = t(TranslationTitles.PERMISION_TITLE_SYSTEM_NOTIFICATION);
                break;

            // TAR_MANAGEMENT
            case "ASSETS":
                title = t(TranslationTitles.PERMISION_TITLE_ASSETS);
                break;
            case "QR":
                title = t(TranslationTitles.PERMISION_TITLE_QR);
                break;
            case "REASIGN_TASK":
                title = t(TranslationTitles.PERMISION_TITLE_REASIGN_TASK);
                break;
            case "TASK":
                title = t(TranslationTitles.PERMISION_TITLE_TASK);
                break;

            // ISSUE_MANAGEMENT
            case "ATTACH_FILES":
                title = t(TranslationTitles.PERMISION_TITLE_ATTACH_FILES);
                break;
            case "CLOSE_ISSUE":
                title = t(TranslationTitles.PERMISION_TITLE_CLOSE_ISSUE);
                break;
            case "OPEN_MANUAL_ISSUE":
                title = t(TranslationTitles.PERMISION_TITLE_OPEN_MANUAL_ISSUE);
                break;
            case "OPEN_TASK_ISSUE":
                title = t(TranslationTitles.PERMISION_TITLE_OPEN_TASK_ISSUE);
                break;
            case "REOPEN_ISSUE":
                title = t(TranslationTitles.PERMISION_TITLE_REOPEN_ISSUE);
                break;
            case "ISSUE":
                title = t(TranslationTitles.PERMISION_TITLE_ISSUE);
                break;
            case "ALLOW_GALERY_PHOTO":
                title = t(TranslationTitles.PERMISION_TITLE_ALLOW_GALERY_PHOTO);
                break;
            case "JOB_DESC_MANAGER":
                title = t(TranslationTitles.PERMISION_TITLE_JOB_DESC_MANAGER);
                break;
            case "APPCC_MODULE":
                title = t(TranslationTitles.PERMISION_TITLE_PLAN_APPCC);
                break;
            case "PLAN_APPCC":
                title = t(TranslationTitles.PERMISION_TITLE_APPCC_MODULE);
                break;
            case "REGISTRY_MANAGEMENT":
                title = t(TranslationTitles.PERMISION_TITLE_REGISTRY_MANAGEMENT);
                break;
            case "CORR_MEASURE_MANAGER":
                title = t(TranslationTitles.PERMISION_TITLE_CORR_MEASURE_MANAGER);
                break;

            // AUDIT
            case "AUDIT_MANAGEMENT":
                title = t(TranslationTitles.PERMISSION_TITLE_AUDIT_MANAGEMENT);
                break;
            case "AUDIT_GROUP_MANAG":
                title = t(TranslationTitles.PERMISSION_TITLE_AUDIT_GROUP_MANAGEMENT);
                break;
            case "AG_CHECKLIST_MANAG":
                title = t(TranslationTitles.PERMISSION_TITLE_AG_CHECKLIST_MANAGEMENT);
                break;
            case "AGCL_ITEM_MANAG":
                title = t(TranslationTitles.PERMISSION_TITLE_AGCL_ITEM_MANAGEMENT);
                break;
            case "AUDIT_INST_MANAG":
                title = t(TranslationTitles.PERMISSION_TITLE_AUDIT_INSTANCE_MANAG);
                break;
            case "AUDIT_INST_REV_NC":
                title = t(TranslationTitles.PERMISSION_TITLE_AUDIT_INSTANCE_NC);
                break;
            case "AUDIT_INST_REPORT":
                title = t(TranslationTitles.PERMISSION_TITLE_AUDIT_INST_REPORT);
                break;
            case "AUDIT_DEL_NC":
                title = t(TranslationTitles.PERMISSION_TITLE_AUDIT_INSTANCE_DEL_NC);
                break;

            // SPORADIC TASK
            case "SPORADIC_TASK":
                title = t(TranslationTitles.PERMISSION_TITLE_SPORADIC_TASK);
                break;
            case "AUTOMATIC_RECORD":
                title = t(TranslationTitles.PERMISSION_TITLE_AUTOMATIC_RECORD);
                break;

            // EMAIL DISTRIBUTION LIST
            case "DISTRIBUTION_LIST":
                title = t(TranslationTitles.PERMISSION_TITLE_EMAIL_DISTRIBUTION_LIST);
                break;

            // DASHBOARD
            case "DASHBOARD":
                title = t(TranslationKeys.MY_TASKS);
                break;

            // ISSUE CLASSIFICATION
            case "ISSUE_CLASSIFICATION":
                title = t(IssueTranslation.ISSUE_CLASSIFICATION);
                break;

            // CLOSE COMPANIES & DEPARTMENTS
            case "CLOSE_COMPANY_DEPT":
                title = t(TranslationTitles.PERMISSION_TITLE_CLOSE_COMPANY_DEPT);
                break;
            default:
                break;
        }

        return title;
    }
}
