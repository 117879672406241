import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType, SortedTypeModel } from "app/components_v2/Table/types";
import { faCirclePlus, faMagnifyingGlass, faPen } from "@fortawesome/pro-regular-svg-icons";
import { faQrcode } from "@fortawesome/pro-light-svg-icons";
import { hasPermissionToAdd, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { TabPropsReduced } from "app/components_v2/Tabs";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
    WorkingPositionTranslations,
} from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { WorkingPositionGridMobileBody } from "./WorkingPositionGridMobileBody/WorkingPositionGridMobileBody";
import { WorkingPositionModel } from "app/models/02-TAR/WorkingPosition/WorkingPositionModel";
import WorkingPositionService from "app/services/02-TAR/WorkingPositionService";
import { PlanAPPCCCounters } from "app/pages/05-QUA/PlanAPPCC/components/PlanAPPCCCounters/PlanAPPCCCounters";
import { formatBigNumber } from "app/helpers/formatBigNumbers";

const INITIAL_SORT_FIELD = "CustomerInstanceWorkingPosition.QrCode";
const INITIAL_SORT_DIRECTION: SortedTypeModel = "asc";

export const WorkingPositionGrid = () => {
    const session = useSession();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { handleToast } = useToast();

    useTitle(t(TranslationTitles.WORKING_POSITION_PAGE_TITLE));

    const [data, setData] = useState<WorkingPositionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const [sortField, setSortField] = useState<string>(INITIAL_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_SORT_DIRECTION);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<number>(0);

    const { handleCompanyChange, selectCompany, filterIcon } = useSelectCompanyTable();

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.ACTIVE_TAB),
            onClick: () => onChangeTab(0),
            type: "dark",
        },
        // {
        //     text: t(TranslationKeys.INACTIVE_TAB),
        //     onClick: () => onChangeTab(1),
        //     type: "dark",
        // },
    ];

    const columns: ColumnsType<WorkingPositionModel>[] = [
        {
            label: t(TranslationKeys.WORKING_POSITION_CODE_LABEL),
            dataIndex: "CustomerInstanceWorkingPosition.QrCode",
            render: ({ customerInstanceWorkingPosition }) => (
                <CellTitle title={customerInstanceWorkingPosition?.qrCode || "-"} />
            ),
            sortedType: "asc",
        },
        {
            label: t(TranslationKeys.WORKING_POSITION_NAME_LABEL),
            dataIndex: "name",
            render: (record) => <CellTitle title={record.name} />,
            sortedType: "default",
        },
        {
            label: t(TranslationKeys.WORKING_POSITION_DESCRIPTION_LABEL),
            dataIndex: "description",
            render: (record) => <>{record.description ? <CellTitle title={record.description} /> : "-"}</>,
            sortedType: "default",
        },
        {
            label: t(WorkingPositionTranslations.WORKING_POSITION_TASK_COUNT),
            dataIndex: "AssociatedTasksCount",
            sortedType: "default",
            className: "workingPositionGrid__taskCount",
            render: ({ associatedTasksCount, id }) => (
                <div className="workingPositionGrid__center">
                    <PlanAPPCCCounters
                        readonly={!hasPermissionToView(session, SecScreen.TASK)}
                        title={formatBigNumber(associatedTasksCount, 2)}
                        to={`/${PrivatePaths.TASK_PAGE}?workingPositionId=${id}`}
                    />
                </div>
            ),
        },
        {
            label: t(WorkingPositionTranslations.WORKING_POSITION_APPCC_COUNT),
            dataIndex: "AssociatedTasksAppccCount",
            sortedType: "default",
            className: "workingPositionGrid__appccCount",
            render: ({ id, associatedTasksAppccCount }) => (
                <div className="workingPositionGrid__center">
                    <PlanAPPCCCounters
                        readonly={!hasPermissionToView(session, SecScreen.BASE_TASK_MANAGEMENT)}
                        title={formatBigNumber(associatedTasksAppccCount, 2)}
                        to={`/${PrivatePaths.REGISTRY_MANAGEMENT}?workingPositionId=${id}`}
                    />
                </div>
            ),
        },
        {
            label: t(TranslationCommon.EDIT),
            dataIndex: "edit",
            alignCenter: true,
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => navigate(`edit/${record.id}`),
                            title: t(TranslationCommon.EDIT),
                        },
                    ]}
                />
            ),
        },
    ];

    const fetch = async (pi?: number, companyId?: string, tab?: number) => {
        setIsLoading(true);
        const pageIndexExtraparam = pi !== undefined ? pi : pageIndex - 1;
        const isDeletedTab = tab !== undefined ? tab : currentTab;
        const companyIdFetch = companyId || selectCompany.company.value;

        const {
            data: assetData,
            error,
            status,
        } = await WorkingPositionService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexExtraparam,
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
            extraParams: `${companyIdFetch !== "-1" ? `companyId=${companyIdFetch}&` : ""}IsDeleted=${
                isDeletedTab === 1 ? "true" : "false"
            }`,
        });

        if (!status()) {
            setIsLoading(false);
            handleToast({ title: error, variant: "danger" });
            return;
        }

        setData(assetData.list);
        setTotal(assetData.count);
        setIsLoading(false);
    };

    const onQuerySubmit = () => {
        if (!query.length) {
            setQuery("");
            setShowSearchInput(false);
        }
        fetch(0);
        setPageIndex(1);
    };

    const onChangeTab = (tabIndex: number) => {
        if (tabIndex === currentTab) return;
        setCurrentTab(tabIndex);
        setPageIndex(1);
        setSortField(INITIAL_SORT_FIELD);
        setSortDirection(INITIAL_SORT_DIRECTION);
        setIsLoading(true);
        fetch(0, undefined, tabIndex);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const companyIdFetch = selectCompany.company.value || session?.workingCompany?.companyId;
        return WorkingPositionService.GetDataExport({
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
            extraParams: `${companyIdFetch !== "-1" ? `companyId=${companyIdFetch}&` : ""}IsDeleted=${
                currentTab === 1 ? "true" : "false"
            }`,
            ...params,
        });
    };

    useEffect(() => {
        fetch(undefined);
    }, [sortField, sortDirection]);

    return (
        <PageContainer paddingTop>
            <TableCollapsable
                cols={columns.filter((col) => !col.hidden)}
                data={data}
                placeholder={<TableError icon={faQrcode} />}
                title={`${t(TranslationTitles.WORKING_POSITION_TITLE)} (${selectCompany.company.label})`}
                subTitle={`${total} ${total === 1 ? t(TranslationKeys.CODE) : t(TranslationKeys.CODES)}`}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={(pi) => {
                    setPageIndex(pi);
                    fetch(pi - 1);
                }}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                    setSortField(sortFieldParam);
                    if (sortedTypeParam) setSortDirection(sortedTypeParam);
                }}
                actionButtons={[
                    {
                        icon: faCirclePlus,
                        onClick: () => navigate("new"),
                        hidden: !hasPermissionToAdd(SecScreen.QR),
                        title: t(TranslationCommon.CREATE),
                    },
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <TableInputText
                                    onChange={setQuery}
                                    value={query}
                                    fetch={onQuerySubmit}
                                    cleanFunction={onQuerySubmit}
                                />
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                    {
                        icon: filterIcon,
                        onClick: () => {
                            handleCompanyChange({ isOpen: true });
                            setShowSearchInput(false);
                        },
                        inputComponent: {
                            component: (
                                <TableSelectCompany
                                    onChange={({ label, value }) => {
                                        handleCompanyChange({
                                            company: { label, value },
                                            isOpen: false,
                                        });
                                        setPageIndex(1);
                                        fetch(0, value);
                                    }}
                                    value={selectCompany.company}
                                    onClickOutside={() => handleCompanyChange({ isOpen: false })}
                                />
                            ),
                            show: selectCompany.isOpen,
                        },
                        title: t(TranslationCommon.FILTER),
                    },
                ]}
                onClickExportCsv={handleExport}
                onDoubleClick={(record) => navigate(`edit/${record.id}`)}
                tabs={tabs}
                currentTab={currentTab}
                mobileBody={(row, _i, rowPosition) => (
                    <WorkingPositionGridMobileBody workingPosition={row} key={row.id} rowPosition={rowPosition} />
                )}
            />
        </PageContainer>
    );
};
