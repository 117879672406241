import { Wizard } from "app/components_v2/wizards/Wizard";
import { useSession } from "app/hooks";
import { useIssueAssetsModal } from "app/hooks/Issue/useIssueAssetsModal";
import { useToast } from "app/hooks/Toast/useToast";
import { IssueInsertModel } from "app/models/05-QUA/IssueModels/IssueModel";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import IssueService from "app/services/05-QUA/IssueService";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { IssueAssetLastStep } from "./IssueAssetLastStep/IssueAssetLastStep";
import { IssueAssetModalStep } from "./IssueAssetModalStep/IssueAssetModalStep";

type IssueAssetsModalProps = {
    onFinish: () => void;
};

export const IssueAssetsModal: FC<IssueAssetsModalProps> = ({ onFinish }) => {
    const { t } = useTranslation();
    const { handleToast, removeToast } = useToast();
    const session = useSession();

    const {
        correctiveMeassureOptions,
        isFormSubmiting,
        supervisorsOptions,
        data,
        onIssueChange,
        reset,
        wizard,
        onIssueSupervisorsChange,
        supervisors,
        taskInstanceId,
        setIsFormSubmiting,
        assetValues,
        showCorrectiveMeassures,
    } = useIssueAssetsModal();

    const { currentStepIndex, goTo, next, onStepChange, headerItems, totalSteps } = wizard;

    const handleFinish = async () => {
        setIsFormSubmiting(true);
        const companyId = session?.workingCompany?.companyId;
        const toastId = v4();

        const issuePromises: Promise<ServiceResponse<unknown>>[] = [];
        let hasPhoto = false;
        data.forEach(({ correctiveMeassures, description, fK_Asset, attachments, fK_IssueClassification }) => {
            if (!hasPhoto) hasPhoto = !!attachments.length;

            const issue: IssueInsertModel = {
                description,
                issueCorrectiveMeasures: correctiveMeassures.map(({ value }) => ({
                    fK_CorrectiveMeasure: Number(value),
                })),
                issueType: "TASK_ASSET",
                audioBase64Content: "",
                fK_TaskInstance: taskInstanceId,
                issueAttachments: attachments.map(({ base64 }) => ({
                    date: new Date(),
                    fileBase64Content: base64 || "",
                })),
                fK_Asset,
                fK_Company: Number(companyId),
                supervisorIds: supervisors.map(({ value }) => Number(value)),
                isCritical: false,
                fK_IssueClassification: fK_IssueClassification === "-1" ? null : Number(fK_IssueClassification),
            };

            if (hasPhoto) {
                handleToast({
                    title: t(TranslationModals.ISSUE_IMAGE_LOADING_TITLE),
                    subtitle: t(TranslationModals.ISSUE_IMAGE_LOADING_DESCRIPTION),
                    variant: "primary",
                    type: "alert",
                    isLoading: true,
                    id: toastId,
                });
            }

            const promiseIssue = IssueService.MultipleSave([issue]);
            issuePromises.push(promiseIssue);
        });

        Promise.all(issuePromises).finally(() => {
            setIsFormSubmiting(false);
            removeToast(toastId);
            reset();
            onFinish();
        });
    };

    const assetSteps = data.map((field, key) => (
        <IssueAssetModalStep
            item={field}
            onChange={onIssueChange}
            assetName={assetValues[key][0].label || ""}
            originalItem={assetValues[key]}
            correctiveMeassuresOptions={correctiveMeassureOptions}
            next={next}
            key={field.id}
            onFinish={handleFinish}
            isLastStep={key === headerItems.length - 1}
            showCorrectiveMeassures={showCorrectiveMeassures}
        />
    ));

    const steps: ReactElement[] = [
        ...assetSteps,
        <IssueAssetLastStep
            onChange={onIssueSupervisorsChange}
            onFinish={handleFinish}
            supervisorOptions={supervisorsOptions}
            supervisors={supervisors}
        />,
    ];

    return (
        <GenericModal
            header={{
                title: t(TranslationKeys.CREATE_MANUAL_ISSUE_TITLE),
                onClose: reset,
            }}
            hideChildrenPadding
            allowCloseOnEscape={false}
            size="md-tall"
        >
            <Wizard
                currentStepIndex={currentStepIndex}
                headerItems={headerItems}
                onStepChange={onStepChange}
                step={steps[currentStepIndex - 1]}
                totalSteps={totalSteps}
                goTo={goTo}
                validateNextStep={false}
                isLoading={isFormSubmiting}
                finishButtonText={t(TranslationKeys.SEND_AND_COMPLETE_MANUAL_ISSUE)}
                onClose={reset}
            />
        </GenericModal>
    );
};
