import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "app/hooks";
import { useAutomaticRecord } from "./hooks/useAutomaticRecord";
import { faClose, faMagnifyingGlass, faSearch, faSliders, faTemperatureList } from "@fortawesome/pro-regular-svg-icons";
import { AutomaticRecordTranslations, TranslationCommon, TranslationTitles } from "app/translation/translationKeys";
import { getFullDateWithTimeReadable } from "app/helpers";
import { PaginationDefaults } from "app/shared/Constants";
import { INITIAL_AUTOMATIC_RECORD_FILTER_DATA } from "./constants/automaticRecordConstants";
import { ColumnsType } from "app/components_v2/Table/types";
import { SensorEntriesListModel } from "app/models/04-SEN/SensorEntriesModels/SensorEntriesListModel";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { Badge } from "app/components_v2/Badge/Badge";
import { MobileAutomaticRecord } from "./components/MobileAutomaticRecord/MobileAutomaticRecord";
import { AutomaticRecordFilter } from "app/components_v2/__filters/AutomaticRecordFilter/AutomaticRecordFilter";
import { Input } from "app/components_v2/__inputs";
import { useSensorBattery } from "./hooks/useSensorBattery";
import { useSensorSignal } from "./hooks/useSensorSignal";
import { isEqual } from "lodash";
import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";

export const AutomaticRecordGrid: FC = () => {
    const { t } = useTranslation();

    useTitle(t(TranslationTitles.AUTOMATIC_RECORD_PAGE_TITLE));

    const {
        automaticRecordList,
        filterData,
        handleChangeCompany,
        isLoading,
        onChangePageIndex,
        onChangeSortDirectionField,
        onCompanyChange,
        onExport,
        onQueryChange,
        onQuerySearch,
        onSaveSf,
        pageIndex,
        query,
        setShowSearchInput,
        setShowSecondaryFilter,
        showSearchInput,
        showSecondaryFilter,
        sortDirection,
        sortField,
        total,
        selectCompany,
        filterIcon,
    } = useAutomaticRecord();
    const { batteryBadgeTitle, batteryBadgeColor, batteryBadgeIcon } = useSensorBattery();
    const { signalBadgeTitle, signalBadgeColor, signalBadgeIcon } = useSensorSignal();

    const columns: ColumnsType<SensorEntriesListModel>[] = [
        {
            dataIndex: "assetId",
            label: t(AutomaticRecordTranslations.AUTOMATIC_RECORD_ASSET_LABEL),
            sortedType: "default",
            render: ({ assetName }) => <CellTitle title={assetName} />,
        },
        {
            dataIndex: "sensorSerialNumber",
            label: t(AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_LABEL),
            sortedType: "default",
            render: ({ sensorSerialNumber }) => <CellTitle title={sensorSerialNumber} />,
        },
        {
            dataIndex: "temp",
            label: t(AutomaticRecordTranslations.TEMP_LABEL),
            sortedType: "default",
            className: "automaticRecord__stretch",
            render: ({ temp }) => (
                <div className="automaticRecord__center">
                    <CellTitle title={`${temp} ºC`} />
                </div>
            ),
        },
        {
            dataIndex: "battery",
            label: t(AutomaticRecordTranslations.BATTERY_LEVEL_LABEL),
            sortedType: "default",
            alignCenter: true,
            render: ({ battery }) => (
                <div className="automaticRecord__center">
                    <Badge
                        size="md"
                        title={batteryBadgeTitle(battery)}
                        variant={batteryBadgeColor(battery)}
                        icon={batteryBadgeIcon(battery)}
                        iconPosition="right"
                    />
                </div>
            ),
        },
        {
            dataIndex: "signalLevel",
            label: t(AutomaticRecordTranslations.SIGNAL_LEVEL_LABEL),
            sortedType: "default",
            alignCenter: true,
            render: ({ signalLevel }) => (
                <div className="automaticRecord__center">
                    <Badge
                        size="md"
                        title={signalBadgeTitle(signalLevel)}
                        variant={signalBadgeColor(signalLevel)}
                        icon={signalBadgeIcon(signalLevel)}
                        iconPosition="right"
                    />
                </div>
            ),
        },
        {
            dataIndex: "dateTimeEntry",
            label: t(AutomaticRecordTranslations.DATE_TIME_ENTRY_LABEL),
            sortedType: "desc",
            alignCenter: true,
            render: ({ dateTimeEntry }) => (
                <div className="automaticRecord__center">
                    <CellTitle title={getFullDateWithTimeReadable(dateTimeEntry.toString())} />
                </div>
            ),
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faMagnifyingGlass,
            onClick: () => {
                setShowSearchInput(true);
            },
            inputComponent: {
                component: (
                    <div>
                        <Input
                            focus
                            onChange={onQueryChange}
                            iconRight={!!query.length ? faSearch : faClose}
                            value={query}
                            onClickIcon={onQuerySearch}
                            onPressEnter={onQuerySearch}
                            whiteInput
                            transparent
                        />
                    </div>
                ),
                show: showSearchInput,
            },
        },
        {
            icon: filterIcon,
            onClick: () => {
                onCompanyChange({ isOpen: true });
                setShowSearchInput(false);
            },
            inputComponent: {
                component: (
                    <TableSelectCompany
                        onChange={handleChangeCompany}
                        value={selectCompany.company}
                        onClickOutside={() => onCompanyChange({ isOpen: false })}
                    />
                ),
                show: selectCompany.isOpen,
            },
            title: t(TranslationCommon.CHANGE_COMPANY),
            hidden: showSearchInput,
        },
        {
            icon: faSliders,
            onClick: () => setShowSecondaryFilter(true),
            showMarkableIcon: !isEqual(INITIAL_AUTOMATIC_RECORD_FILTER_DATA, filterData),
        },
    ];

    return (
        <>
            <AutomaticRecordFilter
                filterData={filterData}
                initialFilterData={INITIAL_AUTOMATIC_RECORD_FILTER_DATA}
                isOpen={showSecondaryFilter}
                onSave={onSaveSf}
                onCancel={() => setShowSecondaryFilter(false)}
            />
            <PageContainer paddingTop>
                <TableCollapsable
                    title={t(TranslationTitles.AUTOMATIC_RECORD_PAGE_TITLE)}
                    subTitle={`${total} ${t(AutomaticRecordTranslations.REGISTRY_NUMBER)}`}
                    cols={columns}
                    data={automaticRecordList}
                    total={total}
                    placeholder={<TableError icon={faTemperatureList} />}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    onChangePageIndex={onChangePageIndex}
                    onChangeSortDirectionField={onChangeSortDirectionField}
                    sortDirection={sortDirection}
                    sortField={sortField}
                    onClickExportCsv={onExport}
                    actionButtons={actionButtons}
                    mobileBody={(row) => <MobileAutomaticRecord data={row} />}
                />
            </PageContainer>
        </>
    );
};
