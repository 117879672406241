import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { EditTaskDisabledFieldsModel } from "app/models/02-TAR/Task/EditTask";

export const baseTaskToDisableds = ({
    name,
    description,
    fotoExample,
    isPhotoRequired,
    reportType,
    allowAnyData,
    isCritical,
}: BaseTaskModel): EditTaskDisabledFieldsModel => {
    return {
        disabledDataToReport: reportType !== "NONE",
        disabledChecklist: reportType === "CHECKLIST" && !allowAnyData,
        disabledDynamicFields: reportType === "DYNAMIC_FIELD" && !allowAnyData,
        disabledTaskTitle: !!name.length,
        disabledTaskDesc: !!description?.length,
        disabledFotoExample: !!fotoExample?.length,
        disabledIsPhotoRequired: isPhotoRequired,
        disabledPlan: true,
        disabledIsCritical: isCritical,
    };
};
