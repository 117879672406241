import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CompanyWizardModelExtra } from "./types";
import { TranslationKeys } from "app/translation/translationKeys";

type Step4Props = {
    handleFinish: () => void;
    data: CompanyWizardModelExtra;
};

const Step4: FC<Step4Props> = ({ data, handleFinish }) => {
    const { t } = useTranslation();
    const { departments, company } = data;

    return (
        <WizardForm
            validate={(e) => {
                e.preventDefault();
                handleFinish();
            }}
        >
            <div className="companyWizardStep4">
                <p className="companyWizardStep4__text">
                    {t(TranslationKeys.WIZARD_COMPANY_CHECK_LAST_STEP)
                        .replace("{1}", company?.name || "")
                        .replace("{2}", company?.groupName || "")}
                </p>
                {!!departments.length && (
                    <>
                        <p className="companyWizardStep4__clone">{t(TranslationKeys.WIZARD_COMPANY_CLONE_LAST_STEP)}</p>
                        <ul className="companyWizardStep4__list">
                            {departments.map(({ name, users, id, subs }) => (
                                <li key={id} className="companyWizardStep4__list__item">
                                    {name} {!!users.length && `(${users.length} ${t(TranslationKeys.USERS)})`}
                                    <ul className="companyWizardStep4__list">
                                        {subs.map(({ id, name, users }) => (
                                            <li key={id} className="companyWizardStep4__list__item">
                                                {name}{" "}
                                                {!!users.length && `(${users.length} ${t(TranslationKeys.USERS)})`}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </WizardForm>
    );
};

export default Step4;
