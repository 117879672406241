import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { MailModal } from "app/components_v2/__modals/base/MailModal/MailModal";
import { OnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingModel";
import { FC, FormEvent, useState } from "react";
import { INITIAL_ON_BOARDING_REVIEW_FORM_ERRORS, INITIAL_ON_BOARDING_REVIEW_FORM_VALUES } from "./constants";
import { OnBoardingReviewFormErrors, OnBoardingReviewFormValues } from "./types";
import { ON_BOARDING_STATUS } from "../../constants/constants";
import { SelectOptions, TextArea } from "app/components_v2/__inputs";
import { useTranslation } from "react-i18next";
import { OnBoardingStepsTranslation, TranslationCommon } from "app/translation/translationKeys";
import { OptionModel } from "app/models/02-TAR/OptionModel";

type OnBoardingReviewStepsModalProps = {
    onComplete: (status: OnBoardingStatus, comment?: string) => void;
    onClose: () => void;
    name: string;
    isLoading: boolean;
};

const formId = "OnBoardingReviewStepsForm";

export const OnBoardingReviewStepsModal: FC<OnBoardingReviewStepsModalProps> = ({
    name,
    onClose,
    onComplete,
    isLoading,
}) => {
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState<OnBoardingReviewFormValues>(INITIAL_ON_BOARDING_REVIEW_FORM_VALUES);
    const [formErrors, setFormErrors] = useState<OnBoardingReviewFormErrors>(INITIAL_ON_BOARDING_REVIEW_FORM_ERRORS);

    const { comment, status } = formValues;
    const { errorComment, errorStatus } = formErrors;
    const options: OptionModel[] = [
        { label: t(OnBoardingStepsTranslation.ONBOARDING_REVIEW_CORRECT), value: ON_BOARDING_STATUS.DONE },
        { label: t(OnBoardingStepsTranslation.ONBOARDING_REVIEW_INCORRECT), value: ON_BOARDING_STATUS.IN_PROGRESS },
    ];

    const handleInputChange = (values: Partial<OnBoardingReviewFormValues>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (errors: Partial<OnBoardingReviewFormErrors>) => {
        setFormErrors((prev) => ({ ...prev, ...errors }));
    };

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!validate() || status === "-1") return;

        onComplete(status, comment);
    };

    const validate = () => {
        let isValid = true;
        setFormErrors(INITIAL_ON_BOARDING_REVIEW_FORM_ERRORS);
        if (status === "-1") {
            handleErrorChange({ errorStatus: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isValid = false;
        }

        if (!comment.length) {
            handleErrorChange({ errorComment: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isValid = false;
        }

        return isValid;
    };

    return (
        <MailModal
            confirmButton={{
                text: t(OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW),
                iconRight: faPaperPlane,
                type: "submit",
                form: formId,
            }}
            loading={isLoading}
            onClose={onClose}
            title={t(OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_TITLE).replace("{0}", name)}
        >
            <form onSubmit={onSubmit} id={formId} className="onBoardingReviewStepsModal">
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => handleInputChange({ status: value as OnBoardingStatus })}
                    options={options}
                    label={t(OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_OPTION)}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    selectedValue={status !== "-1" ? status : undefined}
                    errorMessage={errorStatus}
                />
                <TextArea
                    onChange={(newComment) => handleInputChange({ comment: newComment })}
                    label={t(OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_COMMENT)}
                    text={comment}
                    errorMessage={errorComment}
                    placeholder={t(OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_COMMENT)}
                />
            </form>
        </MailModal>
    );
};
