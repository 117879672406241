import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { AuthLayout } from "app/components_v2/__containers/AuthLayout/AuthLayout";
import { useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import PrivacyService from "app/services/00-LOGIN/PrivacyService";
import { PrivatePaths, PublicPaths } from "app/shared/Constants";
import { Actions } from "app/state/actions";
import { TranslationKeys, TranslationModals, TranslationTitles } from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const AcceptPolicyPage = () => {
    const { t } = useTranslation();
    const session = useSession();
    const isOnBoarding = session?.isOnBoarding;
    const nav = useNavigate();
    const dispatch = useDispatch();
    const { handleToast } = useToast();

    useTitle(t(TranslationTitles.PRIVACY_POLICY_PAGE_TITLE));

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isCheckedErrors, setIsCheckedErrors] = useState<boolean>(false);

    const handleCheck = (boolean: boolean) => setIsChecked(boolean);

    const handleAccept = async () => {
        if (!isChecked) {
            setIsCheckedErrors(true);
            return;
        }
        if (!session) return;

        const { status } = await PrivacyService.AcceptPrivacy(session?.user.id);

        if (!status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        dispatch(Actions.updateIsPrivacyAccepted(true));
        if (isOnBoarding) return nav(`/${PrivatePaths.ON_BOARDING}`);
        nav(session.user.initialScreenCode || PrivatePaths.DASHBOARD);
    };

    useEffect(() => {
        if (session && session.user.isPrivacyAccepted) {
            nav(session.user.initialScreenCode || PrivatePaths.DASHBOARD);
        }
    }, [session]);

    return (
        <>
            <AuthLayout>
                <div className="loginInputForm acceptPolicyPage">
                    <div className="acceptPolicyPage__header">
                        <p className="acceptPolicyPage__title">{t(TranslationKeys.PRIVACY_POLICY)}</p>
                        <p className="acceptPolicyPage__text">{t(TranslationKeys.PRIVACY_POLICY_TEXT)}</p>
                    </div>
                    <div>
                        <div className="acceptPolicyPage__checkboxContainer">
                            <CheckBox checked={isChecked} onChange={handleCheck} />
                            <p className="acceptPolicyPage__checkboxContainer__text">
                                {t(TranslationKeys.PRIVACY_POLICY_ACCEPT)}{" "}
                                <a
                                    className="acceptPolicyPage__checkboxContainer__link"
                                    href={PublicPaths.PRIVACY_POLICY_PAGE}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t(TranslationKeys.PRIVACY_POLICY)}
                                </a>
                            </p>
                        </div>
                        {isCheckedErrors && <ErrorMessage errorMessage={t(TranslationKeys.INPUT_NOT_EMPTY_POLICY)} />}
                    </div>

                    <div className="acceptPolicyPage__button">
                        <NewButton fullWidth text="Aceptar" handleClickButton={handleAccept} />
                    </div>
                </div>
            </AuthLayout>
        </>
    );
};

export default AcceptPolicyPage;
