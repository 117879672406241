import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useTitle } from "app/hooks/CustomHooks";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { UserService } from "app/services";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { PaginationDefaults, SecScreen, USER_TYPE } from "app/shared/Constants";
import { hasPermission, hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { ColumnsType, SortedTypeModel } from "app/components_v2/Table/types";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { useToast } from "app/hooks/Toast/useToast";
import { faCirclePlus, faClose, faMagnifyingGlass, faPen, faSearch, faUser } from "@fortawesome/pro-regular-svg-icons";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { Input } from "app/components_v2/__inputs/Input/Input";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { TabPropsReduced } from "app/components_v2/Tabs";
import { useSession } from "app/hooks";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { MobileUserRow } from "./MobileUserRow/MobileUserRow";
import { GetUserTypeDescription } from "app/helpers/userType/GetUserTypeDescription";
import { Badge } from "app/components_v2/Badge/Badge";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { useGetInvalidTranslation } from "app/hooks/User/useGetInvalidTranslation";
import { fillUserGridExtraParams } from "./fillUserGridExtraParams";
import { UserType } from "app/shared/types/UserTypes";
import { fillUserGridCountersExtraParams } from "./fillUserGridCountersExtraparams";
import { UserCounters } from "app/dtos/00-LOGIN/User/UserCounters";
import { useFetchErrors } from "app/hooks/useFetchErrors";

const INITIAL_SORT_FIELD = "firstName";
const INITIAL_SORT_DIRECTION: SortedTypeModel = "asc";

type IGetUsers = {
    pi?: number;
    companyId?: string;
    tab?: number;
};

type IGetCounters = {
    companyId?: string;
};

const UserGrid: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { handleToast } = useToast();
    const session = useSession();
    const { getErrorMessage } = useFetchErrors();

    const [data, setData] = useState<UserModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const [sortField, setSortField] = useState<string>(INITIAL_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_SORT_DIRECTION);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [counters, setCounters] = useState<UserCounters>({
        activeUsers: 0,
        inactiveUsers: 0,
        temporaryInactiveUsers: 0,
    });
    const { handleCompanyChange, selectCompany, filterIcon } = useSelectCompanyTable();
    const { getInactiveTitleAndDate } = useGetInvalidTranslation();

    useTitle(t(TranslationTitles.USERS_PAGE_TITLE));

    const getUserColor = (userType: UserType): BadgeVariant => {
        return userType === USER_TYPE.ANALIST
            ? "purple"
            : userType === USER_TYPE.SUPERVISOR
            ? "deepGreen"
            : userType === USER_TYPE.WORKER
            ? "deepBlue"
            : "grey";
    };

    const columns: ColumnsType<UserModel>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "firstName",
            render: (record) => <CellTitle title={`${record.firstName} ${record.lastName}`} bolder />,
            sortedType: "asc",
            className: "userGrid__firstName",
        },
        {
            label: t(TranslationKeys.TABLE_USERCODE),
            dataIndex: "userCode",
            render: (record) => <CellTitle title={record.userCode} />,
            sortedType: "default",
            className: "userGrid__userCode",
        },
        {
            label: t(TranslationKeys.USER_TYPE),
            dataIndex: "userType",
            sortedType: "default",
            render: (record) => (
                <Badge variant={getUserColor(record.userType)} title={GetUserTypeDescription(record.userType, t)} />
            ),
            className: "userGrid__userCode",
        },

        {
            label: t(TranslationKeys.LABEL_ROLE),
            dataIndex: "role",
            render: (record) => <CellTitle title={record.roleNameList?.map((roleName) => roleName)?.join(" - ")} />,
            className: "userGrid__role",
        },
        {
            label: t(TranslationKeys.LABEL_DEPARTMENT_NAME),
            dataIndex: "department",
            render: (record) => <CellTitle title={record.departmentNameList?.join(" - ")} />,
            className: "userGrid__department",
        },
        {
            label: t(TranslationKeys.LABEL_INACTIVE_SINCE),
            dataIndex: "automaticActivationDate",
            render: (record) => <Badge title={getInactiveTitleAndDate(record).date} variant="grey" />,
            className: "userGrid__department",
            hidden: currentTab !== 2,
        },
        {
            label: t(TranslationCommon.EDIT),
            dataIndex: "edit",
            render: (record) => (
                <CellIcons
                    icons={[
                        { icon: faPen, onClick: () => navigate(`edit/${record.id}`), title: t(TranslationCommon.EDIT) },
                    ]}
                />
            ),
            alignCenter: true,
            hidden: !hasPermission(SecScreen.USER_MANAGEMENT),
            className: "userGrid__actions",
        },
    ];

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.ACTIVE_TAB),
            onClick: () => {
                onChangeTab(0);
            },
            type: "dark",
            rightCount: counters.activeUsers,
        },
        {
            text: t(TranslationKeys.INACTIVE_TAB),
            onClick: () => {
                onChangeTab(1);
            },
            type: "dark",
            rightCount: counters.inactiveUsers,
        },
        {
            text: t(TranslationKeys.USER_GRID_INACTIVE_WITH_DATE_TAB),
            onClick: () => {
                onChangeTab(2);
            },
            type: "dark",
            rightCount: counters.temporaryInactiveUsers,
        },
    ];

    const onChangeTab = (tabIndex: number) => {
        if (tabIndex === currentTab) return;
        setCurrentTab(tabIndex);
        setPageIndex(1);
        setSortField(INITIAL_SORT_FIELD);
        setSortDirection(INITIAL_SORT_DIRECTION);
        setIsLoading(true);
        getUsers({ pi: 0, tab: tabIndex });
        getCounters();
    };

    const getUsers = async ({ companyId, pi, tab }: IGetUsers = {}) => {
        setIsLoading(true);
        const pageIndexExtraparam = pi ?? pageIndex - 1;
        const currentTabFetch = tab ?? currentTab;
        const companyFetch = companyId || selectCompany.company.value;
        const customerInstanceId = session?.user.customerInstanceId || 0;

        const extraparams = fillUserGridExtraParams({
            companyId: companyFetch,
            currentTab: currentTabFetch,
            customerInstanceId,
        });

        const {
            data: usersData,
            getParsedError,
            status,
        } = await UserService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexExtraparam,
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
        });

        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), variant: "danger", type: "alert" });
            setIsLoading(false);
            return;
        }

        setData(usersData.list);
        setTotal(usersData.count);
        setIsLoading(false);
    };

    const getCounters = async ({ companyId }: IGetCounters = {}) => {
        const companyFetch = companyId || selectCompany.company.value;
        const customerInstanceId = session?.user.customerInstanceId || 0;

        const extraParams = fillUserGridCountersExtraParams({ companyId: companyFetch, customerInstanceId });

        const {
            data: userCounters,
            getParsedError,
            status,
        } = await UserService.GetCounters({
            extraParams,
            query: query,
        });

        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), variant: "danger", type: "alert" });
            setIsLoading(false);
            return;
        }
        setCounters(userCounters);
    };

    useEffect(() => {
        getUsers();
        getCounters();
    }, [sortField, sortDirection]);

    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const extraparams = fillUserGridExtraParams({
            companyId: selectCompany.company.value || String(session?.workingCompany?.companyId),
            currentTab,
            customerInstanceId: session?.user.customerInstanceId || 0,
        });

        return UserService.GetDataExport({
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
            ...params,
        });
    };

    const onQuerySubmit = () => {
        if (!query.length) {
            setQuery("");
            setShowSearchInput(false);
        }
        getUsers({ pi: 0 });
        getCounters();
        setPageIndex(1);
    };

    return (
        <PageContainer paddingTop>
            <TableCollapsable
                cols={columns.filter((x) => !x.hidden)}
                placeholder={<TableError icon={faUser} />}
                data={data}
                title={`${t(TranslationTitles.USERS_TITLE)} (${selectCompany.company.label})`}
                subTitle={`${total} ${total > 1 ? t(TranslationKeys.USERS) : t(TranslationKeys.USER)}`}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={(pi) => {
                    setPageIndex(pi);
                    getUsers({ pi: pi - 1 });
                    getCounters();
                }}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                    setSortField(sortFieldParam);
                    if (sortedTypeParam) setSortDirection(sortedTypeParam);
                }}
                onDoubleClick={(row) => navigate(`edit/${row.id}`)}
                actionButtons={[
                    {
                        icon: faCirclePlus,
                        onClick: () => navigate("new"),
                        hidden: !hasPermissionToAdd(SecScreen.USER_MANAGEMENT),
                        title: t(TranslationCommon.CREATE),
                    },
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <div>
                                    <Input
                                        focus
                                        onChange={setQuery}
                                        iconRight={!!query.length ? faSearch : faClose}
                                        value={query}
                                        onClickIcon={() => {
                                            onQuerySubmit();
                                        }}
                                        onPressEnter={() => {
                                            onQuerySubmit();
                                        }}
                                        whiteInput
                                        transparent
                                    />
                                </div>
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                    {
                        icon: filterIcon,
                        onClick: () => {
                            handleCompanyChange({ isOpen: true });
                            setShowSearchInput(false);
                        },
                        inputComponent: {
                            component: (
                                <TableSelectCompany
                                    onChange={({ label, value }) => {
                                        handleCompanyChange({
                                            company: { label, value },
                                            isOpen: false,
                                        });
                                        setPageIndex(1);
                                        getUsers({ pi: 0, companyId: value });
                                        getCounters({ companyId: value });
                                    }}
                                    value={selectCompany.company}
                                    onClickOutside={() => handleCompanyChange({ isOpen: false })}
                                />
                            ),
                            show: selectCompany.isOpen,
                        },
                        title: t(TranslationCommon.CHANGE_COMPANY),
                    },
                ]}
                onClickExportCsv={handleExport}
                tabs={tabs}
                currentTab={currentTab}
                mobileBody={(row, _i, rowPosition) => (
                    <MobileUserRow key={row.id} user={row} rowPosition={rowPosition} />
                )}
            />
        </PageContainer>
    );
};

export default UserGrid;
