import { useState } from "react";
import AssetService from "app/services/05-QUA/AssetService";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";

export const useGetAssets = (companyId: number, fK_PlanAPPCC?: string) => {
    const [assets, setAssets] = useState<AssetModel2[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const getAssets = async () => {
        setIsLoading(true);
        let extraParams = `companyId=${companyId}&isDeleted=false&hasFields=true`;
        if (fK_PlanAPPCC !== "-1" && !!fK_PlanAPPCC) extraParams += `&fK_PlanAPPCC=${fK_PlanAPPCC}`;
        const assetsSr = await AssetService.GetList({
            extraParams,
        });

        if (!assetsSr.status()) {
            console.error(assetsSr.error);
            setError(assetsSr.error);
            setIsLoading(false);
            return;
        }
        setAssets(assetsSr.data.list);
        setIsLoading(false);
    };

    return {
        assets,
        isLoading,
        error,
        getAssets,
    };
};
