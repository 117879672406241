import { FC, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "app/components_v2/Spinner/Spinner";

export type NewButtonProps = {
    text?: string;
    iconRight?: IconProp;
    iconLeft?: IconProp;
    onlyIcon?: boolean;
    danger?: boolean;
    handleClickButton?: (e?: React.MouseEvent<HTMLElement>) => void;
    buttonType?: "primary" | "secondary" | "tertiary" | "empty" | "emptyWhite";
    disabled?: boolean;
    fullWidth?: boolean;
    loading?: boolean;
    type?: "button" | "submit" | "reset";
    transparent?: boolean;
    form?: string;
};
export const NewButton: FC<NewButtonProps> = ({
    text = "",
    iconLeft,
    iconRight,
    onlyIcon,
    danger = false,
    handleClickButton,
    buttonType = "primary",
    disabled,
    fullWidth,
    loading,
    transparent,
    type = "button",
    form,
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const className = `${
        disabled
            ? `disabledButton${onlyIcon ? "--icon" : ""}`
            : buttonType === "secondary"
            ? `secondaryButton${onlyIcon ? "--icon" : ""}${danger ? "--danger" : ""}${isFocused ? "--focused" : ""}`
            : buttonType === "tertiary"
            ? `tertiaryButton${onlyIcon ? "--icon" : ""}${danger ? "--danger" : ""}${isFocused ? "--focused" : ""}`
            : `newButton${onlyIcon ? "--icon" : ""}${danger ? "--danger" : ""}${isFocused ? "--focused" : ""}`
    } ${fullWidth ? "fullWidth" : ""} ${loading ? `loading${onlyIcon ? "--icon" : ""}` : ""} ${
        buttonType === "empty"
            ? `emptyButton${danger ? "--danger" : ""}${isFocused ? "--focused" : ""}${disabled ? "--disabled" : ""}`
            : ""
    }
    ${
        buttonType === "emptyWhite"
            ? `emptyButton--white${isFocused ? "--focused" : ""}${disabled ? "--disabled" : ""}`
            : ""
    }
    ${transparent ? "transparentButton" : ""}`;

    return (
        <button
            id="myButton"
            className={className}
            onClick={handleClickButton}
            disabled={disabled || loading}
            onMouseDown={() => setIsFocused(true)}
            onMouseUp={() => setIsFocused(false)}
            onMouseLeave={() => setIsFocused(false)}
            type={type}
            form={form}
        >
            {iconLeft && !loading && <FontAwesomeIcon icon={iconLeft} />}
            {loading && <Spinner />}
            {text}
            {iconRight && !loading && <FontAwesomeIcon icon={iconRight} />}
        </button>
    );
};
