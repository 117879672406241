import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { DateRangePicker } from "app/components_v2/__inputs/DateRangePicker/DateRangePicker";
import { Step4, RecurrentTaskModel } from "app/models/02-TAR/TaskWizard";

type StartTaskProps = {
    taskStart: string;
    onChange: (fields: Partial<Step4>) => void;
    customError?: string;
    recurringTask: RecurrentTaskModel | null;
};

export const StartTask: FC<StartTaskProps> = ({ onChange, taskStart, recurringTask }) => {
    const { t } = useTranslation();

    if (!recurringTask) return null;

    return (
        <DateRangePicker
            startDate={new Date(taskStart)}
            onChange={(date) => {
                onChange({
                    taskStart: date[0].toString(),
                    recurringTask: {
                        ...recurringTask,
                        custom: {
                            ...recurringTask.custom,
                            customValue: "day",
                            selectedOptions: { text: "", value: "" },
                        },
                        value: "everyDay",
                        text: `${t(TranslationKeys.EVERY_DAY)}`,
                    },
                    finish: {
                        checked: "never",
                        value: null,
                    },
                });
            }}
            label={t(TranslationKeys.TASK_START)}
            range={false}
            endDate={undefined}
        />
    );
};
