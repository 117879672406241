import { faPen, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { OptionsSearch } from "app/models/FormComponentsModel";
import {
    SelectActionsLastItemsModel,
    SelectActionsModel,
    SelectActionsOptions,
} from "app/components_v2/SelectWithActions/types";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { UserPageFormValues } from "../types";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import JobDescriptionService from "app/services/01-SEG/JobDescriptionService";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { JobDescriptionModel } from "app/models/01-SEG/JobDescription/JobDescriptionModel";

type UseJobDescriptionUserInfoForm = {
    onInputChange: (values: Partial<UserPageFormValues>) => void;
    fk_JobDescription?: string;
};

type DeleteJobDescription = {
    isOpen: boolean;
    jobDescription: JobDescriptionModel | null;
};

export const useJobDescriptionUserInfoForm = ({ onInputChange, fk_JobDescription }: UseJobDescriptionUserInfoForm) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const session = useSession();

    const [jobDescriptionOptions, setJobDescriptionOptions] = useState<SelectActionsOptions[]>([]);
    const [isJobDescriptionModalVisible, setIsJobDescriptionModalVisible] = useState<boolean>(false);
    const [selectedJobDescription, setSelectedJobDescription] = useState<JobDescriptionModel | null>(null);
    const [deleteJobDescriptionState, setDeleteJobDescriptionState] = useState<DeleteJobDescription>({
        isOpen: false,
        jobDescription: null,
    });
    const [isDeleteModalLoading, setIsDeleteModalLoading] = useState<boolean>(false);

    const customerInstanceId = session?.user.customerInstanceId || 0;

    const onJobDescriptionModalVisibleChange = (isVisible: boolean) => setIsJobDescriptionModalVisible(isVisible);

    const addNewJobDescription = (jobDescription: JobDescriptionModel) => {
        onInputChange({ fk_JobDescription: String(jobDescription.id) });
        setJobDescriptionOptions((prev) => [
            ...prev,
            { label: jobDescription.description, value: String(jobDescription.id) },
        ]);
        onCancel();
    };

    const openEditModal = (item: OptionsSearch) => {
        const jobDescription: JobDescriptionModel = {
            customerInstanceId,
            description: item.label,
            id: Number(item.value),
        };
        setSelectedJobDescription(jobDescription);
        onJobDescriptionModalVisibleChange(true);
    };

    const onEditJobDescription = ({ id, description }: JobDescriptionModel) => {
        setJobDescriptionOptions((prev) =>
            prev.map((item) => (item.value === String(id) ? { label: description, value: String(id) } : item))
        );
        onCancel();
    };

    const openDeleteModal = ({ label, value }: OptionsSearch) => {
        const jobDescription: JobDescriptionModel = {
            customerInstanceId,
            description: label,
            id: Number(value),
        };
        setDeleteJobDescriptionState({ isOpen: true, jobDescription });
    };

    const closeDeleteModal = () => {
        setDeleteJobDescriptionState({ isOpen: false, jobDescription: null });
    };

    const onDeleteJobDescription = async () => {
        setIsDeleteModalLoading(true);
        const jobDescriptionId = deleteJobDescriptionState.jobDescription?.id || 0;
        const { status } = await JobDescriptionService.Delete(jobDescriptionId);
        if (!status()) {
            handleToast({
                title: t(TranslationErrors.ERROR_DELETING_JOB_DESCRIPTION),
                type: "alert",
                variant: "danger",
            });
            setIsDeleteModalLoading(false);
            return;
        }
        setJobDescriptionOptions((prev) => prev.filter(({ value }) => value !== String(jobDescriptionId)));
        if (fk_JobDescription === String(jobDescriptionId)) onInputChange({ fk_JobDescription: "-1" });
        setIsDeleteModalLoading(false);
        closeDeleteModal();
    };

    const onCancel = () => {
        onJobDescriptionModalVisibleChange(false);
        setSelectedJobDescription(null);
    };

    const getJobDescription = async () => {
        const { data, getParsedError, status } = await JobDescriptionService.GetData();
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setJobDescriptionOptions([
            {
                label: t(TranslationCommon.SELECT_OPTION),
                value: "-1",
                hideActions: true,
            },
            ...data.list.map(({ description, id }) => ({ label: description, value: String(id) })),
        ]);
    };

    const selectActions: SelectActionsModel[] = [
        {
            icon: faPen,
            onClick: openEditModal,
            hidden: !hasPermissionToEdit(SecScreen.JOB_DESC_MANAGER),
        },
        {
            icon: faTrash,
            onClick: openDeleteModal,
            hidden: !hasPermissionToDelete(SecScreen.JOB_DESC_MANAGER),
        },
    ];
    const lastItems: SelectActionsLastItemsModel[] = [
        {
            icon: faPlus,
            onClick: () => onJobDescriptionModalVisibleChange(true),
            title: t(TranslationKeys.ADD_NEW_JOB_DESCRIPTION),
            hidden: !hasPermissionToAdd(SecScreen.JOB_DESC_MANAGER),
        },
    ];

    useEffect(() => {
        getJobDescription();
    }, []);

    return {
        jobDescriptionOptions,
        lastItems,
        isJobDescriptionModalVisible,
        addNewJobDescription,
        selectedJobDescription,
        selectActions,
        onEditJobDescription,
        onCancel,
        deleteJobDescriptionState,
        closeDeleteModal,
        isDeleteModalLoading,
        onDeleteJobDescription,
    };
};
