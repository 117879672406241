import {
    AnalistTranslation,
    AriaLabels,
    IssueTranslation,
    AssetTranslations,
    AuditGridTranslation,
    TranslationCommon,
    TranslationErrors,
    TranslationFAQs,
    TranslationKeys,
    TranslationModals,
    TranslationModules,
    TranslationTitles,
    AuditTranslation,
    CloseCompanyTranslations,
    AnalistRankingTranslations,
    TaskInstanceTranslations,
    TaskTranslations,
    BaseAuditGroupCheckListItemTranslations,
    BaseAuditGroupCheckListItemTypeTranslations,
    ScanQRTranslations,
    AuditGroupCheckListItemTranslations,
    AuditInstanceTranslations,
    AuditGroupCheckListInstanceTranslations,
    ReviewTaskTranslations,
    NewUserTabsTranslation,
    OnBoardingStepsTranslation,
    AutomaticRecordTranslations,
    BaseTaskTranslations,
    AuditFilterTranslations,
    ChatBotGPTTranslations,
    CompanyTranslations,
    RoleCollapsableTranslations,
    SensorModalTranslations,
    EmailDistributionListTranslations,
    TaskPillTranslations,
    TranscriptionTranslations,
    TaskHistoryTranslations,
    IconTitleTranslations,
    AlertModalTranslations,
    DragFileTranslations,
    PatchNotesTranslations,
    WorkingPositionTranslations,
    DepartmentTranslations,
    NotificationsTranslations,
} from "./translationKeys";
import { wizards } from "./wizards.es";

export const es = {
    translation: {
        /* WIZARDS */
        ...wizards.company,
        ...wizards.department,
        ...wizards.license,
        ...wizards.role,

        /* MODULES */
        [TranslationModules.TAR_REGISTRY]: "Empleado",
        [TranslationModules.TAR_PLAN]: "Planificación de Tareas",
        [TranslationModules.TAR_REVISION]: "Supervisor",
        [TranslationModules.REPORT]: "Informes",
        [TranslationModules.ANALYTICS]: "Analista",

        /* PERMISSIONS */
        // TAR_REVISION
        [TranslationTitles.PERMISION_TITLE_TASK_PHOTO]: "Revisar Tareas",
        [TranslationTitles.PERMISION_TITLE_FEEDBACK_TASKS]: "Mis Valoraciones",
        [TranslationKeys.FEEDBACK_MODAL_ORDER_RECENT]: "Recientes",
        [TranslationKeys.FEEDBACK_MODAL_ORDER_HIGHER]: "Más Alta",
        [TranslationKeys.FEEDBACK_MODAL_ORDER_LOWER]: "Más Baja",

        // ANALYTICS
        [TranslationTitles.PERMISION_TITLE_COMPANY_RANKING]: "Análisis Centros de Trabajo",

        // TITLES
        [TranslationTitles.PERMISION_TITLE_ADMIN]: "Administrador",
        [TranslationTitles.PERMISION_TITLE_ANALYST]: "Analista",
        [TranslationTitles.PERMISION_TITLE_SUPERVISOR]: "Supervisor",
        [TranslationTitles.PERMISION_TITLE_EMPLOYEE]: "Empleado",
        [TranslationTitles.PERMISION_TITLE_ALERTS_NOTIFICATIONS]: "Alertas y Notificaciones",
        [TranslationTitles.PERMISION_TITLE_TAR_MANAGEMENT]: "Gestión de Tareas",
        [TranslationTitles.PERMISION_TITLE_ISSUE_MANAGEMENT]: "Gestión de Incidencias",

        [TranslationTitles.PERMISION_TITLE_ACCES_CONFIG]: "Acceso a Configuración",
        [TranslationTitles.PERMISION_TITLE_CENTERS_MANAGEMENT]: "Gestor de Centros",
        [TranslationTitles.PERMISION_TITLE_ROLE_MANAGEMENT]: "Gestor de Roles",
        [TranslationTitles.PERMISION_TITLE_TEAM_MANAGEMENT]: "Gestor de Equipos",
        [TranslationTitles.PERMISION_TITLE_TEMPORARY_ROLES]: "Roles Temporales",
        [TranslationTitles.PERMISION_TITLE_USER_MANAGEMENT]: "Gestor de Usuarios",
        [TranslationTitles.PERMISION_TITLE_BASE_TASK_MANAGEMENT]: "Gestor de Bolsa de Tareas",
        [TranslationTitles.PERMISION_TITLE_TASK_HISTORY]: "Histórico de Tareas",
        [TranslationTitles.PERMISION_TITLE_TASK_REVIEW]: "Revisión de Tareas",
        [TranslationTitles.PERMISION_TITLE_MY_VALORATION]: "Mis Valoraciones",
        [TranslationTitles.PERMISION_TITLE_RESET_TASK]: "Reiniciar Tareas",
        [TranslationTitles.PERMISION_TITLE_ALERTS]: "Alertas",
        [TranslationTitles.PERMISION_TITLE_SYSTEM_NOTIFICATION]: "Notificaciones de Sistema",
        [TranslationTitles.PERMISION_TITLE_ASSETS]: "Gestor de Activos",
        [TranslationTitles.PERMISION_TITLE_QR]: "Gestor de Códigos QR",
        [TranslationTitles.PERMISION_TITLE_REASIGN_TASK]: "Reasignación de Tareas",
        [TranslationTitles.PERMISION_TITLE_TASK]: "Gestor de Tareas",
        [TranslationTitles.PERMISION_TITLE_ATTACH_FILES]: "Adjuntar Documentación",
        [TranslationTitles.PERMISION_TITLE_CLOSE_ISSUE]: "Cerrar Incidencias",
        [TranslationTitles.PERMISION_TITLE_OPEN_TASK_ISSUE]: "Abrir Incidencias en Tareas",
        [TranslationTitles.PERMISION_TITLE_OPEN_MANUAL_ISSUE]: "Abrir Incidencias Manuales",
        [TranslationTitles.PERMISION_TITLE_REOPEN_ISSUE]: "Reabrir Incidencias",
        [TranslationTitles.PERMISION_TITLE_ISSUE]: "Gestor de Incidencias",
        [TranslationTitles.PERMISION_TITLE_ALLOW_GALERY_PHOTO]: "Permitir Foto de Galeria",
        [TranslationTitles.PERMISION_TITLE_JOB_DESC_MANAGER]: "Gestor de Categorías de Usuario",
        [TranslationTitles.PERMISSION_TITLE_SPORADIC_TASK]: "Tareas Esporádicas",

        /* Módulo APPCC */
        [TranslationTitles.PERMISION_TITLE_PLAN_APPCC]: "Módulo APPCC",
        [TranslationTitles.PERMISION_TITLE_APPCC_MODULE]: "Gestor de Planes",
        [TranslationTitles.PERMISION_TITLE_REGISTRY_MANAGEMENT]: "Gestor de Registros",
        [TranslationTitles.PERMISION_TITLE_ASSET_PAGE_PERMISSION]: "Gestor de Activos",
        [TranslationTitles.PERMISION_TITLE_CORR_MEASURE_MANAGER]: "Gestor de Medidas Correctivas",

        /* Audit Templates */
        [TranslationTitles.PERMISSION_TITLE_AUDIT_MANAGEMENT]: "Plantillas de Auditoría",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_GROUP_MANAGEMENT]: "Plantillas de Auditoría - Agrupaciones",
        [TranslationTitles.PERMISSION_TITLE_AG_CHECKLIST_MANAGEMENT]: "Plantillas de Auditoría - Checklists",
        [TranslationTitles.PERMISSION_TITLE_AGCL_ITEM_MANAGEMENT]: "Plantillas de Auditoría - Ítems del Checklist",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_INSTANCE_MANAG]: "Auditorías",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_INSTANCE_NC]: "Auditorías - Revisar No Conformidades",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_INST_REPORT]: "Auditorías - Informe Auditoría",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_INSTANCE_DEL_NC]: "Auditorías - Eliminar No Conformidades",

        /* Automatic Records */
        [TranslationTitles.PERMISSION_TITLE_AUTOMATIC_RECORD]: "Registros Automáticos",

        /* Email DistributionList */
        [TranslationTitles.PERMISSION_TITLE_EMAIL_DISTRIBUTION_LIST]: "Gestor de Listas de Distribución",

        /* Close Company & Department */
        [TranslationTitles.PERMISSION_TITLE_CLOSE_COMPANY_DEPT]: "Cerrar Centros y Departamentos",

        [TranslationKeys.APPCC_LABEL]: "Tarea crítica ",

        // PAGE TITLES
        [TranslationTitles.LOGIN_PAGE_TITLE]: "Inicio de Sesión",
        [TranslationTitles.RECOVER_PAGE_TITLE]: "Recuperación de contraseña",
        [TranslationTitles.RESET_PASSWORD_PAGE_TITLE]: "Cambio de contraseña",
        [TranslationTitles.ACTIVATE_ACCOUNT_PAGE_TITLE]: "Activación de cuenta",
        [TranslationTitles.SELECT_PROFILE_PAGE_TITLE]: "Seleccione una cuenta",
        [TranslationTitles.SELECT_DEPARTMENT_SUBDEPARTMENT_PAGE_TITLE]: "Seleccione Equipo/Subequipo",
        [TranslationTitles.GROUP_COMPANY_PAGE_TITLE]: "Gestor de Grupos de Centros de Trabajo",
        [TranslationTitles.COMPANY_EDIT_PAGE]: "Editar Centro de Trabajo",
        [TranslationTitles.DEPARTMENT_AND_SUBDEPARTMENT_PAGE_TITLE]: "Gestor de Equipos y Subequipos",
        [TranslationTitles.LICENSES_PAGE_TITLE]: "Gestor de Licencias",
        [TranslationTitles.MANAGE_LICENSE_PAGE_TITLE]: "Administración de Licencia",
        [TranslationTitles.MY_PROFILE_PAGE_TITLE]: "Mi perfil",
        [TranslationTitles.ROLES_PAGE_TITLE]: "Gestor de Roles",
        [TranslationTitles.EDIT_ROLE_PAGE_TITLE]: "Editar Permisos del Rol",
        [TranslationTitles.TEMPORARY_ROLES_PAGE_TITLE]: "Gestor de Roles Temporales",
        [TranslationTitles.USERS_PAGE_TITLE]: "Gestor de Usuarios",
        [TranslationTitles.NEW_USER_PAGE_TITLE]: "Nuevo Usuario",
        [TranslationTitles.EDIT_USER_PAGE_TITLE]: "Editar Usuario",
        [TranslationTitles.USER_CENTERS_AND_DEPARTMENTS_PAGE_TITLE]: "Centros y Equipos del Usuario",
        [TranslationTitles.DASHBOARD_PAGE_TITLE]: "Tareas - {0}",
        [TranslationTitles.TASK_DETAIL_TITLE]: "Detalle de la Tarea",
        [TranslationTitles.SCAN_QR_PAGE_TITLE]: "Tareas de QR",
        [TranslationTitles.REVIEW_TASK_PAGE_TITLE]: "Revisión de Tareas - {0}",
        [TranslationTitles.REVIEW_WORKER_PAGE_TITLE]: "Revisión de Tareas del Empleado",
        [TranslationTitles.FEEDBACK_PAGE_TITLE]: "Revisión de Valoraciones",
        [TranslationTitles.FEEDBACK_DETAIL_TITLE]: "Detalle de la Valoración de la Tarea",
        [TranslationTitles.TASK_PAGE_TITLE]: "Gestor de Tareas",
        [TranslationTitles.NEW_TASK_PAGE_TITLE]: "Nueva Tarea",
        [TranslationTitles.DUPLICATE_TASK_PAGE_TITLE]: "Duplicar de Tarea",
        [TranslationTitles.EDIT_TASK_PAGE_TITLE]: "Editar Tarea",
        [TranslationTitles.TASK_HISTORY_PAGE_TITLE]: "Histórico de Tareas",
        [TranslationTitles.ALERTS_PAGE_TITLE]: "Gestor de Alertas",
        [TranslationTitles.INCOMING_ALERTS_TITLE]: "Alertas Entrantes",
        [TranslationTitles.PRIVACY_POLICY_PAGE_TITLE]: "Política de Privacidad",
        [TranslationTitles.WORKING_POSITION_PAGE_TITLE]: "Gestor de Códigos QR",
        [TranslationTitles.NEW_WORKING_POSITION_PAGE_TITLE]: "Nuevo Código QR",
        [TranslationTitles.EDIT_WORKING_POSITION_PAGE_TITLE]: "Editar Código QR",
        [TranslationTitles.PRINT_QR_PAGE_TITLE]: "Impresión de Código QR",
        [TranslationTitles.BASE_TASK_PAGE_TITLE]: "Gestor de Tareas de la Bolsa",
        [TranslationTitles.NEW_BASE_TASK_PAGE_TITLE]: "Nueva Tarea de la Bolsa",
        [TranslationTitles.EDIT_BASE_TASK_PAGE_TITLE]: "Editar Tarea de la Bolsa",
        [TranslationTitles.GLOBAL_COMPANY_RANKING_PAGE_TITLE]: "Ránking de Centros de Trabajo Global",
        [TranslationTitles.GLOBAL_DEPARTMENT_RANKING_PAGE_TITLE]: "Ránking de Equipos Global",
        [TranslationTitles.GLOBAL_USER_RANKING_PAGE_TITLE]: "Ránking de Usuarios Global",
        [TranslationTitles.USER_BY_DEPARTMENT_RANKING_PAGE_TITLE]: "Ránking de Usuarios por Equipo",
        [TranslationTitles.ANALYTICS_PAGE_TITLE]: "Análisis de Centros de Trabajo",
        [TranslationTitles.DEPARTMENT_RANKING_PAGE_TITLE]: "Ránking de Equipos",
        [TranslationTitles.COMPANY_DETAIL_PAGE_TITLE]: "Detalle de Centro de Trabajo",
        [TranslationTitles.DEPARTMENT_DETAIL_PAGE_TITLE]: "Detalle de Equipo",
        [TranslationTitles.USER_DETAIL_PAGE_TITLE]: "Detalle de Usuario",
        [TranslationTitles.DETAIL_PAGE_TITLE]: "Detalle de Entidad",
        [TranslationTitles.ASSET_PAGE_TITLE]: "Gestor de Activos",
        [TranslationTitles.NEW_ASSET_PAGE_TITLE]: "Nuevo Activo",
        [TranslationTitles.EDIT_ASSET_PAGE_TITLE]: "Editar Activo",
        [TranslationTitles.AUDIT_PAGE_TITLE]: "Gestor de Auditorías",
        [TranslationTitles.NEW_AUDIT_PAGE_TITLE]: "Nueva Auditoría",
        [TranslationTitles.COMPLETED_AUDIT_PAGE_TITLE]: "Finalizar Auditoría",
        [TranslationTitles.REVIEWED_AUDIT_PAGE_TITLE]: "Cerrar Auditoría",
        [TranslationTitles.RESOLVE_AUDIT_PAGE_TITLE]: "Realizar Auditoría",
        [TranslationTitles.REVIEW_AUDIT_PAGE_TITLE]: "Revisar Auditoría",
        [TranslationTitles.AUDIT_TEMPLATE_PAGE_TITLE]: "Gestor de Plantillas de Auditoría",
        [TranslationTitles.CORRECTIVE_MEASURES_PAGE_TITLE]: "Gestor de Medidas Correctivas",
        [TranslationTitles.ISSUE_PAGE_TITLE]: "Gestor de Incidencias",
        [TranslationTitles.NEW_ISSUE_PAGE_TITLE]: "Nueva Incidencia",
        [TranslationTitles.EDIT_ISSUE_PAGE_TITLE]: "Editar Incidencia",
        [TranslationTitles.PLAN_APPCC_PAGE_TITLE]: "Gestor de Planes APPCC",
        [TranslationTitles.TASK_APPCC_PAGE_TITLE]: "Gestor de Registros APPCC",
        [TranslationTitles.NEW_TASK_APPCC_PAGE_TITLE]: "Nuevo Registro APPCC",
        [TranslationTitles.DUPLICATE_TASK_APPCC_PAGE_TITLE]: "Duplicar Registro APPCC",
        [TranslationTitles.EDIT_TASK_APPCC_PAGE_TITLE]: "Editar Registro APPCC",
        [TranslationTitles.FAQS_PAGE_TITLE]: "Preguntas Frecuentes",
        [TranslationTitles.EDIT_ISSUE_TITLE]: "Editar Incidencia",
        [TranslationTitles.BASE_AUDIT_GROUP_CHECKLIST_ITEM]: "Bolsa de Ítems",
        [TranslationTitles.AUTOMATIC_RECORD_PAGE_TITLE]: "Registros Automáticos",
        [TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE]: "Listas de Distribución",
        [TranslationTitles.PATCH_NOTES_TITLE]: "Notas del Parche",

        // COMMON
        // Permission Patents
        [TranslationCommon.ALL_PATENT]: "Todos",
        [TranslationCommon.ADD_PATENT]: "Añadir",
        [TranslationCommon.VIEW_PATENT]: "Ver",
        [TranslationCommon.MODIFY_PATENT]: "Modif.",
        [TranslationCommon.DELETE_PATENT]: "Elim.",

        [TranslationKeys.SEND]: "Enviar",
        [TranslationKeys.UPLOAD_FILE]: "Subir archivo",
        [TranslationKeys.USERS]: "Usuarios",
        [TranslationKeys.ADD_USERS]: "Añadir Usuarios",
        [TranslationKeys.SELECT_USERS]: "Selecciona uno o más usuarios",
        [TranslationKeys.RECORDING]: "Grabando",
        [TranslationKeys.SEEN]: "Visto",
        [TranslationCommon.OPEN]: "Abierto",
        [TranslationCommon.CLOSED]: "Cerrado",
        [TranslationCommon.DATA]: "Datos",
        [TranslationCommon.YES]: "Sí",
        [TranslationCommon.NO]: "No",
        [TranslationCommon.START]: "Inicia",
        [TranslationCommon.NEXT]: "Siguiente",
        [TranslationCommon.PREVIOUS]: "Anterior",
        [TranslationCommon.EXIT]: "Salir",
        [TranslationCommon.PRINT]: "Imprimir",
        [TranslationCommon.UPLOADING_FILE]: "Subiendo Archivo...",
        [TranslationCommon.TRANSCRIPTING]: "Transcribiendo",
        [TranslationKeys.END]: "Termina",
        [TranslationKeys.DAYS]: "Días",
        [TranslationKeys.DAY]: "Día",
        [TranslationKeys.EVERY]: "Cada",
        [TranslationCommon.ADD]: "Añadir",
        [TranslationCommon.NEW]: "Nueva",
        [TranslationCommon.NEW_M]: "Nuevo",
        [TranslationCommon.SEARCH]: "Buscar",
        [TranslationCommon.CHANGE_COMPANY]: "Cambiar de Centro",
        [TranslationCommon.FILTER]: "Filtrar",
        [TranslationCommon.SUBMIT]: "Enviar",
        [TranslationCommon.SAVE]: "Guardar",
        [TranslationCommon.CREATE]: "Crear",
        [TranslationCommon.ACCEPT]: "Aceptar",
        [TranslationCommon.CANCEL]: "Cancelar",
        [TranslationCommon.CONFIRM]: "Confirmar",
        [TranslationCommon.LANGUAGE]: "Idioma",
        [TranslationKeys.TOTAL_RECORDS]: "Registros en total",
        [TranslationCommon.NO_RESULTS]: "¡Ups! No hay resultados para tu búsqueda",
        [TranslationKeys.NO_DEPARTMENTS]: "¡Ups! No hay equipos",
        [TranslationKeys.NO_DEPARTMENTS_ASSIGNED]: "No Hay Equipos Asignados",
        [TranslationCommon.COMPLETE]: "Completar",

        [TranslationKeys.NO_SUBDEPARTMENTS]: "¡Ups! No hay subequipos",
        [TranslationKeys.NO_TASKS_FOR_YOUR_SEARCH_USERS]: "No hay tareas para tu búsqueda",
        [TranslationKeys.NO_TASKS_FOR_YOUR_SEARCH_USERS_TEXT]:
            "Asegúrate de que seleccionar los filtros correctos para obtener los resultados que buscas.",

        [TranslationCommon.ACCESS_DENIED]: "Acceso denegado",
        [TranslationCommon.GO_BACK]: "Volver",
        [TranslationCommon.RELOAD]: "Recargar",
        [TranslationCommon.ARE_YOU_SURE]: "Esta acción no se puede revertir, ¿está seguro?",
        [TranslationKeys.TO_DASHBOARD]: "Ir al dashboard.",
        [TranslationKeys.FORGOT_PASSWORD]: "¿Olvidó la contraseña?",
        [TranslationKeys.FORGOT_PASSWORD_TITLE]:
            "Por favor ingrese su email, un link de recuperacion sera enviado a su casilla.",
        [TranslationKeys.RESET_PASSWORD_TITLE]: "Recuperar Contraseña",
        [TranslationKeys.NEW_PASSWORD_TITLE]: "Nueva Contraseña",
        [TranslationCommon.LOGOUT]: "Cerrar Sesión",

        [TranslationCommon.EDIT]: "Editar",
        [TranslationCommon.DELETE]: "Eliminar",
        [TranslationCommon.FILTERS]: "Filtros",
        [TranslationKeys.DELETE_COMPANY_TEXT]: "Desea eliminar este Centro de Trabajo?",
        [TranslationCommon.DETAILS]: "Detalles",
        [TranslationKeys.DELETE_DEPARTMENT]: "Eliminar Equipo",
        [TranslationKeys.DELETE_MESSAGE]: "Usted eliminará",
        [TranslationKeys.CLONE_MESSAGE]: "Usted clonara",
        [TranslationKeys.MESSAGE]: "Comentario",
        [TranslationKeys.DELETE_DEPARTMENT_TEXT]: "Desea eliminar el Equipo",
        [TranslationKeys.EDIT_DEPARTMENT]: "Editar Equipo",
        [TranslationKeys.ADD_USER]: "Agregar Usuario",
        [TranslationKeys.ADD_USER_TEXT]: "Agregar Usuario al Equipo",
        [TranslationKeys.ADD_SUBDEPARTMENT]: "Agregar Subequipos",
        [TranslationKeys.EDIT_SUBDEPARTMENT]: "Editar Subequipos",
        [TranslationKeys.DELETE_SUBDEPARTMENT]: "Eliminar Subequipos",
        [TranslationKeys.DELETE_SUBDEPARTMENT_TEXT]: "Desea eliminar el Subequipo",
        [TranslationKeys.SELECT_SUBDEPARTMENTS]: "Seleccione los Subequipos",
        [TranslationKeys.SUBDEPARTMENT_NAME]: "Nombre del Subequipo",
        [TranslationKeys.NEW_SUBDEPARTMENT]: "Nuevo Subequipo",
        [TranslationKeys.SELECT_USER]: "Seleccione un usuario",
        [TranslationKeys.DRAG_FILES]: "Arrastre aquí la imagen ",
        [TranslationKeys.UPLOAD_PROFILE_PICTURE]: "SUBIR FOTO DE PERFIL",
        [TranslationKeys.UPLOAD_COMPANY_LOGO]: "SUBIR LOGOTIPO DE Centro de Trabajo",
        [TranslationKeys.INPUT_VALID_EMAIL]: "Por favor, ingrese un mail valido ",
        [TranslationCommon.INPUT_NOT_EMPTY]: "Este campo es obligatorio",
        [TranslationCommon.INVALID_NIF]: "El formato del NIF no es válido. Ej: X1234567L",
        [TranslationKeys.INPUT_NOT_EMPTY_POLICY]: "Debes aceptar nuestra politica de privacidad",
        [TranslationKeys.INPUT_MAX_CHARACTERS]: "Debe tener un máximo de {0} caracteres.",
        [TranslationKeys.INPUT_MINIMUN]: "Debe tener un minimo {0} caracteres.",
        [TranslationKeys.INPUT_RANGE]: "Debe ser un número entre {0} y {1}.",
        [TranslationKeys.INPUT_LENGTH]: "Debe tener entre {0} y {1} caracteres.",
        [TranslationKeys.INPUT_FIXED_LENGTH]: "Debe tener {0} caracteres.",
        [TranslationModals.SUCCESS_SAVE]: "Exito al guardar!",
        [TranslationModals.FAILED_SAVE]: "Fallo al guardar!",
        [TranslationModals.SUCCESS_EDIT]: "Exito al editar!",
        [TranslationModals.FAILED_EDIT]: "Fallo al editar!",
        [TranslationModals.SUCCESS_DELETE]: "Exito al eliminar!",
        [TranslationCommon.FAILED_DATA_LOADED]: "Error al cargar los datos!",
        [TranslationKeys.SELECT_INSTANCE]: "Seleccione una instancia",
        [TranslationKeys.PLEASE_SELECT_INSTANCE]: "Por favor seleccione una instancia.",
        [TranslationKeys.USER_MADE_ACTION]: "El usuario {0} en la fecha {1} realizó la acción {2} sobre {3}",
        [TranslationCommon.ENTITY_HAS_CHILDREN]: "La entidad {0} tiene asociada {1}, por tanto no puede ser eliminada.",
        [TranslationCommon.ACTION_CANNOT_BE_COMPLETED]: "La acción no se puede completar",
        [TranslationKeys.MANAGE_USERS]: "Gestionar Usuarios",
        [TranslationKeys.MODIFY_NAME]: "Modificar Nombre",
        [TranslationKeys.ROLE_NAME]: "Nombre de Rol",
        [TranslationKeys.EDIT_ROLE]: "Editar Role",
        [TranslationCommon.SELECT_OPTION]: "Selecciona una Opción",
        [TranslationCommon.NO_MORE_OPTIONS]: "No hay más opciones",
        [TranslationKeys.ADD_USER_ROLE_TEXT]: "Agregar Usuario al rol {0} para un Centro de Trabajo",
        [TranslationCommon.NOT_FOUND]: "Lo sentimos, la página que busca no existe o ha sido movida.",
        [TranslationCommon.SOMETHING_WENT_WRONG]: "Lo sentimos, ha ocurrido un error inesperado.",
        [TranslationKeys.DASHBOARD_PLACEHOLDER_TEXT]: "Haz click en una tarea para ver su detalle",
        [TranslationCommon.VIEW]: "Ver",
        [TranslationCommon.MODIFY]: "Modificar",
        [TranslationCommon.TOTAL_ACCESS]: "Acceso Total",
        [TranslationKeys.EDIT_PROFILE_PICTURE]: "Editar foto de perfil",
        [TranslationKeys.EDIT_LOGO]: "Editar Logotipo",
        [TranslationKeys.IMPERSONATE]: "Impersonar",
        [TranslationKeys.IMPERSONATING_TO]: "Impersonando al",
        [TranslationKeys.PERSONALIZATION]: "Personalización",
        [TranslationKeys.PERSONALIZED]: "Personalizado",
        [TranslationKeys.EDITING_SUBDEPARTMENT_MODAL_TEXT]: "Editando Subequipos {0} de Equipo {1}",
        [TranslationKeys.NO_USERS_TEXT]: "No existen usuarios disponibles para ser agregados",
        [TranslationKeys.ALL_ROLES_ADDED]: "Todos los roles disponibles han sido agregados",
        [TranslationKeys.NO_DEPARTMENTS_TEXT]: "El Centro de Trabajo no cuenta con Equipos y Subequipos",
        [TranslationErrors.INVALID_CHARACTERS]:
            "El nombre de usuario no puede contener espacios o caracteres especiales",
        [TranslationKeys.DUPLICATE_USERNAME]: "El nombre de usuario ya se encuentra registrado",
        [TranslationKeys.CSV_DOWNLOAD]: "Descargar .csv",
        [TranslationKeys.CSV_DOWNLOAD_ROWS]: "Cantidad de registros que desea exportar",
        [TranslationKeys.CSV_EXPORT]: "Exportar csv",
        [TranslationKeys.ROWS]: "Registros",
        [TranslationKeys.ROW_PAGES]: "Páginas",
        [TranslationKeys.ROW_FROM_THE_PAGE]: "Desde la página",
        [TranslationKeys.EXPORT_ALL]: "Exportar todo",
        [TranslationKeys.MAX_RECORDS]: "(máximo {0} registros)",
        [TranslationKeys.FROM_CURRENT_PAGE]: "Desde la página actual",
        [TranslationKeys.ROW_AMOUNT]: "Cantidad de registros",
        [TranslationKeys.DOWNLOAD]: "Descargar",
        [TranslationKeys.INSTANCE_NAME]: "Nombre de Instancia",
        [TranslationKeys.CONTACT]: "Contacto",
        [TranslationKeys.MANAGE]: "Administrar",
        [TranslationKeys.MAIN_CONTACT]: "Contacto Principal",
        [TranslationKeys.NO_GROUPS_TEXT]: "Aun no se ha creado ningun Centro de Trabajo o grupo de Centros de Trabajo",
        [TranslationKeys.EMPTY_COMPANY]: "Aun no se han creado Equipos para este Centro de Trabajo",
        [TranslationKeys.EMPTY_DEPARTMENT]: "Aun no se han creado Subequipos para este Equipo",
        [TranslationKeys.ENTER_USERNAME]: "Jperez",
        [TranslationCommon.STATUS]: "Estado",
        [TranslationKeys.PRIMARY_ADMINISTRATOR]: "Administrador Principal",
        [TranslationKeys.CONFIRMATION]: "Confirmación",
        [TranslationKeys.INSERT_ROLE_NAME]: "Ingrese el nombre del rol",
        [TranslationKeys.PLACEHOLDER_ROLE_NAME]: "Administrador",
        [TranslationKeys.TEMPORARY]: "Temporal",
        [TranslationKeys.REFRESHING_MODULES]: "Refrescando modulos...",
        [TranslationKeys.REFRESH]: "Refrescar",
        [TranslationKeys.REFRESH_COUNTDOWN]: "Los modulos se refrescaran en {0} segundos.",
        [TranslationKeys.INVALID_PHONE]: "Formato de teléfono incorrecto",
        [TranslationKeys.ORDER_BY_STARS]: "Ordenar:",
        [TranslationCommon.ORDER_BY_TABLE]: "Ordenado Por:",
        [TranslationCommon.ASCENDENT]: "Ascendente",
        [TranslationCommon.DESCENDENT]: "Descendente",
        [TranslationKeys.PRIVACY_POLICY]: "Política de Privacidad",
        [TranslationKeys.PRIVACY_POLICY_ACCEPT]: "He leído y acepto la",
        [TranslationKeys.PRIVACY_POLICY_TEXT]:
            "Te informamos que tenemos una política de privacidad que protege tus datos personales. Tu privacidad es importante para nosotros, y garantizamos la confidencialidad de la información que compartes con nosotros.",
        [TranslationCommon.IMAGE_UPLOAD_FAILED]: "No se han podido subir las imágenes",
        [TranslationCommon.IMAGE_UPLOAD_SUCCEED]: "Imágenes subidas correctamente",

        /* ----------------WIZARD COMPANY START----------------- */
        [TranslationKeys.WIZARD_COMPANY_NAME]: "Nombre del Centro de Trabajo",
        [TranslationKeys.WIZARD_COMPANY_LOGO]: "Logo (PNG o JPG)",
        [TranslationKeys.WIZARD_COMPANY_BELONGS_TO]: "Grupo de Centros de Trabajo",
        [TranslationKeys.WIZARD_COMPANY_CIF]: "CIF",
        [TranslationKeys.WIZARD_COMPANY_FISCAL_NAME]: "Nombre fiscal",
        [TranslationKeys.WIZARD_COMPANY_WHERE_IS]: "Dirección",
        [TranslationKeys.WIZARD_COMPANY_HOW_TO_CONTACT]: "¿Cómo nos contactamos?",
        [TranslationKeys.WIARD_COMPANY_INVENTORY_FREQ]: "¿Cuál es la frecuencia de inventario?",
        [TranslationKeys.WIZARD_COMPANY_WANT_TO_CLONE]:
            "¿Deseas clonar la estructura de Equipos de algun de los Centros de Trabajo existentes?",

        [TranslationKeys.WIZARD_COMPANY_SELECT_TO_INCLUDE]: "Selecciona los que deaseas incluir.",
        [TranslationKeys.WIZARD_COMPANY_WICH_OF]: "¿De cuál de ellas?",
        [TranslationKeys.WIZARD_COMPANY_NO_DEPARTMENTS]: "El centro seleccionada aún no tiene Equipos",
        [TranslationKeys.WIZARD_COMPANY_NO_SUBDEPARTMENTS]: "Este Equipo aún no tiene Subequipos",
        [TranslationKeys.WIZARD_COMPANY_WANT_TO_CLONE_TASK]:
            "¿Deseas clonar también las tareas de los Equipos y Subequipos que seleccionaste?",
        [TranslationKeys.WIZARD_COMPANY_IMPORT_USER_STRUCT]: "¿Deseas importar la estructura de usuarios?",
        [TranslationKeys.WIZARD_COMPANY_IMPORT_USER_STRUCT_INSTRUCTIONS]:
            "Selecciona los usuarios que deseas incluir en el nuevo Centro de Trabajo. Clickea en el icono para verlos individualmente.",
        [TranslationKeys.WIZARD_COMPANY_SURE_TO_CREATE]:
            "¿Confirmas que deseas crear el centro {name} clonando estos elementos?",
        [TranslationKeys.WIZARD_COMPANY_SURE_TO_CREATE_NO_ITEMS]: "¿Confirmas que deseas crear el centro {name}?",
        [TranslationKeys.WIZARD_COMPANY_CONGRATS]: "¡Felicitaciones, has creado el centro {name} exitosamente!",

        [TranslationKeys.WIZARD_EDIT_INTERNAL_ORG]: "Editar organización interna",
        [TranslationKeys.WIZARD_COMPANY_AT_LEAST_ONE]: "Selecciona al menos un Equipo con usuarios.",
        [TranslationKeys.WIZARD_COMPANY_STEP_THREE_INFO]:
            "Por favor selecciona una compañia en el paso previo para ejecutar esta accion.",
        [TranslationKeys.WIZARD_COMPANY_MARK_SUBDEPARTMENTS]: "Marca el Subequipos para administrar usuarios",
        [TranslationKeys.NO_DEPARTMENT_MESSAGE]: "El centro seleccionada aún no cuenta con Equipos",
        [TranslationKeys.NO_SUBDEPARTMENT_MESSAGE]: "Este Equipo no cuenta con Subequipos.",
        [TranslationKeys.WIZARD_COMPANY_SELECT_COMPANY]: "Por favor, seleccion un Centro de Trabajo",
        [TranslationKeys.WIZARD_COMPANY_SELECT_ONE_DEPARTMENT]: "Selecciona al menos un Equipo.",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_NAME]: "Nombre del Centro de Trabajo",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_CIF]: "A12345678",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_FISCAL_NAME]: "4Link S.L.",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_CITY]: "Ciudad",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_DIRECTION]: "Dirección",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_EMAIL]: "email@4link.app",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_PHONE]: "123456789",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_POSTAL_CODE]: "Código Postal",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_LOGO]: "Adjunta Logo",
        [TranslationKeys.IF_NOT_USERCODE_USE_DNI]: "En caso de no tener código de empleado, utilizar DNI o NIE.",
        [TranslationKeys.NO_VALORATION]: "No hay ninguna valoración",
        [TranslationKeys.NO_VALORATION_SUBTITLE]:
            "Crea una tarea para tus equipos para ver las estadísticas de tu gestión.",
        [TranslationKeys.NO_COMMENTS]: "No hay ningún comentario",
        [TranslationKeys.NO_COMMENTS_SUBTITLE]:
            "Añade un comentario a una tarea de para ver las estadísticas de tu gestión.",
        [TranslationKeys.NO_VALORATION_SUBTITLE_TASK_VALORATION]:
            "Crea una tarea para tus equipos para ver las estadísticas de tu gestión.",
        [TranslationKeys.WORKER_VALORATION_SECTION_TASK]: "No tienes tareas",
        [TranslationKeys.WORKER_VALORATION_SECTION_TASK_SUBTITLE]:
            "Para las fechas seleccionadas no tienes ninguna tarea ",
        [TranslationKeys.NO_RESULTS_PERCENTAGE_STARS_WORKER_DETAIL]:
            "Para las fechas seleccionadas no tienes ninguna tarea ",

        [TranslationErrors.WIZARD_COMPANY_ERROR_NAME]: "¡El nombre del centro de trabajo no puede estar vacío!",
        [TranslationErrors.WIZARD_COMPANY_ERROR_COPY_EXISTING_COMPANY]: "Elige una opción",
        [TranslationErrors.WIZARD_COMPANY_ERROR_SELECTED_COMPANY]: "Elige el Centro de Trabajo que deseas clonar",
        [TranslationErrors.WIZARD_COMPANY_ERROR_SELECT_AT_LEAST_ONE_DEPARTMENT]:
            "¡Debes seleccionar al menos un equipo!",

        [TranslationErrors.WIZARD_COMPANY_ERROR_COPY_EXISTING_USER]: "Elige una opción",

        [TranslationKeys.WIZARD_COMPANY_CHECK_LAST_STEP]:
            "Revisa los datos del nuevo Centro de Trabajo {1} que pertenece al grupo {2}.",
        [TranslationKeys.WIZARD_COMPANY_CLONE_LAST_STEP]: "Se van a clonar la siguiente estructura de equipos:",
        /* ----------------WIZARD COMPANY END----------------- */

        /* ----------------WIZARD INSTANCE START----------------- */

        [TranslationKeys.WIZARD_INSTANCE_NAME]: "¿Cómo se llama la nueva instancia?",
        [TranslationKeys.WIZARD_INSTANCE_PLACEHOLDER]: "Ingresa el nombre de la instancia",
        [TranslationKeys.WIZARD_INSTANCE_MAIN_CONTACT]: "¿Quien es el contacto principal?",
        [TranslationKeys.WIZARD_INSTANCE_STEP_TWO_TITLE]:
            "¿Qué módulos y funcionalidades deseas habilitarle a esta instancia?",
        [TranslationKeys.WIZARD_INSTANCE_PRIMARY_ADMIN]: "¿Quién es el administrador principal de esta cuenta?",
        [TranslationKeys.WIZARD_INSTANCE_EMAIL]: "¿Cuál es su email?",
        [TranslationKeys.WIZARD_INSTANCE_DOCUMENT]: "¿Cuál es su número de documento?",
        [TranslationKeys.WIZARD_INSTANCE_CONFIRMATION]: "¿Confirmas que deseas dar de alta la instancia?",

        /* ----------------WIZARD INSTANCE END----------------- */

        /* ----------------WIZARD ROLE START----------------- */

        [TranslationKeys.WIZARD_ROLE_NAME]: "Nombre del nuevo rol",
        [TranslationKeys.WIZARD_ROLE_PLACEHOLDER_NAME]: "Nombre del nuevo rol",
        [TranslationKeys.WIZARD_ROLE_COPY_PERMISSIONS]: "¿Quieres copiar los permisos de un rol existente?",
        [TranslationKeys.WIZARD_ROLE_SELECT_TO_CLONE]: "¿Qué rol existente deseas usar de base?",
        [TranslationKeys.WIZARD_ROLE_SELECT_ROLE]: "Selecciona un rol para copiar sus permisos",
        [TranslationKeys.WIZARD_ROLE_SELECT_PERSMISION]: "Debes elegir al menos un permiso.",
        [TranslationKeys.WIZARD_ROLE_CONFIRM_CREATION]: " Revisa y confirma los permisos antes de crear el rol",
        [TranslationKeys.WIZARD_ROLE_ADD_PATENT]: "Debes seleccionar al menos una opción",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_USER_SELECT]: "¿A qué usuario deseas otorgarle permisos temporales?",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_DATE_PICKER]: "¿Desde qué día hasta que día?",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_CONFIRM]:
            "¿Confirmas que deseas otorgar permisos temporales a {0} desde {1} hasta {2}?",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_SELECT_DATES]: "¿Desde qué día hasta qué día?",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_USER]: "Usuario",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_PLACEHOLDER_USER]: "Usuario",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_ACTIVE_PERMISIONS]: "Se van activar permisos de",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_FOR_USER]: "para el Usuario",
        [TranslationKeys.WIZARD_ROLES_FAILED_SAVE]: "No se ha podido crear el rol",

        /* ----------------WIZARD ROLE END----------------- */

        /* ----------------WIZARD ROLE START----------------- */
        [TranslationKeys.WIZARD_COMPANY_TITLE]: "¿Donde vas a trabajar hoy?",
        [TranslationKeys.SELECT_COMPANY_TITLE]: "¿En qué Centro de Trabajo estás trabajando hoy?",
        [TranslationKeys.SELECT_DEPARTMENT_TITLE]: "¿En que Equipo?",
        [TranslationKeys.SELECT_SUBDEPARTMENT_TITLE]: "¿En que Subequipos?",
        [TranslationKeys.CHOOSE_COMPANY]: "- Seleccionar Centro de Trabajo -",
        [TranslationKeys.CHOOSE_DEPARTMENT]: "- Seleccionar Equipo -",
        [TranslationKeys.CHOOSE_SUBDEPARTMENT]: "- Seleccionar Subequipos -",
        [TranslationKeys.CONFIRM_CONFIG]: "¿Confirmas querer trabajar en {0} {1} {2}?",
        /* ----------------WIZARD ROLE END----------------- */

        // USERS
        [TranslationKeys.CANNOT_DELETE_SUPERVISOR_STATE]: "No se ha podido eliminarte como supervisor",
        [TranslationKeys.CANNOT_ADD_SUPERVISOR_STATE]: "No se ha podido eliminarte como supervisor",
        [TranslationKeys.ACTIVE_TAB]: "Activados",
        [TranslationKeys.INACTIVE_TAB]: "Desactivados",
        [TranslationKeys.USER_GRID_INACTIVE_WITH_DATE_TAB]: "Desactivados con fecha",
        [TranslationErrors.INVALID_LANGUAGE]: "Idioma Inválido",

        // DASHBOARD TASK KEYS
        [TranslationKeys.MY_TASKS]: "Mis Tareas",
        [TranslationKeys.SHARED_TASKS]: "Compartidas",
        [TranslationKeys.MY_DEPARTMENTS]: "Mis Equipos",
        [TranslationKeys.PENDING]: "Pendientes",
        [TranslationKeys.SEE_ALL]: "Ver todo",
        [TranslationKeys.TASKS_DONE_COUNTERS]: "Realizadas",
        [TranslationModals.SUCCESS_EDIT_TITLE_TOAST]: "Datos guardados correctamente",
        [TranslationModals.SUCCESS_EDIT_SUBTITLE_TOAST]:
            "Las tareas de ahora en adelante se han actualizado correctamente",
        [TranslationKeys.EDIT_TASK]: "Editar Tarea",
        [TranslationKeys.DUPLICATE_TASK]: "Duplicar Tarea",
        [TranslationKeys.DELETE_TASK]: "Eliminar tarea",
        [TranslationKeys.EDIT_REGISTER]: "Editar Registro",
        [TranslationKeys.DUPLICATE_REGISTER]: "Duplicar Registro",
        [TranslationKeys.DELETE_REGISTER]: "Eliminar Registro",
        [TranslationKeys.NEW_REGISTER]: "Nuevo Registro",
        [TranslationKeys.SEVEN_DAYS]: " 7 días ",
        [TranslationKeys.LAST_SEVEN_DAYS]: "Últimos 7 días ",
        [TranslationKeys.LAST_THIRTY_DAYS]: "Últimos 30 días ",
        [TranslationKeys.NEAR_END]: "Vence pronto",
        [TranslationKeys.OUT_OF_TIME]: "Fuera de Tiempo",
        [TranslationKeys.NOT_FINISHED]: "No realizada",
        [TranslationKeys.IN_TIME]: "Realizada",
        [TranslationKeys.TIMED_OUT]: "Vencida",
        [TranslationKeys.SHARED_TIMED_OUT]: "Compartidas Vencidas",
        [TranslationKeys.SHARED_TIMED_OUT_TASKS]: "Tareas Compartidas Vencidas",
        [TranslationKeys.TIME_PAST]: "Hace {0}",
        [TranslationKeys.REQUEST_DATE]: "Fecha solicitada",
        [TranslationKeys.DELIVERY_DATE]: "Fecha de entrega",
        [TranslationKeys.WELL_DONE_TASK]: "Bien Realizada",
        [TranslationKeys.WORSE_DONE_TASK]: "Mal Realizada",
        [TranslationKeys.PIC_ASSOCIATED_TASK]: "Foto Requerida",
        [TranslationKeys.REPORTED_DATA_TASK]: "Datos Requeridos",
        [TranslationKeys.APPLY_FILTER]: "Aplicar filtros",
        [TranslationKeys.TASK_FILTER_COMPANY]: "Centro de Trabajo",
        [TranslationKeys.TASK_FILTER_USER]: "Usuario Encargado",
        [TranslationKeys.TASK_FILTER_DEPARTMENT]: "Equipos",
        [TranslationKeys.TASK_FILTER_SUBDEPARTMENT]: "Subequipos",
        [TranslationKeys.TASK_FILTER_WORKING_POSITION]: "QR",
        [TranslationKeys.TASK_FILTER_DATA]: "Dato",
        [TranslationKeys.TASK_FILTER_PLAN]: "Tarea crítica",
        [TranslationKeys.TASK_FILTER_STATUS]: "Estado",
        [TranslationKeys.TASK_FILTER_EVALUATION]: "Valoración",
        [TranslationKeys.MY_TASKS_FILTER]: "Filtro Tareas Personales",
        [TranslationKeys.SHARED_TASKS_FILTER]: "Filtro Tareas Compartidas",
        [TranslationKeys.HI_USER]: "¡Hola {0}!",
        [TranslationKeys.HI]: "Hola",
        [TranslationKeys.SHOW_ALL]: "Ver Todo",
        [TranslationKeys.DASHBOARD_SUBTITLE]: "Filtra las tareas que quieres visualizar",
        [TranslationKeys.DASHBOARD_STAR_RATING_SUPERVIR_TEXT]: "Puntuación media de tus equipos",
        [TranslationKeys.RESET_FILTERS]: "Restablecer filtros",
        [TranslationKeys.TERMINATE]: "Terminar",
        [TranslationKeys.TASK_USER_DEP_LIST]: "Mis Tareas y Equipo",
        [TranslationKeys.COMPLETED_CHECKBOX]: "Completadas",
        [TranslationKeys.CRITICAL_CHECKBOX]: "Tarea Crítica",
        [TranslationKeys.CRITICAL_CHECKBOXES]: "Tareas Críticas",
        [TranslationKeys.QR_CHECKBOX]: "Tareas con QR",
        [TranslationKeys.TASK_FILTER_LAST_SEVEN_DAYS]: "Filtro Lista Tareas No Realizadas",
        [TranslationKeys.OPEN_TASK_DETAIL]: "Abrir Detalle",
        [TranslationKeys.TASKS_OF]: "Tareas de",
        [TranslationKeys.TASKS_WITH_COMENTS]: "Tareas con comentarios",
        [TranslationKeys.TASKS_OF_DEPARTMENT]: "Tareas del Equipo",
        [TranslationKeys.TASKS_OF_SUBDEPARTMENT]: "Tareas del Subequipos",
        [TranslationKeys.DEPARTMENT_TASKS]: "Tareas de Equipos/Subequipos",
        [TranslationKeys.SCHEDULED_ALERT]: "Alerta Programada",
        [TranslationKeys.SUCCESS_ALERT_SENT]: "Alerta Enviada Correctamente",
        [TranslationKeys.SUCCESS_ALERT_INFO]: "Estas alertas se pueden volver a visualizar en tu perfil",

        [TranslationKeys.SUCCESS_ALERT_INFO_SUPERVISOR]:
            "Estas alertas se pueden volver a visualizar en la pantalla de programación de alertas",
        [TranslationKeys.FAILED_ALERT_SENT]: "Ha habido un Error",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK_TITLE]: "¿Estás seguro de que quieres reiniciar la tarea?",
        [TranslationKeys.TASK_DETAIL_TEMPERATURE_TITLE]: "Temperaturas",
        [TranslationKeys.TASK_DETAIL_DATA_TO_REPORT_TITLE]: "Registrar datos",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK_DESC]:
            "La tarea volverá a su estado inicial, las fotos subidas se borrarán y si el tiempo de vencimiento es pasado, pasará a estar vencida.",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK_BTN_OPEN]: "Si, reiniciar la tarea",
        [TranslationModals.REOPENING_TASK_CONFIRM_TEXT]: "Reiniciando...",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK_BTN_CLOSE]: "No, dejarla como está",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK]: "Reiniciar Tarea",
        [TranslationKeys.NO_DEPARTMENTS_FOR_YOUR_SEARCH]: "No hay equipos para tu búsqueda",
        [TranslationKeys.NO_USERS_FOR_YOUR_SEARCH]: "No hay usuarios para tu búsqueda",
        [TranslationKeys.NO_TASKS_FOR_YOUR_SEARCH]: "No hay Centros de Trabajo para tu búsqueda",
        [TranslationKeys.NO_TASKS_FOR_YOUR_SEARCH_DEPARTMENTS]: "No hay equipos para tu búsqueda",
        [TranslationCommon.NO_DATA_FOR_YOUR_SEARCH]: "No hay datos para tu búsqueda",
        [TranslationKeys.TASK_FIELDS_INCOMPLETE]: "¡Todavía quedan datos por completar!",
        [TranslationKeys.TASK_IS_BEING_COMPLETED]: "Completando tarea...",
        [TranslationKeys.TASK_COMPLETED_SUCCESSFULLY]: "¡Tarea completada con éxito!",
        [TranslationKeys.TASK_DETAIL_PHOTO_EXAMPLE]: "Foto de ejemplo",
        [TranslationKeys.TASK_DETAIL_PHOTO_GALERY]: "Realizada al Momento",
        [TranslationKeys.TASK_DETAIL_PHOTO_INSTANT]: "Foto de la Galería",
        [TranslationKeys.TASK_DETAIL_CHECKLIST]: "Checklist",
        [TranslationKeys.TASK_ACTIVE_BETWEEN]: "Activa de {1}h a {2}h",
        [TranslationKeys.TASK_DUE]: "Vence a las",
        [TranslationKeys.TASK_TIMED_OUT]: "Vencida a las",
        [TranslationKeys.TASK_DONE]: "Realizada",
        [TranslationKeys.DRAG_FILE_UPLOAD_PHOTO]: "Subir foto de ejemplo",
        [TranslationKeys.UPLOAD_PHOTO]: "Subir foto",

        // DASHBOARD TASKS PLACEHOLDERS LIST
        [TranslationKeys.DASHBOARD_ALERT_PLACEHOLDER]: "En este Momento no hay Ningún Mensaje que Leer",
        [TranslationKeys.DASHBOARD_FEEDBACK_PLACEHOLDER]: "En este Momento no hay Ninguna tarea que Mostrar",

        // REVIEW TASKS
        [TranslationKeys.PENDING_TASKS_1]: "Tareas ",
        [TranslationKeys.PENDING_TASKS_2]: "por hacer",
        [TranslationKeys.IS_CRITICAL]: "Tareas Críticas",
        [TranslationKeys.IS_NOT_CRITICAL]: "Tareas Comunes",
        [TranslationKeys.TOTAL_TASKS]: "Total de Tareas",
        [TranslationKeys.FEEDBACK]: "Valoración",
        [TranslationKeys.REVIEW_WITH_PHOTO]: "Con foto",
        [TranslationKeys.REVIEW_WITHOUT_PHOTO]: "Sin foto",
        [TranslationKeys.REVIEW_TIMED_OUT]: "Vencidas",
        [TranslationKeys.REVIEW_COMPLETED]: "Completadas",
        [TranslationKeys.WORKERS]: "Empleados",
        [TranslationKeys.TEAMS]: "Equipos",

        // FEEDBACK PAGE
        [TranslationErrors.FEEDBACK_COUNTERS_ERROR]: "Ha habido un error con los contadores de feedback",

        // TASK PILL KEYS
        [TranslationKeys.START_DATE]: "Empieza: ",
        [TranslationKeys.END_DATE]: "Terminada: ",

        /*--------------------TITLES-------------------------------*/
        [TranslationTitles.RECEIVED_ALERTS_TITLE]: "Alertas Recibidas",

        [TranslationKeys.PLANIFICATION_NEW_TASK]: "Crear Tareas",

        /*--------------------TITLES END---------------------------*/

        /* -----------------SEG----------------- */
        // USER PROFILE
        [TranslationKeys.MY_PROFILE]: "Mi Perfil",
        [TranslationTitles.GENERAL_INFO_TITLE]: "Información General",
        [TranslationTitles.COMPANIES_AND_DEPARTMENTS_TITLE]: "Centros y Equipos",
        [TranslationErrors.INVALID_DAY_CODE]: "El Día es Inválido",

        // COMPANY
        [TranslationKeys.DELETE_COMPANY_TITLE]: "Eliminar",
        [TranslationModals.DELETE_TASK_TITLE]: "Eliminar Tarea",
        [TranslationModals.DELETE_TASK_DESCRIPTION]:
            "Las siguientes Tareas se van a Eliminar. Esta acción es irreversible.",
        [TranslationModals.DELETE_REGISTRY_DESCRIPTION]:
            "Los siguientes Registros APPCC se van a Eliminar. Esta acción es irreversible.",

        [TranslationTitles.GROUP_COMPANY_TITLE]: "Grupo de Centros de Trabajo",
        [TranslationKeys.NEW_GROUP_COMPANY]: "Nuevo Grupo",
        [TranslationKeys.NEW_COMPANY]: "Nuevo Centro de Trabajo",
        [TranslationKeys.SELECT_COMPANY]: "Centro de Trabajo",
        [TranslationKeys.COMPANY_GENERAL_INFO_HELPER]: "Edita la información general de tu Centro de Trabajo.",
        [TranslationKeys.COMPANY_INTERNAL_ORG_HELPER]: "Edita la organización interna de tu Centro de Trabajo.",
        [TranslationKeys.ASSIGN_COMPANY]: "Asignar Centro de Trabajo",

        // DEPARTMENTS
        [TranslationTitles.DEPARTMENT_TITLE]: "Equipos",
        [TranslationKeys.DEPARTMENTS]: "Equipos",
        [TranslationKeys.SELECT_DEPARTMENT]: "Selecciona un departmento / Subequipos",
        [TranslationKeys.ALL_DEPTS_ADDED]: "Todos los Equipos disponibles han sido agregados.",
        [TranslationKeys.ASSIGN_DEPARTMENT]: "Asignar Equipo",
        [TranslationKeys.NEW_DEPARTMENT]: "Nuevo Equipo",

        // SUBDEPARTMENTS
        [TranslationKeys.SELECT_SUBDEPARTMENT]: "Subdepartmento",
        [TranslationKeys.ASSIGN_SUBDEPARTMENT]: "Asignar Subequipos",
        [TranslationKeys.SUBDEPARTMENTS]: "Subequipos",
        [TranslationErrors.SUBDEPARTMENT_NOT_EXIST]: "El Subequipo no Existe",

        // USER
        [TranslationKeys.NEW_USER]: "Nuevo Usuario",
        [TranslationKeys.USER_GENERAL_INFO_HELPER]: "Edita la información general sobre el usuario.",
        [TranslationKeys.USER_ROLES_HELPER]: "Edita los roles del usuario.",
        [TranslationKeys.USER_DEPT_HELPER]:
            "Edita a qué Centro de Trabajo / Equipo / Subequipos debe pertenecer el usuario.",

        // ROLES
        [TranslationKeys.ENABLED_MODULES]: "Modulos Habilitados",
        [TranslationTitles.TEMPORARY_ROLES_TITLE]: "Permisos Temporales",

        // INSTANCE CONFIG
        [TranslationKeys.PERSONALIZTION_HELPER]: "Personaliza la paleta de colores para tu instancia.",
        /* ---------------SEG END--------------- */

        /* -----------------TAR----------------- */
        [TranslationCommon.TODAY]: "Hoy",
        [TranslationCommon.YESTERDAY]: "Ayer",

        // REASIGN TASKS
        [TranslationKeys.REASIGN_PLACEHOLDER]: "En un futuro aquí se podrán reasignar tareas",

        // REVIEW TASKS
        [TranslationKeys.REVIEW_PLACEHOLDER]: "Seleccione un usuario/Equipo/Subequipos para revisar sus tareas",

        // TASK FEEDBACK
        [TranslationKeys.FEEDBACK_PLACEHOLDER]: "En un futuro aquí se podrán evaluar tareas",

        // CRUD TASKS
        [TranslationKeys.TASK_PAGE_PLACEHOLDER]: "Aquí puede ver todas las tareas así como crear, editar y eliminarlas",
        [TranslationKeys.NEW_TASK]: "Nueva Tarea",

        // TASK HISTORY
        [TranslationKeys.HISTORY_PLACEHOLDER]: "Aquí puede ver el histórico de todas las tareas hasta la fecha",

        // CALENDAR
        [TranslationKeys.CALENDAR_PLACEHOLDER]: "En un futuro aquí se podrá ver el calendario",

        // APPCC_PLAN
        [TranslationKeys.APPCC_PAGE_PLACEHOLDER]: "En un futuro aquí se podrá ver la lista de Tareas críticas",

        // INCIDENT HISTORY
        [TranslationKeys.INCIDENCE_HISTORY_PLACEHOLDER]:
            "En un futuro aquí se podrá el historico de incidencias de tareas",

        // TASKS FULFILLMENT
        [TranslationKeys.TASK_FULFILLMENT_PLACEHOLDER]: "En un futuro aquí se podrá ver el cumplimiento de tareas",

        // TASKS FULFILLMENT DETAIL
        [TranslationKeys.TASK_FULFILLMENT_DET_PLACEHOLDER]:
            "En un futuro aquí se podrá ver el cumplimiento detallado de una tarea",

        // TASKS DETAIL PLACEHOLDER
        [TranslationKeys.TASK_DETAIL_PLACEHOLDER]: "Haz click en una tarea para ver su detalle",
        [TranslationKeys.TASK_FEEDBACK_PLACEHOLDER]:
            "En esta sección, se encuentran disponibles las valoraciones otorgadas por los supervisores para su consulta.",
        [TranslationKeys.TASK_ALERT_PLACEHOLDER]:
            "En esta sección, se encuentran disponibles las alertas enviadas y recibidas.",

        /* ---------------TAR END--------------- */
        /* --------------ENTITIES--------------- */
        [TranslationKeys.USER]: "Usuario",
        [TranslationKeys.ROLES]: "Roles",

        //USER
        [TranslationKeys.LABEL_USERNAME]: "Username",
        [TranslationKeys.LABEL_FIRSTNAME]: "Nombre",
        [TranslationKeys.LABEL_LASTNAME]: "Apellido",
        [TranslationKeys.LABEL_USER_CODE]: "Código del Empleado",
        [TranslationKeys.LABEL_EMAIL]: "Correo",
        [TranslationKeys.LABEL_JOB_DESCRIPTION]: "Categoría",
        [TranslationKeys.PLACEHOLDER_USERNAME]: "Username",
        [TranslationKeys.PLACEHOLDER_FIRSTNAME]: "Nombre",
        [TranslationKeys.PLACEHOLDER_LASTNAME]: "Apellido",
        [TranslationKeys.PLACEHOLDER_USER_CODE]: "Código del Empleado",
        [TranslationKeys.PLACEHOLDER_EMAIL]: "Correo",
        [TranslationKeys.PLACEHOLDER_JOB_DESCRIPTION]: "Categoría",
        [TranslationKeys.LABEL_DEPARTMENT_NAME]: "Equipo",
        [TranslationKeys.LABEL_INACTIVE_SINCE]: "Inactivo hasta",
        [TranslationKeys.DEPARTMENT_NAME]: "Nombre del Equipo",
        [TranslationKeys.PLACEHOLDER_DEPARTMENT_NAME]: "Nombre del Equipo",
        [TranslationKeys.LABEL_SUBDEPARTMENT_NAME]: "Subdepartmento",
        [TranslationKeys.LABEL_ROLE]: "Rol",
        [TranslationKeys.LABEL_INITIAL_VIEW]: "Vista Inicial",
        [TranslationKeys.LABEL_ROLE_SELECTION]: "Seleccione un Rol",
        [TranslationKeys.LABEL_COMPANY_SELECTION]: "Seleccione una Centro de Trabajo",
        [TranslationKeys.LABEL_COMPANY_AUTOMATIC_ACTIVATION]: "Fecha de activación",
        [TranslationKeys.LABEL_SELECT]: "Seleccione una Opción",
        [TranslationKeys.LABEL_PASSWORD_TYPE]: "Tipo de Contraseña",
        [TranslationKeys.LABEL_PIN]: "PIN",
        [TranslationKeys.LABEL_PASSWORD]: "Contraseña",
        [TranslationKeys.LABEL_PROFILE_PICTURE]: "Foto de Perfil",
        [TranslationKeys.LABEL_SUPERVISOR]: "Supervisor",
        [TranslationKeys.LABEL_NOTIFICATION_SCHEDULE]: "Horario de Notificaciones",
        [TranslationKeys.LABEL_USER_TYPE]: "Selecciona el tipo de usuario",
        [TranslationKeys.NOTIFICATION_SCHEDULE]: "Programar Alerta",
        [TranslationKeys.USER_TYPE]: "Tipo de Usuario",
        [TranslationKeys.SEND_ALL_COMPANY]: "Enviar a los Centros de Trabajo",
        [TranslationKeys.SELECT_A_PLAN]: "Selecciona un Plan",
        [TranslationKeys.ALERT_COMPANY_SELECT_PLACEHOLDER]: "Selecciona los Centros de Trabajo",
        [TranslationKeys.ALERT_USER_SELECT_PLACEHOLDER]: "Selecciona los Usuarios",
        [TranslationKeys.ALERT_DEP_SELECT_PLACEHOLDER]: "Selecciona los Equipos",

        [TranslationKeys.LABEL_MIN_NOTIF_HOUR]: "Hora de Entrada",
        [TranslationKeys.LABEL_MAX_NOTIF_HOUR]: "Hora de Salida",

        //COMPANY
        [TranslationKeys.LABEL_NAME]: "4Link",
        [TranslationKeys.LABEL_PHONE]: "Teléfono",
        [TranslationKeys.PLACEHOLDER_PHONE]: "+34 123 456 789",
        [TranslationKeys.LABEL_COMPANY_TYPE]: "Tipo de Centro de Trabajo",
        [TranslationKeys.LABEL_CUSTOMERINSTANCEID]: "Instancia de cliente",
        [TranslationKeys.LABEL_CITY]: "Ciudad",
        [TranslationKeys.LABEL_LEGAL_NAME]: "Nombre Fiscal",
        [TranslationKeys.PLACEHOLDER_LEGAL_NAME]: "4Link SL.",
        [TranslationKeys.LABEL_ADDRESS]: "Direccion",
        [TranslationKeys.PLACEHOLDER_ADDRESS]: "Pol. Ind. Francolí",
        [TranslationKeys.PLACEHOLDER_CITY]: "Tarragona",
        [TranslationKeys.LABEL_ZIP_CODE]: "Código Postal",
        [TranslationKeys.PLACEHOLDER_ZIP_CODE]: "43006",
        [TranslationKeys.LABEL_LOGO]: "Logotipo",

        //ROLE
        [TranslationKeys.TITLE_PERMISSIONS]: "Permisos",
        [TranslationKeys.TITLE_BASE_ROLE]: "Rol Base",
        [TranslationKeys.TITLE_FROM]: "Desde",
        [TranslationKeys.TITLE_TO]: "Hasta",
        [TranslationKeys.TITLE_ACTIVE]: "Activo",
        [TranslationKeys.TITLE_INACTIVE]: "Inactivo",
        [TranslationKeys.INACTIVE_SINCE]: "Inactivo hasta {1}",
        [TranslationKeys.ROLE_DETAIL_MODAL_SUCCESS_ADD_MESSAGE]: "Se ha añadido el rol al usuario",
        [TranslationKeys.USERS_DETAIL_MODAL_SUCCESS_ADD_MESSAGE]: "Usuarios añadidos correctamente",

        [TranslationKeys.ROLE_ADDED_SUCCESSFULLY]: "El rol se ha Editado Correctamente",
        [TranslationKeys.EDIT_ROLE_FORM_NAME]: "Rol",
        [TranslationKeys.EDIT_ROLE_FORM_PLACEHOLDER_NAME]: "Rol",
        [TranslationKeys.EDIT_TEMPORARY_ROLE_FORM_NAME]: "Rol",
        [TranslationKeys.EDIT_TEMPORARY_ROLE_FORM_PLACEHOLDER_NAME]: "Rol",

        //MOVEMENT_LOG
        [TranslationKeys.TITLE_DATE]: "Fecha",
        [TranslationKeys.TITLE_TIME]: "Hora",

        //InstanceConfig
        [TranslationKeys.TITLE]: "Título",

        [TranslationKeys.TITLE_LOGO_SMALL_BASE_64]: "Logo pequeño",

        /* ------------ENTITIES END------------- */

        /* ----------------TITLES START----------------- */
        [TranslationCommon.ACTIVES]: "Activos",
        [TranslationKeys.TASK_NAME_LABEL]: "Título de la Tarea",
        [TranslationKeys.CODE]: "Código",
        [TranslationKeys.CODES]: "Códigos",

        /* ----------------TITLES END----------------- */

        /* ----------------TASK HISTORY START----------------- */
        [TranslationKeys.NO_TASK_TO_SHOW]: "No hay tareas que mostrar",
        /* ----------------TASK HISTORY END----------------- */

        /* ----------------TASK WIZARD START----------------- */
        [TranslationKeys.INVALID_TIME_FORMAT]: "El formato de las horas no es válido",

        [TranslationKeys.INVALID_HOURS_SAME_HOURS]: "Las horas no son válidas, han de ser diferentes",
        [TranslationKeys.MINIMUM_VALUE]: "El valor mínimo es {1}",

        [TranslationKeys.SELECT_ONE_DAY]: "Seleciona al menos un dia",

        [TranslationErrors.SELECT_AT_LEAST_ONE_OPTION]: "Selecciona al menos una opción",

        [TranslationKeys.TASK_DESCRIPTION]: "Descripción de la Tarea",
        [TranslationKeys.INPUT_OPTIONAL]: "Opcional",
        [TranslationKeys.BELONGNS_APPCC_PLAN]: "¿Pertence a un Tarea crítica?",
        [TranslationKeys.COMPANY_BELONGS]: "Centro de Trabajo a la que Pertenece",
        [TranslationKeys.ASIGN_EMPLOYEE_DEPARTMENT]: "Asignar empleado o {0} responsable",
        [TranslationKeys.EMPLOYEE]: "Empleado",
        [TranslationKeys.COMPANY]: "Centro de Trabajo",
        [TranslationKeys.DEPARTMENT]: "Equipos",
        [TranslationKeys.SUBDEPARTMENT]: "Subequipos",
        [TranslationKeys.EDIT_SUBDEPARTMENTS]: "Editar Subequipos",
        [TranslationKeys.EDIT_DEPARTMENTS]: "Editar Equipos",
        [TranslationKeys.DATA_TO_REPORT]: "¿Hay algún dato a reportar?",
        [TranslationKeys.DATAS_TO_REPORT]: "Datos a Reportar",
        [TranslationKeys.ADD_DATA_TO_REPORT]: "Añadir dato a reportar",
        [TranslationKeys.ADD_ITEM_TO_CHECKLIST]: "Añadir Item de Checklist",
        [TranslationKeys.ADD_OPTION]: "Añadir Opción",
        [TranslationKeys.TASK_END_LABEL]: "El",
        [TranslationKeys.PHOTOGRAPHIC_REGISTER]: "¿Se Requiere Registro Fotográfico?",
        [TranslationKeys.CRITICAL_TASK]: "¿Se Trata de una Tarea Crítica?",
        [TranslationKeys.EXAMPLE_PHOTO]: "¿Quieres añadir una foto de Ejemplo?",
        [TranslationKeys.PLACEHOLDER_TASK_TITLE]: "Registrar temperatura del congelador",
        [TranslationKeys.PLACEHOLDER_TASK_DESCRIPTION]: "Descripción de la Tarea",
        [TranslationKeys.PLACEHOLDER_REPORT_DATA]: "Reporte",
        [TranslationKeys.PLACEHOLDER_CHECKLIST]: "Escribe aquí tu subtarea",
        [TranslationKeys.PLACEHOLDER_WIZARD_TASK_START]: "Selecciona Fecha",
        [TranslationKeys.TASK_START]: "La Tarea Inicia",
        [TranslationKeys.TASK_START_HOURS]: "A las",
        [TranslationKeys.TASK_START_HOUR]: "A la",
        [TranslationKeys.TASK_START_HOUR_MOBILE]: "Hasta",
        [TranslationKeys.TASK_ENDS_HOUR_MOBILE]: "Hasta",
        [TranslationModals.FAILED_SAVE_TASK_TITLE]: "Error al guardar tarea",
        [TranslationModals.FAILED_SAVE_TASK_DESCRIPTION]:
            "Por favor, compruebe todos los campos obligatorios y vuelve a intentarlo.",
        [TranslationKeys.TASK_OUT_OF_TIME_HOUR_MOBILE]: "Completada",
        [TranslationKeys.TASK_COMPLETED_HOUR_MOBILE]: "Completada",
        [TranslationKeys.TASK_TIMED_OUT_HOUR_MOBILE]: "Venció",
        [TranslationKeys.TASKINSTANCE_COMPLETED_OUT_OF_TIME_TEXT]: "Completada:",
        [TranslationKeys.TASKINSTANCE_LIMIT_DATE]: "Fecha Límite:",
        [TranslationKeys.TASKINSTANCE_START_HOUR]: "Hora Inicio:",

        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_NUMBER]: "Introduce la Temperatura (ºC)",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_TEXT]: "Explica…",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT]: "Escoge una Opción",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_OPTION]: "Opción",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE]: "Indique Fecha del Registro",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_HOUR]: "Indique Hora del Registro",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE_AND_HOUR]: "Indique Fecha y Hora del Registro",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE_RANGE]: "Indique el Rango de Fechas",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_FIELD]: "Añadir Nuevo Campo Dinámico",

        [TranslationKeys.TASK_COMPLETED]: "Completada",
        [TranslationKeys.TASK_COMPLETED_OUT_OF_TIME]: "Fuera de Tiempo",
        [TranslationKeys.TASK_PENDING]: "Pendiente",
        [TranslationKeys.TASK_DISABLED]: "Deshabilitada",
        [TranslationKeys.TASK_NEAR_TO_END]: "A Punto de Vencer",
        [TranslationKeys.TASK_NOT_COMPLETED]: "Vencida",

        [TranslationKeys.RECURRENT_TASK_CHECKBOX]: "¿Se Trata de una Tarea Recurrente?",
        [TranslationKeys.EVERY_DAY]: "Cada día",
        [TranslationKeys.EVERY_WEEK_DAY]: "Cada semana el {1}",
        [TranslationKeys.EVERY_MONTH_DAY]: "Cada mes el {1}",
        [TranslationKeys.EVERY_YEAR_DAY_MONTH]: "Anualmente el {1} de {2}",
        [TranslationKeys.EVERY_LABORAL_DAYS]: "Todos los dias laborales (de lunes a viernes)",
        [TranslationKeys.WEEK]: "Semana",
        [TranslationKeys.WEEKS]: "Semanas",
        [TranslationKeys.EVERY_WEEK]: "Cada semana",
        [TranslationKeys.MONTH]: "Mes",
        [TranslationKeys.MONTHS]: "Messes",
        [TranslationKeys.YEAR]: "Año",
        [TranslationKeys.YEARS]: "Años",
        [TranslationKeys.SPECIFIC_DAY]: "El dia {1}",
        [TranslationKeys.FIRST_DAY_WEEK]: "El primer {1}",
        [TranslationKeys.SECOND_DAY_WEEK]: "El segundo {1}",
        [TranslationKeys.THIRD_DAY_WEEK]: "El tercer {1}",
        [TranslationKeys.FOURTH_DAY_WEEK]: "El cuarto {1}",
        [TranslationKeys.FIFTH_DAY_WEEK]: "El quinto {1}",

        [TranslationKeys.AFTER]: "Después de",
        [TranslationKeys.NEVER]: "Nunca",
        [TranslationKeys.REPETITIONS]: "Repeticiones",
        [TranslationKeys.REPETITION]: "repetición",
        [TranslationKeys.REPEAT_EVERY]: "Repetir cada",

        [TranslationKeys.WIZARD_HEADER_TASK_INFORMATION]: "Información",
        [TranslationKeys.WIZARD_HEADER_TASK_RESPONSIBLE]: "Responsable",
        [TranslationKeys.WIZARD_HEADER_TASK_RECORD_INFO]: "Registro Información",
        [TranslationKeys.WIZARD_HEADER_TASK_DATE]: "Fecha",
        [TranslationKeys.WIZARD_HEADER_TASK_FINISH]: "Finalizar",
        [TranslationKeys.WIZARD_HEADER_TASK_REFERENCE]: "Referencia",

        [TranslationKeys.WIZARD_SELECT_CUSTOM]: "Personalizar...",
        [TranslationKeys.CREATING_TASKS]: "Creando tareas...",
        [TranslationKeys.FAILED_GENERATING_TASKS]: "No se han podido generar las tareas",
        [TranslationKeys.TASKS_CREATED]: "Tareas creadas correctamente",
        [TranslationKeys.ACTION_NO_REVERSIBLE]: "Esta acción no es reversible, y se perderan todos los datos",

        [TranslationKeys.DATA_TO_REPORT_NO]: "Ninguna",
        [TranslationKeys.DATA_TO_REPORT_EXTRA_DATA]: "Datos extra",
        [TranslationKeys.DATA_TO_REPORT_MANUAL]: "Datos Manuales a Reportar",
        [TranslationKeys.DATA_TO_REPORT_ASSET]: "Vincular Activos",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_FIELDS]: "Campos Dinámicos",
        [TranslationKeys.CHECKLIST]: "Subtareas",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_DATE]: "Campo de Fecha",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_DATERANGE]: "Campo de Rango de Fechas",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_DATETIME]: "Campo de Fecha y Hora",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_DROPDOWN]: "Campo de Selección",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_NUMBER]: "Campo Numérico",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_TEXT]: "Campo de Texto",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_TIME]: "Campo de Hora",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_REQUIRED]: "Obligatorio",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_OPTION_TITLE]: "Opciones",

        [TranslationKeys.WEEK_MONDAY]: "Lunes",
        [TranslationKeys.WEEK_TUESDAY]: "Martes",
        [TranslationKeys.WEEK_WEDNESDAY]: "Miercoles",
        [TranslationKeys.WEEK_THURSDAY]: "Jueves",
        [TranslationKeys.WEEK_FRIDAY]: "Viernes",
        [TranslationKeys.WEEK_SATURDAY]: "Sábado",
        [TranslationKeys.WEEK_SUNDAY]: "Domingo",

        [TranslationKeys.MONTH_NAME_JANUARY]: "Enero",
        [TranslationKeys.MONTH_NAME_FEBRAURY]: "Febrero",
        [TranslationKeys.MONTH_NAME_MARCH]: "Marzo",
        [TranslationKeys.MONTH_NAME_APRIL]: "Abril",
        [TranslationKeys.MONTH_NAME_MAY]: "Mayo",
        [TranslationKeys.MONTH_NAME_JUNE]: "Junio",
        [TranslationKeys.MONTH_NAME_JULY]: "Julio",
        [TranslationKeys.MONTH_NAME_AUGUST]: "Agosto",
        [TranslationKeys.MONTH_NAME_SEPTEMBER]: "Septiembre",
        [TranslationKeys.MONTH_NAME_OCTOBER]: "Octubre",
        [TranslationKeys.MONTH_NAME_NOVEMBER]: "Noviembre",
        [TranslationKeys.MONTH_NAME_DECEMBER]: "Diciembre",

        [TranslationKeys.MONTH_NAME_JANUARY_SHORT]: "En",
        [TranslationKeys.MONTH_NAME_FEBRAURY_SHORT]: "Feb",
        [TranslationKeys.MONTH_NAME_MARCH_SHORT]: "Mar",
        [TranslationKeys.MONTH_NAME_APRIL_SHORT]: "Abr",
        [TranslationKeys.MONTH_NAME_MAY_SHORT]: "May",
        [TranslationKeys.MONTH_NAME_JUNE_SHORT]: "Jun",
        [TranslationKeys.MONTH_NAME_JULY_SHORT]: "Jul",
        [TranslationKeys.MONTH_NAME_AUGUST_SHORT]: "Ag",
        [TranslationKeys.MONTH_NAME_SEPTEMBER_SHORT]: "Sept",
        [TranslationKeys.MONTH_NAME_OCTOBER_SHORT]: "Oct",
        [TranslationKeys.MONTH_NAME_NOVEMBER_SHORT]: "Nov",
        [TranslationKeys.MONTH_NAME_DECEMBER_SHORT]: "Dic",

        [TranslationKeys.COMPANY_RESPONSIBLE_TITLE]: "Centro de Trabajo y responsable",
        [TranslationKeys.DATA_REPORT_TITLE]: "Dato a reportar",
        [TranslationKeys.CHECKLIST_TITLE]: "Lista de subtareas",
        [TranslationKeys.DYNAMIC_FIELDS_TITLE]: "Campos Dinámicos",
        [TranslationKeys.DATE_START_REPETITIONS_TITLE]: "Fecha de inicio y repetición",
        [TranslationKeys.VERIFY_INFORMATION_TITLE]: "Verifica la información",

        [TranslationKeys.DAY_WEEK_MONDAY_FIRST_LETTER]: "L",
        [TranslationKeys.DAY_WEEK_TUESDAY_FIRST_LETTER]: "M",
        [TranslationKeys.DAY_WEEK_WEDNESDAY_FIRST_LETTER]: "X",
        [TranslationKeys.DAY_WEEK_THURSDAY_FIRST_LETTER]: "J",
        [TranslationKeys.DAY_WEEK_FRIDAY_FIRST_LETTER]: "V",
        [TranslationKeys.DAY_WEEK_SATURDAY_FIRST_LETTER]: "S",
        [TranslationKeys.DAY_WEEK_SUNDAY_FIRST_LETTER]: "D",

        [TranslationKeys.PLAN_APPCC_LABEL]: "Tarea crítica",
        [TranslationKeys.COMPNAY_TASK]: "Tarea de el centro",
        [TranslationKeys.TASK_ASIGNED_TO]: "Tarea Asignada",
        [TranslationKeys.TASK_ASIGNED_TO_USER_IN_DEPARTMENT]: "Del Equipo",
        [TranslationKeys.TASK_ASIGNED_TO_DEPARTMENT]: "Tarea asignada al Equipo",
        [TranslationKeys.TASK_ASIGNED_TO_SUBDEPARTMENT]: "Tarea asignada al Subequipos",
        [TranslationKeys.TASK_ASIGNED_TO_WORKING_POSITION]: "Tarea asignada a un Código QR",
        [TranslationKeys.TASK_REASIGNED_TO]: "Tarea reasignada a",
        [TranslationKeys.CANCEL_REASIGNATION]: "Cancelar la reasignación",
        [TranslationErrors.ERROR_MESSAGE_CANCEL_REASIGNATION_FAILED]: "No se ha podido cancelar la reasignación",

        [TranslationKeys.IMAGE_FOR_REPORT]: "Se requiere imagen para el reporte",
        [TranslationKeys.IS_A_CRITICAL_TASK]: "¿La tarea es crítica?",
        [TranslationKeys.HOURS_OUT_OF_RANGE_TITLE]: "¡Alerta de Rango de Horas de 2 dias!",
        [TranslationKeys.HOURS_OUT_OF_RANGE_DESCRIPTION]:
            "Has programado una tarea que abarca el cambio de día. Se ajustará automáticamente para comenzar un dia y finalizar el siguiente.",

        [TranslationKeys.FULL_DATE]: "{1} de {2} del {3}", // ej: 10 de Agosto del 2022
        [TranslationKeys.FINISH_IN]: "Termina en",

        [TranslationKeys.TASK_REPEAT_EVERY_X_WEEKS]: "La tarea se repite cada {1} semanas los días",
        [TranslationKeys.TASK_REPEAT_EVERY_X_WEEK]: "La tarea se repite cada semana los días",
        [TranslationKeys.TASK_TEMPORALITY]: "Temporalidad de la tarea",
        [TranslationKeys.IN_THE_HOUSR]: "En las horas",
        [TranslationKeys.TASK_REPEAT]: "La tarea se repite",
        [TranslationKeys.TASK_REPEAT_EVERY]: "La tarea se repite cada",
        [TranslationErrors.DEPARTMENT_IS_REQUIRED]: "Selecciona el equipo.",
        [TranslationKeys.MUST_SELECT_USER_OR_QR_TITLE]: "La empresa seleccionada trabaja con Códigos QR.",
        [TranslationKeys.MUST_SELECT_USER_OR_QR_BODY]:
            "Obligatoriamente se debe asignar la Tarea a un Empleado o a un QR.",

        /* ----------------TASK WIZARD END----------------- */

        /* ----------------EDIT_TASK START----------------- */
        [TranslationKeys.TASK_DATA]: "Detalles",
        [TranslationKeys.DATA_TO_REPORT_ATTACHED]: "Dato a Reportar y Adjunto",
        [TranslationKeys.TASK_ENDS]: "La Tarea Termina",
        [TranslationKeys.DATA_TO_REPORT_EDIT]: "Datos a Reportar",
        [TranslationKeys.UPLOAD_TYPE]: "PNG o JPG (5 max)",
        [TranslationKeys.CLICK_TO_UPLOAD]: "Clica o Arrastra para subir una foto",
        [TranslationKeys.CHECKLIST_CANNOT_BE_EMPTY]: "La subtarea no puede estar vacía",
        [TranslationErrors.EMPTY_TASK_ID_LIST]: "La Lista de IDs de Tareas no puede estar vacía",

        /* ----------------EDIT_TASK END----------------- */

        /* ----------------TASK_PAGE START----------------- */
        [TranslationKeys.DONT_HAVE_PLAN_ASSIGNED]: "No tiene un plan asignado",
        [TranslationKeys.RESPONSABLE]: "Responsable",
        [TranslationKeys.DONT_HAVE_USER_ASSIGNED]: "No tiene un usuario asignado",
        [TranslationKeys.DETAIL]: "Detalle",
        [TranslationKeys.FREQUENCY]: "Frecuencia",
        [TranslationKeys.WITH_RECURRENCE]: "Con recurrencia",
        [TranslationKeys.WITH_NO_RECURRENCE]: "Sin recurrencia",
        [TranslationKeys.COMPILANCE]: "Cumplimiento",
        [TranslationKeys.DUPLICATE]: "Duplicar",
        [TranslationKeys.RECURRENT]: "Recurrente",
        [TranslationKeys.ONE_TIME]: "Única",
        [TranslationKeys.HOURS]: "Horas",
        [TranslationKeys.ACTIONS]: "Acciones",
        [TranslationKeys.UNIQUE]: "Único",
        [TranslationKeys.DAILY]: "Diaria",
        [TranslationKeys.WEEKLY]: "Semanal",
        [TranslationKeys.MONTHLY]: "Mensual",
        [TranslationKeys.ANNUALY]: "Anual",
        [TranslationKeys.TASK_PAGE_REGISTRY_TITLE]: "Gestor de Registros",
        [TranslationKeys.TASK_PAGE_REGISTRY_SUBTITLE]: "Registros",
        [TranslationKeys.TASK_PAGE_REGISTRY_SUBTITLE_SINGULAR]: "Registro",
        [TranslationKeys.TASK_PAGE_TASK_SUBTITLE]: "Tarea",
        [TranslationModals.GENERATING_TASKS_TITLE]: "Generando Tareas...",
        [TranslationModals.GENERATING_TASKS_DESCRIPTION]:
            "Se estan aplicando los cambios a todas las tareas a partir del dia {0} de {1}",
        [TranslationModals.REGENERATING_TASKS_TITLE]: "Regenerando Tareas...",

        /* ----------------TASK_PAGE END----------------- */

        /* ----------------HITORY_TASK_PAGE START----------------- */
        [TranslationKeys.DEADLINE]: "Fecha límite",
        [TranslationKeys.TASK_HISTORY_TITLE]: "Registro tarea",
        [TranslationKeys.MOB_TASK_HISTORY_TITLE]: "Registro histórico",
        [TranslationKeys.TASK_HISTORY_COMPLETED]: "Realizado",
        [TranslationKeys.TASK_HISTORY_ASIGNED]: "Inicio",
        [TranslationKeys.TASK_HISTORY_FINISHED_BY]: "Finalización",
        [TranslationKeys.TASK_HISTORY_STATUS]: "Estado",
        [TranslationKeys.TASK_HISTORY_VALUATION]: "Valoración",
        [TranslationKeys.TASK_NO_PLAN]: "Sin plan",
        [TranslationKeys.TASK_STATUS_PENDING]: "Pendiente",
        [TranslationKeys.TASK_STATUS_COMPLETED]: "Completada",
        [TranslationKeys.TASK_STATUS_OUT_OF_TIME]: "Fuera de Tiempo",
        [TranslationKeys.TASK_STATUS_UNKNOWN]: "Desconocido",
        [TranslationKeys.ALL_TIME_DATES]: "Todas las Fechas",

        /* ----------------HITORY_TASK_PAGE END----------------- */

        /* ----------------REVIEW_TASK_PAGE START----------------- */
        [TranslationKeys.NO_TASKS_PERFOMED]: "No hay tareas realizadas",
        [TranslationKeys.NO_TASKS_TO_PERFOM]: "No hay tareas para realizar",
        [TranslationKeys.MARK_ALL_AS_VIEWED]: "Marcar todo como visto",

        /* ----------------REVIEW_TASK_PAGE END----------------- */

        /* ----------------NEW_NOTIFICATION START----------------- */
        [TranslationKeys.TO]: "Para",
        [TranslationKeys.FROM]: "De",
        [TranslationKeys.SENT]: "Enviadas",
        [TranslationKeys.READED]: "Leídas",
        [TranslationKeys.NOT_READED]: "No Leídas",
        [TranslationKeys.RECEIVED]: "Recibidas",
        [TranslationKeys.PROGRAMMED]: "Programadas",
        [TranslationKeys.QR_PROGRAMMED]: "Programadas Con QR",
        [TranslationKeys.WRITE_HERE]: "Escribe aquí",
        [TranslationKeys.SELECT_A_COMPANY]: "Selecciona un Centro de Trabajo",
        [TranslationKeys.NEW_NOTIFICATION]: "Nueva notificación",
        [TranslationKeys.SEND_TO]: "Enviar a",
        [TranslationKeys.FILL_THIS_FIELD]: "Tienes que rellenar este campo",
        [TranslationKeys.SCHEDULE_SEND]: "Programar envío",
        [TranslationKeys.SCHEDULE_SEND_NEXT_DAY_MORNING]: " Siguiente día a las 8:00 am",
        [TranslationKeys.SCHEDULE_SEND_NEXT_DAY_AFTERNOON]: "Siguiente día a las 16:00 pm",
        [TranslationKeys.SCHEDULE_SEND_NEXT_MONDAY]: "Próximo lunes a las 8:00 am",
        [TranslationKeys.FILES]: "Archivos",
        [TranslationKeys.FILE]: "Archivo",
        [TranslationKeys.IMAGE]: "Imagen",
        [TranslationKeys.IMAGES]: "Imágenes",
        [TranslationKeys.AUDIO]: "Audio",
        [TranslationKeys.AUDIOS]: "Audios",
        [TranslationKeys.VIDEO]: "Vídeo",
        [TranslationKeys.VIDEOS]: "vídeos",
        [TranslationKeys.ALERT_TEXTAREA_PLACEHOLDER]: "Escribe o graba tu alerta.",

        /* ----------------NEW_NOTIFICATION END----------------- */

        /* ----------------NOTIFICATIONS START----------------- */
        [TranslationKeys.NOTIFICATIONS]: "Notificaciones",
        [TranslationKeys.NOTIFICATION_DAYS_DESCRIPTION]: "Configura tus preferencias para recibir notificaciones.",
        [TranslationKeys.MARK_AS_VIEWED]: "Marcar como visto",
        [TranslationKeys.SYSTEM_NOTIFICATION]: "Sistema",
        [TranslationKeys.RECEIVED_NOTIFICATION]: "Recibidos",
        [TranslationKeys.NO_NOTIFICATION]: "No hay Alertas para ver",
        [TranslationKeys.NEW_ALERT]: "Nueva Alerta",
        [TranslationKeys.REVIEW_LATER]: "Revisar más tarde",
        [TranslationKeys.MARK_AS_READED]: "Marcar como leído",
        [TranslationKeys.CLOSE]: "Cerrar",
        [TranslationKeys.SINGLE_ALERT]: "Alerta",
        /* ----------------NOTIFICATIONS END----------------- */

        /* ----------------VALIDATE_TIME START----------------- */
        [TranslationKeys.NO_TIME_SELECTED]: "No hay ninguna hora seleccionada",
        [TranslationKeys.TIME_EMPTY]: "La hora está vacía",
        [TranslationKeys.MAX_TIME_EMPTY]: "La hora máxima está vacía",
        [TranslationKeys.TIME_SMALL]: "La hora ha de ser más pequeña",
        [TranslationKeys.SAME_TIMES]: "Las horas son las mismas",
        [TranslationKeys.OVERLAP_TIME]: "Los rangos de fechas se solapan",
        /* ----------------VALIDATE_TIME END----------------- */

        /* ----------------TIME START----------------- */
        [TranslationCommon.TIME_FROM]: "Desde",
        [TranslationCommon.TIME_TO]: "Hasta",
        /* ----------------TIME END----------------- */

        /* ----------------ASSET_PAGE START----------------- */
        [TranslationKeys.TEMPERATURE]: "Temperatura",
        [TranslationKeys.DESCRIPTION]: "Descripción",
        [TranslationKeys.ASSET_TYPE]: "Tipo",
        [TranslationKeys.ASSET_TYPE_COLUMN]: "Origen",
        [TranslationKeys.ASSET_PAGE]: "Activo",
        [TranslationKeys.NEW_ASSET]: "Nuevo Activo",
        [TranslationKeys.NAME]: "Nombre",
        [TranslationKeys.ASSET_ID]: "Id del Activo",
        [TranslationKeys.ASSET_MIN]: "Mínimo Registro Aceptado",
        [TranslationKeys.ASSET_MAX]: "Máxima Registro Aceptado",
        [TranslationKeys.ASSET_LINK_TO_SENSOR]: "Vincular Sensor",
        [TranslationKeys.EDIT_ASSET]: "Editar Activo",
        [TranslationKeys.ASSET_NAME_PLACEHOLDER]: "Nombre",
        [TranslationKeys.ASSET_DESCRIPTION_PLACEHOLDER]: "Descripción",
        [TranslationKeys.ASSET_SELCT_OPTION]: "Selecciona un Activo",
        [TranslationKeys.ASSET_DELETE_MODAL_TITLE]: "Estás seguro que quieres eliminar el activo?",
        [TranslationKeys.ASSET_DELETE_MODAL_SUBTITLE]: "No se podrá recuperar una vez eliminado",
        [TranslationKeys.SUCCESS_ACTIVE_ASSET]: "Se ha activado correctamente",
        [TranslationKeys.FAILED_ACTIVE_ASSET]: "Fallo al activar",
        [TranslationKeys.SUCCESS_DEACTIVE_ASSET]: "Se ha desactivado correctamente",
        [TranslationKeys.FAILED_DEACTIVE_ASSET]: "Fallo al desactivar",
        [TranslationKeys.ASSET_MODAL_TITLE_ACTIVATE]: 'El activo "{0}" se activará',
        [TranslationKeys.ASSET_MODAL_TITLE_DEACTIVATE]: 'El activo "{0}" se desactivará',
        [TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE]:
            "Al desactivar este Activo, se va a desvincular de todas las tareas automaticamente. Recomendamos revisar las tareas antes de desactivarlo. Esta acción es irreversible.",
        [TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE_NO_RELATED]:
            "Este Activo no esta vinculado a ninguna tarea. Se puede desactivar sin afectar a ninguna tarea existente.",
        [TranslationKeys.ASSET_MODAL_DESCRIPTION_ACTIVATE]: "Estás seguro que quieres activar el Activo?",

        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_ASSETID_ASSET]: "Código del Sensor",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_MIN_ASSET]: "Mínimo Registro Aceptado",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_MAX_ASSET]: "Máximo Registro Aceptado",
        [TranslationKeys.ASSET_UNIT_OF_MEASURE]: "Unidad de Medida",
        [TranslationKeys.ASSET_LABEL]: "Título",
        [TranslationKeys.ASSET_PLACEHOLDER_LABEL]: "Título",
        [TranslationKeys.ASSET_OPTION_NUMERIC]: "Numérico",
        [TranslationKeys.ASSET_OPTION_TEXT]: "Texto",
        [TranslationKeys.ASSET_NUMBER_LABEL]: "¿Que Dato se debe Registrar?",
        [TranslationKeys.ASSET_TEXT_LABEL]: "¿Que Dato se debe Registrar?",

        /* ----------------ASSET_PAGE END----------------- */

        /* ----------------ASSET_VALIDATIONS START----------------- */
        [TranslationErrors.ASSET_NAME_REQUIRED]: "El nombre es obligatorio",
        [TranslationErrors.ASSET_MIN_TEMP_REQUIRED]: "La temperatura mínima es obligatoria",
        [TranslationErrors.ASSET_MAX_TEMP_REQUIRED]: "La temperatura máxima es obligatoria",
        [TranslationErrors.ASSET_MIN_TEMP_BIGGER_THAN_MAX]: "La temperatura mínima ha de ser menor a la máxima",
        [TranslationErrors.ASSET_COMPANY_REQUIRED]: "El centro de trabajo es obligatoria",
        [TranslationErrors.ASSET_MIN_MAX_LENGTH_ASSETID]: "El id del activo ha de tener 8 carácteres",
        [TranslationErrors.ASSET_MIN_MAX_LENGTH_SENSOR_SERIAL_NUMBER]: "El Código del Sensor ha de tener 8 carácteres",
        [TranslationErrors.ASSET_ID_ALREADY_IN_USE]: "El Código del Sensor ya está en uso",
        [TranslationErrors.SENSOR_NUMBER_INVALID]: "El Código del Sensor no es Válido",
        /* ----------------ASSET_VALIDATIONS END----------------- */

        /* ----------------MULTI TASK HOUR START----------------- */
        [TranslationKeys.ADD_HOUR]: "Añadir Hora Límite",
        [TranslationKeys.ADD_RANGE_HOUR]: "Añadir Rango de Hora",
        /* ----------------MULTI TASK HOUR END----------------- */

        /* ----------------RELOAD_INSTANCES START----------------- */
        [TranslationKeys.RELOAD_INSTANCES]: "Recargar Instancias",
        /* ----------------RELOAD_INSTANCES END----------------- */

        /* ----------------INPUT_PLACEHOLDERS START----------------- */
        [TranslationKeys.INPUT_PLACEHOLDER_EMAIL]: "nombre@4link.app",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_USERNAME_MY_PROFILE]: "Jperez",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_EMIAL_MY_PROFILE]: "jperez@4link.app",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_FIRSTNAME_MY_PROFILE]: "Juan",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_LASTNAME_MY_PROFILE]: "Perez",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_DOCUMENT_NUMBER_MY_PROFILE]: "12345678A",
        /* ----------------INPUT_PLACEHOLDERS END----------------- */

        /* ----------------TASK_DETAIL START----------------- */
        [TranslationKeys.COMPLETE_TASK]: "Completar",
        [TranslationKeys.THREE_STAR_MESSAGE]:
            "Para Valoraciones Inferiores a 3 Estrellas, se debe justificar con un comentario para el empleado.",
        [TranslationKeys.FEEDBACK_TITLE_RATING_TWO_STARS]: "¿Cual es el motivo de esta valoración tan baja?",
        [TranslationKeys.FEEDBACK_BODY_RATING_TWO_STARS]: "La tarea esta mal realizada por...",
        [TranslationKeys.FEEDBACK_TITLE_RATING]: "¿Desea añadir algun comentario a su valoración?",
        [TranslationKeys.FEEDBACK_BODY_RATING]: "La tarea esta bien realizada por...",
        [TranslationKeys.WITH_REVIEW]: "Con reseña",
        [TranslationKeys.REVIEWS]: "Reseñas",
        [TranslationKeys.WITH_REVIEWS]: "Con Reseñas",
        [TranslationKeys.WITH_COMMENTS]: "Con Comentarios",
        [TranslationKeys.WITH_NO_REVIEWS]: "Sin Reseñas",
        [TranslationKeys.WITHOUT_REVIEW]: "Sin reseña",
        [TranslationKeys.TEMPERATURE_OUT_OF_RANGE]: "Hay temperaturas fuera de rango",
        [TranslationKeys.EMPTY_OPTIONALS]: "Hay datos opcionales sin rellenar",
        [TranslationKeys.EMPTY_OPTIONALS_DESCRIPTION]: "¿Quieres completar la tarea sin completar todos los datos?",

        [TranslationModals.INACTIVE_CHECKLIST_ITEMS_TITLE]: "Se han dejado items del checklist sin marcar",
        [TranslationModals.INACTIVE_CHECKLIST_ITEMS_DESCRIPTION]:
            "¿Estás seguro de que deseas completar la tarea sin haberlos contestado?",
        [TranslationKeys.COULD_NOT_CHECK_SUBTASK]: "No se ha podido marcar esta subtarea",
        [TranslationKeys.DELETE_IMAGE_MODAL_TITLE]: "Borrar mensaje :",
        [TranslationKeys.TASK_COMMENTS_MAX_250_CHARACTERS]: "El Núnero Máximo de Carácteres es de 250",
        [TranslationKeys.OPEN_ANOTHER_ISSUE]: "Abrir Otra Incidencia",
        /* ----------------TASK_DETAIL END----------------- */

        /* ----------------ADMIN ERROR_MESSAGES START----------------- */
        [TranslationKeys.REVIEW_TASK_COMPLETED_OUT_OF_TIME]: "Completado Fuera de Tiempo",
        [TranslationKeys.REVIEW_TASK_COMPLETED_IN_TIME]: "Completado a Tiempo",
        [TranslationKeys.REVIEW_TASK_OFF_TIME]: "Fuera de Tiempo",
        [TranslationKeys.REVIEW_TASK_UNSEEN]: "No vistas",
        /* ----------------ADMIN ERROR_MESSAGES END----------------- */
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_VALORATION]: "Valoración de Tareas",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS]: "Tus Tareas Realizadas",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_COMMENTS]: "Gestión de Comentarios",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_TYPES]: "Tipos de Valoraciones Manuales",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TEAM_AVG]: "Tus Equipos no tienen ninguna puntuación",

        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TEAMS]: "Puntuación de tus equipos",

        /* ----------------ERROR_MESSAGES END----------------- */
        [TranslationErrors.USERNAME_ALREADY_REGISTERED]: "Nombre de usuario ya en uso.",
        [TranslationErrors.USER_EMAIL_ALREADY_REGISTERED]: "Correo electronico ya en uso.",
        /* ---------------- ERROR_MESSAGES END----------------- */

        /* ----------------TASK_COMMENTS----------------- */
        [TranslationKeys.TASK_COMMENTS_INFO_1]: "Estos comentarios sirven para resolver dudas.",
        [TranslationKeys.TASK_COMMENTS_INFO_2]:
            "Cualquier comentario mandado aqui se notificará automaticamente a tu responsable.",
        [TranslationKeys.TASK_COMMENTS_TITLE]: "Comentarios",
        [TranslationKeys.TASK_COMMENTS_PLACEHOLDER]: "No hay comentarios",
        [TranslationKeys.INSERT_MESSAGE_FAILED]: "Error al insertar el comentario en la base de datos",
        /* ----------------TASK_COMMENTS END----------------- */

        /* ----------------TASKINSTANCE_STATUS START----------------- */
        [TranslationKeys.PENDING_OUT_OF_TIME_TASKI]: "Vencidas",
        [TranslationKeys.PENDING_TO_EXPIRE_TASKI]: "A punto de vencer",
        [TranslationKeys.DONE_TASKI]: "Realizadas",
        [TranslationErrors.STATUS_CODE_INVALID]: "El Estado de la Tarea no es Válido",
        /* ----------------TASKINSTANCE_STATUS END----------------- */

        /* ----------------EMPTY_LIST_TASKINSTANCES START----------------- */
        [TranslationKeys.EMPTY_LIST_TASKI]: "No hay tareas para visualizar.",
        [TranslationKeys.EMPTY_LIST_TASK_REVIEW]: "No hay tareas para revisar.",
        /* ----------------EMPTY_LIST_TASKINSTANCES END----------------- */

        /*--------------------Valoration POPUP--START------------------------------*/
        [TranslationKeys.LOW_EVALUATION_REASON]: "¿Cual es el motivo de esta reseña tan baja?",
        [TranslationKeys.ADD_EVALUATION_COMMENT]: "¿Desea añadir alguna reseña a su valoración?",
        [TranslationKeys.TASK_POORLY_DONE]: "La tarea esta mal realizada por...",
        [TranslationKeys.TASK_DONE_DONE]: "La tarea esta bien realizada por...",
        [TranslationKeys.MESSAGE_IS_REQUIRED]: "El mensaje es obligatorio",
        [TranslationKeys.VALORATE_WITH_NO_MESSAGE]: "Solamente Valorar",
        [TranslationKeys.SEND_MESSAGE]: "Enviar Comentario",
        [TranslationKeys.VALORATE_TASK]: "Valorar",
        [TranslationKeys.VALORATION_COMMENTS]: "Reseña",
        [TranslationKeys.VALORATION_TEXTAREA_PLACEHOLDER]: "Deja una Reseña.",
        /*--------------------Valoration POPUP--END------------------------------*/
        /*--------------------MY-PROFILE-PAGE--START------------------------------*/
        [TranslationKeys.MY_DATA]: "Mis Datos",
        [TranslationKeys.MY_ALERTS]: "Alertas",
        [TranslationKeys.MY_CONFIGURATION]: "Configuración",
        [TranslationKeys.NOTIFICATIONS_LANGUAGE]: "Notificaciones e Idioma",

        [TranslationKeys.DO_NOT_DISTURB]: "Modo No Molestar Permanente",
        [TranslationKeys.DO_NOT_DISTURB_MODE]: "Estás en modo No molestar",

        [TranslationKeys.DO_NOT_DISTURB_SCHEDULED_DESCRIPTION]:
            "Activa esta opción para definir cuando queires recibir notificaciones.",
        [TranslationKeys.DO_NOT_DISTURB_DESCRIPTION]:
            "Activa esta opción para no recibir notificaciones en tu teléfono.",
        [TranslationKeys.DO_NOT_DISTURB_SCHEDULED]: "Programar Horario de Notificaciones",
        [TranslationKeys.SELECT_YOUR_LANGUAGE]: "Selecciona tu idioma para toda la aplicación.",
        [TranslationErrors.TIME_RANGE_ERROR]: "¡La hora de inicio no puede ser mayor que la hora de finalización!",
        [TranslationErrors.START_TIME_NULL_OR_EMPTY]: "La hora de inicio no puede ser nula o vacía",
        [TranslationErrors.END_TIME_NULL_OR_EMPTY]: "La hora de finalización no puede ser nula o vacía",
        /*--------------------MY-PROFILE-PAGE--END------------------------------*/

        /*--------------------REVIEW-WORKER-PROFILE--START------------------------------*/
        [TranslationKeys.NOT_REVIEWED]: "No Revisadas",
        [TranslationKeys.EVALUATED]: "Valoradas",
        /*--------------------REVIEW-WORKER-PROFILE--END------------------------------*/

        /*--------------------UserReview--START------------------------------*/
        [TranslationKeys.BETTER_THAN_LAST_MONTH]: "{1} mejor que el mes pasado",
        [TranslationKeys.WORSE_THAN_LAST_MONTH]: "{1} peor que el mes pasado",
        [TranslationKeys.SAME_THAN_LAST_MONTH]: "Igual que el mes pasado",
        /*--------------------UserReview--END------------------------------*/

        [TranslationErrors.GENERIC_ERROR]: "Hubo un error durante la ejecución",
        [TranslationKeys.GO_TO_MODULE]: "Ir al modulo",
        [TranslationKeys.SECURITY]: "Seguridad",
        [TranslationKeys.TASKS]: "Tareas",
        [TranslationKeys.TASK_IS_DISABLED]: "Todavia No puedes Realizar esta Tarea",

        /*--------------------AUTH START-------------------------------*/
        [TranslationKeys.BACK_TO_LOGIN]: "Volver al Inicio",
        [TranslationKeys.PASSWORD]: "Contraseña",
        [TranslationKeys.NEW_PASSWORD]: "Nueva Contraseña",
        [TranslationKeys.RECOVER_PASSWORD]: "Recuperar Contraseña",
        [TranslationKeys.PASSWORD_CONFIRM]: "Confirmar Contraseña",
        [TranslationKeys.CHECK_EMAIL]:
            "Consulta tu correo electrónico, te hemos enviado las instrucciones necesarias para recuperar tu contraseña.",
        [TranslationKeys.USERNAME]: "Nombre de usuario",
        [TranslationKeys.EMAIL]: "Email",
        [TranslationKeys.PLEASE_INPUT]: "Por favor ingrese su",
        [TranslationErrors.PASS_ADMIN_INVALID]:
            "Escriba una contraseña entre 6 a 12 caracteres, que contienga al menos una letra minúscula, una letra mayúscula, un dígito numérico y un carácter especial.",
        [TranslationErrors.PASSWORDS_NOT_MATCH]: "Las contraseñas no coinciden.",
        [TranslationErrors.LOGIN_INVALID_CREDENTIALS]: "Usuario o contraseña incorrectos",
        [TranslationKeys.LOGIN]: "Iniciar sesión",
        [TranslationKeys.LOGIN_TITLE]: "Inicio de sessión",
        [TranslationKeys.LOGIN_CHANGE_PROFILE]: "Cambiar de perfil",
        [TranslationKeys.EMPTY_LOGIN_USERNAME]: "Introduce tu nombre de usuario",
        [TranslationKeys.EMPTY_LOGIN_PASSWORD]: "Introduce una contraseña",
        [TranslationModals.RESTABLISH_PASSWORD_TITLE_TOAST]: "Consulta tu correo electrónico",

        [TranslationModals.RESTABLISH_PASSWORD_SUBTITLE_TOAST]:
            "Te hemos enviado las instrucciones necesarias para recuperar tu contraseña",
        [TranslationErrors.RESTABLISH_PASSWORD_EMAIL_ERROR_MESSAGE]:
            "Introduzca una dirección correo electronico válida",
        /*--------------------AUTH END-------------------------------*/

        /*--------------------ERROR MESSAGES START-------------------------------*/
        [TranslationErrors.NO_COMPANY_ASSIGNED]:
            "Para poder acceder a la aplicación como minimo debes estar asignado a un Centro de Trabajo. Porfavor contacta con tu administrador.",
        [TranslationErrors.APP_FAILED_TOKEN]: "Por favor, cierre la aplicación y luego vuelva a abrirla.",
        [TranslationErrors.NO_PERMISSIONS_TITLE]: "Empleado sin Permisos",
        [TranslationErrors.NO_PERMISSIONS_SUBTITLE]:
            "Este empleado no dispone de permisos para usar la aplicación, porfavor contacte con su administrador para activarlo.",
        [TranslationErrors.ERROR_UNDER_MAINTENIANCE_SUBTITLE]:
            "Nuestro sitio web está actualmente en mantenimiento programado. Disculpa las molestias y gracias por tu paciencia.",
        [TranslationErrors.ERROR_UNDER_MAINTENIANCE_TITLE]: "Página en mantenimiento",
        [TranslationErrors.NO_COMPANY_ASSIGNED_TITLE]: "No tienes Centros de Trabajo asignados",
        [TranslationErrors.PAGE_NOT_FOUND_TITLE]: "Página no encontrada",
        [TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE]: "Ha ocurrido un error inesperado",
        [TranslationErrors.GENERIC_ERROR_MESSAGE_VALUES_IS_OBLIGATORY]: "El valor es obligatorio",
        [TranslationErrors.GENERIC_ERROR_MESSAGE_VALUES_MUST_BE_GREATER_THAN_ZERO]: "El valor ha de ser mayor a 0",
        [TranslationErrors.INVALID_PHONE_NUMBER]: "El número de teléfono no es válido",
        [TranslationErrors.INITIALS_CANNOT_BE_EMPTY]: "Iniciales requeridas",
        [TranslationErrors.INITIALS_MAX_LENGTH]: "Las iniciales solo deben tener 2 carácteres",
        [TranslationErrors.INITIALS_INVALID_FORMAT]: "Las iniciales deben contener únicamente carácteres alfabéticos",
        [TranslationErrors.INITIALS_ALREADY_USED]: "Las iniciales ya están en uso",
        [TranslationKeys.MAX_30_MB]: "Máximo 30 MB",
        [TranslationKeys.COMPANIES]: "Centros de Trabajo",

        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_SUBTITLE]: "No hay ninguna valoración",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_DATE]:
            "Para las fechas seleccionadas tus equipos no tienen tareas",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_SUBTITLE]: "No tienes tareas",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_DATE]:
            "No Hay Valoraciones para las Fechas Seleccionadas",
        /*--------------------ERROR MESSAGES START-------------------------------*/

        /*--------------------TABLE START-------------------------------*/
        [TranslationKeys.TABLE_NO_DATA]: "No hay datos",
        [TranslationKeys.TABLE_EXPORT_TO_CSV]: "Exportar csv",
        /*--------------------TABLE END-------------------------------*/

        /*--------------------PAGINATION START-------------------------------*/
        [TranslationKeys.PAGINATION_DE]: "de",
        /*--------------------PAGINATION END-------------------------------*/

        /*--------------------RECONNECTING START-------------------------------*/
        [TranslationKeys.RECONNECTING]: "Reconectando...",
        /*--------------------RECONNECTING START-------------------------------*/

        /*-------------------- TOASTERS -------------------------------*/
        [TranslationModals.TOAST_GENERIC_ERROR]: "Ha habido un error.",
        [TranslationModals.TOAST_QR_ERROR]: "Este QR no es Válido Para Esta Aplicación.",
        [TranslationModals.TOAST_PHOTO_NOT_ALLOWED_TITLE]: "Archivo multimedia inválido.",
        [TranslationModals.TOAST_PHOTO_NOT_ALLOWED_SUBTITLE]: "Solo se permiten imágenes.",
        [TranslationModals.TOAST_SUCCESS_SAVE]: "Se ha guardado con éxito.",
        [TranslationModals.TOAST_SUCCESS_TASK]: "La tarea se ha completado con éxito.",
        [TranslationModals.TOAST_WARNING_PHOTO_REQUIRED]: "Para terminar la tarea es necesario subir una foto.",
        [TranslationModals.TOAST_WARNING_SELECT_OPTION]: "Por favor selecciona una opción:",
        [TranslationModals.TOAST_SUCCESS_EDIT]: "Se ha editado con éxito",
        [TranslationModals.TOAST_ERROR_LOADING_PHOTO]: "Error cargando la foto",
        [TranslationModals.TOAST_WARNING_PHOTO_ALREADY_UPLOADED]: "¡Ya has actualizado esta foto de perfil!",
        [TranslationModals.TOAST_ERROR_LOGIN]: "Usuario o contraseña incorrectos",
        [TranslationModals.TOAST_ERROR_USER_NOT_FOUND_LOGIN]: "El usuario no existe",
        [TranslationModals.TOAST_ALERT_SENDED]: "Alerta enviada correctamente",
        [TranslationModals.TOAST_ERROR_COMPANY_GROUP]: "No se han podido obtener los grupos de Centro de Trabajo",
        [TranslationModals.TOAST_ERROR_COMPANY]: "No se han podido obtener los Centros de Trabajo",
        [TranslationModals.TOAST_ERROR_DEPARTMENT]: "No se han podido obtener los equipos",
        [TranslationModals.TOAST_ERROR_SUBDEPARTMENT]: "No se han podido obtener los subequipos",
        [TranslationModals.TOAST_PHOTO_ERROR_TITLE]: "Hubo un error al acceder a la cámara.",
        [TranslationModals.TOAST_PHOTO_ERROR_SUBTITLE]:
            "Por favor, asegúrate de que la cámara esté conectada y que has dado permiso para su uso.",

        /*--------------------RECONNECTING END-------------------------------*/

        /*--------------------TABLE EMPTY STATE START-------------------------------*/
        [TranslationKeys.TABLE_EMPTY_STATE_TASK]: "Crea una tarea para para gestionar a los usuarios de tus equipos.",
        [TranslationKeys.REVIEW_QUALITY_1_STAR_NO_REV]: "1 estrella sin reseña",
        [TranslationKeys.REVIEW_QUALITY_3_STAR_NO_REV]: "2 y 3 sin reseña",
        [TranslationKeys.REVIEW_QUALITY_HIGH_REVIEW]: "4 y 5 con o sin reseña",
        [TranslationKeys.REVIEW_QUALITY_LOW_REVIEW]: "1, 2, 3 con reseña",
        [TranslationKeys.FAST_REVIEW]: "Manuales",
        [TranslationKeys.SLOW_REVIEW]: "Fuera de tiempo +24h",
        [TranslationKeys.NO_REVIEW]: "Automáticas",
        [TranslationKeys.FAST_COMMENT]: "Responde Ágilmente",
        [TranslationKeys.SLOW_COMMENT]: "Tarda en Responder +2h",
        [TranslationKeys.NO_COMMENT]: "Sin Responder",
        [TranslationKeys.TASKS_WITH_FEEDBACK]: "Tareas con reseña",

        /*--------------------TABLE EMPTY STATE END-------------------------------*/

        /*--------------------TABLE COLS START-------------------------------*/
        [TranslationKeys.TABLE_USERCODE]: "C. Empleado",
        [TranslationKeys.USERCODE_MIN_LENGTH]: "El Código de Empleado debe ser de entre 1 y 20 carácteres",

        [TranslationKeys.TABLE_AREA]: "Area",
        /*--------------------TABLE COLS END-------------------------------*/

        /*--------------------SECONDARY FILTER START-------------------------------*/
        [TranslationKeys.SECONDARY_FILTER_CLEAR_FILTERS]: "Limpiar filtros",
        [TranslationKeys.PENDING_STATUS]: "Pendientes de Realizar",
        [TranslationKeys.NEAR_END_STATUS]: "A Punto de Vencer",
        [TranslationKeys.TIMED_OUT_STATUS]: "Vencida",
        [TranslationKeys.IN_TIME_STATUS]: "Hecha a Tiempo",
        [TranslationKeys.OUT_OF_TIME_STATUS]: "Hecha Fuera de Tiempo",
        [TranslationKeys.STATUS_CODE]: "Estado de la Tarea",
        /*--------------------SECONDARY FILTER END-------------------------------*/

        /*--------------------TASK HISTORY START-------------------------------*/
        [TranslationKeys.CANNOT_FETCH]: "No se ha podido buscar {0}",
        /*--------------------TASK HISTORY END-------------------------------*/

        /*--------------------REASIGN TASKS START-------------------------------*/
        [TranslationKeys.REASIGN_TASK_TITLE]: "Reasignar tareas",
        [TranslationKeys.REASIGN_TASK_STATUS_PERMANENT]: "Permanente",
        [TranslationKeys.REASIGN_TASK_STATUS_TEMPORAL]: "Temporal",
        [TranslationErrors.REASIGN_TASK_DATES_ERROR_MESSAGE]: "La fecha de inicio ha de ser menor a la fecha final",
        [TranslationKeys.REASIGN_TASK_TO]: "Reasignar a",
        [TranslationKeys.REASIGN_TASK_CHANGE_Will_BE]: "El cambio será",
        [TranslationKeys.REASIGN_TASK_START_DATE_PLACEHOLDER]: "Selecciona un día",
        [TranslationKeys.REASIGN_TASK_START_DATE_LABEL]: "Del",
        [TranslationKeys.REASIGN_TASK_END_DATE_PLACEHOLDER]: "Selecciona un día",
        [TranslationKeys.REASIGN_TASK_END_DATE_LABEL]: "Al",
        [TranslationKeys.REASIGN_TASK]: "Reasignar",
        [TranslationKeys.REASIGN_TASK_SELECT_USER]: "Selecciona un responsable",
        [TranslationKeys.REASIGN_TASK_SELECT_DEPARTMENT]: "Selecciona un Equipo",
        [TranslationKeys.REASIGN_TASK_SELECT_SUBDEPARTMENT]: "Selecciona un Subequipos",
        /*--------------------REASIGN TASKS END-------------------------------*/

        /*--------------------EDIT COMPANY FORM START-------------------------------*/
        [TranslationKeys.EDIT_FORM_COMPANY_NAME]: "Nombre de Centro de Trabajo",
        [TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_NAME]: "Nombre de Centro de Trabajo",
        [TranslationKeys.EDIT_FORM_COMPANY_ADDRESS]: "Direccion",
        [TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_ADDRESS]: "Direccion",
        [TranslationKeys.EDIT_FORM_COMPANY_CITY]: "Ciudad",
        [TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_CITY]: "Ciudad",
        [TranslationKeys.EDIT_FORM_COMPANY_NIF]: "NIF",
        [TranslationKeys.EDIT_FORM_COMPANY_MAIL]: "Correo",
        [TranslationKeys.EDIT_FORM_COMPANY_POSTAL_CODE]: "Código Postal",
        [TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_POSTAL_CODE]: "Código Postal",
        [TranslationKeys.EDIT_FORM_COMPANY_GROUP]: "Grupo de Centros de Trabajo",
        [TranslationKeys.EDIT_COMPANY_TAB_INFO]: "Información",
        [TranslationKeys.EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY]:
            "Al desactivar este Centro de Trabajo, se va a desvincular de todas las tareas automaticamente. Recomendamos revisar las tareas antes de desactivarlo. Esta acción es irreversible.",
        [TranslationKeys.EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_NO_RELATED]:
            "Este Centro de Trabajo no està vinculado a ninguna tarea. Se puede desactivar sin afectar a ninguna tarea existente.",
        [TranslationKeys.EDIT_COMPANY_TAB_INTERNAL_ORG]: "Organización interna",
        [TranslationKeys.UNDO_CHANGES]: "Deshacer cambios",
        [TranslationKeys.EDIT_COMPANY_GROUP_TITLE]: "Editar grupo de Centros de Trabajo",
        [TranslationKeys.COMPANY_CLOSED]: "Centro Cerrado",
        [TranslationKeys.COMPANY_OPEN]: "Centro Abierto",
        [TranslationKeys.WORKS_WITH_QR]: "¿Esta Empresa Trabaja con QRs?",
        /*--------------------EDIT COMPANY FORM END-------------------------------*/

        /*--------------------EDIT USER FORM START-------------------------------*/
        [TranslationKeys.EDIT_FORM_USER_DEACTIVATE]: "Desactivar",
        [TranslationKeys.EDIT_FORM_USER_ACTIVATE]: "Activar",
        [TranslationKeys.EDIT_FORM_USER_WILL_DEACTIVATE]:
            "Al desactivar un usuario, todas las tareas asociadas a este usuario pasaran a ser compartidas. Esta acción no será reversible.",
        [TranslationKeys.EDIT_FORM_USER_WILL_DEACTIVATE_NO_RELATED_TASK]:
            "Este usuario no esta vinculado a ninguna tarea. Se puede desactivar sin afectar a ninguna tarea existente.",
        [TranslationKeys.EDIT_FORM_USER_WILL_ACTIVATE]: "El usuario se activará",
        [TranslationKeys.EDIT_FORM_USER_DEACTIVATE_QUESTION]: "Estás seguro que quieres desactivar el usuario?",
        [TranslationKeys.EDIT_FORM_USER_ACTIVATE_QUESTION]: "Estás seguro que quieres activar el usuario?",
        [TranslationModals.EDIT_FORM_USER_TITLE_TOAST]: "La contraseña se ha actualizado correctamente",
        [TranslationModals.EDIT_FORM_USER_TITLE_MODAL]: "La contraseña se reiniciará",
        [TranslationModals.EDIT_FORM_USER_DESCRIPTION_MODAL]:
            "La contraseña se cambiará y se le asignará por defecto su Código de Empleado",
        // USER ERRORS
        [TranslationErrors.USERNAME_INVALID_CHARACTERS]: "Nombre de usuario no válido.",
        [TranslationErrors.USERNAME_IS_REQUIRED]: "El Nombre de Usuario es Requerido",
        [TranslationErrors.EMAIL_IS_REQUIRED]: "El Email es Requerido",
        [TranslationErrors.EMAIL_IS_INVALID]: "El Email es Inválido",
        [TranslationErrors.FIRSTNAME_IS_REQUIRED]: "El Nombre es Requerido",
        [TranslationErrors.LASTNAME_IS_REQUIRED]: "El Apellido es Requerido",
        [TranslationErrors.USERCODE_IS_REQUIRED]: "El Código de Usuario es Requerido",
        [TranslationErrors.PROFILEPICTUREID_IS_INVALID]: "La Foto de Perfil Tiene un Formato Inválido",
        [TranslationErrors.USERCOMPANIES_0_IS_INVALID]: "La Primera Empresa es Inválida",
        [TranslationErrors.USERCOMPANIES_1_IS_INVALID]: "La Segunda Empresa es Inválida",
        [TranslationErrors.USERCOMPANIES_2_IS_INVALID]: "La Tercera Empresa es Inválida",
        [TranslationErrors.USERCOMPANIES_3_IS_INVALID]: "La Cuarta Empresa es Inválida",
        [TranslationErrors.USERCOMPANIES_4_IS_INVALID]: "La Quinta Empresa es Inválida",
        [TranslationErrors.USERCOMPANIES_5_IS_INVALID]: "La Sexta Empresa es Inválida",
        [TranslationErrors.USERCOMPANIES_6_IS_INVALID]: "La Séptima Empresa es Inválida",
        [TranslationErrors.USERCOMPANIES_7_IS_INVALID]: "La Octava Empresa es Inválida",
        [TranslationErrors.USERCOMPANIES_8_IS_INVALID]: "La Novena Empresa es Inválida",
        [TranslationErrors.USERCOMPANIES_9_IS_INVALID]: "La Décima Empresa es Inválida",
        /*--------------------EDIT USER FORM END-------------------------------*/

        /* ----------------------PENDING ALERTS START-------------------------- */
        [TranslationKeys.THERE_ARE_NO_MORE_ALERTS]: "No Hay Alertas Pendientes de Ver",
        /* ----------------------PENDING ALERTS START-------------------------- */

        /*--------------------CREATE TASK START-------------------------------*/
        [TranslationKeys.CANCEL_REASIGNATION_TITLE]: "¿Deseas Anular la Reasignación?",
        /*--------------------CREATE TASK END-------------------------------*/

        /*--------------------LICENSE WIZARD START-------------------------------*/
        [TranslationKeys.WIZARD_LICENSE_TITLE]: "Crear instancia",
        [TranslationKeys.WIZARD_LICENSE_TITLE_DATA]: "Datos de la instancia",
        [TranslationKeys.WIZARD_LICENSE_TITLE_MODULES]: "Módulos y funcionalidades",
        [TranslationKeys.WIZARD_LICENSE_TITLE_ADMIN]: "Datos del administrador",
        [TranslationKeys.WIZARD_LICENSE_CONTACT_NAME]: "Nombre de contacto",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_CONTACT_NAME]: "Nombre de contacto",
        [TranslationKeys.WIZARD_LICENSE_CONTACT_EMAIL]: "Email de contacto",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_CONTACT_EMAIL]: "Email de contacto",
        [TranslationKeys.WIZARD_LICENSE_CONTACT_PHONE]: "Teléfono de contacto",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_CONTACT_PHONE]: "Teléfono de contacto",
        [TranslationKeys.WIZARD_LICENSE_INITIALS]: "Iniciales QR",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_INITIALS]: "Iniciales QR",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_NAME]: "Nombre del administrador",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_NAME]: "Nombre del administrador",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_LASTNAME]: "Apellido del administrador",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_LASTNAME]: "Apellido del administrador",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_USERNAME]: "Usuario del administrador",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_USERNAME]: "Usuario del administrador",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_EMAIL]: "Correo electrónico del administrador",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_EMAIL]: "Correo electrónico del administrador",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_DOCUMENT_NUMBER]: "Código de Empleado del administrador",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_DOCUMENT_NUMBER]:
            "Código de Empleado del administrador",
        [TranslationKeys.WIZARD_LICENSE_PHONE_NUMBER]: "Número de teléfono",
        [TranslationKeys.WIZARD_LICENSE_NAME_AND_LASTNAME]: "Nombre y apellido",
        [TranslationKeys.WIZARD_LICENSE_EMIAL]: "Correo electrónico",
        [TranslationKeys.WIZARD_LICENSE_DOCUMENT_NUMBER]: "Número de identidad",
        /*--------------------LICENSE WIZARD END-------------------------------*/

        /*--------------------GROUP COMPANY START-------------------------------*/
        [TranslationKeys.GROUP_COMPANY_DELETE_TITLE]: "El Grupo {0} se va a Eliminar",
        [TranslationKeys.GROUP_COMPANY_FORM_NAME_LABEL]: "Nombre del Grupo",
        [TranslationKeys.GROUP_COMPANY_FORM_NAME_PLACEHOLDER]: "Centro de TrabajoPrincipal S.A.",
        /*--------------------GROUP COMPANY END-------------------------------*/

        /*--------------------WIZARD SELECT DEPARTMENT START-------------------------------*/
        [TranslationKeys.WELCOME_COMPANY]: "Bienvenido",
        [TranslationKeys.SELCT_DEPARTMENT_COMPANY_TITLE]: "¿En qué Centro de Trabajo vas a trabajar hoy?",
        [TranslationKeys.SELCT_DEPARTMENT_COMPANY_TITLE_SUPERVISOR]: "¿Que centro de trabajo quieres revisar?",

        [TranslationKeys.SELCT_DEPARTMENT_DEPARTMENT_TITLE]: "¿En qué equipo?",
        [TranslationKeys.SELCT_DEPARTMENTS_DEPARTMENT_TITLE]: "¿En qué equipo vas a trabajar hoy?",
        [TranslationKeys.SELCT_DEPARTMENT_SUBDEPARTMENT_TITLE]: "¿En qué subequipo?",
        [TranslationKeys.SELCT_DEPARTMENT_SUBDEPARTMENTS_TITLE]: "¿En qué subequipo vas a trabajar hoy?",
        /*--------------------WIZARD SELECT DEPARTMENT END-------------------------------*/

        /*--------------------INSTABCE PAGE FORM START-------------------------------*/
        [TranslationKeys.INSTANCE_FORM_LABEL_NAME]: "Nombre de la Instancia",
        [TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_NAME]: "Nombre del Administrador",
        [TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_EMAIL]: "Correo del Administrador",
        [TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_PHONE]: "Teléfono del Administrador",
        [TranslationKeys.INSTANCE_FORM_PLACEHOLDER_NAME]: "Nombre de la Instancia",
        [TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_NAME]: "Nombre del Administrador",
        [TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_EMAIL]: "Correo del Administrador",
        [TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_PHONE]: "Teléfono del Administrador",
        /*--------------------INSTABCE PAGE FORM END-------------------------------*/

        /*--------------------INSTABCE PAGE FORM START-------------------------------*/
        [TranslationKeys.RANKING_TITLE_USER]: "Ranking de Empleados",
        [TranslationKeys.RANKING_ASC_NAME]: "Nombre Ascendente",
        [TranslationKeys.RANKING_DESC_NAME]: "Nombre Descendente",
        [TranslationKeys.RANKING_ASC_STARS]: "Más Alta",
        [TranslationKeys.RANKING_DESC_STARS]: "Más Baja",
        [TranslationKeys.STARS]: "Estrellas",
        [TranslationKeys.STAR]: "Estrella",
        [TranslationKeys.TASKS_VALORATION]: "Valoración de tareas",
        [TranslationKeys.RANKING_COMPLETED_OUT_OF_TIME]: "Completadas Fuera de Tiempo",
        [TranslationKeys.RANKING_NOT_COMPLETED]: "No Completadas",
        [TranslationTitles.RANKING_COMPANY_TITLE]: "Ranking de Centros",
        [TranslationTitles.RANKING_DEPARTMENT_TITLE]: "Equipos de {1}",
        [TranslationTitles.RANKING_USERS_BY_DEPARTMENT_TITLE]: "Empleados de {1}",

        /*--------------------INSTABCE PAGE FORM END-------------------------------*/
        /*--------------------ERROR MESSAGES FETCH START-------------------------------*/
        [TranslationErrors.TASK_INSTANCE_ALREADY_COMPLETED]: "La tarea ya ha sido completado",
        [TranslationErrors.TASK_INSTANCE_ALREADY_REOPENED]: "La tarea ya ha sido reabierta",
        [TranslationErrors.WORKING_POSITION_ALREADY_EXIST]: "El código ya existe",
        [TranslationErrors.WORKING_POSITION_ENTITY_NOT_FOUND]: "No se ha encontrado el Código QR",
        [TranslationErrors.WORKING_POSITION_TASKS_EMPTY]: "No se han Encontrado Tareas para Hoy",
        [TranslationErrors.DEPARTMENT_NOT_EXIST]: "El Equipo No Existe",
        [TranslationErrors.TASKS_ALREADY_IMPORTED]: "Las tareas de este QR ya han sido importadas",
        [TranslationErrors.COMPANIES_FAILED_LOAD_DATA]: "No se han podido recuperar los Centros de Trabajo",
        [TranslationErrors.WORKING_POSITION_FAILED_LOAD_DATA]: "No se han podido recuperar los QR",
        [TranslationErrors.WORKING_POSITION_ALREADY_SELECTED]: "El Código QR ya ha sido seleccionado",
        [TranslationErrors.PASSWORD_CONTAINS_ASTERISK]: `La contraseña no puede contener asteriscos (*) ni comillas (')`,
        [TranslationErrors.TASK_INSTANCE_NOT_YOURS]: `La Tarea no es tuya, ya ha sido Modificada por otro Empleado`,
        [TranslationErrors.TASK_INSTANCE_NOT_EXIST]: `La Tarea no existe`,
        [TranslationErrors.TASK_INSTANCE_CHECKLIST_NOT_YOURS]: `La Tarea no es tuya, ya ha sido Modificada por otro Empleado`,
        [TranslationErrors.TASK_INSTANCE_CHECKLIST_FAILED]: `Este Checklist no se ha podido marcar`,
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NULL_OR_EMPTY]:
            "¡Para terminar la tarea es necesario adjuntar una foto!",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NOT_FOUND]: "No se ha encontrado la Foto",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_MAX_FILES]: "No se pueden subir más de 5 fotos",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NO_PHOTOS]: "No se ha podido subir la foto",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NOT_MINE]:
            "La Tarea no es tuya, ya ha sido Modificada por otro Empleado",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_TASK_ALREADY_COMPLETED]: "La tarea ya ha sido completada",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_FAILED_UPLOADING]: "No se han podido subir algunas fotos",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NOT_EXIST]: "No se ha encontrado la foto",

        /*--------------------ERROR MESSAGES FETCH END-------------------------------*/

        /*--------------------WORKING POSITION START-------------------------------*/
        [TranslationKeys.WORKING_POSITION]: "Códigos QR",
        [TranslationKeys.WORKING_POSITION_EDIT_TITLE]: "Editar el Código QR",
        [TranslationKeys.WORKING_POSITION_NEW_TITLE]: "Nuevo Código QR",
        [TranslationKeys.WORKING_POSITION_DISABLE_TITLE]: "Estás seguro de que quieres desactivar el Código QR?",
        [TranslationKeys.WORKING_POSITION_DISABLE_DESCRIPTION]:
            "Todas las Tareas Asignadas a este QR quedarán Asignadas al Equipo.",
        [TranslationKeys.WORKING_POSITION_DISABLE_DESCRIPTION_NO_RELATED]:
            "Este QR no esta vinculado a ninguna tarea. Se puede desactivar sin afectar a ninguna tarea existente.",
        [TranslationKeys.WORKING_POSITION_ENABLE_TITLE]: "Estás seguro de que quieres activar el Código QR?",
        [TranslationKeys.WORKING_POSITION_ENABLE_DESCRIPTION]: "El Código QR se activará",
        [TranslationKeys.WORKING_POSITION_ENABLE]: "Activado",
        [TranslationKeys.WORKING_POSITION_DISABLE]: "Desactivado",
        [TranslationKeys.WORKING_POSITION_ACTIVE]: "Activar",
        [TranslationKeys.WORKING_POSITION_DEACTIVE]: "Desactivar",
        [TranslationKeys.WORKING_POSITION_NAME_LABEL]: "Nombre",
        [TranslationKeys.WORKING_POSITION_NAME_PLACEHOLDER]: "Nombre",
        [TranslationKeys.WORKING_POSITION_CODE_LABEL]: "Código",
        [TranslationKeys.WORKING_POSITION_CODE_PLACEHOLDER]: "Código",
        [TranslationKeys.WORKING_POSITION_DESCRIPTION_LABEL]: "Descripción",
        [TranslationKeys.WORKING_POSITION_DESCRIPTION_PLACEHOLDER]: "Descripción",
        [TranslationKeys.WORKING_POSITION_COMPANY_LABEL]: "Centro de trabajo",
        [TranslationKeys.WORKING_POSITION_FAILED_ACTIVATE]: "Fallo al activar",
        [TranslationKeys.WORKING_POSITION_FAILED_DEACTIVATE]: "Fallo al desactivar",
        [TranslationKeys.WORKING_POSITION_SUCCESS_ACTIVATE]: "Se ha activado correctamente",
        [TranslationKeys.WORKING_POSITION_SUCCESS_DEACTIVATE]: "Se ha desactivado correctamente",
        [TranslationKeys.WORKING_POSITION_ALL]: "Todos los Códigos QR",
        [TranslationKeys.PRINT_QR]: "Imprimir QR",
        [TranslationCommon.YES_CHANGE]: "Sí, hacer cambio",
        [TranslationCommon.NO_GOBACK]: "No, volver",

        /*--------------------WORKING POSITION END-------------------------------*/

        /*--------------------USER SCAN QR START-------------------------------*/
        [TranslationModals.QR_CHECKER_MODAL_TITLE]: "¿Estás seguro que quieres importar las tareas del QR {1}?",
        [TranslationModals.QR_CHECKER_MODAL_DESCRIPTION]:
            "Una vez importadas las tareas, pasan a ser responsabilidad tuya.",
        [TranslationKeys.QR_CHECKER_TITLE]: "¿En qué equipo vas a trabajar hoy?",
        /*--------------------USER SCAN QR END-------------------------------*/

        /*--------------------ANALIST-------------------------------*/
        [TranslationKeys.RANKING]: "Ranking",
        [TranslationKeys.GLOBAL_RANKING_COMPANIES]: "Ranking de Centros",

        [TranslationKeys.RANKING_DEPARTMENTS]: "Ranking de Equipos",
        [TranslationKeys.GLOBAL_RANKING_DEPARTMENTS]: "Ranking de Equipos Global",
        [TranslationKeys.SEE_RANKING_DEPARTMENTS]: "Ver Ranking de Equipos",
        [TranslationKeys.SEE_GLOBAL_RANKING_DEPARTMENTS]: "Ver Ranking de Equipos Global",

        [TranslationKeys.GLOBAL_RANKING_USERS]: "Ranking de Empleados Global",
        [TranslationKeys.SEE_GLOBAL_RANKING_USERS]: "Ver Ranking de Empleados Global",
        [TranslationKeys.SEE_RANKING_USERS]: "Ver Ranking de Empleados",

        [TranslationKeys.ANALIST_TITLE_DONUT]: "Cumplimiento de Tareas",
        [TranslationKeys.ANALIST_TITLE_DONUT_DEPARTMENTLIST]: "Equipos con Tareas {1}",
        [TranslationKeys.ANALIST_TITLE_DONUT_USERLIST]: "Usuarios con Tareas {1}",
        [TranslationKeys.ANALIST_TITLE_DONUT_TASKLIST]: "Tareas {1}",
        [TranslationKeys.ANALIST_TITLE_STARS]: "Valoración de Tareas",
        [TranslationKeys.ANALIST_TITLE_LINECHART]: "Evolución del {1}",
        [TranslationKeys.ANALIST_TITLE_TASKLIST]: "Tareas {0} {1}",
        [TranslationErrors.ANALIST_DETAIL_ERROR]: "Error al cargar este detalle de {0}",
        [TranslationErrors.ANALIST_TASK_COUNTERS_ERROR]: "Error al cargar los contadores de las tareas",
        [TranslationErrors.ANALIST_DETAIL_LIST_ERROR]: "Error al cargar la lista de tareas",

        [TranslationKeys.ANALIST_SHARED_TIMED_OUT]: "Compartidas Vencidas",
        [TranslationKeys.ANALIST_TIMED_OUT]: "Vencidas",
        [TranslationKeys.ANALIST_OUT_OF_TIME]: "Fuera de Tiempo",
        [TranslationKeys.ANALIST_IN_TIME]: "Realizadas a Tiempo",

        [TranslationKeys.ANALIST_DISABLED_USER_TITLE]: "Usuario sin Datos",
        [TranslationKeys.ANALIST_DISABLED_USER_BODY]:
            "El usuario seleccionado no dispone de Datos de Analisis por el momento.",
        [TranslationKeys.ANALIST_TASKLIST_COMMENTS_TAB]: "Comentarios",
        [TranslationKeys.ANALIST_TASKLIST_FEEDBACK_TAB]: "Reseñas",
        /*--------------------ANALIST END-------------------------------*/

        /*--------------------Common START-------------------------------*/
        [TranslationCommon.SKIP]: "Omitir",
        [TranslationKeys.ATTACHED_FILES]: "Documentos adjuntos",
        [TranslationKeys.COMMENTS_OR_QUESTIONS]: "¿Quieres dejar un comentario?",

        /*--------------------Common END-------------------------------*/
        [TranslationKeys.ADDEDS_USERS_TO_TEAM]: "Usuarios Añadidos al Equipo",
        [TranslationKeys.DELETEDS_USERS_TO_TEAM]: "Eliminados",

        /*--------------------Menu sidebar START-------------------------------*/
        [TranslationKeys.MENU_SIDEBAR_TASK_VALORATION]: "Valoración de tareas",
        /*--------------------Menu sidebar END-------------------------------*/

        /*--------------------Edit company START-------------------------------*/
        [TranslationKeys.EDIT_COMPANY_DELETE_SUBDEPARTMENT]:
            "Al desactivar este Subequipo, se va a desvincular de todas las tareas automaticamente. Recomendamos revisar las tareas antes de desactivarlo. Esta acción es irreversible.",
        [TranslationKeys.EDIT_COMPANY_DELETE_SUBDEPARTMENT_NO_RELATED]:
            "Este Subequipo no esta vinculado a ninguna tarea. Se puede desactivar sin afectar a ninguna tarea existente.",
        [TranslationKeys.EDIT_COMPANY_DELETE_DEPARTMENT]:
            "Al desactivar este equipo, se va a desvincular de todas las tareas automaticamente. Recomendamos revisar las tareas antes de desactivarlo. Esta acción es irreversible.",
        [TranslationKeys.EDIT_COMPANY_DELETE_DEPARTMENT_NO_RELATED]:
            "Este equipo no esta vinculado a ninguna tarea. Se puede desactivar sin afectar a ninguna tarea existente.",
        /*--------------------Edit company END-------------------------------*/
        /* --------------------MENUS-------------------- */
        /* HEADER */
        [TranslationTitles.MY_TASKS_HEADER_TITLE]: "Mis Tareas",
        [TranslationTitles.HEADER_REVIEW_TASKS_TITLE]: "Revisión",
        [TranslationTitles.HEADER_REVIEW_WORKERS_TITLE]: "Ranking",
        [TranslationTitles.HEADER_PLAN_APPCC_TITLE]: "Planes APPCC",
        [TranslationTitles.HEADER_ON_BOARDING_TITLE]: "Onboarding",

        // MULTICOMPANY MENU
        [TranslationTitles.COMPANIES_MAINTENANCE_TITLE]: "Centros de Trabajo y Equipos",
        [TranslationTitles.ROLES_MAINTENANCE_TITLE]: "Roles y Permisos",
        [TranslationTitles.ROLES_TEMPORARY_TITLE]: "Roles y Permisos Temporales",
        [TranslationTitles.USERS_TITLE]: "Usuarios",
        [TranslationKeys.ASSETS]: "Activos",
        [TranslationTitles.WORKING_POSITION_TITLE]: "Códigos QR",
        /* HEADER END */

        /* SIDEBAR */
        [TranslationTitles.CONFIGURATION_TITLE]: "Configuración",

        [TranslationKeys.MENU_SIDEBAR_TASK_MANAGER]: "Gestión de Tareas",
        [TranslationKeys.MENU_SIDEBAR_NEW_TASK_WIZARD_TITLE]: "Alta Guiada de Tareas",
        [TranslationTitles.SCAN_QR_TASK_TITLE]: "Escanear Tareas",
        [TranslationTitles.SCAN_QR_SENSOR_TITLE]: "Escanear Sensor",

        [TranslationKeys.MENU_SIDEBAR_TASK_REVIEW]: "Supervisión de Tareas",
        [TranslationTitles.REVIEW_TASKS_TITLE]: "Revisar Tareas",
        [TranslationKeys.MENU_SIDEBAR_USER_RANKING]: "Ranking de Empleados",
        [TranslationTitles.REVIEW_FEEDBACK_TITLE]: "Mis Valoraciones",

        [TranslationKeys.MENU_SIDEBAR_REPORTS]: "Informes",
        [TranslationKeys.MENU_SIDEBAR_COMPANY_RANKING]: "Ranking de Centros",

        [TranslationKeys.MENU_SIDEBAR_COMUNICATION]: "Comunicación",
        [TranslationKeys.MENU_SIDEBAR_ALERTS]: "Alertas",
        [TranslationKeys.MENU_SIDEBAR_NEW_REGISTRY]: "Alta Guiada de Registros",

        [TranslationKeys.MENU_SIDEBAR_CONTROL_PANEL]: "Panel de Control",
        [TranslationKeys.MENU_SIDEBAR_APPCC_PLAN]: "Módulo APPCC",
        [TranslationKeys.MENU_SIDEBAR_AUDIT]: "Auditorías",
        /* SIDEBAR END */

        [TranslationTitles.LICENSES_TITLE]: "Licencias",
        /* --------------------MENUS END-------------------- */

        /* --------------------RESET PASSWORD START-------------------- */
        [TranslationErrors.RESET_PASSWORD_ERROR_LENGTH_MESSAGE]: "Entre 6 y 12 Caracteres",
        [TranslationErrors.RESET_PASSWORD_ERROR_CAPITAL_LETTER_AND_MINUSCULE_MESSAGE]: "1 Mayúscula y 1 Minuscula",
        [TranslationErrors.RESET_PASSWORD_ERROR_NUMBER_MESSAGE]: "1 Número",
        [TranslationErrors.RESET_PASSWORD_ERROR_SPECIAL_CHARACTER_MESSAGE]: "1 Carácter Especial",
        /* --------------------RESET PASSWORD END-------------------- */

        /* --------------------SELECT PROFILE START-------------------- */
        [TranslationKeys.SELECT_PROFILE_TITLE]: "Elige tu perfil",
        [TranslationKeys.SELECT_PROFILE_ADD_NEW_PROFILE]: "Añadir nuevo perfil",
        /* --------------------SELECT PROFILE END-------------------- */

        /* --------------------COMPANIES START-------------------- */
        [TranslationModals.COMPANIES_ADD_DELETE_USERS_TITLE]:
            "¿Estás seguro que deseas añadir y eliminar los usuarios seleccionados?",
        [TranslationModals.COMPANIES_ADD_DELETE_USERS_DESCRIPTION]:
            "Al eliminar un usuario de un equipo, todas las tareas asociadas a este usuario pasaran a ser compartidas. Esta acción no será reversible.",
        [TranslationModals.COMPANIES_ADD_USERS_TITLE]: "¿Estás seguro que deseas añadir estos usuarios?",
        [TranslationModals.COMPANIES_DELETE_USERS_TITLE]:
            "¿Estás seguro que deseas eliminar los usuarios seleccionados?",
        [TranslationModals.COMPANIES_DELETE_USERS_DESCRIPTION]:
            "Al eliminar un usuario de un equipo, todas las tareas asociadas a este usuario pasaran a ser compartidas. Esta acción no será reversible.",

        [TranslationKeys.NO_ALERTS_RECEIVED]: "No hay alertas recibidas",
        [TranslationKeys.NO_ALERTS_SENT]: "No hay alertas enviadas",
        [TranslationKeys.NO_ALERTS_PROGRAMMED]: "No hay alertas programadas",
        [TranslationKeys.USER_DEACTIVATED]: "Empleado desactivado",
        [TranslationKeys.USER_ACTIVATED]: "Empleado activado",

        /* --------------------COMPANIES END-------------------- */

        /* --------------------ARIA LABLES START-------------------- */

        /* --------------------RANKING PILL COUNTER START-------------------- */
        [AriaLabels.AL_RANKING_PILL_COUNTER_ALARM]: "Alarma",
        [AriaLabels.AL_RANKING_PILL_COUNTER_DEFAULT_ALARM]: "Alarma sin tareas",
        [AriaLabels.AL_RANKING_PILL_COUNTER_ERROR]: "Error",
        [AriaLabels.AL_RANKING_PILL_COUNTER_SUCCESS]: "Éxito",
        [AriaLabels.AL_RANKING_PILL_COUNTER_CROSS]: "Cruz",
        [AriaLabels.AL_RANKING_PILL_COUNTER_EQUALS]: "Icono",
        [AriaLabels.AL_RANKING_PILL_COUNTER_TOTAL]: "Total",
        [AriaLabels.AL_RANKING_PILL_COUNTERS_TOTAL]: "Total Tareas",
        [AriaLabels.AL_RANKING_PILL_INFO_AVATAR_DESCRIPTION]: "Descripción del Usuario",
        [AriaLabels.AL_COLLAPSABLE_TITLE]: "Título del collapsable",
        [AriaLabels.AL_COLLAPSABLE_TITLE_ICON]: "Icono Abrir Cerrar Collapsable",
        /* --------------------RANKING PILL COUNTER END-------------------- */

        /* --------------------ASSETS SATRT-------------------- */
        [TranslationModals.ASSET_FAILED_TITLE]: "No se ha podido guardar el activo",
        [TranslationModals.ASSET_FAILED_DESCRIPTION]:
            "Por favor, compruebe todos los campos obligatorios y vuelve a intentarlo.",
        [TranslationKeys.ASSET_DYNAMIC_ASSETS_TITLE]: "Campos Dinámicos",
        [TranslationKeys.ASSET_ADD_DYNAMIC_FIELD_PLACEHOLDER]: "Añadir Campo Dinámico",
        /* --------------------ASSETS END-------------------- */

        /* --------------------ISSUE START-------------------- */
        [TranslationKeys.ISSUE_COUNT]: "Incidencias Consecutivas",
        [TranslationKeys.ISSUE_COUNT_TOTAL]: "Incidencias Totales",
        [TranslationKeys.MOBILE_ISSUE_COUNT]: "Inc. Consecutivas",
        [TranslationKeys.MOBILE_ISSUE_COUNT_TOTAL]: "Inc. Totales",
        [TranslationKeys.ISSUE_TYPE_TASK]: "Tarea",
        [TranslationKeys.ISSUE_TYPE_ASSET]: "Activo",
        [TranslationKeys.ISSUE_TYPE_GENERIC]: "Común",
        [TranslationKeys.ISSUE_TYPE_AUDIT]: "Auditoria",
        [TranslationKeys.ISSUE_TYPE_TASK_TAB]: "Tareas",
        [TranslationKeys.ISSUE_TYPE_ASSET_TAB]: "Activos",
        [TranslationKeys.ISSUE_TYPE_GENERIC_TAB]: "Comunes",
        [TranslationKeys.ISSUE_TYPE_AUDIT_TAB]: "Auditorias",
        [AriaLabels.AL_ISSUE_COUNT]: "Número de Incidencias Consecutivas para este Asset",
        [AriaLabels.AL_ISSUE_COUNT_TOTAL]: "Número de Incidencias Totales para este Asset",

        [TranslationKeys.OPEN_ISSUE]: "Abrir Incidencia",
        [TranslationErrors.ERROR_MESSAGE_MAX_10_COR_MES]:
            "Solo puedes añadir hasta un máximo de 10 medidas correctivas",
        [TranslationErrors.ERROR_MESSAGE_MAX_20_SUPERVISORS]:
            "Solo puedes añadir hasta un máximo de 20 supervisores / analistas",
        [TranslationErrors.ERROR_MESSAGE_ISSUE_NOT_CREATED]: "La Incidencia No se ha podido Crear",
        [TranslationKeys.ISSUE_CREATED_SUCCESSFULLY]: "La Incidencia se ha Creado",
        [TranslationModals.ISSUE_IMAGE_LOADING_TITLE]: "Subiendo Imagenes...",
        [TranslationModals.ISSUE_IMAGE_LOADING_DESCRIPTION]: "Se esta generando la incidencia, por favor espera.",
        [TranslationKeys.PROBLEM_DETECTED_LABEL]: "¿A que se debe esta desviación?",
        [TranslationKeys.PROBLEM_DETECTED_PLACEHOLDER]: "Explica el Problema que se ha Detectado",
        [TranslationKeys.ISSUE_PROBLEM_DETECTED_LABEL]: "¿Que Problema ha Detectado?",
        [TranslationKeys.ATTACH_FILES_LABEL]: "Documentos adjuntos",
        [TranslationKeys.CORRECTIVE_MEASSURE_LABEL]: "Medidas Correctivas (Opcional)",
        [TranslationKeys.CORRECTIVE_MEASSURE_PLACEHOLDER]: "¿Que Medidas Correctivas se Deben Aplicar?",
        [TranslationKeys.ISSUE_ASSET_LABEL]: "Activo Vinculado",
        [TranslationKeys.SUPERVISOR_ISSUE_LABEL]: "Enviar Aviso (mín 1, máx 20)",
        [TranslationKeys.SUPERVISOR_ISSUE_PLACEHOLDER]: "¿A quien Quieres Notificar?",
        [TranslationKeys.CREATE_MANUAL_ISSUE_TITLE]: "Abrir Nueva Incidencia",
        [TranslationKeys.SEND_MANUAL_ISSUE]: "Enviar Incidencia",
        [TranslationKeys.SEND_AND_COMPLETE_MANUAL_ISSUE]: "Enviar y Completar",
        [TranslationKeys.ISSUE_ASSET_STEP_TITLE]: "Se han registrado datos fuera de rango ",
        [TranslationKeys.ISSUE_ASSET_STEP_TITLE_2]: "{1} veces seguidas",
        [TranslationKeys.ISSUE_ASSET_STEP_TITLE_3]: " Para el activo ",
        [TranslationKeys.ISSUE_ASSET_STEP_TITLE_4]: '"{0}"',

        [TranslationKeys.ISSUE_ASSET_STEP_SUBTITLE]: "{0}: {1} {2}.",
        [TranslationKeys.ISSUE_ASSET_STEP_SUBTITLE_SINGULAR]: "Registro Fuera de Rango.",
        [TranslationKeys.ISSUE_ASSET_STEP_SUBTITLE_PLURAL]: "Registros Fuera de Rango:",
        [TranslationKeys.ISSUE_ASSET_LAST_STEP_TITLE]:
            "Selecciona a los Responsables a quién quieres notificar esta Incidencia.",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK]: "No hay Incidencias vinculadas a Tareas",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_OPEN]: "No hay Incidencias Abiertas vinculadas a Tareas",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_CLOSE]: "No hay Incidencias Cerradas vinculadas a Tareas",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET]: "No hay Incidencias vinculadas a Activos",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET_OPEN]: "No hay Incidencias Abiertas vinculadas a Activos",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET_CLOSE]: "No hay Incidencias Cerradas vinculadas a Activos",
        [TranslationErrors.TABLE_ERROR_ISSUE_GENERIC]: "No hay Incidencias",
        [TranslationErrors.TABLE_ERROR_ISSUE_GENERIC_OPEN]: "No hay Incidencias Abiertas",
        [TranslationErrors.TABLE_ERROR_ISSUE_GENERIC_CLOSE]: "No hay Incidencias Cerradas",
        [TranslationErrors.TABLE_ERROR_ISSUE_AUDIT]: "No hay Incidencias vinculadas a Auditorias",
        [TranslationErrors.TABLE_ERROR_ISSUE_AUDIT_OPEN]: "No hay Incidencias Abiertas vinculadas a Auditorias",
        [TranslationErrors.TABLE_ERROR_ISSUE_AUDIT_CLOSE]: "No hay Incidencias Cerradas vinculadas a Auditorias",
        [TranslationModals.ISSUES_TOAST_FAILED_LOAD_DATA]: "Error al Cargar las Incidencias",
        [TranslationKeys.ISSUE_ID]: "ID",
        [TranslationKeys.ISSUE_DESCRIPTION]: "Descripción",
        [TranslationKeys.ISSUE_OPEN_DATE]: "Apertura",
        [TranslationKeys.ISSUE_OPEN_BY]: "Abierto Por",
        [TranslationKeys.ISSUE_ACTUAL_STATUS]: "Estado",
        [TranslationKeys.ISSUE_STATUS_OPEN]: "Abierta",
        [TranslationKeys.ISSUE_STATUS_CLOSE]: "Cerrada",
        [TranslationKeys.ISSUE_STATUS_OPEN_AND_CLOSE]: "Abierta y Cerrada",
        [TranslationKeys.ISSUE_STATE]: "Estado de la Incidencia",
        [TranslationKeys.ISSUES]: "Incidencias",
        [TranslationKeys.ISSUE_MENU_TITLE]: "Gestión de Incidencias",
        /* --------------------ISSUE END-------------------- */

        /* --------------------BASE TASK START-------------------- */
        [TranslationTitles.BASE_TASK_TITLE]: "Bolsa de Tareas",
        [TranslationTitles.BASE_TASK_REGISTRY_TITLE]: "Bolsa de Registros",
        [TranslationModals.BASE_TASK_TOAST_FAILED_LOAD_DATA]: "Error al cargar la bolsa de tareas",
        [TranslationModals.TOAST_SAVE_APPCC_BASE_TASK]: "Registro APPCC de la Bolsa Creado Correctamente",
        [TranslationModals.TOAST_SAVE_BASE_TASK]: "Tarea de la Bolsa Creada Correctamente",
        [TranslationModals.TOAST_EDIT_APPCC_BASE_TASK]: "Registro APPCC de la Bolsa Actualizado Correctamente",
        [TranslationModals.TOAST_EDIT_BASE_TASK]: "Tarea de la Bolsa Actualizada Correctamente",
        [BaseTaskTranslations.BASE_TASK_ID]: "Id",
        [BaseTaskTranslations.BASE_TASK_NAME]: "Detalle",
        [BaseTaskTranslations.BASE_TASK_DESCRIPTION]: "Descripción",
        [BaseTaskTranslations.BASE_TASKS]: "Tareas",
        [BaseTaskTranslations.BASE_TASKS_APPCC]: "Registros APPCC",
        [BaseTaskTranslations.BASE_TASK_TYPE_LABEL]: "Clasificación",
        [BaseTaskTranslations.BASE_TASKS_PLAN_APPCC_LABEL]: "Plan APPCC",
        [BaseTaskTranslations.NEW_APPCC_BASE_TASK]: "Nuevo Registro APPCC de la Bolsa",
        [BaseTaskTranslations.NEW_BASE_TASK]: "Nueva Tarea de la Bolsa",
        [BaseTaskTranslations.EDIT_APPCC_BASE_TASK]: "Editar Registro APPCC de la Bolsa",
        [BaseTaskTranslations.EDIT_BASE_TASK]: "Editar Tarea de la Bolsa",
        [BaseTaskTranslations.MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_TITLE]:
            "Se van a modificar todas las Tareas Vinculadas",
        [BaseTaskTranslations.MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_BODY]:
            "Modificar esta Tarea de la Bolsa implica modificar todas las Tareas que tiene vinculadas de todas las empresas a partir de mañana.",
        [BaseTaskTranslations.BASE_TASK_NOT_ALLOWED_DATA]: "No Permitir Ningun Dato",
        [BaseTaskTranslations.BASE_TASK_ADD_CHECKLIST]: "Añadir Checklist",
        [BaseTaskTranslations.BASE_TASK_ADD_DYNAMIC_FIELDS]: "Añadir Campos Dinámicos",
        [BaseTaskTranslations.BASE_TASK_ASSETS_IS_REQUIRED]: "Obligar a Vincular Activos",
        [BaseTaskTranslations.BASE_TASK_ALLOW_ANY_DATA]: "¿Permitir cualquier tipo de dato?",
        [BaseTaskTranslations.BASE_TASKS_REPORT_TYPE_LABEL]: "Tipos de Datos",
        [BaseTaskTranslations.BASE_TASKS_TYPE_LABEL]: "Clasificaciones de Tarea de la Bolsa",
        [BaseTaskTranslations.BASE_TASK_ALL_REPORT_TYPES_LABEL]: "Cualquier Dato",
        [BaseTaskTranslations.BASE_TASK_NONE_REPORT_TYPES_LABEL]: "Ningún Dato",
        [BaseTaskTranslations.BASE_TASK_DYNAMIC_FIELD_REPORT_TYPES_LABEL]: "Campos Dinámicos",
        [BaseTaskTranslations.BASE_TASK_CHECKLIST_REPORT_TYPES_LABEL]: "Checklist",
        [BaseTaskTranslations.BASE_TASK_ASSET_REPORT_TYPES_LABEL]: "Activos Vinculados",
        [BaseTaskTranslations.BASE_TASKS_IS_PHOTO_REQUIRED_LABEL]: "¿Foto Requerida?",
        [BaseTaskTranslations.BASE_TASK_ASSOCIATED_TASKS_COUNT_LABEL]: "Nº Tareas Asociadas",
        [BaseTaskTranslations.BASE_TASK_SEARCHING_TASKS]: "Buscando Tareas Similares...",
        [BaseTaskTranslations.BASE_TASK_SEARCHING_REGISTRIES]: "Buscando Registros Similares...",
        [BaseTaskTranslations.BASE_TASK_SIMILAR_TASKS]: "Tareas Similares Encontradas:",
        [BaseTaskTranslations.BASE_TASK_SIMILAR_REGISTRIES]: "Registros Similares Encontrados:",
        [BaseTaskTranslations.BASE_TASK_SAME_TITLE_TASK_TITLE]: "Título de tarea duplicado detectado",
        [BaseTaskTranslations.BASE_TASK_SAME_TITLE_TASK_DESCRIPTION]:
            "Se ha encontrado al menos una tarea con un título similar. Te recomendamos revisar los títulos existentes para evitar duplicidades o continuar si estás seguro de crear esta nueva tarea.",
        [BaseTaskTranslations.BASE_TASK_SAME_TITLE_REGISTRY_TITLE]: "Título del registro duplicado detectado",
        [BaseTaskTranslations.BASE_TASK_SAME_TITLE_REGISTRY_DESCRIPTION]:
            "Se ha encontrado al menos un registro con un título similar. Te recomendamos revisar los títulos existentes para evitar duplicidades o continuar si estás seguro de crear este nuevo registro.",
        /* --------------------BASE TASK END-------------------- */

        /* --------------------BASE TASK TYPE START-------------------- */
        [TranslationKeys.BASE_TASK_SAVE_TITLE]: "Guardar Clasificación de Tarea de la Bolsa",
        [TranslationKeys.BASE_TASK_EDIT_TITLE]: "Editar Clasificación de Tarea de la Bolsa",
        [TranslationErrors.BASE_TASK_TYPE_NAME_ERROR]: "El nombre no puede estar vacío.",
        [TranslationErrors.BASE_TASK_TYPE_NOT_FOUND]: "La Clasificación de la Tarea de la Bolsa no encontrado.",
        [TranslationModals.BASE_TASK_TYPE_SAVED_SUCCESSFULLY]:
            "La Clasificación de la Tarea de la Bolsa se ha Creado Correctamente.",
        [TranslationModals.BASE_TASK_TYPE_EDITED_SUCCESSFULLY]:
            "La Clasificación de la Tarea de la Bolsa se ha Editado Correctamente.",
        [TranslationModals.BASE_TASK_TYPE_DELETE_MODAL_TITLE]: "Eliminar Clasificación de Tarea de la Bolsa",
        [TranslationModals.BASE_TASK_TYPE_DELETE_MODAL_DESCRIPTION]:
            "Se va a Eliminar la Clasificación de la Tarea de la Bolsa {0}, ¿está seguro?",
        [TranslationKeys.BASE_TASK_TYPE_ADD_NEW_TYPE_TITLE]: "Añadir Nueva Clasificación de Tarea de la Bolsa",
        [TranslationModals.BASE_TASK_TYPE_DELETED_SUCCESSFULLY]:
            "La Clasificación de la Tarea de la Bolsa se ha Eliminado Correctamente.",
        [TranslationModals.CANNOT_DELETE_BASE_TASK_TYPE_TITLE]: "No se ha podido Eliminar",
        [TranslationModals.CANNOT_DELETE_BASE_TASK_TYPE_IN_USE]:
            "Para Eliminar la Clasificación de la Tarea de la Bolsa primero se tiene que desasignar de Todas las Tareas. Como alternativa, se puede Editar el Nombre.",
        /* --------------------BASE TASK TYPE END-------------------- */

        /* --------------------JOB DESCRIPTION START-------------------- */
        [TranslationErrors.JOB_DESCRIPTION_NOT_FOUND]: "La Categoría No Existe",
        [TranslationErrors.JOB_DESCRIPTION_ALREADY_EXIST]: "La Categoría Ya Existe",
        [TranslationKeys.JOB_DESCRIPTION_CREATE_NEW_TITLE]: "Añadir Nueva Categoría",
        [TranslationKeys.JOB_DESCRIPTION_EDIT_TITLE]: "Editar Categoría",
        [TranslationKeys.JOB_DESCRIPTION]: "Categoría",
        [TranslationModals.JOB_DESCRIPTION_DELETE_MODAL_TITLE]: "Eliminar Categoría",
        [TranslationModals.JOB_DESCRIPTION_DELETE_MODAL_DESCRIPTION]:
            "Vas a Eliminar la Categoría Ayudante Producción. Es una acción irreversible. ¿Estas Seguro?",
        [TranslationErrors.ERROR_DELETING_JOB_DESCRIPTION]: "No se ha Podido Eliminar la Categoría",
        [TranslationKeys.ADD_NEW_JOB_DESCRIPTION]: "Añadir nueva categoría",
        /* --------------------JOB DESCRIPTION END-------------------- */

        /* --------------------DRAGFILE START-------------------- */
        [TranslationKeys.DRAG_FILE_MAKE_PHOTO]: "Hacer una Foto",
        [TranslationKeys.DRAG_FILE_GALLERY]: "Galeria",
        /* --------------------DRAGFILE END-------------------- */

        /* --------------------LOGIN START-------------------- */
        [TranslationKeys.LOGGIN_USERNAME]: "Nombre de Usuario",
        [TranslationKeys.LOGGIN_PLACEHOLDER_USERNAME]: "Nombre de Usuario",
        [TranslationKeys.LOGGIN_PASSWORD]: "Contraseña",
        [TranslationKeys.LOGGIN_PLACEHOLDER_PASSWORD]: "Contraseña",
        [TranslationKeys.RECOVER_PASSWORD_EMAIL]: "Email",
        [TranslationKeys.RECOVER_PASSWORD_PLACEHOLDER_EMAIL]: "Email",
        [TranslationKeys.RESET_PASSWORD]: "Nueva Contraseña",
        [TranslationKeys.RESET_PASSWORD_PLACEHOLDER]: "Contraseña",
        [TranslationKeys.RESET_PASSWORD_CONFIRM_PASSWORD]: "Confirmar Contraseña",
        [TranslationKeys.RESET_PASSWORD_PLACEHOLDER_CONFIRM_PASSWORD]: "Confirmar Contraseña",
        /* --------------------LOGIN END-------------------- */

        /* --------------------PLACEHOLDER PAGE START-------------------- */
        [TranslationKeys.SITE_UNDER_CONSTRUCTION]: "Página en Construcción",
        /* --------------------PLACEHOLDER PAGE END-------------------- */

        /* --------------------PLANAPPCC STAR-------------------- */
        [TranslationKeys.PLAN_DELETE]: "Eliminar Plan",
        [TranslationKeys.PLAN_EDIT]: "Editar Plan",
        [TranslationKeys.PLAN_CREATE]: "Crear Plan",
        [TranslationErrors.PLAN_NOT_FOUND]: "No se ha encontrado el Plan",
        [TranslationKeys.PLAN_ID]: "Id",
        [TranslationKeys.PLAN_NAME]: "Nombre",
        [TranslationKeys.PLAN_NAME_TASK_COUNT]: "Registros Vinculadas",
        [TranslationKeys.PLAN_NAME_ASSET_COUNT]: "Activos Vinculadas",
        [TranslationKeys.PLAN_NAME_CORRECTIVE_MEASSURE_COUNT]: "Medidas Correctivas",
        [TranslationErrors.PLANS_NOT_FOUND]: "No se han Encontrado Planes",
        [TranslationKeys.PLANS]: "Planes",
        [TranslationModals.PLANS_FAILED_EDIT]: "No se ha Podido Editar el Plan",
        [TranslationModals.PLANS_FAILED_CREATE]: "No se ha Podido Crear el Plan",
        [TranslationModals.PLANS_SUCCESS_EDIT]: "Se ha Actualizado correctamente el Plan",
        [TranslationModals.PLANS_SUCCESS_CREATE]: "Se ha Creado correctamente el Plan",
        [TranslationModals.PLANS_SUCCESS_DELETE]: "Se ha Eliminado correctamente el Plan",
        [TranslationKeys.EDIT_FORM_PLAN_NAME_LABEL]: "Nombre del Plan",
        [TranslationKeys.EDIT_FORM_PLAN_NAME_PLACEHOLDER]: "Nombre del Plan",
        [TranslationModals.PLANS_DELETE_MODAL_TITLE]: 'El plan "{0}" se eliminará.',
        [TranslationModals.PLANS_DELETE_MODAL_DESCRIPTION]:
            "Al eliminar un plan se Van a Eliminar todos los Registros y Activos Vinculados así como las medidas Correctivas del Plan. Esta acción és irreversible.",
        /* --------------------PLANAPPCC END-------------------- */

        /* ---------------------AUDIT_PAGE START--------------------------------- */
        // AUDIT TEMPLATE
        [TranslationTitles.AUDIT_TEMPLATE_TITLE]: "Plantillas de Auditoría",
        [TranslationTitles.AUDIT_GRID_TITLE]: "Auditorías",
        [TranslationKeys.ADD_AUDIT]: "Nueva Plantilla",
        [TranslationKeys.AUDIT_NAME_LABEL]: "Nombre de la Plantilla",
        [TranslationErrors.AUDIT_NOT_FOUND]: "Plantilla no Encontrada",
        [TranslationModals.DELETE_AUDIT_TITLE]: 'Se va a borrar la Plantilla "{0}"',
        [TranslationModals.DELETE_AUDIT_BODY]:
            "Esta acción eliminará de forma permanente las agrupaciones y los checklists asociados a esta plantilla.",
        [TranslationKeys.NEW_AUDIT]: "Crear Nueva Plantilla de Auditoría",
        [TranslationKeys.EDIT_AUDIT]: "Editar Plantilla de Auditoría",
        [TranslationKeys.AUDIT_LIST_EMPTY_TITLE]: "No hay ninguna Plantilla de Auditoría",
        [TranslationKeys.AUDIT_LIST_EMPTY_BODY]: "Crea una Nueva Plantilla para facilitar una Auditoría.",
        [TranslationModals.AUDIT_SAVE_TOAST]: "Plantilla Guardada Correctamente",
        [TranslationModals.AUDIT_EDIT_TOAST]: "Plantilla Actualizada Correctamente",
        [TranslationModals.AUDIT_DELETE_TOAST]: "Plantilla Borrada Correctamente",

        // AUDIT GROUP
        [TranslationKeys.AUDIT_GROUP_NAME_LABEL]: "Nombre de la Agrupación de Checklists",
        [TranslationErrors.AUDIT_GROUP_NOT_FOUND]: "Agrupación no Encontrada",
        [TranslationModals.DELETE_AUDIT_GROUP_TITLE]: 'Se va a borrar la Agrupación "{0}".',
        [TranslationModals.DELETE_AUDIT_GROUP_BODY]:
            "Esta acción eliminará permanentemente las checklists asociadas dentro de esta plantilla.",
        [TranslationKeys.ADD_AUDIT_GROUP]: "Nueva Agrupación",
        [TranslationKeys.NEW_AUDIT_GROUP]: "Crear Nueva Agrupación",
        [TranslationKeys.EDIT_AUDIT_GROUP]: "Editar Agrupación",
        [TranslationModals.AUDIT_GROUP_SAVE_TOAST]: "Grupo Guardado Correctamente",
        [TranslationModals.AUDIT_GROUP_EDIT_TOAST]: "Grupo Actualizado Correctamente",
        [TranslationModals.AUDIT_GROUP_DELETE_TOAST]: "Grupo Borrado Correctamente",
        [TranslationKeys.AUDIT_GROUP_EMPTY_TITLE]: "No hay niguna Agrupación",
        [TranslationKeys.AUDIT_GROUP_EMPTY_BODY]:
            "Crea una Nueva Agrupación para facilitar la Agrupación dentro de una Auditoría.",

        // AUDIT GROUP CHECKLIST
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL]: "Nombre del Checklist",
        [TranslationKeys.NEW_AUDIT_GROUP_CHECKLIST]: "Nuevo Checklist",
        [TranslationKeys.EDIT_AUDIT_GROUP_CHECKLIST]: "Editar Checklist",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME]: "Checklists",
        [TranslationErrors.AUDIT_GROUP_CHECKLIST_NAME_ERROR]: "El Nombre no puede estar vacío.",
        [TranslationErrors.AUDIT_GROUP_CHECKLIST_SUBMIT_ERROR_TITLE]: "Algo ha fallado al actualizar el Checklist.",
        [TranslationKeys.AUDIT_GROUP_CHECKLISTS]: "Checklists",
        [TranslationModals.DELETE_AUDIT_GROUP_CHECKLIST_TITLE]: 'Se va a borrar el Checklist "{0}"',
        [TranslationModals.DELETE_AUDIT_GROUP_CHECKLIST_BODY]:
            "Esta acción eliminará permanentemente los ítems dentro del checklist",
        [TranslationModals.AUDIT_GROUP_CHECKLIST_SAVE_TOAST]: "Checklist Guardado Correctamente",
        [TranslationModals.AUDIT_GROUP_CHECKLIST_EDIT_TOAST]: "Checklist Actualizado Correctamente",
        [TranslationModals.AUDIT_GROUP_CHECKLIST_DELETE_TOAST]: "Checklist Borrado Correctamente",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_EMPTY_TITLE]: "No hay ningún checklist",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_EMPTY_BODY]:
            "Crea un Nuevo Checklist para facilitar la resolución de una Auditoría",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_ITEM_COUNT]: "Cantidad",

        // AUDIT GROUP CHECKLIST ITEM
        [TranslationKeys.ADD_AUDIT_GROUP_CHECKLIST_ITEM]: "Nuevo Ítem del Checklist",
        [TranslationErrors.AUDIT_GROUP_CHECKLIST_ITEM_ERROR]: "Selecciona un Ítem.",
        /* ---------------------AUDIT_PAGE END--------------------------------- */

        /* --------------------PLANAPPCC STAR-------------------- */
        [TranslationKeys.BASE_TASK_MODAL_TITLE]: "Selecciona una Tarea de la Bolsa",
        [TranslationKeys.BASE_TASK_MODAL_REGISTRY_TITLE]: "Selecciona un Registro APPCC de la Bolsa",
        [TranslationKeys.BASE_TASK_MODAL_IS_APPCC]: "¿Se Trata de una Tarea APPCC?",
        [TranslationKeys.BASE_TASK_MODAL_IS_TASK_TYPE_LABEL]: "Selecciona la Clasificación de la Tarea",
        [TranslationKeys.BASE_TASK_MODAL_TASKS_LABEL]: "Selecciona la Tarea de la Bolsa",
        [TranslationKeys.BASE_TASK_MODAL_CREATE_NEW]: "¿No Encuentras la Tarea? ¡Crea una!",
        [TranslationErrors.BASE_TASK_NOT_FOUND]: "No se ha Encontrado la Tarea",
        [TranslationKeys.BASE_TASK_LABEL]: "Tarea de la Bolsa",
        /* --------------------PLANAPPCC END-------------------- */

        /* --------------------CORRECTIVE MEASURES START-------------------- */
        [TranslationKeys.CORRECTIVE_MEASSURE_MEASURE]: "Medidas",
        [TranslationKeys.CORRECTIVE_MEASSURE_MODAL_TITLE_CREATE]: "Crear Medida Correctiva",
        [TranslationKeys.CORRECTIVE_MEASSURE_MODAL_TITLE_EDIT]: "Editar Medida Correctiva",
        [TranslationKeys.CORRECTIVE_MEASSURE_MODAL_LABEL]: "Nombre de la Medida Correctiva",
        [TranslationKeys.CORRECTIVE_MEASSURE_MODAL_PLACEHOLDER]: "Nombre de la Medida Correctiva",
        [TranslationKeys.CORRECTIVE_MEASSURE_DESCRIPTION]: "Descripción",
        [TranslationKeys.CORRECTIVE_MEASSURE_PLAN]: "Plan APPCC",
        [TranslationKeys.CORRECTIVE_MEASSURE_ID]: "Id",
        [TranslationModals.CORRECTIVE_MEASSURE_FAILED_DELETE]: "No se ha Podido Eliminar la Medida Correctiva",
        [TranslationModals.CORRECTIVE_MEASSURE_DELETE_MODAL_DESCRIPTION]:
            "¿Estás seguro que quieres Eliminar la Media Correctora? Esta acción és irreversible.",
        [TranslationModals.CORRECTIVE_MEASSURE_DELETE_MODAL_TITLE]: "Eliminar {0}",
        [AriaLabels.AL_QR_COMPANY_PHOTO]: "Logo de la empresa",
        [AriaLabels.AL_QR_FORULINK_PHOTO]: "Logo 4Link",
        [AriaLabels.AL_POPOVER]: "Popover",
        [AriaLabels.AL_ALERT_PILL_READED_BADGE]: "Alerta Leída",
        [AriaLabels.AL_ALERT_PILL_SENDED_BADGE]: "Alerta Enviada",
        /* --------------------CORRECTIVE MEASURES END-------------------- */

        /* --------------------AUDIT START-------------------- */
        [TranslationKeys.AUDIT]: "Auditoria",
        [TranslationKeys.START_AUDIT]: "Iniciar Auditoria",
        [TranslationKeys.START_AUDIT_MODAL_TITLE]: "Iniciar Auditoria",
        [TranslationKeys.START_AUDIT_MODAL_COMPANY_LABEL]: "Centro de Trabajo",
        [TranslationKeys.START_AUDIT_MODAL_AUDIT_LABEL]: "Auditoria",
        [TranslationKeys.AUDIT_NOT_EXIST]: "La auditoria no existe",
        [TranslationKeys.AUDIT_CHECKLIST_INSTANC_NOT_EXIST]: "No se han encontrado los Checklist",
        [TranslationKeys.AUDIT_TOTAL]: "Total",
        [TranslationErrors.AUDIT_CHECKLIST_ERROR_FORM_VALUES]:
            "Complete todos los items del checklist para finalizarlo",
        [TranslationKeys.COMPLETE_CHECKLIST]: "Finalizar Checklist",
        [TranslationModals.AUDIT_CHECKLIST_ITEM_FAILED_UPDATE]: "Error al actualizar",
        [TranslationKeys.AUDIT_CHECKLIST_ITEM_REQUIRED]: "Obligatorio",
        [TranslationModals.AUDIT_CHECKLIST_MODAL_TITLE]: "Checklist Incompleto",
        [TranslationModals.AUDIT_CHECKLIST_MODAL_DESCRIPTION]:
            'Todos los items se van a marcar automaticamente como "No Aplica" para este checklist.',
        [TranslationModals.AUDIT_CHECKLIST_FAILED_COMPLETE]: "No se ha podido completar el checklist",
        [TranslationModals.AUDIT_CHECKLIST_COMPLETED]: "Se ha completado el checklist",
        [TranslationKeys.AUDIT_STATUS]: "Estado",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_COMPLETED]: "Completado",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_IN_PROGRESS]: "Pendiente",
        [TranslationKeys.AUDIT_ID]: "Id",
        [TranslationKeys.AUDIT_OPENED_BY]: "Auditor",
        [TranslationKeys.AUDIT_OPENED_DATE]: "Fecha de Apertura",
        [TranslationKeys.AUDIT_CLOSED_BY]: "Responsable Cierre",
        [TranslationKeys.AUDIT_CLOSED_DATE]: "Fecha de Cierre",
        [TranslationKeys.AUDIT_CLOSED]: "Cerrada",
        [TranslationKeys.AUDIT_OPEN]: "Abierta",
        [TranslationKeys.AUDITS]: "Auditorias",
        [TranslationKeys.AUDIT_OPEN_TAB]: "Abiertas",
        [TranslationKeys.AUDIT_ALL_CHECKLIST_REQUIRED]: "Tienes que Completar Todos los Checklist",
        [TranslationKeys.AUDIT_FAILED_COMPLETE]: "Fallo al Completar la Auditoria",
        [TranslationKeys.AUDIT_SUCCESS_COMPLETE]: "Auditoría completada",
        [TranslationKeys.AUDIT_FINISH]: "Finalizar Auditoria",
        [TranslationKeys.AUDIT_FINISH_CONFIRM_MODAL_TITLE]: "Finalizar Auditoría",
        [TranslationKeys.AUDIT_FINISH_CONFIRM_MODAL_SUBTITLE]:
            "Una vez finalizada la auditoria no se va a poder modificar. Asegurate que todos los checklist y No Conformidades se han rellenado correctamente.",
        [TranslationKeys.AUDIT_OPEN_DAY]: "Abierto el {0}h",
        [TranslationKeys.AUDIT_CLOSED_DAY]: "Cerrado el {0}h",
        /* --------------------AUDIT END-------------------- */

        /* --------------------MEASUREMENT UNIT START-------------------- */
        [TranslationKeys.ADD_MEASUREMENT_UNIT]: "Añadir Nueva Unidad de Medida",
        [TranslationKeys.SAVE_MEASUREMENT_UNIT_TITLE]: "Guardar Unidad de Medida",
        [TranslationKeys.EDIT_MEASUREMENT_UNIT_TITLE]: "Editar Unidad de Medida",
        [TranslationErrors.MEASUREMENT_UNIT_CANNOT_BE_NULL]: "La Unidad de Medida no puede estar vacía.",
        [TranslationModals.MEASUREMENT_UNIT_SAVED_TOAST]: "Unidad de Medida Guardada Correctamente",
        [TranslationModals.MEASUREMENT_UNIT_EDITED_TOAST]: "Unidad de Medida Editada Correctamente",
        [TranslationModals.MEASUREMENT_UNIT_DELETE_TITLE]: "Eliminar Unidad de Medida",
        [TranslationModals.MEASUREMENT_UNIT_DELETE_DESCRIPTION]:
            "Se va a Eliminar la Unidad de Medida {0}, ¿está seguro?",
        [TranslationModals.MEASUREMENT_UNIT_DELETED_TOAST]: "Unidad de Medida Eliminada Correctamente",
        [TranslationModals.CANNOT_DELETE_MEASUREMENT_UNIT_TOAST_TITLE]: "No se puede Eliminar",
        [TranslationModals.CANNOT_DELETE_MEASUREMENT_UNIT_IN_USE]:
            "Para poder eliminar esta Unidad de Medida, hay que desvincularla de todos los Activos. Como alternativa puedes cambiarle el nombre.",
        [IssueTranslation.CREATING_AUDIT_TITLE_TOAST]: "Guardando Auditoría...",
        [IssueTranslation.CREATING_AUDIT_DESCRIPTION_TOAST]:
            "Se está guardando la auditoría y generando las incidencias.",
        [IssueTranslation.DELETE_NON_CONFORMITY_TITLE]: "Eliminar No Conformidad",
        [IssueTranslation.DELETE_NON_CONFORMITY_DESCRIPTION]:
            "Se va a eliminar la No Conformidad asociada a este checklist. Si esta No Conformidad tiene una Tarea asociada, ésta también será eliminada. Esta acción es irreversible.",
        /* --------------------MEASUREMENT UNIT END-------------------- */

        /* --------------------ALL THING END-------------------- */
        [TranslationKeys.ALL_TASKS]: "Todas las Tareas",
        [TranslationKeys.ALL_QRS]: "Todos los QRs",
        [TranslationKeys.ALL_PLANS]: "Todos los Planes",
        [TranslationKeys.ALL_COMPANIES]: "Todos los Centros",
        /* --------------------ALL THING END-------------------- */

        /* --------------------FAQS START-------------------- */
        [TranslationKeys.MENU_SIDEBAR_FAQS]: "FAQs y Problemas",
        [TranslationFAQs.FAQ_1_TITLE]: "¿Qué debo hacer si olvido mi contraseña?",
        [TranslationFAQs.FAQ_1_DESCRIPTION]:
            'Si olvidas tu contraseña, haz clic en "¿Olvidaste tu contraseña?" en la pantalla de inicio de sesión. Ingresa tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.',
        [TranslationFAQs.FAQ_2_TITLE]: "¿Qué hago si la aplicación tarda mucho en cargar mi lista de tareas?",
        [TranslationFAQs.FAQ_2_DESCRIPTION]:
            "A veces el servidor puede estar sobrecargado de peticiones, prueba a cerrar y abrir de nuevo la aplicación. Si el problema persiste, contacte con el administrador de la aplicación.",
        [TranslationFAQs.FAQ_3_TITLE]: "¿Cómo contactar con el soporte técnico?",
        [TranslationFAQs.FAQ_3_DESCRIPTION]:
            "Si necesitas ayuda adicional, puedes contactar al equipo de soporte técnico enviando un correo electrónico a soporte@4link.app.",
        /* --------------------FAQS END-------------------- */

        /* --------------------ISSUE START-------------------- */
        [IssueTranslation.ISSUE_BODY_DESCRIPTION_TITLE]: "Explicación de la Incidencia",
        [IssueTranslation.ISSUE_BODY_CHECKLIST_ITEM_TITLE]: "Punto del Ckecklist causante de la No Conformidad",
        [IssueTranslation.ISSUE_BODY_DESCRIPTION_ASSET_NAME]: "Nombre Activo",
        [IssueTranslation.ISSUE_BODY_DESCRIPTION_ASSET_RANGE]: "Rango Esperado",
        [IssueTranslation.ISSUE_BODY_RESPONSIBLE_TITLE]: "Responsables Notificados",
        [IssueTranslation.ISSUE_BODY_ATTACHMENT_TITLE]: "Documentos Adjuntos",
        [IssueTranslation.ISSUE_BODY_ATTACHMENT_ADD_DOCUMENT]: "Añadir Documento",
        [IssueTranslation.ISSUE_HISTORY_TITLE]: "Historial",
        [IssueTranslation.ISSUE_HISTORY_OPEN]: "Se abrió el {0}",
        [IssueTranslation.ISSUE_HISTORY_CLOSE]: "Se cerró el {0}",
        [IssueTranslation.ISSUE_HISTORY_REOPEN_ISSUE]: "Reabrir Incidencia",
        [IssueTranslation.ISSUE_HISTORY_CLOSE_ISSUE]: "Cerrar Incidencia",
        [IssueTranslation.ISSUE_HISTORY_COMMENT_PLACEHOLDER]: "Explica el porqué...",
        [IssueTranslation.ISSUE_TITLE_AUDIT]: "Incidencia Auditoria #",
        [IssueTranslation.ISSUE_TITLE_GENERIC]: "Incidencia Genérica #",
        [IssueTranslation.ISSUE_TITLE_TASK]: "Incidencia Tarea #",
        [IssueTranslation.ISSUE_TITLE_ASSET]: "Incidencia #{0} - Activo #{1}",
        [IssueTranslation.ISSUE_BODY_GO_TO_TASK]: "Ver Detalles de la Tarea",
        [IssueTranslation.ISSUE_SAVE_CONFIRM_BUTTON]: "Guardar Incidencia",
        [IssueTranslation.ISSUE_MODAL_AUDIT_CREATE_TASK]: "Crear Tarea (Opcional)",
        [IssueTranslation.DELETE_NON_CONFORMITY_AND_TASK_TITLE]: "Eliminar Tarea de No Conformidad",
        [IssueTranslation.DELETE_NON_CONFORMITY_AND_TASK_DESCRIPTION]:
            "Si eliminas esta Tarea de No Conformidad se eliminará también la No Conformidad asociada. Esta acción es irreversible.",
        [IssueTranslation.ISSUE_CLASSIFICATION]: "Clasificación",
        [IssueTranslation.DELETE_ISSUE_CLASSIFICATION_TITLE]: "Eliminar Clasificación",
        [IssueTranslation.DELETE_ISSUE_CLASSIFICATION_DESCRIPTION]:
            "Vas a Eliminar la Clasificación {0}. Es una acción irreversible. ¿Estas Seguro?",
        [IssueTranslation.ADD_NEW_ISSUE_CLASSIFICATION]: "Añadir nueva clasificación",
        [IssueTranslation.ERROR_DELETING_ISSUE_CLASSIFICATION]: "Ha habido un error al eliminar una clasificación.",
        [IssueTranslation.NEW_ISSUE_CLASSIFICATION]: "Nueva Clasificación",
        [IssueTranslation.EDIT_ISSUE_CLASSIFICATION]: "Editar Clasificación",
        /* --------------------ISSUE END-------------------- */

        /* --------------------AUDIT GRID START-------------------- */
        [AuditGridTranslation.EMPTY_LIST_OPEN]: "No Hay Auditorías Abiertas",
        [AuditGridTranslation.EMPTY_LIST_CLOSED]: "No Hay Auditorías Cerradas",
        /* --------------------AUDIT GRID END-------------------- */

        /* --------------------ASSET START-------------------- */
        [AssetTranslations.ASSET_INFO_TITLE]: "Information",
        [AssetTranslations.IS_ASSET_NOT_WORKING]: "¿El Activo está Averiado?",
        [AssetTranslations.ASSET_NOT_WORKING_QUESTION]: "¿Averiado?",
        [AssetTranslations.CANNOT_BE_REPAIRED]: "No se ha podido Registrar la Reparación.",
        [AssetTranslations.ASSET_REPARATION_TITLE]: "Reparación del Activo",
        [AssetTranslations.ASSET_REPARATION_DESCRIPTION]:
            "Estás a punto de confirmar que este activo ha sido reparado. ¿Deseas continuar y registrar esta reparación?",
        [AssetTranslations.ASSET_NOT_WORKING]: "Averiado",
        [AssetTranslations.ASSET_REPAIRED]: "Operativo",
        [AssetTranslations.FREEZER]: "Congelador",
        [AssetTranslations.ASSET_LINKED_TO_A_SENSOR]: "Vinculado a Sensor",
        [AssetTranslations.ASSET_CONSECUTIVE_ISSUES_LABEL]: "Inc. Consec.",
        [AssetTranslations.ICE_CREAM_FREEZER]: "Congelador de Helados",
        [AssetTranslations.FRIDGE]: "Nevera",
        [AssetTranslations.SWIMMING_POOL]: "Piscina",
        [AssetTranslations.TEMPERATURE_OPTION]: "Temperatura",
        [AssetTranslations.PH]: "PH",
        [AssetTranslations.RED_WINE_FRIDGE]: "Nevera de Vinos Tintos",
        [AssetTranslations.WHITE_WINE_FRIDGE]: "Nevera de Vinos Blancos",
        [AssetTranslations.WITHOUT_SENSOR]: "Sin Sensor",
        [AssetTranslations.WITH_SENSOR]: "Con Sensor",
        [AssetTranslations.DEACTIVATED]: "Desactivados",
        [AssetTranslations.ASSET_REDIRECT_AUTOMATIC_RECORD_LABEL]: "Ver Últimos Registros",
        [AssetTranslations.ASSET_SENSOR_TITLE]: "Información del Sensor",
        [AssetTranslations.ASSET_SENSOR_NO_DATA]: "El sensor no ha registrado ningun dato.",
        [AssetTranslations.ASSET_FORM_REPLACE_SENSOR_ENTRIES_TITLE]: "Detectado Sensor con Registros",
        [AssetTranslations.ASSET_FORM_REPLACE_SENSOR_ENTRIES_DESCRIPTION]:
            "Al guardar el Código de Sensor {0}, {1} registros anteriores se vincularán al Activo {2}. Esta acción es irreversible. ¿Estás seguro?",
        [AssetTranslations.ASSET_NOT_FOUND]: "No se ha encontrado el activo",
        /* --------------------ASSET END-------------------- */

        /* --------------------ANALIST START-------------------- */
        [AnalistTranslation.TOTAL_TASKS_NUMBER]: "Número Total de Tareas",
        /* --------------------ANALIST END-------------------- */

        /* --------------------ISSUE START-------------------- */
        [IssueTranslation.ISSUE_MODAL_AUDIT_TITLE]: "No Conformidad",
        [IssueTranslation.ISSUE_MODAL_AUDIT_NON_CONFORMITY]: "Incidencia Crítica",
        [IssueTranslation.AUDIT_CODE]: "Código de Auditoría",
        /* --------------------ISSUE END-------------------- */

        /* --------------------AUDIT START-------------------- */
        [AuditTranslation.AUDIT_TITLE]: "Auditoría",
        [AuditTranslation.AUDIT_NON_CONFORMITY]: "No Conformidades",
        [AuditTranslation.AUDIT_REPORT]: "Informe",
        [AuditTranslation.AUDIT_FINISH_REVIEW]: "Finalizar Revisión",
        [AuditTranslation.AUDIT_CLOSE_AUDIT]: "Cerrar Auditoria",
        [AuditTranslation.AUDIT_IN_PROGRESS]: "En Curso",
        [AuditTranslation.AUDIT_IN_REVIEW]: "En Revisión",
        [AuditTranslation.AUDIT_REVIEWED]: "Revisadas",
        [AuditTranslation.AUDIT_REVIEWED_SINGULAR]: "Revisada",
        [AuditTranslation.AUDIT_CLOSED_TAB]: "Cerradas",
        [AuditTranslation.AUDIT_IN_PROGRESS_TAB]: "Abiertas",
        [AuditTranslation.AUDIT_FAILED_CLOSE]: "No se ha podido cerrar la auditoria",
        [AuditTranslation.AUDIT_FAILED_FINISH_REVIEW]: "No se ha podido finalizar la revisión",
        [AuditTranslation.AUDIT_FINISH_MODAL_TITLE]: "Finalizar Revisión",
        [AuditTranslation.AUDIT_FINISH_MODAL_DESCRIPTION]:
            "Una vez revisadas y cerradas las No Conformidades, no se van a poder modificar hasta la supervisión del Auditor. Asegurate que todas las Incidencias se han cerrado correctamente.",
        [AuditTranslation.AUDIT_CLOSE_MODAL_TITLE]: "Aprobar Revisión",
        [AuditTranslation.AUDIT_CLOSE_MODAL_DESCRIPTION]:
            "Una vez aprovada la revisión la auditoria no se va a poder modificar. Esta acción es irreversible.",
        [AuditTranslation.AUDIT_ISSUE_MODAL_TITLE]: "Revisar No Conformidad",
        [AuditTranslation.AUDIT_ISSUE_DELETE_NON_CONFORMITY_BUTTON]: "Eliminar No Conformidad",
        [AuditTranslation.AUDIT_ISSUE_DELETE_NON_CONFORMITY]:
            "El item asociado a esta No Conformidad se va a marcar como OK.",
        [AuditTranslation.AUDIT_ISSUE_PLACEHOLDER]: "¡Enhorabuena! Esta auditoría no tiene ninguna no conformidad.",
        [AuditTranslation.AUDIT_ALL_ISSUES_HAS_TO_BE_CLOSED]: "Todas las incidencias deben de estar cerradas",
        [AuditTranslation.AUDIT_IN_PROGRESS_FILTER_BUTTON]: "En Progreso",
        [AuditTranslation.AUDIT_DONE_FILTER_BUTTON]: "Hechas",
        [AuditTranslation.AUDIT_ORDER_BY_AUDITOR_DEFAULT]: "Auditor",
        [AuditTranslation.AUDIT_ORDER_BY_AUDITOR_ASC]: "Auditor Asc.",
        [AuditTranslation.AUDIT_ORDER_BY_AUDITOR_DESC]: "Auditor Desc.",
        /* --------------------AUDIT END-------------------- */

        /* --------------------CLOSE COMPANY START-------------------- */
        [CloseCompanyTranslations.CLOSE_COMPANIES_MODAL_TITLE]: "Estado de Centros de Trabajo Actualizado",
        [CloseCompanyTranslations.OPEN_COMPANIES_COLLAPSABLE_TITLE]: "Nuevas Aperturas",
        [CloseCompanyTranslations.CLOSE_COMPANIES_COLLAPSABLE_TITLE]: "Cerrados",
        [CloseCompanyTranslations.CLOSE_COMPANIES_COLLAPSABLE_LABEL]: "Cerrado:",
        [CloseCompanyTranslations.OPEN_COMPANIES_COLLAPSABLE_LABEL]: "Nueva Apertura",
        [CloseCompanyTranslations.ADD_NEW_CLOSE_DATE_RANGE]: "Añadir Fechas de Cierre",
        [CloseCompanyTranslations.OVERLAPING_DATES_NOT_PERMITTED]: "Los rangos se solapan.",
        [CloseCompanyTranslations.SOME_EMPTY_VALUES]: "Hay algun campo inválido.",
        [CloseCompanyTranslations.CLOSE_COMPANY]: "¿Quieres Cerrar el Centro?",
        [CloseCompanyTranslations.COMPANY_CLOSED_DAYS]: "Cerrado ({1})",
        [CloseCompanyTranslations.COMPANY_OPEN_LABEL]: "Centros",
        [CloseCompanyTranslations.COMPANY_CLOSED_LABEL]: "Centros",
        /* --------------------CLOSE COMPANY END-------------------- */

        /* --------------------RANKING START-------------------- */
        [AnalistRankingTranslations.JOB_DESCRIPTION_LABEL]: "Categoría",
        /* --------------------RANKING END-------------------- */

        /* --------------------TASK INSTANCE START-------------------- */
        [TaskInstanceTranslations.CANNOT_REOPEN_TASK_INSTANCE]:
            "No se puede Reabrir una Tarea Completada en un Día Distinto a Hoy",
        /* --------------------TASK INSTANCE END-------------------- */

        /* --------------------TASK START-------------------- */
        [TaskTranslations.IS_TASK_SPORADIC]: "¿Se trata de una Tarea Esporádica?",
        [TaskTranslations.TASK_TYPE_SPORADIC]: "Esporádica",
        [TaskTranslations.TASK_TYPE_NORMAL]: "Normal",
        [TaskTranslations.TASK_TYPE_AUDIT]: "Auditoría",
        [TaskTranslations.TASK_TYPE_TITLE]: "Tipo de Tarea",
        [TaskTranslations.TASK_ASSIGNED_TITLE]: "Assignación",
        [TaskTranslations.TASK_DATE_TITLE]: "Fecha",
        [TaskTranslations.TASK_ATTATHCMENTS_TITLE]: "Adjunto",
        [TaskTranslations.TASK_APPCC_LABEL]: "Plan APPCC",
        [TaskTranslations.TASK_APPCC_PLACEHOLDER]: "Selecciona un plan",
        [TaskTranslations.TASK_ALL]: "Todos",
        [TaskTranslations.TASK_ALL_COLUMN]: "Asignación",
        [TaskTranslations.TASK_SAVE_CONFIRM_BUTTON]: "Guardar Tarea",

        /* --------------------TASK END-------------------- */

        /* --------------------BASE AUDIT GROUP CHECKLIST ITEM START-------------------- */
        [BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_ID_LABEL]: "Id",
        [BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_NAME_LABEL]: "Descripción",
        [BaseAuditGroupCheckListItemTranslations.TYPE_LABEL]: "Clasificación",
        [BaseAuditGroupCheckListItemTranslations.PONDERATION_LEVEL_LABEL]: "Ponderación por Defecto",
        [BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_GRID_TITLE]: "Bolsa de Ítems",
        [BaseAuditGroupCheckListItemTranslations.CELL_BUTTONS_LABEL]: "Acciones",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_TITLE]:
            "Se va a Eliminar el Ítem del Checklist de la Bolsa {0}",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_DESCRIPTION]:
            "Esta acción implica eliminar este ítem de checklist en todas las plantillas de auditoría y no se puede revertir, ¿estás seguro?",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_SUCCESS_TOAST]:
            "El Ítem del Checklist de la Bolsa se ha Eliminado Correctamente",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_SAVE]:
            "El Ítem del Checklist de la Bolsa se ha Creado Correctamente",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_EDIT]:
            "El Ítem del Checklist de la Bolsa se ha Editado Correctamente",
        [BaseAuditGroupCheckListItemTranslations.NAME_IS_REQUIRED]: "El Nombre no puede estar vacío.",
        [BaseAuditGroupCheckListItemTranslations.FK_BASEAUDITGROUPCHECKLISTITEMTYPE_IS_REQUIRED]:
            "Seleccione la Clasificación del Ítem del Checklist.",
        [BaseAuditGroupCheckListItemTranslations.PONDERATIONLEVEL_IS_REQUIRED]: "La Ponderación es requerida.",
        [BaseAuditGroupCheckListItemTranslations.PONDERATION_RANGE_ERROR]: "La Ponderación debe estar entre 0 y 10.",
        [BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SELECTOR_LABEL]: "Ítems",

        /* --------------------BASE AUDIT GROUP CHECKLIST ITEM END-------------------- */

        /* --------------------BASE AUDIT GROUP CHECKLIST ITEM TYPE START-------------------- */
        [BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_ADD_TITLE]:
            "Añadir Nueva Clasificación de Ítem de Checklist",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_SUCCESS_TOAST]:
            "La clasificación del Ítem del Checklist se ha Eliminado Correctamente",
        [BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_NAME_LABEL]: "Nombre",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_SAVE_SUCCESS_TOAST]:
            "La clasificación del Ítem del Checklist se ha Creado Correctamente",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_EDIT_SUCCESS_TOAST]:
            "La clasificación del Ítem del Checklist se ha Editado Correctamente",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_TITLE]:
            "Se va a Eliminar la Clasificación del Ítem del Checklist {0}",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_DESCRIPTION]:
            "Esta acción implica eliminar esta clasificación de ítem de checklist en todas las plantillas de auditoría y no se puede revertir, ¿estás seguro?",
        [TranslationModals.CANNOT_DELETE_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_IN_USE]:
            "Para Eliminarlo primero se tiene que desasignar de Todos los Ítems del Checklist. Como alternativa, se puede Editar el Nombre.",
        [TranslationModals.CANNOT_DELETE_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_IN_USE_TITLE_TOAST]:
            "No se ha podido Eliminar la Clasificación de Ítem del Checklist",
        [TranslationModals.BASE_TASK_COMPLETE_ALL_TASK]:
            "Completa los siguientes campos para empezar la creación de la tarea:",
        [TranslationModals.BASE_TASK_COMPLETE_ALL_REGISTRY]:
            "Completa los siguientes campos para empezar la creación del registro:",
        [BaseAuditGroupCheckListItemTypeTranslations.NAME_IS_REQUIRED]: "El Nombre no puede estar vacío.",
        [BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_ALL_TYPES]: "Todos",
        /* --------------------BASE AUDIT GROUP CHECKLIST ITEM TYPE END-------------------- */

        /* --------------------QR SCANNER START-------------------- */
        [ScanQRTranslations.QR_SCANNER_SCANNING]: "Escaneando",
        [ScanQRTranslations.QR_SCANNER_START_SCANNING]: "Comenzar a escanear",
        [ScanQRTranslations.QR_SCANNER_STOP_SCANNING]: "Dejar de Escanear",
        [ScanQRTranslations.QR_SCANNER_MODAL_TITLE]: "Escanea el QR o introdúcelo manualmente",
        [ScanQRTranslations.QR_SCANNER_MODAL_ALTERNATIVE_TITLE]: "Escribe el código QR manualmente",
        [ScanQRTranslations.QR_SCANNER_MODAL_SCAN]: "ESCANEAR",
        [ScanQRTranslations.QR_SCANNER_MODAL_MANUAL_SCAN]: "INTRODUCIR",
        [ScanQRTranslations.QR_SCANNER_MODAL_CLOSE_SCANNER]: "Cerrar Escáner",
        /* --------------------QR SCANNER END-------------------- */

        /* -------------------- AUDIT GROUP CHECKLIST ITEM START-------------------- */
        [AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECKLIST_ITEM_PONDERATION_LABEL]: "Ponderación",
        [AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_TITLE_LABEL]:
            "Cambios Detectados",
        [AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_DESCRIPTION_LABEL]:
            "Has realizado cambios y vas a descartarlos, ¿estás seguro?",
        /* -------------------- AUDIT GROUP CHECKLIST ITEM END-------------------- */

        /* -------------------- AUDIT INSTANCE START-------------------- */
        [AuditInstanceTranslations.AUDIT_INSTANCE_REPORT_ERROR]: "Ha habido un error al obtener los datos.",
        [AuditInstanceTranslations.AUDIT_INSTANCE_REPORT_BARCHART_TITLE]: "Histórico de las Auditorías",
        [AuditInstanceTranslations.EMPTY_BAR_CHART_TITLE]: "No hay Histórico de Auditorías",
        [AuditInstanceTranslations.EMPTY_BAR_CHART_DESCRIPTION]:
            "El Histórico de Auditorías no existe o tiene Auditorías que No Aplican.",
        /* -------------------- AUDIT INSTANCE END-------------------- */

        /* -------------------- AUDIT GROUP CHECKLIST INSTANCE START-------------------- */
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NAME]: "Nombre",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_TITLE]: "No hay Resultados",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION]:
            "No hay Informe de la Auditoría",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION_FILTER]:
            "No hay Ítems de la Clasificación seleccionada",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_GRADE]: "Nota",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_VALORATION]: "Valoración",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NOT_APPLICABLE]: "No Aplica",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_ISSUE_COUNT]: "No Conformidades",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_ISSUE_COUNT_SMALL]: "NC",
        /* -------------------- AUDIT GROUP CHECKLIST INSTANCE END-------------------- */

        /* -------------------- REVIEW TASK START-------------------- */
        [ReviewTaskTranslations.ALL_DATA_TO_REPORT]: "Todos",
        [ReviewTaskTranslations.NO_DATA_TO_REPORT]: "Solo tareas sin datos",
        [ReviewTaskTranslations.WITH_DATA_TO_REPORT]: "Solo tareas con datos",
        [ReviewTaskTranslations.WITH_CHECKLIST_TO_REPORT]: "Solo tareas con checklist",
        [ReviewTaskTranslations.WITH_ASSET_TO_REPORT]: "Solo tareas con activos",
        [ReviewTaskTranslations.LINKED_DATA]: "Datos Vinculados",
        /* -------------------- REVIEW TASK END-------------------- */

        /* -------------------- New User Tabs START-------------------- */
        [NewUserTabsTranslation.RESET_PASSWORD_USER]: "Reiniciar contraseña",
        [NewUserTabsTranslation.USER_DATA_TITLE]: "Datos del Usuario",
        [NewUserTabsTranslation.USER_PERMISSIONS_TITLE]: "Permisos",
        [NewUserTabsTranslation.ASSIGNED_COMPANIES__TITLE]: "Centros Assignados",
        /* -------------------- New User Tabs END-------------------- */

        /* --------------------OnBoarding Steps START-------------------- */
        [OnBoardingStepsTranslation.ONBOARDING_INITIAL_CONFIG]: "Configuración Inicial",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_COMPANY]: "Creación de Centros y Equipos",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_ROLES]: "Configuración de Permisos",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_USERS]: "Creación de Usuarios",
        [OnBoardingStepsTranslation.ONBOARDING_ASSIGN_USERS_TO_TEAMS]: "Assignar Usuarios a Equipos",
        [OnBoardingStepsTranslation.ONBOARDING_APPCC_CONFIG]: "Configuración Módulo APPCC",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_PLANS]: "Creación de Planes",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_CORRECTIVE_MEASURES]: "Creación de Medidas Correctivas",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_ASSETS]: "Alta de Activos",
        [OnBoardingStepsTranslation.ONBOARDING_BASETASK_CONFIG]: "Configuración Bolsa de Tareas",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_BASETASK]: "Creación Bolsa de Tareas Operativas",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_APPCC_BASETASK]: "Creación Bolsa de Registros APPCC",
        [OnBoardingStepsTranslation.ONBOARDING_TASK_CONFIG]: "Asignación de Tareas a cada Centro de Trabajo",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_QR]: "Creación de Códigos QRs",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_TASK]: "Creación de Tareas Operativas",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_APPCC_TASK]: "Creación de Registros APPCC",
        [OnBoardingStepsTranslation.ONBOARDING_AUDIT_CONFIG]: "Configuración de Auditorías",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_BASE_AUDIT_ITEMS]: "Creación Bolsa de Ítems de Auditoría Base",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_AUDIT_TEMPLATE]: "Creación de Plantillas de Auditoría",
        [OnBoardingStepsTranslation.ONBOARDING_TITLE]: "Onboarding",
        [OnBoardingStepsTranslation.ONBOARDING_UNLOCK_TITLE]: "Desbloquear el paso {0}",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_TITLE]: "Completar el paso {0}",
        [OnBoardingStepsTranslation.ONBOARDING_UNLOCK]: "Desbloquear",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE]: "Finalizar",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_STEP]: "Enviar",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_SUBSTEP]: "Completar",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_STEP]: "Aprobar",
        [OnBoardingStepsTranslation.ONBOARDING_UNLOCK_STEP]: "Desbl.",
        [OnBoardingStepsTranslation.ONBOARDING_UNLOCK_DESCRIPTION]:
            "El paso {0} se desbloqueará para que el administrador pueda seguir configurando 4Link",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_DESCRIPTION]:
            "El paso pasará a estar en revisión. Enviaremos un correo electrónico a nuestro revisor para que realice la evaluación correspondiente.",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_SUBSTEP_DESCRIPTION]:
            "¿Estás seguro de que deseas completar este subpaso? Al hacerlo, desbloquearás el siguiente y este paso se marcará como Completado.",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_CORRECT]: "Correcto",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_INCORRECT]: "Incorrecto",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW]: "Enviar Revisión",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_TITLE]: "Enviar Revisión de {0}",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_COMMENT]: "Comentario",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_OPTION]: "¿Qué te ha parecido este paso?",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_BLOCKED]:
            "El paso está bloqueado. Para desbloquearlo, el revisor debe marcar el paso anterior como válido.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_DONE]: "El paso ha sido finalizado.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_IN_PROGRESS]: "El paso está en progreso.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_REVIEW]:
            "El paso está en revisión. Le notificaremos por correo cuando la revisión esté completa y el estado del paso haya cambiado.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_BLOCKED]:
            "El subpaso está bloqueado. Para desbloquearlo, debe completar el subpaso anterior.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_DONE]: "El subpaso ha sido finalizado.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_IN_PROGRESS]: "El subpaso está en progreso.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_REVIEW]:
            "El subpaso está en revisión. Le notificaremos cuando la revisión esté completa.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_BLOCKED]: "Bloqueado",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_IN_PROGRESS]: "En Progreso",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_REVIEW]: "En Revisión",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_DONE]: "Finalizado",
        [OnBoardingStepsTranslation.ONBOARDING_FINISH_TITLE]: "Finalizar Onboarding",
        [OnBoardingStepsTranslation.ONBOARDING_FINISH_DESCRIPTION]:
            "El paso pasará a estar en revisión. Enviaremos un correo electrónico a nuestro revisor para que realice la evaluación correspondiente.",
        [OnBoardingStepsTranslation.ONBOARDING_SENDING_EMAIL_TITLE]: "Enviando Correo Electronico",
        [OnBoardingStepsTranslation.ONBOARDING_EMAIL_SENDED_TITLE]: "Correo Electronico Enviado Correctamente",
        [OnBoardingStepsTranslation.ONBOARDING_RESET]: "Reiniciar",
        [OnBoardingStepsTranslation.ONBOARDING_RESET_MODAL_TITLE]: "Reiniciar Onboarding",
        [OnBoardingStepsTranslation.ONBOARDING_RESET_MODAL_DESCRIPTION]:
            "El Onboarding se reiniciará y pasará al estado inicial. Esta acción es irreversible.",
        [OnBoardingStepsTranslation.ONBOARDING_RESET_TOAST_SUCCESS_TITLE]: "Onboarding Reiniciado",

        [TranslationErrors.ONBOARDING_SUBSTEP_INSTANCE_NOT_FOUND]: "No se ha encontrado el subpaso",
        [TranslationErrors.ONBOARDING_SUBSTEP_INSTANCE_INVALID_STATUS]: "El estado es inválido",
        [TranslationErrors.ONBOARDING_STEP_INSTANCE_NOT_FOUND]: "No se ha encontrado el paso",
        [TranslationErrors.ONBOARDING_STEP_INSTANCE_INVALID_STATUS]: "El estado es inválido",
        [TranslationErrors.ONBOARDING_STEP_INSTANCE_COMMENT_CANNOT_BE_EMPTY]: "El comentario es obligatorio",
        [TranslationErrors.ONBOARDING_SUBSTEPS_NOT_COMPLETED]:
            "Se han de completar todos los subpasos para completar el paso",
        [TranslationErrors.ONBOARDING_FAILED_FETCH]: "No se han podido conseguir los pasos",
        [TranslationErrors.ONBOARDING_FAILED_COMPLETE]: "No se ha podido completar",
        [TranslationErrors.ONBOARDING_FAILED_REVIEW]: "No se ha podido revisar",
        [TranslationErrors.ONBOARDING_FAILED_SEND]: "Error al Enviar",
        [TranslationErrors.ONBOARDING_FAILED_IN_PROGRESS]: "Error al Desbloquear",

        [TranslationErrors.SENSOR_NOT_EXIST]: "El sensor no existe",

        /* --------------------OnBoarding Steps END-------------------- */

        /* -------------------- AUTOMATIC RECORDS START-------------------- */
        [AutomaticRecordTranslations.AUTOMATIC_RECORD_ASSET_LABEL]: "Activo",
        [AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_LABEL]: "Código Sensor",
        [AutomaticRecordTranslations.GATEWAY_SERIAL_NUMBER_SMALL_LABEL]: "Nº Gateway",
        [AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_SMALL_LABEL]: "Nº Sensor",
        [AutomaticRecordTranslations.TEMP_LABEL]: "Temperatura",
        [AutomaticRecordTranslations.BATTERY_LEVEL_LABEL]: "Batería",
        [AutomaticRecordTranslations.SIGNAL_LEVEL_LABEL]: "Señal",
        [AutomaticRecordTranslations.DATE_TIME_ENTRY_LABEL]: "Fecha",
        [AutomaticRecordTranslations.BATTERY_LEVEL_LOW_LABEL]: "Baja",
        [AutomaticRecordTranslations.BATTERY_LEVEL_MEDIUM_LABEL]: "Media",
        [AutomaticRecordTranslations.BATTERY_LEVEL_HIGH_LABEL]: "Alta",
        [AutomaticRecordTranslations.SIGNAL_LEVEL_LOW_LABEL]: "Baja",
        [AutomaticRecordTranslations.SIGNAL_LEVEL_MEDIUM_LABEL]: "Media",
        [AutomaticRecordTranslations.SIGNAL_LEVEL_HIGH_LABEL]: "Alta",
        [AutomaticRecordTranslations.REGISTRY_NUMBER]: "Registros",
        /* -------------------- AUTOMATIC RECORDS END-------------------- */

        /* -------------------- AUDIT FILTER START-------------------- */
        [AuditFilterTranslations.AUDIT_FILTER_START_DATE]: "Fecha de Apertura",
        [AuditFilterTranslations.AUDIT_FILTER_CLOSED_DATE]: "Fecha de Cierre",
        /* -------------------- AUDIT FILTER END-------------------- */

        /* -------------------- CHAT BOT GPT START-------------------- */
        [ChatBotGPTTranslations.CHAT_BOT_GTP_LOADING_MESSAGE]: "Cargando...",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_ERROR_MESSAGE]: "Algo ha salido mal",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_INPUT_PLACEHOLDER]: "Escribe tu mensaje aquí",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_IA_PRESENTATION_MESSAGE]:
            "Hola, soy LaIA, tu asistente personal. ¿En qué puedo ayudarte?",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_USER_OPTION]: "Usuarios",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_TASK_OPTION]: "Tareas",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_APPCC_OPTION]: "Planes APPCC",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_WORKER_TEXT]: "Ten en cuenta que soy un usuario empelado",
        /* -------------------- CHAT BOT GPT END-------------------- */

        /* -------------------- COMPANY START-------------------- */
        [CompanyTranslations.EDIT_COMPANY_DETAIL_TITLE]: "Datos del Centro",
        [CompanyTranslations.EDIT_COMPANY_OPTIONS_TITLE]: "Cerrar Centro",
        [CompanyTranslations.COMPANY_STATUS]: "Estado",
        [TranslationErrors.MAX_EMAIL_LENGTH_REACHED]: "Hay demasiados emails de contacto.",
        [TranslationErrors.INVALID_EMAIL]: "Hay algún email con un formato incorrecto.",
        /* -------------------- COMPANY END-------------------- */

        /* -------------------- ROLE COLLAPSABLE START-------------------- */
        [RoleCollapsableTranslations.ROLE_COLLAPSABLE_MARK_ALL]: "Seleccionar todo",
        /* -------------------- ROLE COLLAPSABLE END-------------------- */

        /* -------------------- SENSOR MODAL START-------------------- */
        [SensorModalTranslations.SENSOR_NOT_LINKED_TITLE]: "Nuevo Sensor ({0})",
        [SensorModalTranslations.SENSOR_NOT_LINKED_PLACEHOLDER_TITLE]: "Sensor no encontrado",
        [SensorModalTranslations.SENSOR_NOT_LINKED_DESCRIPTION]:
            "El código del sensor no esta registrado en el sistema",
        [SensorModalTranslations.SENSOR_NOT_LINKED_CREATE_ASSET]: "Dar de Alta",
        [SensorModalTranslations.SENSOR_NOT_LINKED_REPLACE]: "Substituir",
        [SensorModalTranslations.SENSOR_TOAST_LOADING]: "Recuperando el Sensor",
        [SensorModalTranslations.SENSOR_LINKED_TITLE]: "Sensor ({0})",
        [SensorModalTranslations.SENSOR_LINKED_REPLACE]: "Substituir",
        [SensorModalTranslations.SENSOR_LINKED_DELETE_ASSET]: "Desactivar",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_NAME]: "Nombre",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_DESCRIPTION]: "Descripción",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_COMPANY]: "Empresa",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_TYPE]: "Tipo de Activo",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_PLAN]: "Plan APPCC",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_NOT_WORKING]: "¿Activo Averiado?",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_TITLE]: "Intercambio de Sensores",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_TITLE]: "Sensor Nuevo",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_DESCRIPTION]:
            "Ambos códigos escaneados estan vinculados a un activo. Los sensores se van a intercambiar entre sí. ¿Estas seguro?",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_DESCRIPTION]:
            "El segundo sensor ({0}) se vinculará al Activo del primer sensor. El primer sensor quedará desvinculado. ¿Estas seguro?",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON]: "Intercambiar",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_CONFIRM_BUTTON]: "Vincular",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_SUCCESS_REPLACE_TITLE]: "Intercambiados",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_SUCCESS_REPLACE_TITLE]: "Intercambiado",
        [SensorModalTranslations.SENSOR_SAME_QR_ERROR_TITLE]: "Los sensores tiene que ser distintos",
        [SensorModalTranslations.SENSOR_QR_SCANNER_TITLE]: "Escanea el Sensor o introdúcelo manualmente",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_TITLE]: "Ambos Codigos sin Activo",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_DESCRIPTION]:
            "Para intercambiar sensores, por lo menos uno debe estar vinculado a un Activo.",
        [SensorModalTranslations.SENSOR_NOT_LINKED_QR_SCANNER_TITLE]: "Escanear Nuevo Sensor",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_LINKED_TITLE]: "Sensor Nuevo",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_LINKED_DESCRIPTION]:
            "El primer sensor se vinculará al Activo del segundo sensor ({0}). El segundo sensor quedará desvinculado. ¿Estas seguro?",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON]: "Intercambiar",
        /* -------------------- SENSOR MODAL END-------------------- */

        /* -------------------- EDIT IMAGE START-------------------- */
        [AriaLabels.AL_EDIT_IMAGE_RECTANGLE]: "Forma - Rectángulo",
        [AriaLabels.AL_EDIT_IMAGE_CIRCLE]: "Forma - Círculo",
        [AriaLabels.AL_EDIT_IMAGE_UNDO]: "Acción - Borrar todo",
        [AriaLabels.AL_EDIT_IMAGE_MIRROR]: "Acción - Modo Espejo",
        [AriaLabels.AL_EDIT_IMAGE_ROTATE]: "Acción - Rotar",
        [AriaLabels.AL_EDIT_IMAGE_CROPPER]: "Acción - Empezar ha Recortar",
        [AriaLabels.AL_EDIT_IMAGE_FORMS]: "Acción - Dibujar Formas",
        [AriaLabels.AL_EDIT_IMAGE_DRAW]: "Acción - Dibujar Garabatos",
        [AriaLabels.AL_EDIT_IMAGE_CROP]: "Acción - Recortar",
        [AriaLabels.AL_EDIT_IMAGE_CANCEL_CROP]: "Acción - No Recortar",
        /* -------------------- EDIT IMAGE END-------------------- */

        /* -------------------- AVATAR START-------------------- */
        [AriaLabels.AL_AVATAR_PROFILE_PICTURE]: "Foto de perfil del usuario",
        /* -------------------- AVATAR END-------------------- */

        /* -------------------- ICON SELECT START-------------------- */
        [AriaLabels.AL_CHEVRON_UP]: "Abrir Selector",
        [AriaLabels.AL_CHEVRON_DOWN]: "Cerrar Selector",
        /* -------------------- ICON SELECT END-------------------- */

        /* -------------------- EMAIL DISTRIBUTION LIST START-------------------- */
        [EmailDistributionListTranslations.DISTRIBUTION_LIST_SPORADIC_TASK]: "Resumen Semanal de Tareas Esporádicas",
        [EmailDistributionListTranslations.DISTRIBUTION_LIST_AUDIT_REPORT]: "Copia de Informes de Auditoría",
        [EmailDistributionListTranslations.DISTRIBUTION_LIST_PATCH_NOTES]: "Notas del Parche",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_FUNCTIONALITY_LABEL]: "Funcionalidad",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_COMPANY_LABEL]:
            "¿De que centro de trabajo se quieren enviar las notificaciones?",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_COMPANY_GRID_LABEL]: "Centro de Trabajo",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_USER_COUNT_LABEL]: "Nº Usuarios",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_EDIT_USERS_MODAL_TITLE]:
            "Editar Usuarios de la Lista de Distribución",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_CREATE_USERS_MODAL_TITLE]:
            "Añadir Usuarios para Recibir la Notificación",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_SAVE_SUCCESS]:
            "La Lista de Distribución se ha Guardado Correctamente",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_EDIT_SUCCESS]:
            "Los Usuarios de la Lista de Distribución se han Editado Correctamente",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_TYPE_LABEL]: "Selecciona la Lista de Distribución",
        [TranslationErrors.EMAIL_DISTRIBUTION_LIST_NOT_FOUND]: "Lista de Distribución no Encontrada",
        [TranslationErrors.EMAIL_DISTRIBUTION_LIST_ALREADY_ADDED]: "Lista de Distribución ya Creada",
        [TranslationErrors.MAX_USERIDLIST_EXCEDED]:
            "Se ha superado el límite de 20 Usuarios en la Lista de Distribución",
        [TranslationErrors.EMAIL_DISTRIBUTION_LIST_FULL_ERROR]:
            "Ya se han añadido todas las Lista de Distribución posibles para este Centro de Trabajo",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_DELETE_TITLE_LABEL]:
            "Eliminar Lista de Distribución",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_DELETE_DESCRIPTION_LABEL]:
            "Vas a Eliminar esta Lista de Distribución, ¿estás seguro?",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_SUCCESS_DELETE]:
            "La Lista de Distribución se ha Eliminado Correctamente",
        /* -------------------- EMAIL DISTRIBUTION LIST END-------------------- */

        /* -------------------- TASK PILL START-------------------- */
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES]: "{0} min tarde",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR]: "{0} h {1} min tarde",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_EARLY]: "{1} min antes",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_EARLY]: "{0} h {1} min antes",
        /* -------------------- TASK PILL END-------------------- */

        /* -------------------- TRANSCRIPTION START-------------------- */
        [TranscriptionTranslations.BROWSER_NOT_COMPATIBLE]: "El navegador no es compatible.",
        [TranscriptionTranslations.ERROR_STARTING_TRANSCRIPTING]: "No se ha podido empezar a grabar tu voz",
        /* -------------------- TRANSCRIPTION END-------------------- */

        /* -------------------- PREVIEW CHECKLIST MODAL START-------------------- */
        [TranslationModals.PREVIEW_CHECKLIST_TITLE]: "Previsualización del Checklist",
        /* -------------------- PREVIEW CHECKLIST MODAL END-------------------- */

        /* -------------------- TASK HISTORY START-------------------- */
        [TaskHistoryTranslations.TASK_HISTORY_LIST]: "Lista",
        [TaskHistoryTranslations.TASK_HISTORY_TABLE]: "Tabla",
        /* -------------------- TASK HISTORY END-------------------- */

        /* -------------------- STAR PERCENTAGE START-------------------- */
        [AriaLabels.AL_STAR_PERCENTAGE_OVERLAY]: "Overlay",
        /* -------------------- STAR PERCENTAGE END-------------------- */

        /* -------------------- STAR VALORATION START-------------------- */
        [IconTitleTranslations.IT_STAR]: "Star {0}",
        /* -------------------- STAR VALORATION END-------------------- */

        /* -------------------- QR LABEL START-------------------- */
        [AlertModalTranslations.QR_LABEL]: "Qr",
        [AlertModalTranslations.SELECT_HOUR_QR]: "Selecciona una Fecha",
        /* -------------------- QR LABEL END-------------------- */

        /* -------------------- DRAG FILE START-------------------- */
        [DragFileTranslations.INVALID_FILE_TYPE]: "Formato de Archivo Inválido",
        /* -------------------- DRAG FILE END-------------------- */

        /* -------------------- PATCH NOTES START-------------------- */
        [PatchNotesTranslations.PATCH_NOTES_VERSION_LABEL]: "Nº de la Versión",
        [PatchNotesTranslations.PATCH_NOTES_TITLE_LABEL]: "Título Resumen",
        [PatchNotesTranslations.PATCH_NOTES_PUBLISH_DATE_LABEL]: "Fecha de Publicación",
        [PatchNotesTranslations.PATCH_NOTES_CUSTOMERS_COUNT_LABEL]: "Clientes",
        [PatchNotesTranslations.PATCH_NOTE_DELETE_TITLE_LABEL]: "Borrar las Notas del Parche de la Versión {0}",
        [PatchNotesTranslations.PATCH_NOTE_DELETE_DESCRIPTION_LABEL]: "Esta acción es irreversible, ¿estás seguro?",
        [PatchNotesTranslations.PATCH_NOTES_CREATE_MODAL_TITLE]: "Nueva Versión de las Notas del Parche",
        [PatchNotesTranslations.PATCH_NOTE_CREATED_SUCCESSFULLY]: "Versión de Notas del Parche Creada Correctamente",
        [PatchNotesTranslations.PATCH_NOTES_EDIT_MODAL_TITLE]: "Editar Versión de las Notas del Parche",
        [PatchNotesTranslations.PATCH_NOTE_EDITED_SUCCESSFULLY]: "Versión de Notas del Parche Editada Correctamente",
        [PatchNotesTranslations.PATCH_NOTE_CUSTOMER_INSTANCES_LABEL]: "Clientes a los que Notificar",
        [PatchNotesTranslations.PATCH_NOTES_NOTE_LABEL]: "Lista de Mejoras y Nuevas Funcionalidades",
        [PatchNotesTranslations.PATCH_NOTE_CANCEL_CHANGES_TITLE_LABEL]: "Cambios Detectados",
        [PatchNotesTranslations.PATCH_NOTE_CANCEL_CHANGES_DESCRIPTION_LABEL]:
            "Estás a punto de descartar los cambios que has hecho, ¿estás seguro?",
        [PatchNotesTranslations.PATCH_NOTES_SEND_SUCCESSFULLY]: "Notas del Parche Enviadas Correctamente",
        [TranslationErrors.PATCH_NOTE_VERSION_DESCRIPTION_NOT_FOUND]: "Notas del Parche no encontradas",
        [PatchNotesTranslations.PATCH_NOTE_SEND_TITLE_LABEL]: "Enviar Notas del Parche",
        [PatchNotesTranslations.PATCH_NOTE_SEND_DESCRIPTION_LABEL]:
            "Estás a punto de enviar las Notas del Parche a los Clientes que se muestran abajo, ¿estás seguro?",
        [PatchNotesTranslations.PATCH_NOTES_ADD_NOTE_LABEL]: "Añadir Mejora/Nueva Funcionalidad",
        /* -------------------- PATCH NOTES END-------------------- */

        /* -------------------- WORKING POSITION START-------------------- */
        [WorkingPositionTranslations.WORKING_POSITION_TASK_COUNT]: "Nº Tareas Asociadas",
        [WorkingPositionTranslations.WORKING_POSITION_APPCC_COUNT]: "Nº Registros Asociados",
        /* -------------------- WORKING POSITION END-------------------- */

        /* -------------------- DEPARTMENT START-------------------- */
        [DepartmentTranslations.DEPARTMENT_DELETE_MODAL_TITLE]: "El equipo {0} se va a eliminar",
        [DepartmentTranslations.CLOSE_DEPARTMENT]: "Cerrar Departamento",
        [DepartmentTranslations.CLOSED_DEPARTMENT_SUCCESSFULLY]: "Se ha Cerrado el Departamento Correctamente.",
        [DepartmentTranslations.CLOSE_DEPARTMENT_DATES]: "Fecha/s de Cierre",
        [DepartmentTranslations.DEPARTMENT_STATUS]: "Estado",
        [DepartmentTranslations.DEPARTMENTS_CLOSED_LABEL]: "Departamentos",
        [DepartmentTranslations.DEPARTMENTS_OPEN_LABEL]: "Departamentos",
        [DepartmentTranslations.CLOSED_DEPARTMENT_OPEN_TITLE]: "Abrir Departamento",
        [DepartmentTranslations.CLOSED_DEPARTMENT_CLOSED_TITLE]: "Cerrar Departamento",
        [DepartmentTranslations.CLOSED_DEPARTMENT_OPEN_DESCRIPTION]: "Se va a abrir el Departamento, ¿estás seguro?",
        [DepartmentTranslations.CLOSED_DEPARTMENT_CLOSED_DESCRIPTION]: "Se va a cerrar el Departamento, ¿estás seguro?",
        [DepartmentTranslations.OPEN_DEPARTMENT_SUCCESSFULLY]: "Se ha Abierto el Departamento Correctamente.",
        [DepartmentTranslations.CLOSED_DEPARTMENT_ERROR_NO_CLOSE_DATE]: "Indica al menos una fecha de cierre.",
        /* -------------------- DEPARTMENT END-------------------- */

        /* -------------------- NOTIFICATION START-------------------- */
        [NotificationsTranslations.NOTIFICATION_WHICH_COMPANY_LABEL]: "Filtro de Centros de Trabajo",
        [NotificationsTranslations.NOTIFICATION_ASSIGN_USER]: "¿A qué Usuarios quieres notificar?",
        [NotificationsTranslations.NOTIFICATION_ASSIGN_DEPARTMENT]: "¿A qué Equipos quieres notificar?",
        [NotificationsTranslations.NOTIFICATION_ASSIGN_COMPANY]: "¿A qué Centros de Trabajo quieres notificar?",
        [NotificationsTranslations.NOTIFICATION_ASSIGN_QR]: "¿A qué QRs quieres notificar?",
        /* -------------------- NOTIFICATION END-------------------- */
    },
};
