import { MAX_INITIALS_LENGTH, RegexPatterns } from "app/shared/Constants";
import { TranslationCommon, TranslationErrors } from "app/translation/translationKeys";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { WizardLicenseStepOneModel } from "../../types";
import { InstanceService } from "app/services";

type IuseStepOne = WizardLicenseStepOneModel & {
    next: () => void;
};
export const useStepOne = ({ contactEmail, contactName, instanceName, phoneNumber, initials, next }: IuseStepOne) => {
    const { t } = useTranslation();
    const [validations, setValidations] = useState({
        errorContactEmail: "",
        errorContactName: "",
        errorInstanceName: "",
        errorPhoneNumber: "",
        errorInitials: "",
    });

    const areInitialsUsedFetch = async (initials: string) => {
        const { data } = await InstanceService.AreInitialsUsed(initials);
        return data;
    };

    const validate = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;
        let aux = { ...validations };

        aux.errorInstanceName = "";
        if (!instanceName.length) {
            aux.errorInstanceName = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        aux.errorContactName = "";
        if (!contactName.length) {
            aux.errorContactName = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        aux.errorContactEmail = "";
        if (!new RegExp(RegexPatterns.mail).test(contactEmail)) {
            aux.errorContactEmail = t(TranslationErrors.RESTABLISH_PASSWORD_EMAIL_ERROR_MESSAGE);
            check = false;
        }

        aux.errorPhoneNumber = "";
        if (!new RegExp(RegexPatterns.phoneNumber).test(phoneNumber)) {
            aux.errorPhoneNumber = t(TranslationErrors.INVALID_PHONE_NUMBER);
            check = false;
        }

        aux.errorInitials = "";
        if (!initials.length) {
            aux.errorInitials = t(TranslationErrors.INITIALS_CANNOT_BE_EMPTY);
            check = false;
        } else if (initials.length !== MAX_INITIALS_LENGTH) {
            aux.errorInitials = t(TranslationErrors.INITIALS_MAX_LENGTH);
            check = false;
        } else if (!RegexPatterns.onlyTwoAlphabeticalCharacters.test(initials)) {
            aux.errorInitials = t(TranslationErrors.INITIALS_INVALID_FORMAT);
            check = false;
        } else if (await areInitialsUsedFetch(initials)) {
            aux.errorInitials = t(TranslationErrors.INITIALS_ALREADY_USED);
            check = false;
        }

        if (check) next();
        else setValidations({ ...aux });
    };

    return { validate, errorMessages: validations };
};
