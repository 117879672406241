import { FC, useEffect } from "react";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { StepThreeProps } from "./models";
import { useStepThree } from "./hooks";
import { useTranslation } from "react-i18next";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { FieldValues, SelectDataToReport } from "app/components_v2/WizardTask/SelectDataToReport/SelectDataToReport";
import { v4 } from "uuid";
import { Switch } from "app/components_v2/__inputs/Switch/Switch";
import { OptionsSearchT } from "app/models/FormComponentsModel";
import { useDynamicFields } from "app/hooks/useDynamicFields";
import { DynamicFieldsModel, DynamicFieldsType, reportType } from "app/models/02-TAR/TaskWizard";
import { useGetDynamicFieldsTranslations } from "app/hooks/dynamicFields/useGetDynamicFieldsTranslations";
import { isDropdownDynamicField } from "app/helpers/dynamicFields/isDropdownDynamicField";
import { useGetAssets } from "app/hooks/Asset/useGetAssets";
import { SwitchDragFile } from "app/components_v2/SwitchDragFile/SwitchDragFile";
import { DataToReportReadOnly } from "../../components/DataToReportReadOnly/DataToReportReadOnly";
import { Label } from "app/components_v2/__inputs/Label";
import { compressImage } from "app/helpers/images/compressImage";
import { Divider } from "app/components_v2/Divider/Divider";

export const StepThree: FC<StepThreeProps> = ({
    somethingToReportCheckBox,
    somethingToReportImage,
    criticalTask,
    onChange,
    next,
    somethingToReportCheckBoxImage,
    reportData,
    reportType,
    companyForTask,
    checkList,
    dynamicFields,
    disabledFields,
    allowAnyData,
    imageBase64,
    fK_PlanAPPCC,
}) => {
    const { t } = useTranslation();
    const { getDynamicFieldTranslation } = useGetDynamicFieldsTranslations();

    const { validate, validations } = useStepThree(
        somethingToReportCheckBox,
        somethingToReportCheckBoxImage,
        reportData,
        onChange,
        next,
        reportType,
        checkList,
        dynamicFields
    );

    const {
        assets,
        error: isAssetErrored,
        isLoading: isAssetsLoading,
        getAssets,
    } = useGetAssets(Number(companyForTask.value), fK_PlanAPPCC);

    const { dynamicFieldsOptions, getDynamicFields, isLoadingDynamicFields } = useDynamicFields();
    const { disabledDataToReport } = disabledFields;

    const onChangeImg = (img: string) => {
        if (!img) return;
        onChange({
            imageBase64: img,
        });
    };

    const fillOptions = (): OptionsSearchT<typeof reportType>[] => {
        const options: OptionsSearchT<reportType>[] = [
            {
                label: t(TranslationKeys.DATA_TO_REPORT_NO),
                value: "NONE",
            },
        ];

        if (allowAnyData)
            options.push(
                {
                    label: t(TranslationKeys.CHECKLIST),
                    value: "CHECKLIST",
                },
                {
                    label: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_FIELDS),
                    value: "DYNAMIC_FIELD",
                }
            );

        if (!!fK_PlanAPPCC)
            options.push({
                label: t(TranslationKeys.DATA_TO_REPORT_ASSET),
                value: "ASSET",
            });

        return options;
    };

    const handleChange = (inputValues: Partial<FieldValues>) => {
        if (inputValues.fieldValue === null) {
            resetReportFields(inputValues);
            return;
        }

        if (inputValues.selectableValue === "NONE") {
            handleSelectNone();
            return;
        }

        if (inputValues.selectableValue === "DATA") {
            handleSelectData(inputValues);
            return;
        }

        if (inputValues.selectableValue === "ASSET") {
            handleSelectAsset(inputValues);
            return;
        }

        if (inputValues.selectableValue === "CHECKLIST") {
            handleSelectCheckList(inputValues);
            return;
        }

        if (inputValues.selectableValue === "DYNAMIC_FIELD" && inputValues.dynamicFieldsType) {
            handleSelectDynamicField(inputValues.dynamicFieldsType);
        }
    };

    const resetReportFields = ({ selectableValue }: Partial<FieldValues>) => {
        onChange({
            reportType: selectableValue,
            reportData:
                selectableValue === "DATA"
                    ? [
                          {
                              id: v4(),
                              isDeleted: false,
                              value: "",
                          },
                      ]
                    : [],
            checkList: selectableValue === "CHECKLIST" ? [{ id: v4(), isDeleted: false, name: "" }] : [],
            dynamicFields: [],
        });
    };

    const handleSelectNone = () => {
        onChange({
            reportType: "NONE",
            reportData: [],
        });
    };

    const handleSelectData = ({ fieldValue }: Partial<FieldValues>) => {
        onChange({
            reportType: "DATA",
            reportData: reportData.map((item) => {
                return item.id === fieldValue?.id ? { ...item, value: fieldValue.value } : { ...item };
            }),
        });
    };

    const handleDeleteAsset = (assetId: number) => {
        onChange({ reportData: reportData.filter((asset) => assetId !== asset.assetId) });
    };

    const handleSelectAsset = ({ fieldValue }: Partial<FieldValues>) => {
        if (!fieldValue) return;
        const assetFiltered = assets.find(({ id }) => id === Number(fieldValue.value));
        if (!assetFiltered) return;
        const { id: assetId, name, assetFields } = assetFiltered;
        onChange({
            reportData: [
                ...reportData,
                {
                    id: v4(),
                    isDeleted: false,
                    value: name,
                    assetId: Number(assetId),
                    assetsFields: assetFields.map(({ assetDynamicField, label, assetFieldRange, id }) => ({
                        id: Number(id),
                        name: label,
                        assetDynamicField: assetDynamicField,
                        assetFieldRangeId: assetFieldRange?.id,
                        isDeleted: false,
                        max: assetFieldRange?.max,
                        min: assetFieldRange?.min,
                        unit: assetFieldRange?.unit,
                    })),
                },
            ],
        });
    };

    const handleSelectCheckList = ({ fieldValue }: Partial<FieldValues>) => {
        if (!fieldValue?.id) return;
        onChange({
            checkList: checkList.map((value) =>
                value.id === fieldValue.id ? { ...value, name: fieldValue.value } : value
            ),
        });
    };

    const handleAddNewChecklist = () => {
        if (checkList[checkList.length - 1].name === "") return;
        if (checkList.length >= 50) return;
        onChange({ checkList: [...checkList, { id: v4(), isDeleted: false, name: "" }] });
    };

    const handleDeleteChecklist = (checklistId: string) => {
        if (checkList.length <= 1) return;
        onChange({ checkList: checkList.filter(({ id }) => id !== checklistId) });
    };

    const handleBlurCheckList = () => {
        if (checkList.length <= 1) return;
        return onChange({
            checkList: checkList.filter(({ name }) => name !== ""),
        });
    };

    const handleDynamicFieldChange = (
        id: string,
        field: DynamicFieldsModel,
        fieldToUpdate: Partial<DynamicFieldsModel>
    ): DynamicFieldsModel => {
        return field.id === id ? { ...field, ...fieldToUpdate } : field;
    };

    const handleSelectDynamicField = (dynamicFieldsType: DynamicFieldsType) => {
        if (dynamicFields.length >= 20) return;
        const newDynamicField = {
            dynamicFieldsType,
            id: v4(),
            isRequired: true,
            taskFieldOptions: isDropdownDynamicField(dynamicFieldsType) ? [{ id: v4(), label: "" }] : [],
            label: "",
            isDeleted: false,
        };
        onChange({
            dynamicFields: [...dynamicFields, newDynamicField],
        });
    };

    const handleDynamicFieldInputChange = (value: string, id: string) => {
        onChange({
            dynamicFields: [...dynamicFields.map((field) => handleDynamicFieldChange(id, field, { label: value }))],
        });
    };

    const handleDynamicFieldCheckboxChange = (value: boolean, id: string) => {
        onChange({
            dynamicFields: [
                ...dynamicFields.map((field) => handleDynamicFieldChange(id, field, { isRequired: value })),
            ],
        });
    };

    const handleChangeDynamicDropDownField = (id: string, itemId: string, value: string) => {
        const selectedDynamicField = dynamicFields.find((field) => field.id === id);
        if (!selectedDynamicField) return;
        const dynamicFieldsUpdated = dynamicFields.map((field) =>
            handleDynamicFieldChange(id, field, {
                taskFieldOptions: [
                    ...field.taskFieldOptions.map((taskfield) => {
                        if (taskfield.id !== itemId) return taskfield;
                        return {
                            ...taskfield,
                            label: value,
                        };
                    }),
                ],
            })
        );

        onChange({ dynamicFields: dynamicFieldsUpdated });
    };

    const handleDeleteDynamicField = (id: string) => {
        const selectedDynamicField = dynamicFields.find((field) => field.id === id);
        if (!selectedDynamicField) return;

        onChange({
            dynamicFields: dynamicFields.filter((field) => field.id !== id),
        });
    };

    const handleAddNewDynamicDropDownField = (id: string) => {
        const selectedDynamicField = dynamicFields.find((field) => field.id === id);
        if (!selectedDynamicField) return;
        if (selectedDynamicField.taskFieldOptions.length >= 10) return;
        onChange({
            dynamicFields: [
                ...dynamicFields.map((field) =>
                    handleDynamicFieldChange(id, field, {
                        taskFieldOptions: [...field.taskFieldOptions, { id: v4(), label: "" }],
                    })
                ),
            ],
        });
    };

    const handleDeleteDynamicDropDownField = (id: string, itemId: string) => {
        const selectedDynamicField = dynamicFields.find((field) => field.id === id);
        if (!selectedDynamicField) return;
        if (selectedDynamicField.taskFieldOptions.length < 2) return;
        const dynamicFieldsDeleted = dynamicFields.map((field) =>
            handleDynamicFieldChange(id, field, {
                taskFieldOptions: [...field.taskFieldOptions.filter((taskfield) => taskfield.id !== itemId)],
            })
        );
        onChange({ dynamicFields: dynamicFieldsDeleted });
    };

    const handleChangePhoto = async (e: any) => {
        const photo = await compressImage({ file: e.target.files[0], quality: 0.6 });
        onChange({
            somethingToReportImage: photo as File,
        });
    };

    useEffect(() => {
        if (reportType === "ASSET") getAssets();
        if (reportType === "DYNAMIC_FIELD") getDynamicFields();
    }, [reportType]);

    return (
        <WizardForm validate={validate}>
            <SwitchDragFile
                image={imageBase64 || somethingToReportImage}
                isChecked={somethingToReportCheckBoxImage}
                onDeleteImage={(checked) => {
                    onChange({
                        imageBase64: null,
                        somethingToReportCheckBoxImage: disabledFields?.disabledIsPhotoRequired ? true : checked,
                        somethingToReportImage: "",
                    });
                }}
                handleChangePhoto={handleChangePhoto}
                onChangePhoto={onChangeImg}
                disabledCheckbox={disabledFields?.disabledIsPhotoRequired}
                disabledImage={disabledFields?.disabledFotoExample}
                isImageEditable
            />
            <div className="step-three-file-wrapper__switchGroup">
                <Switch
                    checked={criticalTask}
                    onChange={(checked) => {
                        onChange({
                            criticalTask: checked,
                        });
                    }}
                />
                <Label label={t(TranslationKeys.CRITICAL_TASK)} />
            </div>
            <Divider />
            {!allowAnyData && reportType === "NONE" ? null : !allowAnyData &&
              (reportType === "DYNAMIC_FIELD" || reportType === "CHECKLIST") ? (
                <div className="wizardTaskStepThree__readOnly">
                    <DataToReportReadOnly
                        dynamicfields={dynamicFields.filter((x) => !x.isDeleted)}
                        checkList={checkList.filter((x) => !x.isDeleted)}
                        reportType={reportType}
                    />
                </div>
            ) : (
                <div className="wizardTaskStepThree__readOnly">
                    {!allowAnyData && reportType === "ASSET" && <Label label={t(TranslationKeys.ASSETS)} />}
                    <SelectDataToReport
                        options={fillOptions()}
                        value={reportType}
                        reportData={reportData}
                        onChange={handleChange}
                        assets={assets}
                        isAssetErrored={!!isAssetErrored}
                        isAssetsLoading={isAssetsLoading}
                        onDeleteAsset={handleDeleteAsset}
                        customError={validations.reportData}
                        checkList={checkList}
                        onAddNewChecklist={handleAddNewChecklist}
                        onDeleteCheckList={handleDeleteChecklist}
                        onBlurCheckList={handleBlurCheckList}
                        dynamicFields={dynamicFields}
                        dynamicFieldsOptions={dynamicFieldsOptions.map(({ label, value }) => ({
                            label: getDynamicFieldTranslation(label as DynamicFieldsType),
                            value,
                        }))}
                        isLoadingDynamicFields={isLoadingDynamicFields}
                        onDynamicFieldInputChange={handleDynamicFieldInputChange}
                        onDynamicFieldCheckboxChange={handleDynamicFieldCheckboxChange}
                        onDeleteDynamicField={handleDeleteDynamicField}
                        onDeleteDynamicDropDownField={handleDeleteDynamicDropDownField}
                        onChangeDynamicDropDownField={handleChangeDynamicDropDownField}
                        onAddNewDynamicDropDownField={handleAddNewDynamicDropDownField}
                        dynamicFieldsError={validations.dynamicFields}
                        checklistError={validations.checklist}
                        variant="modal"
                        disabledChecklist={disabledDataToReport}
                        disabledDynamicFields={disabledDataToReport}
                        hideSelect={!allowAnyData && reportType === "ASSET"}
                    />
                </div>
            )}
        </WizardForm>
    );
};
