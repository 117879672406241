import { checkAddedDeletedBetweenTwoArraysOfNumbers } from "app/helpers/checkAddedDeletedBetweenTwoArraysOfNumbers";
import { ComponentStatus } from "app/models/FormComponentsModel";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import { DepartmentService } from "app/services";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import {
    DepartmentTranslations,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { convertUTCtoLocaleDate } from "app/helpers";

type IUseDepartmentGrid = {
    companyId: number;
};

type IFetch = {
    pi?: number;
};

type IAddUsers = {
    usersId: number[];
    name: string;
    allUsers?: any;
};

export const useDepartmentGrid = ({ companyId }: IUseDepartmentGrid) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();

    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [departments, setDepartments] = useState<DepartmentModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [query, setQuery] = useState<string>("");
    const [total, setTotal] = useState<number>(0);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(!!query.length);

    const [showAddDepartmentModal, setShowAddDepartmentModal] = useState<boolean>(false);
    const [isLoadingAddNewDept, setisLoadingAddNewDept] = useState<boolean>(false);

    const [selectedDepartment, setSelectedDepartment] = useState<DepartmentModel | null>(null);

    const [showDeleteDepartmentModal, setShowDeleteDepartmentModal] = useState<boolean>(false);
    const [isLoadingDeleteDepartment, setIsLoadingDeleteDepartment] = useState<boolean>(false);

    const [showAddUsersToDepModal, setShowAddUsersToDepModal] = useState<boolean>(false);
    const [isLoadingAddUsersToDepModal, setIsLoadingAddUsersToDepModal] = useState<boolean>(false);
    const [selectedUsersInDepartment, setSelectedUsersInDepartment] = useState<OptionModel[]>([]);
    const [initialsSelectedUsersInDepartment, setInitialsSelectedUsersInDepartment] = useState<OptionModel[]>([]);

    const [usersAdded, setUsersAdded] = useState<OptionModel[]>([]);
    const [usersDeleted, setUsersDeleted] = useState<OptionModel[]>([]);
    const [isConfirmAddUsersModalVisible, setIsConfirmAddUsersModalVisible] = useState<boolean>(false);
    const [allUsers, setAllUsers] = useState<IAddUsers | null>(null);

    const [showCloseDepartmentModal, setShowCloseDepartmentModal] = useState<boolean>(false);
    const [isLoadingCloseDepartment, setIsLoadingCloseDepartment] = useState<boolean>(false);

    const getData = async ({ pi }: IFetch = {}) => {
        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;

        setStatus("loading");
        const sr = await DepartmentService.GetData({
            extraParams: `companyId=${companyId}`,
            pageIndex: pageIndexFetch,
            query,
        });
        if (!sr.status()) {
            handleToast({
                title: t(getErrorMessage(sr.getParsedError())),
                type: "alert",
                variant: "danger",
            });
            setStatus("complete");
            setTotal(0);
            setDepartments([]);
            return;
        }

        setDepartments(sr.data.list);
        setTotal(sr.data.count);
        setStatus("complete");
    };

    const onChangePageIndex = (pi: number) => {
        setPageIndex(pi);
        getData({ pi: pi - 1 });
    };

    const onSearch = () => {
        setShowSearchInput(false);
        getData({ pi: 0 });
        onChangePageIndex(1);
    };

    const handleAddDepartment = async (DeptName: string) => {
        setisLoadingAddNewDept(true);
        const body: DepartmentModel = {
            companyId: companyId,
            isActive: true,
            name: DeptName,
            usersCount: 0,
            isDeletable: true,
            id: 0,
            closeDepartments: null,
        };
        await DepartmentService.Save(body);
        setShowAddDepartmentModal(false);
        setisLoadingAddNewDept(false);
        getData();
    };

    const openDeleteModal = (dept: DepartmentModel) => {
        setSelectedDepartment(dept);
        setShowDeleteDepartmentModal(true);
    };

    const onCloseDeleteModal = () => {
        setSelectedDepartment(null);
        setShowDeleteDepartmentModal(false);
    };

    const onDelete = async () => {
        if (!selectedDepartment) return;

        setIsLoadingDeleteDepartment(true);
        const { id } = selectedDepartment;
        const delSr = await DepartmentService.Delete(id);

        if (!delSr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setIsLoadingDeleteDepartment(false);
            return;
        }

        handleToast({
            title: t(TranslationModals.SUCCESS_DELETE),
            type: "alert",
            variant: "success",
        });

        setShowDeleteDepartmentModal(false);
        setSelectedDepartment(null);
        setIsLoadingDeleteDepartment(false);
        setPageIndex(1);
        getData({ pi: 0 });
    };

    const openEditDepartment = async (dept: DepartmentModel) => {
        setSelectedDepartment(dept);

        const { status, data } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: `departmentId=${dept.id}`,
        });
        if (!status) {
            console.error("error");
            return;
        }
        setSelectedUsersInDepartment(data);
        setInitialsSelectedUsersInDepartment(data);
        setShowAddUsersToDepModal(true);
    };

    const onCloseEditDepartment = () => {
        setSelectedDepartment(null);
        setSelectedUsersInDepartment([]);
        setInitialsSelectedUsersInDepartment([]);
        setShowAddUsersToDepModal(false);
    };

    const filterUsers = (usersId: number[], usersToCompare: OptionModel[]): OptionModel[] =>
        usersToCompare.filter(({ value }) => usersId.includes(Number(value)));

    const handleOpenModifyUsers = async ({ name, usersId, allUsers }: IAddUsers) => {
        const { added, deleted } = checkAddedDeletedBetweenTwoArraysOfNumbers(
            initialsSelectedUsersInDepartment.map(({ value }) => Number(value)),
            usersId
        );

        const addedUsers = filterUsers(added, allUsers);
        const deletedusers = filterUsers(deleted, allUsers);

        setUsersAdded(addedUsers);
        setUsersDeleted(deletedusers);

        if (!added.length && !deleted.length && selectedDepartment?.name === name) {
            onCloseEditDepartment();
            return;
        }

        if (added.length || deleted.length) {
            setAllUsers({ name, usersId });
            setIsConfirmAddUsersModalVisible(true);
            return;
        }

        await handleAddUsers({ name, usersId });
        getData();
    };

    const handleConfirmModifyUsers = async () => {
        setIsConfirmAddUsersModalVisible(false);
        setInitialsSelectedUsersInDepartment([]);
        if (allUsers === null) {
            setAllUsers(null);
            return;
        }

        await handleAddUsers(allUsers);
        setAllUsers(null);
        getData();
    };

    const handleAddUsers = async ({ name, usersId }: IAddUsers) => {
        if (!selectedDepartment) return;

        setIsLoadingAddUsersToDepModal(true);

        const { status } = await DepartmentService.PutUsersInDepartment(selectedDepartment.id, {
            userIds: usersId,
            companyId: selectedDepartment.companyId,
            name,
        });
        handleToast({
            title: t(
                !status() ? TranslationErrors.GENERIC_ERROR : TranslationKeys.USERS_DETAIL_MODAL_SUCCESS_ADD_MESSAGE
            ),
            variant: !status() ? "danger" : "success",
            type: "alert",
        });

        setShowAddUsersToDepModal(false);
        setSelectedDepartment(null);

        setIsLoadingAddUsersToDepModal(false);
    };

    const getConfirmModifyUserTitle = () => {
        const { added, deleted } = checkAddedDeletedBetweenTwoArraysOfNumbers(
            initialsSelectedUsersInDepartment.map(({ value }) => Number(value)) || [],
            allUsers?.usersId || []
        );

        if (added.length && deleted.length) return t(TranslationModals.COMPANIES_ADD_DELETE_USERS_TITLE);
        if (added.length) return t(TranslationModals.COMPANIES_ADD_USERS_TITLE);
        return t(TranslationModals.COMPANIES_DELETE_USERS_TITLE);
    };

    const getConfirmModifyUserDescription = () => {
        const { added, deleted } = checkAddedDeletedBetweenTwoArraysOfNumbers(
            initialsSelectedUsersInDepartment.map(({ value }) => Number(value)) || [],
            allUsers?.usersId || []
        );

        if (added.length && deleted.length) return t(TranslationModals.COMPANIES_ADD_DELETE_USERS_DESCRIPTION);
        if (added.length) return undefined;
        return t(TranslationModals.COMPANIES_DELETE_USERS_DESCRIPTION);
    };

    const getDeleteModalDescription = (): string => {
        return selectedDepartment?.isRelatedWithTasks
            ? t(TranslationKeys.EDIT_COMPANY_DELETE_DEPARTMENT)
            : t(TranslationKeys.EDIT_COMPANY_DELETE_DEPARTMENT_NO_RELATED);
    };

    const onCloseSumaryModal = () => {
        setIsConfirmAddUsersModalVisible(false);
        setAllUsers(null);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        return DepartmentService.GetDataExport({
            ...params,
            query: query,
            extraParams: `companyId=${companyId}`,
        });
    };

    const openCloseDepartmentModal = async (dept: DepartmentModel) => {
        setSelectedDepartment(dept);
        setShowCloseDepartmentModal(true);
    };

    const onCloseCloseDepartment = () => {
        setSelectedDepartment(null);
        setShowCloseDepartmentModal(false);
    };

    const onSubmitCloseDepartment = async () => {
        if (!selectedDepartment) return;
        setIsLoadingCloseDepartment(true);

        const { id, closeDepartments } = selectedDepartment;
        const { status, getParsedError } = await DepartmentService.CloseDepartment(id, closeDepartments ?? []);
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) {
            setIsLoadingCloseDepartment(false);
            return;
        }

        const successMessage =
            closeDepartments == null || !closeDepartments.length
                ? t(DepartmentTranslations.OPEN_DEPARTMENT_SUCCESSFULLY)
                : t(DepartmentTranslations.CLOSED_DEPARTMENT_SUCCESSFULLY);
        handleSuccessManager(successMessage);
        getData();
        setShowCloseDepartmentModal(false);
        setIsLoadingCloseDepartment(false);
    };

    const onClosingDates = (dates: Date[] | undefined[], index: number) => {
        if (!selectedDepartment) return;

        const { closeDepartments } = selectedDepartment;

        if (!closeDepartments) return;
        if (!dates) return;

        const [start, end] = dates;

        setSelectedDepartment({
            ...selectedDepartment,
            closeDepartments: closeDepartments.map((cc, i) => {
                if (i === index)
                    return { ...cc, closeDate: start ? convertUTCtoLocaleDate(start) : undefined, openDate: undefined };
                return cc;
            }),
        });
        if (end)
            setSelectedDepartment({
                ...selectedDepartment,
                closeDepartments: closeDepartments.map((cc, i) => {
                    if (i === index)
                        return {
                            ...cc,
                            closeDate: start ? convertUTCtoLocaleDate(start) : undefined,
                            openDate: end ? convertUTCtoLocaleDate(end) : undefined,
                        };
                    return cc;
                }),
            });
    };

    const onAddNewRangeHour = () => {
        if (!selectedDepartment) return;

        const { closeDepartments } = selectedDepartment;
        if (closeDepartments == null) {
            setSelectedDepartment({
                ...selectedDepartment,
                closeDepartments: [
                    { id: 0, fK_Department: selectedDepartment.id, closeDate: new Date(), openDate: new Date() },
                ],
            });
            return;
        }
        setSelectedDepartment({
            ...selectedDepartment,
            closeDepartments: [
                ...closeDepartments,
                { id: 0, fK_Department: selectedDepartment.id, closeDate: new Date(), openDate: new Date() },
            ],
        });
    };

    const onDeleteNewRangeHour = (index: number) => {
        if (!selectedDepartment) return;

        const { closeDepartments } = selectedDepartment;
        const filteredCloseDepartments = closeDepartments?.filter((_, i) => i !== index);
        setSelectedDepartment({
            ...selectedDepartment,
            closeDepartments: filteredCloseDepartments !== undefined ? filteredCloseDepartments : null,
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        status,
        departments,
        showAddDepartmentModal,
        setShowAddDepartmentModal,
        handleAddDepartment,
        pageIndex,
        query,
        total,
        onChangePageIndex,
        showSearchInput,
        setShowSearchInput,
        onSearch,
        setQuery,
        isLoadingAddNewDept,
        isLoadingDeleteDepartment,
        openDeleteModal,
        showDeleteDepartmentModal,
        onCloseDeleteModal,
        selectedDepartment,
        onDelete,
        showAddUsersToDepModal,
        isLoadingAddUsersToDepModal,
        openEditDepartment,
        onCloseEditDepartment,
        selectedUsersInDepartment,
        handleOpenModifyUsers,
        getConfirmModifyUserTitle,
        getConfirmModifyUserDescription,
        usersAdded,
        usersDeleted,
        isConfirmAddUsersModalVisible,
        handleConfirmModifyUsers,
        onCloseSumaryModal,
        getDeleteModalDescription,
        handleExport,
        openCloseDepartmentModal,
        onCloseCloseDepartment,
        onSubmitCloseDepartment,
        showCloseDepartmentModal,
        isLoadingCloseDepartment,
        onClosingDates,
        onAddNewRangeHour,
        onDeleteNewRangeHour,
    };
};
