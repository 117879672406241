import { FC, useEffect, useState } from "react";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useHeaderTitle } from "app/hooks/useHeaderTitle";
import { useBreadcrumbs } from "../../state/BreadcrumbsProvider";
import { useNavigateToInitialScreen } from "app/hooks/useNavigateToInitialScreen";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { rankingExtraParams } from "../../helpers/rankingExtraParams";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { getRankingExtraParam } from "app/helpers/03-REP/getRankingExtraParam";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import RankingService from "app/services/03-REP/RankingService";
import { EntityRankingModel } from "app/models/03-REP/Ranking/EntityRankingModel";
import { CompanyRankingProps, DateRange, getDataProps } from "../../types";
import { RankingSortBy } from "app/components_v2/Layout/RankingLayout/RankingHeader/RankingHeader";
import { RankingLayout } from "app/components_v2/Layout/RankingLayout/RankingLayout";

export const CompanyRanking: FC<CompanyRankingProps> = ({
    startDate: startDateProp,
    endDate: endDateProp,
    onDateChange,
}) => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const session = useSession();
    const { resetBreadcrums } = useBreadcrumbs();
    const { onChangeHeaderTitle } = useHeaderTitle();

    const { navigateToInitialScreen } = useNavigateToInitialScreen();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();

    const [startDate, setStartDate] = useState<Date>(startDateProp);
    const [endDate, setEndDate] = useState<Date | undefined>(endDateProp);
    const [sortBy, setSortBy] = useState<RankingSortBy>("STAR_ASC");
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [data, setData] = useState<EntityRankingModel[]>([]);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const handleDateChange = (dates: DateRange) => {
        const [start, end] = dates;
        if (start) {
            setStartDate(start);
            setEndDate(undefined);
            onDateChange({ startDate: start, endDate: undefined });
        }
        if (end) {
            setEndDate(end);
            onDateChange({ endDate: end });
        }
    };

    const getData = async ({ query, pageI }: getDataProps) => {
        setLoading(true);
        const { sortDirection, sortField } = getRankingExtraParam(sortBy);
        const { data, status } = await RankingService.GetCompanyRanking({
            pageIndex: pageI ? pageI - 1 : 0,
            pageSize: PaginationDefaults.PAGE_SIZE,
            extraParams: rankingExtraParams({ startDate, endDate }),
            query,
            sortDirection,
            sortField,
        });
        if (!status() || data?.list === null) {
            setLoading(false);
            setData([]);
            setTotal(0);
            return;
        }
        firstLoad &&
            data?.list?.length === 1 &&
            (session?.user.userType === "ANALIST"
                ? nav(`/${PrivatePaths.COMPANY_RANKING}/company/${data.list[0].id}`)
                : nav(`/${PrivatePaths.COMPANY_RANKING}/ranking-users?companyId=${data.list[0].id}`));

        setData(data.list);
        resetBreadcrums({ name: t(TranslationKeys.GLOBAL_RANKING_COMPANIES) });
        setTotal(data.count);
        setFirstLoad(false);
        setLoading(false);
    };

    const onClick = (rank: EntityRankingModel) => {
        if (rank.companyId) changeWorkingCompanyByCompanyId(rank.companyId);
        nav(`company/${rank.id}`);
    };

    useEffect(() => {
        if (endDate === undefined) return;
        getData({ pageI: 0 });
        setPageIndex(0);
    }, [startDate, endDate, sortBy]);

    useEffect(() => {
        if (!hasPermissionToView(session, SecScreen.COMPANY_RANKING)) navigateToInitialScreen();

        onChangeHeaderTitle(t(TranslationTitles.GLOBAL_COMPANY_RANKING_PAGE_TITLE));
    }, []);

    return (
        <RankingLayout
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
            onPressEnter={(query) => getData({ query, pageI: 0 })}
            title={t(TranslationKeys.GLOBAL_RANKING_COMPANIES)}
            data={data}
            pageIndex={pageIndex}
            onPageIndexChange={(pi) => {
                setPageIndex(pi);
                getData({ pageI: pi });
            }}
            rankingTypePages="COMPANY"
            onClickPill={onClick}
            total={total}
            isLoading={loading}
            sortBy={sortBy}
            onChangeSortBy={setSortBy}
        />
    );
};
