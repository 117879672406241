import { useRef, useState, useEffect, FC, MutableRefObject } from "react";
import jsQR from "jsqr";
import { NewButton } from "../__buttons/NewButton/NewButton";
import { faPlay, faSpinnerThird, faStop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { ScanQRTranslations, TranslationModals } from "app/translation/translationKeys";
import Spinner from "../Spinner/Spinner";

type QRScannerProps = {
    onScan: (data: string) => void;
    videoRef: MutableRefObject<HTMLVideoElement | null>;
};

export const QRScanner: FC<QRScannerProps> = ({ onScan, videoRef }) => {
    const { handleToast } = useToast();
    const { t } = useTranslation();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const animationFrameId = useRef<number | null>(null);

    const [isRecording, setIsRecording] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handlePlayEvent = () => {
        animationFrameId.current = requestAnimationFrame(detectQRCode);
    };

    const startRecording = async () => {
        try {
            setIsLoading(true);
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
            setIsRecording(true);
            setIsLoading(false);
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (error) {
            console.error("Error accessing camera:", error);
            handleToast({
                title: t(TranslationModals.TOAST_PHOTO_ERROR_TITLE),
                subtitle: t(TranslationModals.TOAST_PHOTO_ERROR_SUBTITLE),
                variant: "danger",
                type: "alert",
            });
        }
    };

    const stopRecording = () => {
        if (!videoRef.current || !videoRef.current.srcObject) return;
        setIsRecording(false);

        const stream = videoRef.current.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
            if (track.readyState === "live") {
                track.stop();
            }
        });
        videoRef.current.removeEventListener("play", handlePlayEvent);
        videoRef.current.srcObject = null;
        if (animationFrameId.current !== null) {
            cancelAnimationFrame(animationFrameId.current);
        }
    };

    const detectQRCode = () => {
        if (!canvasRef.current || !videoRef.current || !isRecording || videoRef.current.videoWidth === 0) return;
        const context = canvasRef.current.getContext("2d", { willReadFrequently: true });
        if (!context) return;
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

        const imageData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
            onScan(code.data);
            stopRecording();
        } else {
            animationFrameId.current = requestAnimationFrame(detectQRCode);
        }
    };

    useEffect(() => {
        if (videoRef.current && isRecording) {
            videoRef.current.addEventListener("play", handlePlayEvent);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener("play", handlePlayEvent);
                if (animationFrameId.current !== null) {
                    cancelAnimationFrame(animationFrameId.current);
                }
            }
        };
    }, [isRecording]);

    useEffect(() => {
        startRecording();
    }, []);

    return (
        <>
            <canvas ref={canvasRef} style={{ display: "none" }} />
            <div className="customQrScanner">
                <div className="customQrScanner__video">
                    {isLoading && (
                        <div className="customQrScanner__spinner">
                            <Spinner />
                        </div>
                    )}
                    <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted
                        className={`customQrScanner__video__content--${isRecording ? "visible" : "hidden"}`}
                    />
                </div>

                {isRecording && !isLoading && (
                    <p className="customQrScanner__recording">
                        {t(ScanQRTranslations.QR_SCANNER_SCANNING)}
                        <span className="customQrScanner__recording__icon">
                            <FontAwesomeIcon
                                icon={faSpinnerThird}
                                className="customQrScanner__recording__icon__content"
                            />
                        </span>
                    </p>
                )}

                <NewButton
                    handleClickButton={() => (isRecording ? stopRecording() : startRecording())}
                    iconRight={isRecording ? faStop : faPlay}
                    text={
                        isRecording
                            ? t(ScanQRTranslations.QR_SCANNER_STOP_SCANNING)
                            : t(ScanQRTranslations.QR_SCANNER_START_SCANNING)
                    }
                    danger={isRecording}
                />
            </div>
        </>
    );
};
