import React, { FC } from "react";
import { ActionButtonsModel } from "../TableTabHeader/TableTabHeader";
import { Icon } from "app/components_v2/Icon/Icon";

type TableActionButtonsProps = {
    actionButtons: ActionButtonsModel[];
};

export const TableActionButtons: FC<TableActionButtonsProps> = ({ actionButtons }) => {
    return (
        <>
            {actionButtons.map(
                ({ icon, onClick, hidden, inputComponent, disabled, showMarkableIcon, title }, i) =>
                    !hidden && (
                        <div
                            className={`tableTab__header__actions__icons__container ${
                                !!showMarkableIcon ? "relative" : ""
                            }`}
                            key={i}
                        >
                            {inputComponent && inputComponent.show ? (
                                inputComponent.component
                            ) : (
                                <>
                                    {showMarkableIcon && (
                                        <div className="markableIcon__notification tableTab__header__actions__markableIcon"></div>
                                    )}
                                    <Icon disabled={disabled} icon={icon} onClick={onClick} title={title} />
                                </>
                            )}
                        </div>
                    )
            )}
        </>
    );
};
