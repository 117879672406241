import { faFileCertificate } from "@fortawesome/pro-regular-svg-icons";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { AuditModel } from "app/models/05-QUA/AuditModels/AuditModels";
import AuditGroupService from "app/services/05-QUA/AuditGroupService";
import AuditService from "app/services/05-QUA/AuditService";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditBoxBody } from "./AuditBoxBody/AuditBoxBody";
import { AuditBoxHeader } from "./AuditBoxHeader/AuditBoxHeader";
import { AuditBoxModal } from "./AuditBoxModal/AuditBoxModal";

type AuditBoxProps = {
    audit: AuditModel;
    fetchAudits: () => Promise<void>;
};

export const AuditBox: FC<AuditBoxProps> = ({ audit, fetchAudits }) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();

    const [internalAudit, setInternalAudit] = useState<AuditModel>(audit);
    const [showSaveAuditGroupModal, setShowSaveAuditGroupModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const fetchAuditGroup = async () => {
        const extraParams = new URLSearchParams();
        extraParams.append("AuditId", String(audit.id));
        const { data, status, getParsedError } = await AuditGroupService.GetData({
            extraParams: extraParams.toString(),
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            return;
        }

        setInternalAudit((prev) => ({ ...prev, auditGroups: data.list }));
    };

    const handleSaveAuditGroup = async (value: string) => {
        setIsLoadingSave(true);
        const { status, getParsedError } = await AuditGroupService.Save({
            id: 0,
            name: value,
            fK_Audit: audit.id,
            auditGroupCheckLists: [],
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoadingSave, setShowSaveAuditGroupModal);
            return;
        }

        handleSuccessManager(t(TranslationModals.AUDIT_GROUP_SAVE_TOAST));

        fetchAuditGroup();
        reset(setIsLoadingSave, setShowSaveAuditGroupModal);
    };

    const handleEditAudit = async (value: string) => {
        setIsLoadingEdit(true);
        const { data, status, getParsedError } = await AuditService.Edit(internalAudit.id, {
            ...audit,
            name: value,
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoadingEdit, setShowEditModal);
            return;
        }

        handleSuccessManager(t(TranslationModals.AUDIT_EDIT_TOAST));

        setInternalAudit(data);
        reset(setIsLoadingEdit, setShowEditModal);
    };

    const handleDeleteAudit = async () => {
        setIsLoadingDelete(true);
        const { status, getParsedError } = await AuditService.Delete(internalAudit.id);

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoadingDelete, setShowDeleteModal);
            return;
        }

        handleSuccessManager(t(TranslationModals.AUDIT_DELETE_TOAST));

        fetchAudits();
        reset(setIsLoadingDelete, setShowDeleteModal);
    };

    const reset = (setIsLoading: (value: boolean) => void, setShowModal: (value: boolean) => void) => {
        setIsLoading(false);
        setShowModal(false);
    };

    return (
        <WhiteBox fullHeight>
            {showSaveAuditGroupModal && (
                <AuditBoxModal
                    isLoading={isLoadingSave}
                    onSubmit={handleSaveAuditGroup}
                    onCloseModal={() => setShowSaveAuditGroupModal(false)}
                    type="SAVE_AUDIT_GROUP"
                />
            )}
            {showEditModal && (
                <AuditBoxModal
                    isLoading={isLoadingEdit}
                    onSubmit={handleEditAudit}
                    onCloseModal={() => setShowEditModal(false)}
                    value={internalAudit.name}
                    type="EDIT_AUDIT"
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    title={t(TranslationModals.DELETE_AUDIT_TITLE).replace("{0}", internalAudit.name)}
                    description={t(TranslationModals.DELETE_AUDIT_BODY)}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={handleDeleteAudit}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    isLoading={isLoadingDelete}
                    type="delete"
                    variant="appcc"
                />
            )}
            <div className="auditBox">
                <AuditBoxHeader
                    auditName={internalAudit.name}
                    onChangeShowSaveModal={setShowSaveAuditGroupModal}
                    onChangeShowEditModal={setShowEditModal}
                    onChangeShowDeleteModal={setShowDeleteModal}
                />
                <div className="auditBox__content">
                    {!internalAudit.auditGroups.length ? (
                        <div className="auditBox__error">
                            <TableError
                                icon={faFileCertificate}
                                title={t(TranslationKeys.AUDIT_GROUP_EMPTY_TITLE)}
                                description={t(TranslationKeys.AUDIT_GROUP_EMPTY_BODY)}
                            />
                        </div>
                    ) : (
                        <AuditBoxBody auditGroups={internalAudit.auditGroups} fetchAuditGroups={fetchAuditGroup} />
                    )}
                </div>
            </div>
        </WhiteBox>
    );
};
