import { KeyboardEvent, useEffect, useState } from "react";
import { v4 } from "uuid";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { INITIAL_PATCH_NOTE_ERRORS } from "../../../constants/PatchNotesConstants";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { mapPatchNoteToFormValues } from "../helpers/mapPatchNotes";
import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";
import { PatchNoteFormValues } from "../models/PatchNoteFormValues";
import { PatchNoteVersionErrorsModel } from "../../../models/PatchNoteVersionGridModel";
import { ActionType } from "app/models/FormComponentsModel";

export const usePatchNotesModal = (patchNote: PatchNoteVersionModel, type: ActionType, noteInputId: string) => {
    const [customerInstanceOptions, setCustomerInstanceOptions] = useState<OptionModel[]>([]);
    const [optionsLoading, setOptionsLoading] = useState<boolean>(true);
    const [internalPatchNote, setInternalPatchNote] = useState<PatchNoteFormValues>(
        mapPatchNoteToFormValues(patchNote)
    );
    const [internalPatchNoteCopy, setInternalPatchNoteCopy] = useState<PatchNoteFormValues>(internalPatchNote);
    const [patchNoteErrors, setPatchNoteErrors] = useState<PatchNoteVersionErrorsModel>(INITIAL_PATCH_NOTE_ERRORS);
    const [note, setNote] = useState<string>("");
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const { patchNotes: internalPatchNotes, customerInstances } = internalPatchNote;

    const getCustomerInstanceOptions = async () => {
        setOptionsLoading(true);
        const { data, status } = await SegSelectorService.GetCustomerInstances();
        if (!status) {
            setCustomerInstanceOptions([]);
            return;
        }
        setCustomerInstanceOptions(data);
        if (type === "create") {
            const patchNoteUpated = {
                ...internalPatchNote,
                customerInstances: data,
            };
            handleInternalPatchNoteChange(patchNoteUpated);
            setInternalPatchNoteCopy(patchNoteUpated);
        }
        setOptionsLoading(false);
    };

    const onChangePatchNotes = (value: string) => setNote(value);

    const onPressEnterPatchNotes = (e: KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (!note.length) return;
        handleInternalPatchNoteChange({
            patchNotes: [
                ...internalPatchNotes,
                {
                    dbId: 0,
                    id: v4(),
                    fK_PatchNoteVersion: 0,
                    note,
                },
            ],
        });
        setNote("");
    };

    const onAddNote = () => {
        if (!note.length) return;
        handleInternalPatchNoteChange({
            patchNotes: [
                ...internalPatchNotes,
                {
                    dbId: 0,
                    id: v4(),
                    fK_PatchNoteVersion: 0,
                    note,
                },
            ],
        });
        setNote("");
        const noteInput = document.querySelector(`#${noteInputId}`) as HTMLElement;
        noteInput.focus();
    };

    const onDeletePatchNote = (id: string) =>
        handleInternalPatchNoteChange({ patchNotes: internalPatchNotes.filter((pn) => pn.id !== id) });

    const handleInternalPatchNoteChange = (values: Partial<PatchNoteFormValues>) => {
        setPatchNoteErrors(INITIAL_PATCH_NOTE_ERRORS);
        setInternalPatchNote((prev) => ({ ...prev, ...values }));
    };

    const handleDeleteItems = (id: string) => {
        const selectedValue = customerInstances.find((ci) => ci.value === id);
        if (!selectedValue) return;

        handleInternalPatchNoteChange({ customerInstances: customerInstances.filter((ci) => ci.value !== id) });
    };

    useEffect(() => {
        getCustomerInstanceOptions();
    }, []);

    return {
        customerInstanceOptions,
        handleDeleteItems,
        handleInternalPatchNoteChange,
        hasChanges,
        internalPatchNote,
        internalPatchNoteCopy,
        note,
        onAddNote,
        onChangePatchNotes,
        onDeletePatchNote,
        onPressEnterPatchNotes,
        optionsLoading,
        patchNoteErrors,
        setHasChanges,
        setPatchNoteErrors,
    };
};
