import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { OnBoardingStatus, OnBoardingStatusModal } from "app/models/01-SEG/Onboarding/OnBoardingModel";
import { OnBoardingStepsTranslation, TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type OnBoardingStepModalProps = {
    name: string;
    isLoading: boolean;
    status: OnBoardingStatus;
    isLastStep: boolean;
    onComplete: () => void;
    onClose: () => void;
};

export const OnBoardingStepModal: FC<OnBoardingStepModalProps> = ({
    name,
    isLoading,
    onClose,
    onComplete,
    status,
    isLastStep,
}) => {
    const { t } = useTranslation();

    const title: Record<OnBoardingStatusModal, string> = {
        BLOCKED: t(OnBoardingStepsTranslation.ONBOARDING_UNLOCK_TITLE).replace("{0}", name),
        IN_PROGRESS: t(OnBoardingStepsTranslation.ONBOARDING_COMPLETE_TITLE).replace("{0}", name),
    };

    const onConfirmText: Record<OnBoardingStatusModal, string> = {
        BLOCKED: t(OnBoardingStepsTranslation.ONBOARDING_UNLOCK),
        IN_PROGRESS: t(OnBoardingStepsTranslation.ONBOARDING_COMPLETE_STEP),
    };

    const description: Record<OnBoardingStatusModal, string> = {
        BLOCKED: t(OnBoardingStepsTranslation.ONBOARDING_UNLOCK_DESCRIPTION).replace("{0}", name),
        IN_PROGRESS: t(OnBoardingStepsTranslation.ONBOARDING_COMPLETE_DESCRIPTION),
    };

    if (status === "DONE" || status === "REVIEW") {
        onClose();
        return null;
    }
    return (
        <ConfirmModal
            onConfirm={onComplete}
            onConfirmText={isLastStep ? t(OnBoardingStepsTranslation.ONBOARDING_COMPLETE) : onConfirmText[status]}
            title={isLastStep ? t(OnBoardingStepsTranslation.ONBOARDING_FINISH_TITLE) : title[status]}
            description={isLastStep ? t(OnBoardingStepsTranslation.ONBOARDING_FINISH_DESCRIPTION) : description[status]}
            onClose={onClose}
            onCloseText={t(TranslationKeys.CLOSE)}
            isLoading={isLoading}
        />
    );
};
