import { faEnvelope, faPaperPlane, faUnlock } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "app/components_v2/Icon/Icon";
import { useOnBoardingStepModal } from "app/hooks/OnBoarding/useOnBoardingStepModal";
import { OnBoardingStepsInstanceModel } from "app/models/01-SEG/Onboarding/OnBoardingStepsInstanceModel";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { OnBoardingStepModal } from "../OnBoardingModals/OnBoardingStepModal/OnBoardingStepModal";
import { getOnBoardingStatusInfo } from "../helpers/getOnBoardingStatusInfo";
import { OnBoardingStepIcon } from "../OnBoardingStepIcon/OnBoardingStepIcon";
import { OnBoardingReviewStepsModal } from "../OnBoardingModals/OnBoardingReviewStepsModal/OnBoardingReviewStepsModal";
import { OnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingModel";
import { OnBoardingStepsTranslation } from "app/translation/translationKeys";

type OnBoardingStepsTitleProps = {
    isCompleteVisible: boolean;
    step: OnBoardingStepsInstanceModel;
    stepIndex: number;
    isInProgressVisible: boolean;
    isLastStep: boolean;
    onCompleteStep: () => void;
    onReviewStep: () => void;
    onInProgressStep: () => void;
};

export const OnBoardingStepsTitle: FC<OnBoardingStepsTitleProps> = ({
    isCompleteVisible,
    isInProgressVisible,
    step,
    stepIndex,
    isLastStep,
    onCompleteStep,
    onReviewStep,
    onInProgressStep,
}) => {
    const { t } = useTranslation();

    const [isCompleteModalVisible, setIsCompleteModalVisible] = useState<boolean>(false);
    const [isReviewCompleteModalVisible, setIsReviewCompleteModalVisible] = useState<boolean>(false);

    const { completeStep, reviewStep, inProgressStep, isLoading } = useOnBoardingStepModal({
        onCompleteStep: () => {
            setIsCompleteModalVisible(false);
            onCompleteStep();
        },
        onReviewStep: () => {
            setIsReviewCompleteModalVisible(false);
            onReviewStep();
        },
        onInProgressStep: () => {
            setIsCompleteModalVisible(false);
            onInProgressStep();
        },
    });

    const { id, name, status } = step;
    const { title, icon } = getOnBoardingStatusInfo(status);

    const openModal = () => {
        status === "REVIEW" ? setIsReviewCompleteModalVisible(true) : setIsCompleteModalVisible(true);
    };

    const handleReviewStep = (status: OnBoardingStatus, comment?: string) => {
        reviewStep({ id, status, comment });
    };

    const handleComplete = () => {
        if (status === "BLOCKED") return inProgressStep(id);

        completeStep(id);
    };

    return (
        <>
            {isCompleteModalVisible && (
                <OnBoardingStepModal
                    name={t(name)}
                    onComplete={handleComplete}
                    isLoading={isLoading}
                    onClose={() => setIsCompleteModalVisible(false)}
                    status={status}
                    isLastStep={isLastStep && status === "DONE"}
                />
            )}
            {isReviewCompleteModalVisible && (
                <OnBoardingReviewStepsModal
                    isLoading={isLoading}
                    name={t(name)}
                    onClose={() => setIsReviewCompleteModalVisible(false)}
                    onComplete={handleReviewStep}
                />
            )}
            <div className="onBoardingStepsTitle">
                {status !== "IN_PROGRESS" && (
                    <OnBoardingStepIcon
                        title={`${t(name)} - ${t(title)}`}
                        icon={icon}
                        status={status}
                        type="step"
                        name={t(name)}
                    />
                )}
                <p
                    className={`onBoardingStepsTitle__text ${
                        status === "BLOCKED" ? "onBoardingStepsTitle__text--blocked" : ""
                    }`}
                >
                    {`${stepIndex} - ${t(name)}`}
                </p>
                {(isCompleteVisible || isInProgressVisible) && (
                    <Icon
                        icon={status === "REVIEW" ? faEnvelope : isCompleteVisible ? faPaperPlane : faUnlock}
                        theme="light"
                        onClick={openModal}
                        text={
                            isLastStep && status === "DONE"
                                ? t(OnBoardingStepsTranslation.ONBOARDING_COMPLETE)
                                : status === "REVIEW"
                                ? t(OnBoardingStepsTranslation.ONBOARDING_REVIEW_STEP)
                                : status === "BLOCKED"
                                ? t(OnBoardingStepsTranslation.ONBOARDING_UNLOCK_STEP)
                                : t(OnBoardingStepsTranslation.ONBOARDING_COMPLETE_STEP)
                        }
                        size="xxs"
                    />
                )}
            </div>
        </>
    );
};
