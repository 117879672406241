import { FC } from "react";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { wizardTaskInitialDays } from "../../utilities";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { RecurentTaskCustomValues, RecurrentTaskModel, Step4 } from "app/models/02-TAR/TaskWizard";
import { OptionModel } from "app/models/02-TAR/OptionModel";

type CustomSelectOptionsProps = {
    onChange: (fields: Partial<Step4>) => void;
    recurringTask: RecurrentTaskModel;
    options: OptionModel[];
    disabled?: boolean;
};

export const CustomSelectOptions: FC<CustomSelectOptionsProps> = ({ recurringTask, onChange, options, disabled }) => {
    const handleChange = (value: RecurentTaskCustomValues) => {
        onChange({
            recurringTask: {
                ...recurringTask,
                custom: {
                    ...recurringTask.custom,
                    customValue: value,
                    days: wizardTaskInitialDays,
                    selectedOptions: { text: "", value: "" },
                },
            },
        });
    };
    return (
        <SelectOptions
            isMulti={false}
            onChange={({ value }) => handleChange(value as RecurentTaskCustomValues)}
            selectedValue={recurringTask.custom?.customValue || "day"}
            disabled={disabled}
            options={options}
            isSearchable={false}
        />
    );
};
