import { FC } from "react";
import { AssetFieldRangeModel } from "app/models/05-QUA/AssetModels/AssetFieldRangeModel";

export type IssueBodyAssetFieldProps = {
    assetFieldRange: AssetFieldRangeModel;
    fieldValue?: string;
    name: string;
};

export const IssueBodyAssetField: FC<IssueBodyAssetFieldProps> = ({ assetFieldRange, fieldValue, name }) => {
    const issueAssetField = `${name}: ${fieldValue?.length ? `${fieldValue} ${assetFieldRange?.unit}` : ""} (${
        assetFieldRange?.min
    } / ${assetFieldRange?.max} ${assetFieldRange?.unit})`;

    return <li className="issueBodyAssetField">{issueAssetField}</li>;
};
