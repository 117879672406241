import { getMonthName } from "app/helpers/Date.utilities";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { hasPermissionToAdd, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import BaseTaskService from "app/services/02-TAR/BaseTaskService";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { TranslationErrors, TranslationModals } from "app/translation/translationKeys";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import { INITIAL_BASE_TASK_VALUES, INITIAL_DATA_TASK_MODEL } from "../../EditBaseTask/constants/editBaseTaskConstants";
import {
    mapBaseTaskToDataTaskModel,
    mapCheckListToBaseCheckList,
    mapDynamicFieldsToBaseTaskFields,
} from "../../helpers/mappers";
import { BaseTaskFormModel } from "../models/EditBaseTaskFormModels";
import { useEditBaseTaskErrors } from "./useEditBaseTaskErrors";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { useInputTaskData } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskData/hooks/useInputTaskData";

export const useEditBaseTask = () => {
    const nav = useNavigate();
    const session = useSession();
    const { id } = useParams();
    const { handleToast, removeToast } = useToast();
    const { t } = useTranslation();
    const [urlParams] = useSearchParams();
    const { pathname } = useLocation();
    const requiredPlanAPPCC = pathname.includes(PrivatePaths.BASE_TASK_APPCC_MANAGEMENT);
    const [baseTask, setBaseTask] = useState<BaseTaskModel>(INITIAL_BASE_TASK_VALUES);
    const [dataTask, setDataTask] = useState<BaseTaskFormModel>(INITIAL_DATA_TASK_MODEL);
    const [dataTaskCopy, setDataTaskCopy] = useState<BaseTaskFormModel>(INITIAL_DATA_TASK_MODEL);
    const [baseTaskTypes, setBaseTaskTypes] = useState<OptionsSearch[]>([]);
    const [selectedBaseTaskType, setSelectedBaseTaskType] = useState<OptionsSearch>({
        label: "",
        value: "",
    });
    const [planAPPCCOptions, setPlanAPPCCOptions] = useState<OptionsSearch[]>([]);
    const [selectedPlanAPPCC, setSelectedPlanAPPCC] = useState<OptionsSearch>({
        label: "",
        value: "",
    });
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showBaseTaskTitleModal, setShowBaseTaskTitleModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const {
        isBaseTaskPopoverLoading,
        onChangeInputValue,
        baseTaskOptions,
        isBaseTaskPopoverVisible,
        setIsBaseTaskPopoverVisible,
        onFocusInputValue,
    } = useInputTaskData({
        hasPlanAPPCC: !!requiredPlanAPPCC,
        onChange: (newValue) => onChange({ taskTitle: newValue }),
        value: dataTask.taskTitle,
        id: id ? Number(id) : undefined,
    });

    const { validate, validations } = useEditBaseTaskErrors(
        dataTask,
        selectedBaseTaskType,
        selectedPlanAPPCC,
        requiredPlanAPPCC
    );

    const isCreatePage = useCallback(() => {
        return pathname.split("/").includes("new");
    }, [pathname]);

    const isDisabled = () => {
        return isCreatePage()
            ? !hasPermissionToAdd(SecScreen.BASE_TASK_MANAGEMENT)
            : !hasPermissionToEdit(SecScreen.BASE_TASK_MANAGEMENT);
    };

    const fetchSelectors = async () => {
        setIsLoading(true);

        const baseTaskTypePromise = TarSelectorService.GetBaseTaskTypes();
        const planAPPCCPromise = QuaSelectorService.GetPlansAPPCC();

        Promise.all([baseTaskTypePromise, planAPPCCPromise])
            .then(([baseTaskTypeSr, planAPPCCSr]) => {
                if (handleParsedErrorToast(baseTaskTypeSr.status(), baseTaskTypeSr.getParsedError())) return;
                if (handleParsedErrorToast(planAPPCCSr.status(), planAPPCCSr.getParsedError())) return;
                setBaseTaskTypes(baseTaskTypeSr.data);
                setPlanAPPCCOptions(planAPPCCSr.data);
            })
            .catch(() =>
                handleToast({
                    title: t(TranslationErrors.GENERIC_ERROR),
                    variant: "danger",
                    type: "alert",
                })
            )
            .finally(() => setIsLoading(false));
    };

    const fetchBaseTask = async () => {
        setIsLoading(true);

        const baseTaskPromise = BaseTaskService.GetOne(Number(id));
        const baseTaskTypePromise = TarSelectorService.GetBaseTaskTypes();
        const planAPPCCPromise = QuaSelectorService.GetPlansAPPCC();

        Promise.all([baseTaskTypePromise, baseTaskPromise, planAPPCCPromise])
            .then(([baseTaskTypeSr, baseTaskSr, planAPPCCSr]) => {
                if (handleParsedErrorToast(baseTaskTypeSr.status(), baseTaskTypeSr.getParsedError())) return;
                if (handleParsedErrorToast(baseTaskSr.status(), baseTaskSr.getParsedError())) return;
                if (handleParsedErrorToast(planAPPCCSr.status(), planAPPCCSr.getParsedError())) return;
                setBaseTaskTypes(baseTaskTypeSr.data);
                setBaseTask(baseTaskSr.data);
                setPlanAPPCCOptions(planAPPCCSr.data);
                setSelectedBaseTaskType({
                    label: baseTaskSr.data.type,
                    value: String(baseTaskSr.data.fK_BaseTaskType),
                });
                if (!!baseTaskSr.data.fK_PlanAPPCC && !!baseTaskSr.data.planName?.length)
                    setSelectedPlanAPPCC({
                        label: baseTaskSr.data.planName,
                        value: String(baseTaskSr.data.fK_PlanAPPCC),
                    });
                const formDataTask = mapBaseTaskToDataTaskModel(baseTaskSr.data);
                setDataTask(formDataTask);
                setDataTaskCopy(formDataTask);
            })
            .catch(() =>
                handleToast({
                    title: t(TranslationErrors.GENERIC_ERROR),
                    variant: "danger",
                    type: "alert",
                })
            )
            .finally(() => setIsLoading(false));
    };

    const handleParsedErrorToast = (status: boolean, parsedError: string) => {
        if (!status) {
            handleToast({
                title: parsedError,
                variant: "danger",
                type: "alert",
            });
            setIsSaving(false);
            return true;
        }
        return false;
    };

    const handleCancel = async () => {
        !isCreatePage() && fetchBaseTask();
        setDataTask(dataTaskCopy);
        setShowCancelModal(false);
    };

    const handleDelete = async () => {
        const { status, getParsedError } = await BaseTaskService.Delete(Number(id));
        if (handleParsedErrorToast(status(), getParsedError())) return;
        nav(requiredPlanAPPCC ? PrivatePaths.BASE_TASK_APPCC_MANAGEMENT : PrivatePaths.BASE_TASK_MANAGEMENT);
    };

    const onChange = (data: Partial<BaseTaskFormModel>) => setDataTask((prev) => ({ ...prev, ...data }));

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate(e)) return;
        if (baseTaskOptions.length) {
            setShowBaseTaskTitleModal(true);
            return;
        }
        saveBaseTask();
    };

    const saveBaseTask = () => {
        if (isCreatePage()) {
            setIsSaving(true);
            handleSaveNewBaseTask();
            return;
        }

        setShowUpdateModal(true);
    };

    const handleSaveNewBaseTask = async () => {
        const saveBaseTask: BaseTaskModel = {
            id: 0,
            name: dataTask.taskTitle,
            description: dataTask.taskDesc,
            customerInstanceId: session?.user.customerInstanceId || 0,
            fK_BaseTaskType: Number(selectedBaseTaskType.value),
            type: "",
            isPhotoRequired: dataTask.isPhotoRequired,
            imageBase64Content: dataTask.imageBase64 ?? undefined,
            baseTaskCheckList: mapCheckListToBaseCheckList(dataTask.checkList, false),
            baseTaskFields: mapDynamicFieldsToBaseTaskFields(dataTask.dynamicFields),
            fK_PlanAPPCC: selectedPlanAPPCC.value.length ? Number(selectedPlanAPPCC.value) : undefined,
            allowAnyData: !!dataTask.allowAnyData,
            reportType: dataTask.reportType,
            isCritical: dataTask.criticalTask,
            counter: 0,
        };

        const { status, getParsedError, data } = await BaseTaskService.Save(saveBaseTask);

        if (handleParsedErrorToast(status(), getParsedError())) return;

        handleToast({
            title: requiredPlanAPPCC
                ? t(TranslationModals.TOAST_SAVE_APPCC_BASE_TASK)
                : t(TranslationModals.TOAST_SAVE_BASE_TASK),
            variant: "success",
            type: "alert",
        });

        setIsSaving(false);
        handleNavigate(data);
    };

    const handleNavigate = (id: number) => {
        const prevPath = urlParams.get("prevPath");
        const baseTaskPath = requiredPlanAPPCC
            ? PrivatePaths.BASE_TASK_APPCC_MANAGEMENT
            : PrivatePaths.BASE_TASK_MANAGEMENT;

        const pathToRedirect = prevPath ? `${prevPath}?baseTaskId=${id}` : `/${baseTaskPath}`;
        nav(pathToRedirect);
    };

    const handleEditBaseTask = async () => {
        setIsSaving(true);
        const toastId = v4();
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        handleToast({
            title: t(TranslationModals.GENERATING_TASKS_TITLE),
            subtitle: t(TranslationModals.GENERATING_TASKS_DESCRIPTION)
                .replace("{0}", String(tomorrow.getDate()))
                .replace("{1}", getMonthName(tomorrow.toString())),
            variant: "primary",
            type: "alert",
            isLoading: true,
            id: toastId,
        });

        const editedBaseTask: BaseTaskModel = {
            id: Number(id),
            name: dataTask.taskTitle,
            description: dataTask.taskDesc,
            customerInstanceId: baseTask.customerInstanceId,
            fK_BaseTaskType: Number(selectedBaseTaskType.value),
            type: "",
            imageBase64Content: dataTask.imageBase64 ?? undefined,
            fotoExampleId: dataTask.fotoExampleId ?? undefined,
            isPhotoRequired: dataTask.isPhotoRequired,
            fK_PlanAPPCC: selectedPlanAPPCC.value.length ? Number(selectedPlanAPPCC.value) : undefined,
            allowAnyData: !!dataTask.allowAnyData,
            baseTaskCheckList: mapCheckListToBaseCheckList(dataTask.checkList, false),
            baseTaskFields: mapDynamicFieldsToBaseTaskFields(dataTask.dynamicFields, id),
            reportType: dataTask.reportType,
            isCritical: dataTask.criticalTask,
            counter: 0,
        };

        const { status, getParsedError } = await BaseTaskService.Edit(Number(id), editedBaseTask);

        if (handleParsedErrorToast(status(), getParsedError())) {
            removeToast(toastId);
            setIsSaving(false);
            return;
        }

        removeToast(toastId);
        handleToast({
            title: requiredPlanAPPCC
                ? t(TranslationModals.TOAST_EDIT_APPCC_BASE_TASK)
                : t(TranslationModals.TOAST_EDIT_BASE_TASK),
            variant: "success",
            type: "alert",
        });
        setIsSaving(false);
        nav(requiredPlanAPPCC ? PrivatePaths.BASE_TASK_APPCC_MANAGEMENT : PrivatePaths.BASE_TASK_MANAGEMENT);
    };

    const handleSubmitWithSameTitle = () => {
        setShowBaseTaskTitleModal(false);
        saveBaseTask();
    };

    useEffect(() => {
        if (isCreatePage()) fetchSelectors();
        else fetchBaseTask();
    }, []);

    return {
        showDeleteModal,
        setShowDeleteModal,
        handleDelete,
        showCancelModal,
        setShowCancelModal,
        handleCancel,
        isLoading,
        isCreatePage,
        isDisabled,
        isSaving,
        onSubmit,
        dataTask,
        dataTaskCopy,
        onChange,
        selectedBaseTaskType,
        setSelectedBaseTaskType,
        baseTaskTypes,
        setBaseTaskTypes,
        selectedPlanAPPCC,
        setSelectedPlanAPPCC,
        planAPPCCOptions,
        validations,
        showUpdateModal,
        setShowUpdateModal,
        handleEditBaseTask,
        requiredPlanAPPCC,
        isBaseTaskPopoverLoading,
        onChangeInputValue,
        baseTaskOptions,
        isBaseTaskPopoverVisible,
        setIsBaseTaskPopoverVisible,
        handleSubmitWithSameTitle,
        setShowBaseTaskTitleModal,
        showBaseTaskTitleModal,
        onFocusInputValue,
    };
};
