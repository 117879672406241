import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { CustomerInstanceModel } from "app/models/01-SEG/CustomerInstance/CustomerInstanceModel";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";

export default class InstanceService {
    public static async GetInstances(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<CustomerInstanceModel>>({
            url: `${ApiPaths.SEG_API}/customerInstances`,
            paginationParams: paginationParams,
        });
    }

    public static async GetInstancesExport() {
        return FetchService.get<PaginatedResult<CustomerInstanceModel>>({
            url: `${ApiPaths.SEG_API}/customerInstances/export`,
            csvExport: true,
        });
    }

    public static async GetInstance(instanceId: string | number) {
        return FetchService.get<CustomerInstanceModel>({
            url: `${ApiPaths.SEG_API}/customerInstances/${instanceId}`,
        });
    }

    public static async AreInitialsUsed(initials: string) {
        return FetchService.get<boolean>({
            url: `${ApiPaths.SEG_API}/customerInstances/AreInitialsUsed/${initials}`,
        });
    }

    public static async SaveInstance(body: CustomerInstanceModel) {
        return FetchService.post<number>({
            url: `${ApiPaths.SEG_API}/customerInstances`,
            body: body,
        });
    }

    public static async DeleteInstance(instanceId: string | number) {
        return FetchService.delete<string>({
            url: `${ApiPaths.SEG_API}/customerInstances/${instanceId}`,
        });
    }

    public static async EditInstance(instanceId: string | number, body: CustomerInstanceModel) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/customerInstances/${instanceId}`,
            body: body,
        });
    }
}
