import { calculateStar } from "app/helpers/taskpill/calculateStar";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import {
    compareTwoDates,
    convertUTCtoLocaleDate,
    getDayMonthAndHourFormatedShort,
    getDayMonthName,
    getHoursFromDateUTC,
} from "app/helpers/Date.utilities";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { ErrorConstants } from "app/shared/errorConstants";
import { faArrowRotateLeft, faTriangleExclamation, faWarning } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    GenericModalFooter,
    ModalFooterButtonProps,
} from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { getAvatarInfo } from "app/helpers/taskInstance/getAvatarInfo";
import { getTaskInstanceHour } from "app/helpers/taskInstance/getTaskInstanceHour";
import { hasPermissionToEdit, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { ImageToSendTaskDetailDto } from "app/dtos/02-TAR/TaskInstance/ImageToSendTaskDetailDto";
import { IssueAssetsModal } from "app/components_v2/__modals/IssueAssetsModal/IssueAssetsModal";
import { IssueAssetsModalContext } from "app/state/context/issueAssetsModalContext/issueAssetsModalContext";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { IssueTypeCode, PrivatePaths, SecScreen, TaskStatus, screenSize } from "app/shared/Constants";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { TaskCheckList } from "./TaskCheckList/TaskCheckList";
import { TaskComments } from "./TaskComments";
import { TaskDetailDynamicFields } from "app/components_v2/TaskDetailDynamicFields/TaskDetailDynamicFields";
import { TaskFields } from "./TaskFields/TaskFields";
import { TaskInstanceCheckListModel } from "app/models/02-TAR/TaskInstance/TaskInstanceCheckListModel";
import { TaskInstanceFeedbackMessageModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFeedbackMessageModel";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { taskInstancePhotoBadge } from "app/helpers/taskInstance/getTaskInstancePhotoBadge";
import {
    TaskInstanceTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { TaskPill } from "app/components_v2/TaskPill/TaskPill";
import { UPLOAD_FILE_TASK_ERRORS_DETAIL } from "../constants/uploadFilesErrors";
import { useCompleteTaskInstance, useSession, useWindowSize } from "app/hooks";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { validateStatus } from "app/helpers/__validates/validateTaskpillStatus";
import i18next from "i18next";
import TaskInstanceCheckListService from "app/services/02-TAR/TaskInstanceCheckListService";
import TaskInstanceService from "app/services/02-TAR/TaskInstanceService";
import { taskInstancePhotoModelToImageDragFile } from "app/mappers/TaskInstancePhotoModelToImageDragFile";
import { convertAndCompressImages } from "app/helpers/dragFile/convertAndCompressImage";
import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";
import { DragFile } from "app/components_v2/__draggableFiles/DragFile/DragFile";
import { getCompletedDate } from "app/helpers/taskpill/getCompletedDate";

type TaskDetailProps = {
    taskInstance?: TaskInstanceModel;
    setTaskInstance: (ti: TaskInstanceModel) => void;
    onCancel?: () => void;
    isFeedback?: boolean;
    onComplete?: () => void;
    editable?: boolean;
    isReviewPhotos?: boolean;
    onStarsChange?: () => void;
    hideFeedBack?: boolean;
    editStarRating?: boolean;
    onLoading?: (value: boolean) => void;
    showValoratedBy?: boolean;
    allowOpenIssue?: boolean;
};

type avatarUserProps = {
    id: number;
    url: string;
    name: string;
};

export const TaskDetail: FC<TaskDetailProps> = ({
    taskInstance,
    setTaskInstance,
    onCancel,
    isFeedback,
    onComplete,
    editable,
    isReviewPhotos,
    onStarsChange,
    editStarRating,
    onLoading,
    showValoratedBy,
    allowOpenIssue = true,
}) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const { handleToast } = useToast();
    const session = useSession();
    const { getErrorMessage } = useFetchErrors();
    const nav = useNavigate();
    const { open } = useContext(IssueModalContext);
    const { isOpen: isIssueAssetsOpen } = useContext(IssueAssetsModalContext);
    const location = useLocation();
    const offset = session?.user.timeZoneOffset;

    const [isReopenModalOpen, setIsReopenModalOpen] = useState<boolean>(false);
    const [isConfirmModalOpenEmtpyOptionals, setIsConfirmModalOpenEmtpyOptionals] = useState<boolean>(false);

    const [userAvatar, setUserAvatar] = useState<avatarUserProps | undefined>(undefined);
    const [fields, setFields] = useState<TaskInstanceFieldModel[]>(taskInstance?.taskInstanceFields2 || []);
    const [taskCheckList, setTaskCheckList] = useState<TaskInstanceCheckListModel[]>(
        taskInstance?.taskInstanceCheckList || []
    );
    const [dragFileImages, setDragFileImages] = useState<ImageDragFile[]>(
        taskInstancePhotoModelToImageDragFile(taskInstance)
    );

    const {
        handleTaskComplete,
        customErrors,
        imageCustomError,
        firstError,
        errorFound,
        loading,
        completeTaskInstance,
        checkListModalOpen,
        setcheckListModalOpen,
        dynamicFieldsErrors,
        setTemperatureOutOfRangeModalIsOpen,
        temperatureOutOfRangeModalIsOpen,
    } = useCompleteTaskInstance(setTaskInstance, onComplete, setIsConfirmModalOpenEmtpyOptionals, onLoading);

    const [loadingButton, setLoadingButton] = useState<boolean>(false);

    const errorRefs = useRef<HTMLDivElement[]>([]);

    const editedBy = taskInstance?.editedBy;
    const asignedToUserId = taskInstance?.asignedToUserId;
    const sessionUserId = session?.user.id;
    const feedbackMessage = taskInstance?.taskInstanceFeedbackMessage;
    const feedbackId = feedbackMessage ? feedbackMessage.id : undefined;
    const userType = taskInstance?.userType;

    const registerInputRef = (ref: HTMLDivElement | null) => {
        if (ref && !errorRefs.current.includes(ref)) errorRefs.current.push(ref);
    };

    useEffect(() => {
        if (firstError) {
            const errorInputRef = errorRefs.current.find((ref) => {
                const input = ref.querySelector("input") as HTMLInputElement;
                return input.id === firstError;
            });
            if (errorInputRef) {
                handleToast({
                    title: t(TranslationKeys.TASK_FIELDS_INCOMPLETE),
                    variant: "warning",
                });
                errorInputRef.scrollIntoView({ behavior: "smooth", inline: "start" });
                const input = errorInputRef.querySelector("input") as HTMLInputElement;
                input.focus({ preventScroll: true });
            }
        }
    }, [errorFound]);

    const customSetFields = (newField: TaskInstanceFieldModel) => {
        setFields((prev) => prev.map((field) => (field.id === newField.id ? newField : field)));
    };

    const setComment = (feedback: TaskInstanceFeedbackMessageModel) => {
        const comment = {
            name: feedback.message?.length || showValoratedBy ? feedback.senderUserName : "",
            date: feedback.message?.length ? getDayMonthName(feedback.sendDate?.toString() || "") : undefined,
            comment: feedback.message,
        };
        return comment;
    };
    const handleStateChange = async () => {
        if (taskInstance?.statusCode === TaskStatus.COMPLETED) !isFeedback && setIsReopenModalOpen(true);
        else if (!isFeedback) {
            const resp = await handleTaskComplete(taskInstance, fields, dragFileImages, taskCheckList);
            if (resp) onComplete && onComplete();
        }
    };

    const handleCompleteTask = async (e?: React.MouseEvent<HTMLElement>) =>
        await handleTaskComplete(taskInstance, fields, dragFileImages, taskCheckList, errorRefs, e);

    const handleConfirmModal = () => {
        completeTaskInstance(taskInstance, fields);
        setcheckListModalOpen(false);
        setIsConfirmModalOpenEmtpyOptionals(false);
        setTemperatureOutOfRangeModalIsOpen(false);
    };

    const { starColorFeedback, starSizeFeedback } = calculateStar({
        taskIFeedbackMsg: feedbackMessage,
        userId: sessionUserId,
        isMyTask: editedBy === sessionUserId || (asignedToUserId === sessionUserId && !editedBy),
        starColor: isFeedback ? "yellow" : undefined,
        starSize: isFeedback ? "xs" : undefined,
        userType: session?.user.userType,
        taskUserType: userType,
    });

    const onSelectCheckList = async (isActive: boolean | null, taskCheckListId: number, taskInstanceId: number) => {
        if (taskInstance?.statusCode === TaskStatus.PENDING) {
            const { status, getParsedError } = await TaskInstanceCheckListService.Edit(
                taskCheckListId,
                taskInstanceId,
                { value: isActive }
            );

            if (!status()) {
                handleToast({
                    title: getErrorMessage(getParsedError()),
                    type: "alert",
                    variant: "danger",
                });
                if (
                    getParsedError() === ErrorConstants.TASK_INSTANCE_CHECKLIST_NOT_YOURS ||
                    getParsedError() === ErrorConstants.TASK_INSTANCE_NOT_YOURS
                ) {
                    nav(`/${PrivatePaths.DASHBOARD}`);
                    return;
                }
                return;
            }

            setTaskCheckList((prev) =>
                prev.map((taskCheck) =>
                    taskCheck.taskCheckListId === taskCheckListId ? { ...taskCheck, isActive } : taskCheck
                )
            );
            if (!taskInstance?.asignedToUserId && status() && session) {
                setUserAvatar({
                    name: `${session.user.firstName} ${session.user.lastName}`,
                    id: session.user.id,
                    url: session.user.profilePictureURL || "",
                });
            }
        }
    };

    const isDisabled: boolean =
        taskInstance?.taskType !== "SPORADIC" &&
        !!taskInstance?.startDate &&
        convertUTCtoLocaleDate(taskInstance?.startDate) > new Date();

    const showCompleteButtons = taskInstance?.statusCode === TaskStatus.PENDING && !isFeedback && editable;

    const showReopenButton =
        taskInstance?.statusCode === TaskStatus.COMPLETED && session?.user.id === taskInstance?.editedBy && !isFeedback;

    const reopenButton: ModalFooterButtonProps = {
        text: t(TranslationKeys.TASK_DETAIL_REOPEN_TASK),
        iconLeft: faArrowRotateLeft,
        onClick: handleStateChange,
        buttonType: "tertiary",
        fullWidth: width < screenSize.TABLET,
    };
    const completeTaskButton: ModalFooterButtonProps = {
        text: t(TranslationKeys.COMPLETE_TASK),
        iconLeft: faCheck,
        fullWidth: width < screenSize.TABLET,
        disabled: isDisabled || !editable,
        onClick: handleCompleteTask,
    };
    const cancelButton: ModalFooterButtonProps = {
        text: t(TranslationCommon.CANCEL),
        onClick: onCancel,
        buttonType: "tertiary",
    };

    const confirmButton: ModalFooterButtonProps = showCompleteButtons ? completeTaskButton : reopenButton;

    const canReopenTask =
        showReopenButton &&
        hasPermissionToView(session, SecScreen.RESET_TASK) &&
        editedBy === session?.user.id &&
        compareTwoDates(taskInstance.finishedDate ?? new Date(), new Date());

    const showDragFile =
        !isDisabled &&
        taskInstance?.isPhotoRequired &&
        taskInstance.statusCode !== TaskStatus.COMPLETED &&
        !isFeedback &&
        editable;

    const showFooterButttons = (ti: TaskInstanceModel) => {
        // Si es una tasca esporàdica no ha de sortir el botó de reobre
        if (ti.statusCode === TaskStatus.COMPLETED && ti.deadlineDate == null) return false;

        return (
            (canReopenTask || (ti.statusCode === TaskStatus.PENDING && (showCompleteButtons || showReopenButton))) &&
            location.pathname.includes(PrivatePaths.DASHBOARD)
        );
    };

    const handleReopenTaskInstance = async () => {
        if (!taskInstance) return;
        if (!canReopenTask) {
            handleToast({
                title: t(TaskInstanceTranslations.CANNOT_REOPEN_TASK_INSTANCE),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setLoadingButton(true);

        const { status, getParsedError } = await TaskInstanceService.ReopenTask(taskInstance.id);
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsReopenModalOpen(false);
            setLoadingButton(false);
            onComplete && onComplete();
            return;
        }
        setDragFileImages([]);
        setIsReopenModalOpen(false);
        setLoadingButton(false);
        onComplete && onComplete();
    };

    // IMAGES
    const handlePhotoChanges = async (photos: ImageDragFile[]) => {
        if (!taskInstance) return;

        setDragFileImages((prev) => [...prev, ...photos]);
        const photosToSend: ImageToSendTaskDetailDto[] = await convertAndCompressImages(photos);
        const base64Record: Record<string, string> = {};
        photosToSend.forEach((photo) => (base64Record[photo.id] = photo.imageBase64Content));

        const { status, errorResponse, getParsedError, data } = await TaskInstanceService.UploadPhotos(
            taskInstance.id,
            photosToSend
        );

        if (!status()) {
            if (UPLOAD_FILE_TASK_ERRORS_DETAIL.includes(errorResponse.errors[0].errorCode)) {
                handleToast({
                    title: getErrorMessage(getParsedError()),
                    type: "alert",
                    variant: "danger",
                });
                // nav(`${PrivatePaths.DASHBOARD}`);
                return;
            }

            setDragFileImages((prev) =>
                prev.map((photo) => {
                    return {
                        ...photo,
                        isLoading: false,
                        isErrored: photos.some((x) => x.id === photo.id) ? true : photo.isErrored,
                        dbId: undefined,
                    };
                })
            );
            return;
        }

        setDragFileImages((prev) =>
            prev.map((photo) => {
                const imageCorrectlySaved = data.find(({ frontId }) => frontId === photo.id);
                return {
                    ...photo,
                    isLoading: false,
                    isErrored: !imageCorrectlySaved && photos.some((x) => x.id === photo.id) ? true : photo.isErrored,
                    dbId: imageCorrectlySaved ? imageCorrectlySaved.taskPhotoId : photo.dbId,
                    base64: !!base64Record[photo.id]?.length ? base64Record[photo.id] : photo.base64,
                };
            })
        );

        if (!taskInstance?.asignedToUserId && session) {
            setUserAvatar({
                name: `${session.user.firstName} ${session.user.lastName}`,
                id: session.user.id,
                url: session.user.profilePictureURL || "",
            });
        }
    };

    const handleDeleteImage = async (photoId: string) => {
        const imageToDelete = dragFileImages.find(({ id }) => photoId === id);
        if (!imageToDelete) return;
        if (imageToDelete.isErrored) {
            setDragFileImages((prev) => prev.filter(({ id }) => id !== photoId));
            return;
        }
        if (!imageToDelete.dbId) return;

        setDragFileImages((prev) => prev.map((image) => ({ ...image, isLoading: image.id === photoId })));
        const sr = await TaskInstanceService.DeletePhoto(taskInstance!.id, imageToDelete.dbId);
        if (!sr.status()) {
            handleToast({
                title: getErrorMessage(sr.getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return false;
        }
        setDragFileImages((prev) => prev.filter(({ id }) => id !== photoId));
        return true;
    };

    const handleEditImage = async (image: ImageDragFile, editedImageId: string) => {
        const imageToDelete = dragFileImages.find(({ id }) => editedImageId === id);
        if (!imageToDelete) return;
        if (!imageToDelete.dbId) return;
        if (!image.base64) return;

        setDragFileImages((prev) => prev.map((photo) => (photo.id === editedImageId ? image : photo)));

        const { status, getParsedError, data } = await TaskInstanceService.EditPhoto(
            taskInstance!.id,
            imageToDelete.dbId,
            {
                id: image.id,
                imageBase64Content: image.base64 || "",
                name: image.fileName,
            }
        );

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setDragFileImages((prev) =>
                prev.map((photo) =>
                    photo.id === editedImageId ? { ...photo, isLoading: false, isErrored: true } : photo
                )
            );
            return false;
        }

        setDragFileImages((prev) =>
            prev.map((photo) =>
                photo.id === editedImageId
                    ? { ...photo, dbId: data.taskPhotoId, url: data.taskPhoto, isLoading: false }
                    : { ...photo, isLoading: false }
            )
        );
    };
    // END IMAGES

    return (
        <>
            {isIssueAssetsOpen && <IssueAssetsModal onFinish={handleConfirmModal} />}

            {taskInstance && (
                <div className="taskDetail">
                    {isDisabled && (
                        <div className="taskDetail__disabledText">
                            <FontAwesomeIcon icon={faWarning} className="taskDetail__disabledText__icon" />

                            <p>{t(TranslationKeys.TASK_IS_DISABLED)}</p>
                        </div>
                    )}
                    <div className="taskDetail__container">
                        <div className="taskDetail__container__column flexStart">
                            {temperatureOutOfRangeModalIsOpen && (
                                <ConfirmModal
                                    onClose={() => setTemperatureOutOfRangeModalIsOpen(false)}
                                    onConfirm={handleConfirmModal}
                                    title={t(TranslationKeys.TEMPERATURE_OUT_OF_RANGE)}
                                    description={t(TranslationKeys.TEMPERATURE_OUT_OF_RANGE)}
                                    onCloseText={t(TranslationCommon.CANCEL)}
                                    onConfirmText={t(TranslationKeys.COMPLETE_TASK)}
                                />
                            )}
                            {isReopenModalOpen && (
                                <ConfirmModal
                                    onClose={() => setIsReopenModalOpen(false)}
                                    onConfirm={handleReopenTaskInstance}
                                    title={t(TranslationKeys.TASK_DETAIL_REOPEN_TASK_TITLE)}
                                    description={t(TranslationKeys.TASK_DETAIL_REOPEN_TASK_DESC)}
                                    onCloseText={t(TranslationKeys.TASK_DETAIL_REOPEN_TASK_BTN_CLOSE)}
                                    onConfirmText={
                                        loadingButton
                                            ? t(TranslationModals.REOPENING_TASK_CONFIRM_TEXT)
                                            : t(TranslationKeys.TASK_DETAIL_REOPEN_TASK_BTN_OPEN)
                                    }
                                    isLoading={loadingButton}
                                />
                            )}
                            {isConfirmModalOpenEmtpyOptionals && (
                                <ConfirmModal
                                    onClose={() => setIsConfirmModalOpenEmtpyOptionals(false)}
                                    onConfirm={handleConfirmModal}
                                    title={t(TranslationKeys.EMPTY_OPTIONALS)}
                                    description={t(TranslationKeys.EMPTY_OPTIONALS_DESCRIPTION)}
                                    onCloseText={t(TranslationCommon.CANCEL)}
                                    onConfirmText={t(TranslationKeys.COMPLETE_TASK)}
                                />
                            )}

                            {checkListModalOpen && (
                                <ConfirmModal
                                    onClose={() => setcheckListModalOpen(false)}
                                    onConfirm={handleConfirmModal}
                                    title={t(TranslationModals.INACTIVE_CHECKLIST_ITEMS_TITLE)}
                                    description={t(TranslationModals.INACTIVE_CHECKLIST_ITEMS_DESCRIPTION)}
                                    onCloseText={t(TranslationCommon.CANCEL)}
                                    onConfirmText={t(TranslationKeys.COMPLETE_TASK)}
                                />
                            )}

                            {taskInstance && (
                                <TaskPill
                                    isSporadic={
                                        taskInstance.taskType === "SPORADIC" || taskInstance.deadlineDate != null
                                    }
                                    taskType={taskInstance.taskType}
                                    editStarRating={editStarRating}
                                    showMobileTaskPillOnly
                                    hidePadding={!isDisabled}
                                    user={userAvatar || getAvatarInfo(taskInstance, session)}
                                    assignedToUserId={taskInstance.asignedToUserId}
                                    hour={getTaskInstanceHour(taskInstance, offset)}
                                    completedDate={getCompletedDate(taskInstance, t)}
                                    onStarsChange={onStarsChange}
                                    title={taskInstance.taskName}
                                    valorationMessage={
                                        taskInstance.taskInstanceFeedbackMessage &&
                                        taskInstance.taskInstanceFeedbackMessage.message
                                    }
                                    audioURL={
                                        taskInstance.taskInstanceFeedbackMessage &&
                                        taskInstance.taskInstanceFeedbackMessage.audioURL
                                    }
                                    date={
                                        taskInstance.deadlineDate != null
                                            ? getDayMonthAndHourFormatedShort(
                                                  convertUTCtoLocaleDate(
                                                      taskInstance.deadlineDate,
                                                      offset ? offset * -60 : 0
                                                  ),
                                                  i18next.language
                                              )
                                            : undefined
                                    }
                                    state={validateStatus(taskInstance)}
                                    isCritical={taskInstance.isCritical}
                                    comment={
                                        taskInstance.taskInstanceFeedbackMessage &&
                                        setComment(taskInstance.taskInstanceFeedbackMessage)
                                    }
                                    stars={
                                        taskInstance.taskInstanceFeedbackMessage &&
                                        taskInstance.taskInstanceFeedbackMessage.rating
                                    }
                                    carrouselPhotos={
                                        taskInstance.taskInstancePhotos &&
                                        taskInstance.statusCode === TaskStatus.COMPLETED
                                            ? taskInstance.taskInstancePhotos.map(({ taskPhoto }) => taskPhoto)
                                            : taskInstance.fotoExample
                                            ? [taskInstance.fotoExample]
                                            : undefined
                                    }
                                    displayOnly
                                    description={taskInstance.taskDescription}
                                    hideFeedback={!isFeedback && !isReviewPhotos}
                                    oneColumn
                                    starColor={
                                        !hasPermissionToEdit(SecScreen.TASK_REVIEW) ? "yellow" : starColorFeedback
                                    }
                                    starSize={!hasPermissionToEdit(SecScreen.TASK_REVIEW) ? "xs" : starSizeFeedback}
                                    taskInstanceId={taskInstance.id}
                                    feedbackId={feedbackId}
                                    updateTaskInstance={setTaskInstance}
                                    disabled={isDisabled}
                                    isDisabledRange={
                                        taskInstance.taskType !== "SPORADIC"
                                            ? taskInstance.startDate &&
                                              convertUTCtoLocaleDate(taskInstance.startDate) > new Date()
                                            : false
                                    }
                                    startHour={
                                        taskInstance?.startDate
                                            ? getHoursFromDateUTC(convertUTCtoLocaleDate(taskInstance.startDate))
                                            : undefined
                                    }
                                    isMyFeedback={
                                        taskInstance.taskInstanceFeedbackMessage &&
                                        taskInstance.taskInstanceFeedbackMessage.senderUserId === session?.user.id
                                    }
                                    carrouselBadge={taskInstancePhotoBadge(taskInstance, t)}
                                    asignedToWorkingPositionId={taskInstance.asignedToWorkingPositionId}
                                />
                            )}
                            {hasPermissionToView(session, SecScreen.OPEN_MANUAL_ISSUE) && allowOpenIssue && (
                                <div className="taskDetail__openIssue">
                                    <NewButton
                                        text={
                                            taskInstance.issueCount > 0
                                                ? t(TranslationKeys.OPEN_ANOTHER_ISSUE)
                                                : t(TranslationKeys.OPEN_ISSUE)
                                        }
                                        iconLeft={faTriangleExclamation}
                                        buttonType="secondary"
                                        fullWidth={width < screenSize.TABLET}
                                        handleClickButton={() => {
                                            open({ issueType: IssueTypeCode.TASK, taskinstanceId: taskInstance.id });
                                        }}
                                    />
                                </div>
                            )}
                            {!!taskInstance?.taskInstanceFields2?.length &&
                            taskInstance?.taskInstanceFields2[0]?.asset2 != null ? (
                                <TaskFields
                                    fields={fields}
                                    customSetFields={customSetFields}
                                    customErrors={customErrors}
                                    readOnly={
                                        isFeedback || !editable || taskInstance.statusCode === TaskStatus.COMPLETED
                                    }
                                    errorRef={registerInputRef}
                                    disabled={isDisabled}
                                    statusCode={taskInstance.statusCode}
                                />
                            ) : (
                                !!taskInstance?.taskInstanceFields2?.length && (
                                    <TaskDetailDynamicFields
                                        onChange={setFields}
                                        taskinstanceFields={taskInstance?.taskInstanceFields2}
                                        readonly={
                                            isFeedback ||
                                            !editable ||
                                            taskInstance.statusCode === TaskStatus.COMPLETED ||
                                            isDisabled
                                        }
                                        errors={dynamicFieldsErrors}
                                    />
                                )
                            )}

                            {!!taskInstance?.taskInstanceCheckList?.length && (
                                <TaskCheckList
                                    taskInstanceCheckList={taskInstance.taskInstanceCheckList}
                                    onSelect={onSelectCheckList}
                                    readOnly={
                                        isDisabled ||
                                        isFeedback ||
                                        !editable ||
                                        taskInstance.statusCode === TaskStatus.COMPLETED
                                    }
                                />
                            )}
                        </div>
                        <div className="taskDetail__container__column">
                            {showDragFile && (
                                <Collapsable isCollapsable={false} title={t(TranslationKeys.ATTACHED_FILES)}>
                                    <DragFile
                                        name={t(TranslationKeys.PHOTOGRAPHIC_REGISTER)}
                                        placeholder={t(TranslationKeys.CLICK_TO_UPLOAD)}
                                        subPlaceholder={t(TranslationKeys.UPLOAD_TYPE)}
                                        onChange={handlePhotoChanges}
                                        onDelete={handleDeleteImage}
                                        images={dragFileImages}
                                        disabled={isDisabled}
                                        captureMode={
                                            hasPermissionToView(session, SecScreen.ALLOW_GALERY_PHOTO)
                                                ? "both"
                                                : "camera"
                                        }
                                        errorMessage={imageCustomError}
                                        onEditImage={handleEditImage}
                                        editable
                                        multiple
                                    />
                                </Collapsable>
                            )}
                            <TaskComments taskInstance={taskInstance} />
                        </div>
                    </div>
                    {showFooterButttons(taskInstance) && (
                        <GenericModalFooter
                            confirmButton={{
                                ...confirmButton,
                                disabled: confirmButton.disabled || dragFileImages.some(({ isLoading }) => isLoading),
                            }}
                            closeButton={showCompleteButtons ? cancelButton : undefined}
                            loading={loading}
                            buttonsJustification="end"
                        />
                    )}
                </div>
            )}
        </>
    );
};
