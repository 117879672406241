import { DateRange } from "app/models/utilities";
import { CloseCompanyTranslations } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "../__inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { AddNewButton } from "../__buttons/AddNewButton/AddNewButton";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

type CloseRangeDatesProps = {
    closeDates?: DateRange[];
    onClosingDates: (dates: Date[] | undefined[], index: number) => void;
    onAddNewRangeHour: () => void;
    onDeleteNewRangeHour: (index: number) => void;
    errorMessage: string;
};

export const CloseRangeDates: FC<CloseRangeDatesProps> = ({
    closeDates,
    onAddNewRangeHour,
    onClosingDates,
    onDeleteNewRangeHour,
    errorMessage,
}) => {
    const { t } = useTranslation();

    const canAddNewRangeDate = closeDates == null || closeDates.length < 3;

    return (
        <div className="closeRangeDates">
            <div className="closeRangeDates">
                {closeDates != null &&
                    closeDates.map(({ "0": closeDate, "1": openDate }, i) => (
                        <div key={i} className="closeRangeDates__select">
                            <DateRangePicker
                                onChange={(dates) => onClosingDates(dates, i)}
                                startDate={closeDate ? new Date(closeDate) : undefined}
                                endDate={openDate ? new Date(openDate) : undefined}
                                range={true}
                                disabled={!hasPermissionToEdit(SecScreen.CENTERS_MANAGEMENT)}
                            />
                            {
                                <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="deleteInputIcon"
                                    onClick={() => onDeleteNewRangeHour(i)}
                                />
                            }
                        </div>
                    ))}
                {canAddNewRangeDate && (
                    <div className="closeRangeDates__button">
                        <AddNewButton onClick={onAddNewRangeHour}>
                            {t(CloseCompanyTranslations.ADD_NEW_CLOSE_DATE_RANGE)}
                        </AddNewButton>
                    </div>
                )}
            </div>
            {!!errorMessage.length && <ErrorMessage errorMessage={errorMessage} />}
        </div>
    );
};
