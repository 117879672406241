import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { Input } from "app/components_v2/__inputs/Input/Input";
import { useToast } from "app/hooks/Toast/useToast";
import { ProfileService } from "app/services";
import { PublicPaths, RegexPatterns } from "app/shared/Constants";
import { TranslationErrors, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const RecoverPassword = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const nav = useNavigate();
    const { t } = useTranslation();

    const [email, setEmail] = useState<string>("");
    const [errorEmail, setErrorEmail] = useState<string>("");
    const { handleToast } = useToast();

    const onFinish = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) return;
        setLoading(true);
        const sr = await ProfileService.SendRecoverPasswordEmail({ email, password: "", token: "" });
        if (!sr.status()) {
            setLoading(false);
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        handleToast({
            title: t(TranslationModals.RESTABLISH_PASSWORD_TITLE_TOAST),
            subtitle: t(TranslationModals.RESTABLISH_PASSWORD_SUBTITLE_TOAST),
            type: "alert",
            variant: "primary",
        });
        nav(PublicPaths.LOGIN);
    };

    const validateForm = () => {
        setErrorEmail("");
        if (!new RegExp(RegexPatterns.mail).test(email)) {
            setErrorEmail(t(TranslationErrors.RESTABLISH_PASSWORD_EMAIL_ERROR_MESSAGE));
            return true;
        }

        return false;
    };

    return (
        <form onSubmit={onFinish} className="loginInputForm">
            <h3 className="recoverPassword__title">{t(TranslationKeys.RECOVER_PASSWORD)}</h3>
            <div className="recoverPassword__container">
                <Input
                    onChange={(newValue) => setEmail(newValue)}
                    value={email}
                    label={t(TranslationKeys.RECOVER_PASSWORD_EMAIL)}
                    placeholder={t(TranslationKeys.RECOVER_PASSWORD_PLACEHOLDER_EMAIL)}
                    errorMessage={errorEmail}
                />
                <div className="recoverPassword__container__footer">
                    <NewButton
                        text={t(TranslationKeys.RECOVER_PASSWORD).toUpperCase()}
                        fullWidth
                        loading={loading}
                        type="submit"
                    />
                    <p
                        className="recoverPassword__container__footer__backToLogin"
                        onClick={() => {
                            nav(`${PublicPaths.LOGIN}`);
                        }}
                    >
                        {t(TranslationKeys.BACK_TO_LOGIN)}
                    </p>
                </div>
            </div>
        </form>
    );
};
