import { FC, useState } from "react";
import {
    IssueAssetsModalContext,
    IIssueAssetOpen,
} from "app/state/context/issueAssetsModalContext/issueAssetsModalContext";
import { IssueAssetValues, ProviderProps } from "../types";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";

export const IssueAssetsModalProvider: FC<ProviderProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showCorrectiveMeassures, setShowCorrectiveMeassures] = useState<boolean>(false);
    const [taskInstanceId, setTaskInstanceId] = useState<number | null>(null);
    const [assetsOutOfRange, setAssetsOutOfRange] = useState<TaskInstanceFieldModel[]>([]);
    const [assetValues, setAssetValues] = useState<Record<number, IssueAssetValues[]>>([]);

    const open = ({ assetsOutOfRange, hasPlan, taskinstanceId }: IIssueAssetOpen) => {
        const formattedFields: Record<number, IssueAssetValues[]> = {};

        assetsOutOfRange.forEach(({ fK_Asset2, assetFieldRange, taskInstanceFieldValues, label, asset2 }) => {
            if (!fK_Asset2) return;
            if (!formattedFields[fK_Asset2]) {
                formattedFields[fK_Asset2] = [];
            }
            formattedFields[fK_Asset2].push({
                label: asset2?.name || "",
                min: assetFieldRange?.min || 0,
                max: assetFieldRange?.max || 0,
                unit: assetFieldRange?.unit || "",
                value: Number(taskInstanceFieldValues[0].value),
                fk_Asset: Number(fK_Asset2),
                assetDynamicFieldLabel: label,
                issueCount: asset2?.issueCount || 0,
            });
        });
        setAssetValues(formattedFields);

        setIsOpen(true);
        setTaskInstanceId(taskinstanceId);
        setAssetsOutOfRange(assetsOutOfRange);
        setShowCorrectiveMeassures(hasPlan);
    };

    const reset = () => {
        setIsOpen(false);
        setTaskInstanceId(null);
        setAssetsOutOfRange([]);
        setShowCorrectiveMeassures(false);
    };

    return (
        <IssueAssetsModalContext.Provider
            value={{ assetsOutOfRange, isOpen, open, reset, taskInstanceId, assetValues, showCorrectiveMeassures }}
        >
            {children}
        </IssueAssetsModalContext.Provider>
    );
};
