import { FC, useState } from "react";
import { ProviderProps } from "../types";
import { CloseCompanyModalContext } from "app/state/context/CloseCompanyModalContext/CloseCompanyModalContext";
import { CloseCompaniesAndDepartmentsDtoModel } from "app/dtos/01-SEG/CloseCompaniesAndDepartmentsDtoModel/CloseCompaniesAndDepartmentsDtoModel";

export const INITIAL_CLOSED_COMPANIES_AND_DEPARTMENTS: CloseCompaniesAndDepartmentsDtoModel = {
    closedCompanies: [],
    openCompanies: [],
    closedDepartments: [],
    openDepartments: [],
};

export const CloseCompanyModalProvider: FC<ProviderProps> = ({ children }) => {
    const [closeOpenCompaniesAndDepartments, setCloseOpenCompaniesAndDepartments] =
        useState<CloseCompaniesAndDepartmentsDtoModel>(INITIAL_CLOSED_COMPANIES_AND_DEPARTMENTS);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const open = (closeOpenCompaniesAndDepartments: CloseCompaniesAndDepartmentsDtoModel) => {
        setIsVisible(true);
        setCloseOpenCompaniesAndDepartments(closeOpenCompaniesAndDepartments);
    };

    const reset = () => {
        setIsVisible(false);
        setCloseOpenCompaniesAndDepartments(INITIAL_CLOSED_COMPANIES_AND_DEPARTMENTS);
    };

    return (
        <CloseCompanyModalContext.Provider
            value={{
                closeOpenCompaniesAndDepartments,
                isOpen: isVisible,
                open,
                reset,
            }}
        >
            {children}
        </CloseCompanyModalContext.Provider>
    );
};
