import { IssueTypeCodeModel } from "app/shared/types/IssueTypes";
import { TranslationErrors } from "app/translation/translationKeys";

export const getIssueTableErrorCode = (issueType: IssueTypeCodeModel, isOpen?: boolean | null) => {
    if (issueType === "TASK" && isOpen === null) return TranslationErrors.TABLE_ERROR_ISSUE_TASK;
    if (issueType === "TASK" && isOpen) return TranslationErrors.TABLE_ERROR_ISSUE_TASK_OPEN;
    if (issueType === "TASK" && !isOpen) return TranslationErrors.TABLE_ERROR_ISSUE_TASK_CLOSE;

    if (issueType === "TASK_ASSET" && isOpen === null) return TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET;
    if (issueType === "TASK_ASSET" && isOpen) return TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET_OPEN;
    if (issueType === "TASK_ASSET" && !isOpen) return TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET_CLOSE;

    if (issueType === "GENERIC" && isOpen === null) return TranslationErrors.TABLE_ERROR_ISSUE_GENERIC;
    if (issueType === "GENERIC" && isOpen) return TranslationErrors.TABLE_ERROR_ISSUE_GENERIC_OPEN;
    if (issueType === "GENERIC" && !isOpen) return TranslationErrors.TABLE_ERROR_ISSUE_GENERIC_CLOSE;

    if (issueType === "AUDIT" && isOpen === null) return TranslationErrors.TABLE_ERROR_ISSUE_AUDIT;
    if (issueType === "AUDIT" && isOpen) return TranslationErrors.TABLE_ERROR_ISSUE_AUDIT_OPEN;
    return TranslationErrors.TABLE_ERROR_ISSUE_AUDIT_CLOSE;
};
