import { ChangeEvent, MouseEvent, FC, useRef, useState, FocusEvent, KeyboardEvent } from "react";
import { ErrorIcon } from "app/components_v2/ErrorIcon/ErrorIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Hint } from "../Hint/Hint";
import { v4 } from "uuid";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { validateNumberLength } from "app/helpers/__validates/validateNumberLength";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { Label } from "../Label";
import { ReadOnlyInput } from "../ReadOnlyInput/ReadOnlyInput";
import { ReadOnlyType, ReadOnlyVariants } from "../ReadOnlyInput/types";

export type inputTextTypes = "number" | "text" | "password" | "email";
export type ReadOnlyInputOptions = {
    icon?: { value: IconProp; variant?: ReadOnlyVariants };
    type?: ReadOnlyType;
};

export type InputProps = {
    inputId?: string;
    disabled?: boolean;
    value?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    type?: inputTextTypes;
    iconLeft?: IconProp;
    iconRight?: IconProp;
    label?: string;
    errorMessage?: string;
    hint?: string;
    readonly?: boolean;
    onClickIcon?: () => void;
    transparent?: boolean;
    onBlur?: () => void;
    focus?: boolean;
    min?: number;
    inverted?: boolean;
    showBorderColor?: boolean;
    max?: number;
    onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
    whiteInput?: boolean;
    size?: "md" | "xs";
    onClick?: (e: MouseEvent<HTMLInputElement, globalThis.MouseEvent>) => void;
    isOptional?: boolean;
    readOnlyOptions?: ReadOnlyInputOptions;
    onFocus?: (e: FocusEvent<HTMLInputElement, Element>) => void;
};

export const Input: FC<InputProps> = ({
    inputId,
    disabled,
    onChange,
    placeholder,
    value,
    type = "text",
    iconLeft,
    iconRight,
    label,
    errorMessage,
    hint,
    readonly = false,
    onClickIcon,
    transparent,
    onBlur,
    focus,
    min,
    inverted,
    showBorderColor,
    max,
    onPressEnter,
    whiteInput,
    size = "md",
    onClick,
    isOptional,
    readOnlyOptions,
    onFocus,
}) => {
    const htmlFor = useRef<string>(v4());
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isFocused, setIsFocused] = useState<boolean>(!!focus);
    const [isHintVisible, setIsHintVisible] = useState<boolean>(false);
    const [showPwd, setShowPwd] = useState(false);
    const isPwd = useRef<boolean>(type === "password");
    const { t } = useTranslation();

    const handleChangeHint = (value: boolean) => {
        setIsHintVisible(value);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (type === "number" && (inputValue === "-" || !inputValue.length)) {
            onChange(inputValue);
            return;
        }
        if (type === "number" && max && !validateNumberLength(Number(inputValue), 10)) return;
        if (type === "text" && max && inputValue.length > max) return;
        onChange(inputValue);
    };

    const handleRightIconClick = (e: any) => {
        e.stopPropagation();
        type === "password"
            ? setShowPwd(!showPwd)
            : hint
            ? handleChangeHint(!isHintVisible)
            : onClickIcon && onClickIcon();
    };

    const handleRightIcon = () => {
        if (type === "password") {
            return showPwd ? faEyeSlash : faEye;
        }
        return hint ? faCircleQuestion : iconRight;
    };

    const righIcon = handleRightIcon();

    if (readonly)
        return (
            <ReadOnlyInput
                value={value || ""}
                label={label}
                icon={readOnlyOptions?.icon}
                type={readOnlyOptions?.type}
                placeholder={placeholder}
            />
        );

    return (
        <div
            className={`inputContainer${disabled ? "--disabled" : ""}`}
            onBlur={() => {
                if (!disabled) {
                    setIsFocused(false);
                    onBlur && onBlur();
                }
            }}
            onClick={onClick}
        >
            {label && (
                <div className="inputTextWrapper__labelContainer">
                    <Label
                        label={`${label} ${isOptional ? `(${t(TranslationKeys.INPUT_OPTIONAL)})` : ""}`}
                        disabled={disabled}
                        htmlFor={inputId || htmlFor.current}
                        onLabelClick={() => {
                            !disabled && setIsFocused(true);
                        }}
                    />
                    {hint && type === "password" && (
                        <FontAwesomeIcon
                            icon={faCircleQuestion}
                            className={`hintIcon${inverted ? "--inverted" : ""}`}
                            onClick={() => handleChangeHint(!isHintVisible)}
                        />
                    )}
                </div>
            )}
            <div
                className={`inputTextWrapper${isFocused ? "--focus" : ""}${disabled ? "--disabled" : ""} ${
                    !disabled && (!!errorMessage?.length || showBorderColor) ? "errored" : ""
                }  ${transparent ? "transparent" : ""} ${whiteInput ? "whiteInput" : ""} ${
                    size ? `inputTextWrapper--${size}` : ""
                }`}
                data-testid="input-container"
                onClick={() => inputRef.current?.focus()}
                onMouseDownCapture={() => !disabled && setIsFocused(true)}
                onFocus={() => !disabled && setIsFocused(true)}
            >
                {iconLeft && (
                    <div className="inputTextWrapper__iconwrapper">
                        <FontAwesomeIcon
                            icon={iconLeft}
                            className={`inputTextWrapper__iconwrapper__icon${isFocused ? "--focus" : ""}`}
                        />
                    </div>
                )}
                <div className={`inputTextWrapper__wrapper ${transparent ? "transparent" : ""}`}>
                    <input
                        ref={inputRef}
                        type={showPwd ? "text" : type}
                        value={value}
                        onChange={handleChange}
                        className={`inputTextWrapper__wrapper__input ${transparent ? "transparent" : ""} ${
                            whiteInput ? "inputTextWrapper__wrapper__input--white" : ""
                        }`}
                        placeholder={placeholder}
                        id={inputId || htmlFor.current}
                        disabled={disabled}
                        onFocus={(e) => {
                            !disabled && setIsFocused(true);
                            onFocus && onFocus(e);
                        }}
                        readOnly={readonly}
                        autoFocus={focus}
                        onKeyDown={(e) => e.key === "Enter" && onPressEnter && onPressEnter(e)}
                        autoComplete={type === "password" ? "true" : "false"}
                        inputMode="text"
                        data-testid="input-element"
                    />
                </div>
                {!!errorMessage?.length && !disabled && !isPwd.current ? (
                    <ErrorIcon />
                ) : (
                    righIcon && (
                        <div
                            className="inputTextWrapper__iconwrapper__right"
                            onMouseDownCapture={(e) => {
                                e.preventDefault();
                                !disabled && setIsFocused(true);
                            }}
                        >
                            <FontAwesomeIcon
                                data-testid="eye-icon"
                                icon={righIcon}
                                className={`inputTextWrapper__iconwrapper__icon${
                                    !!errorMessage?.length && !disabled && isPwd.current ? "--error" : ""
                                }${isFocused ? "--focus" : ""} ${!disabled ? "pointer" : ""} ${
                                    whiteInput ? "whiteInput--icon" : ""
                                }`}
                                onClick={handleRightIconClick}
                            />
                        </div>
                    )
                )}
            </div>
            {!!errorMessage?.length && !disabled ? (
                <ErrorMessage errorMessage={errorMessage} />
            ) : (
                hint && isHintVisible && <Hint text={hint} />
            )}
        </div>
    );
};
