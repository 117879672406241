import { useContext, useEffect, useState } from "react";
import { useSession } from "../useSession";
import { useFetchErrors } from "../useFetchErrors";
import { useToast } from "../Toast/useToast";
import { useWizard } from "../useWizard";
import { v4 } from "uuid";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { IssueAssetsModel } from "app/components_v2/__modals/IssueAssetsModal/types";
import { IssueAssetsModalContext } from "app/state/context/issueAssetsModalContext/issueAssetsModalContext";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import RepSelectorService from "app/services/03-REP/RepSelectorService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { getInitials } from "app/helpers/getInitials";

export const useIssueAssetsModal = () => {
    const { assetsOutOfRange, reset, taskInstanceId, assetValues, showCorrectiveMeassures } =
        useContext(IssueAssetsModalContext);

    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const session = useSession();

    const [totalSteps, setTotalSteps] = useState(Object.keys(assetValues).length + 1);

    const [headerItems, setHeaderItems] = useState<{ title?: string }[]>([
        ...Object.values(assetValues).map(() => ({ title: "" })),
        { title: "" },
    ]);

    const { currentStepIndex, onStepChange, goTo, prev, next } = useWizard(totalSteps);

    const [data, setData] = useState<IssueAssetsModel[]>(
        Object.values(assetValues).map(([asset2]) => ({
            correctiveMeassures: [],
            description: "",
            id: v4(),
            fK_Asset: Number(asset2.fk_Asset),
            attachments: [],
            fK_IssueClassification: "-1",
        }))
    );

    const [correctiveMeassureOptions, setCorrectiveMeassureOptions] = useState<OptionModel[]>([]);

    const [supervisors, setSupervisors] = useState<OptionModel[]>([]);
    const [supervisorsOptions, setSupervisorsOptions] = useState<OptionModel[]>([]);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const [isFormSubmiting, setIsFormSubmiting] = useState<boolean>(false);

    const handleIssueChange = (fieldUpdated: IssueAssetsModel) => {
        setData((prev) => prev.map((field) => (fieldUpdated.id === field.id ? fieldUpdated : field)));
    };

    const getData = async () => {
        setIsDataLoading(true);
        const correctiveMeassurePromise = QuaSelectorService.GetCorrectiveMeassures();
        const supervisorParams = `userId=${session?.user.id}`;
        const supervisorsAndAnalystsPromise = RepSelectorService.GetSupervisorAndAnalyst({
            extraParams: supervisorParams,
        });
        Promise.all([correctiveMeassurePromise, supervisorsAndAnalystsPromise])
            .then(([correctiveMeassureSr, supervisorsAndAnalystsSr]) => {
                correctiveMeassureResponseManager(correctiveMeassureSr);
                supervisorsAndAnalistsResponseManager(supervisorsAndAnalystsSr);
            })
            .finally(() => {
                setIsDataLoading(false);
            });
    };

    const correctiveMeassureResponseManager = ({ data, status, getParsedError }: ServiceResponse<OptionModel[]>) => {
        if (!status()) {
            handleToast({
                variant: "danger",
                title: getErrorMessage(getParsedError()),
                type: "alert",
            });
            setCorrectiveMeassureOptions([]);
            return;
        }

        setCorrectiveMeassureOptions(data);
    };

    const supervisorsAndAnalistsResponseManager = ({
        data,
        status,
        getParsedError,
    }: ServiceResponse<OptionModel[]>) => {
        if (!status()) {
            handleToast({
                variant: "danger",
                title: getErrorMessage(getParsedError()),
                type: "alert",
            });
            setSupervisorsOptions([]);
            return;
        }

        if (!data?.length) {
            const headerItemsWithoutLastStep = headerItems.map((item, i) =>
                headerItems.length - 1 === i ? { title: undefined } : item
            );
            setHeaderItems(headerItemsWithoutLastStep);
            setTotalSteps((prev) => prev - 1);
        }

        setSupervisorsOptions(
            data.map(({ label, value, profilePictureURL }) => ({
                label,
                value,
                profilePictureURL,
                initials: getInitials(label),
            }))
        );
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        correctiveMeassureOptions,
        supervisorsOptions,
        isDataLoading,
        isFormSubmiting,
        assetsOutOfRange,
        data,
        onIssueChange: handleIssueChange,
        reset,
        wizard: { currentStepIndex, onStepChange, goTo, prev, next, headerItems, totalSteps },
        onIssueSupervisorsChange: setSupervisors,
        supervisors,
        taskInstanceId,
        setIsFormSubmiting,
        assetValues: Object.values(assetValues),
        showCorrectiveMeassures,
    };
};
