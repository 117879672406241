import { FC } from "react";
import { IssueBodyAssetField } from "../IssueBodyAssetField/IssueBodyAssetField";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";

export type IssueBodyAssetFieldListProps = {
    taskInstanceFields2?: TaskInstanceFieldModel[];
};

export const IssueBodyAssetFieldList: FC<IssueBodyAssetFieldListProps> = ({ taskInstanceFields2 }) => {
    return (
        <ul className="issueBodyAssetFieldList">
            {taskInstanceFields2?.map(({ asset2, taskInstanceFieldValues, label, id, assetFieldRange }) => {
                if (!asset2) return null;

                const value = taskInstanceFieldValues[0].value;
                if (!assetFieldRange) return null;

                return (
                    <IssueBodyAssetField assetFieldRange={assetFieldRange} fieldValue={value} name={label} key={id} />
                );
            })}
        </ul>
    );
};
