import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType } from "app/components_v2/Table/types";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { EmailDistributionListModel } from "app/models/01-SEG/EmailDistributionList/EmailDistributionListModel";
import {
    EmailDistributionListTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { faCirclePlus, faPen, faTemperatureList, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { MobileEmailDistributionListRow } from "./components/MobileEmailDistributionListRow/MobileEmailDistributionListRow";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { useEmailDistributionList } from "./hooks/useEmailDistributionList";
import { useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import { CreateEmailDistributionListUsersModal } from "./components/CreateEmailDistributionListUsersModal/CreateEmailDistributionListUsersModal";
import { EditCreateEmailDistributionListUsersModal } from "./components/EditEmailDistributionListUsersModal/EditEmailDistributionListUsersModal";

export const EmailDistributionListGrid: FC = () => {
    const { t } = useTranslation();

    useTitle(t(TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE));

    const {
        emailDistributionList,
        filterIcon,
        handleSubmit,
        isLoading,
        onChangePageIndex,
        onChangeSortDirectionField,
        onClickEdit,
        onClickDelete,
        onChangeCompany,
        onDelete,
        pageIndex,
        selectCompany,
        showCreateModal,
        showDeleteModal,
        showEditModal,
        sortDirection,
        sortField,
        total,
        currentEmailDistributionList,
        onAddNew,
        onClickOutsideCompany,
        onChangeShowCreateModal,
        onChangeShowEditModal,
        onChangeShowDeleteModal,
        onClickFilterIcon,
    } = useEmailDistributionList();

    const columns: ColumnsType<EmailDistributionListModel>[] = [
        {
            dataIndex: "Functionality",
            label: t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_FUNCTIONALITY_LABEL),
            sortedType: "default",
            render: ({ functionality }) => <CellTitle title={t(functionality)} />,
        },
        {
            dataIndex: "FK_Company",
            alignCenter: true,
            label: t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_COMPANY_GRID_LABEL),
            sortedType: "default",
            render: ({ companyName }) => (
                <div className="emailDistributionList__center">
                    <CellTitle title={companyName || "-"} />
                </div>
            ),
        },
        {
            dataIndex: "UserList",
            alignCenter: true,
            label: t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_USER_COUNT_LABEL),
            render: ({ userList }) => (
                <div className="emailDistributionList__center">
                    <Badge title={String(userList.length)} variant="blue" />
                </div>
            ),
        },
        {
            dataIndex: "actions",
            className: "emailDistributionList__actions",
            alignCenter: true,
            label: t(TranslationKeys.ACTIONS),
            render: ({ fK_Company, fK_DistributionList, userList }) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => onClickEdit(fK_Company, fK_DistributionList, userList),
                            hidden: !hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST),
                        },
                        {
                            icon: faTrash,
                            onClick: () => onClickDelete(fK_Company, fK_DistributionList, userList),
                            hidden: !hasPermissionToDelete(SecScreen.DISTRIBUTION_LIST),
                        },
                    ]}
                />
            ),
            hidden:
                !hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST) &&
                !hasPermissionToDelete(SecScreen.DISTRIBUTION_LIST),
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faCirclePlus,
            onClick: onAddNew,
            hidden: !hasPermissionToAdd(SecScreen.DISTRIBUTION_LIST),
        },
        {
            icon: filterIcon,
            onClick: onClickFilterIcon,
            inputComponent: {
                component: (
                    <TableSelectCompany
                        onChange={onChangeCompany}
                        value={selectCompany.company}
                        onClickOutside={onClickOutsideCompany}
                    />
                ),
                show: selectCompany.isOpen,
            },
        },
    ];

    return (
        <>
            {showCreateModal && (
                <CreateEmailDistributionListUsersModal
                    title={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_CREATE_USERS_MODAL_TITLE)}
                    onClose={() => onChangeShowCreateModal(false)}
                    onSubmit={(values) => handleSubmit(values, "create")}
                />
            )}
            {showEditModal && emailDistributionList && (
                <EditCreateEmailDistributionListUsersModal
                    emailDistributionList={currentEmailDistributionList}
                    title={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_EDIT_USERS_MODAL_TITLE)}
                    onClose={() => onChangeShowEditModal(false)}
                    onSubmit={(values) => handleSubmit(values, "edit")}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    title={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_DELETE_TITLE_LABEL)}
                    description={t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_DELETE_DESCRIPTION_LABEL)}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={onDelete}
                    onClose={() => onChangeShowDeleteModal(false)}
                    type="delete"
                />
            )}
            <PageContainer paddingTop>
                <TableCollapsable
                    title={`${t(TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE)} ${
                        selectCompany.company.value === "-1" ? `(${t(TranslationKeys.ALL_COMPANIES)})` : ""
                    }`}
                    cols={columns}
                    data={emailDistributionList}
                    total={total}
                    placeholder={<TableError icon={faTemperatureList} />}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    onDoubleClick={({ fK_Company, fK_DistributionList, userList }) =>
                        onClickEdit(fK_Company, fK_DistributionList, userList)
                    }
                    onChangePageIndex={onChangePageIndex}
                    onChangeSortDirectionField={onChangeSortDirectionField}
                    sortDirection={sortDirection}
                    sortField={sortField}
                    actionButtons={actionButtons}
                    mobileBody={(row) => (
                        <MobileEmailDistributionListRow
                            data={row}
                            onClickEdit={onClickEdit}
                            onClickDelete={onClickDelete}
                        />
                    )}
                />
            </PageContainer>
        </>
    );
};
