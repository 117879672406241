import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "../../../shared/Constants";
import PrivateRoute from "../../../routes/PrivateRoute";
import UserGrid from "./Components/UserGrid";
import { NewUserTabs } from "./NewUserTabs";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";

const UsersPage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_USERS);

    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
            <Route path="" element={<PrivateRoute component={UserGrid} viewCode={SecScreen.USER_MANAGEMENT} />}></Route>
            <Route
                path="new"
                element={<PrivateRoute component={NewUserTabs} viewCode={SecScreen.USER_MANAGEMENT} />}
            ></Route>
            <Route
                path="edit/:userId"
                element={<PrivateRoute component={NewUserTabs} viewCode={SecScreen.USER_MANAGEMENT} />}
            ></Route>
        </Routes>
    );
};

export default UsersPage;
