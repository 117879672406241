import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorInputValuesModel, IDownLoadCsvReturn, InputValuesModel, IuseExportCsvModal } from "../types";
import { TranslationErrors } from "app/translation/translationKeys";
import { PaginationDefaults } from "app/shared/Constants";

const INITIAL_INPUT_VALUES: InputValuesModel = {
    rows: "",
    allRegistres: false,
    startPage: "",
    actualPage: false,
};

const INITIAL_ERROR_INPUT_VALUES: ErrorInputValuesModel = {
    rows: "",
    startPage: "",
};

export const useExportCsvModal = ({ pageIndex, total }: IuseExportCsvModal) => {
    const { t } = useTranslation();
    const [inputValues, setInputValues] = useState<InputValuesModel>(INITIAL_INPUT_VALUES);
    const [errorInputValues, setErrorInputValues] = useState<ErrorInputValuesModel>(INITIAL_ERROR_INPUT_VALUES);
    const { actualPage, allRegistres, rows, startPage } = inputValues;

    const handleInputChange = (values: Partial<InputValuesModel>) => setInputValues((prev) => ({ ...prev, ...values }));

    const handleErrorsChange = (values: Partial<ErrorInputValuesModel>) =>
        setErrorInputValues((prev) => ({ ...prev, ...values }));

    const handleDownloadCsv = (): IDownLoadCsvReturn | undefined => {
        if (handleValidateForm()) return;
        const values: IDownLoadCsvReturn = { pageIndex: undefined, pageSize: undefined };
        if (allRegistres) {
            values.pageIndex = undefined;
            values.pageSize = total;
        } else if (actualPage) {
            values.pageIndex = pageIndex ? pageIndex - 1 : 0;
            values.pageSize = PaginationDefaults.PAGE_SIZE;
            values.totalRows = Number(rows);
        } else {
            values.pageIndex = Number(startPage) - 1;
            values.pageSize = PaginationDefaults.PAGE_SIZE;
            values.totalRows = Number(rows);
        }

        return values;
    };

    const handleValidateForm = () => {
        handleErrorsChange({ ...INITIAL_ERROR_INPUT_VALUES });
        const rowIsEmpty = !rows.length;
        const rowIsLowerThanZero = Number(rows) <= 0;
        const startPageIsEmpty = !startPage.length;
        const startPageIsLowerThanZero = Number(startPage) <= 0;
        if (allRegistres) return false;

        if (rowIsEmpty) {
            handleErrorsChange({
                rows: t(TranslationErrors.GENERIC_ERROR_MESSAGE_VALUES_IS_OBLIGATORY),
            });
            return true;
        }
        if (rowIsLowerThanZero) {
            handleErrorsChange({
                rows: t(TranslationErrors.GENERIC_ERROR_MESSAGE_VALUES_MUST_BE_GREATER_THAN_ZERO),
            });
            return true;
        }
        if (actualPage) return false;

        if (startPageIsEmpty) {
            handleErrorsChange({
                startPage: t(TranslationErrors.GENERIC_ERROR_MESSAGE_VALUES_IS_OBLIGATORY),
            });
            return true;
        }
        if (startPageIsLowerThanZero) {
            handleErrorsChange({
                startPage: t(TranslationErrors.GENERIC_ERROR_MESSAGE_VALUES_MUST_BE_GREATER_THAN_ZERO),
            });
            return true;
        }

        return false;
    };

    return {
        inputValues,
        ...inputValues,
        errorInputValues,
        handleInputChange,
        handleErrorsChange,
        handleDownloadCsv,
        handleValidateForm,
    };
};
