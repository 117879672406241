import { isDropdownDynamicField } from "app/helpers/dynamicFields/isDropdownDynamicField";
import { useDynamicFieldsErrorHandler } from "app/hooks/dynamicFields/useDynamicFieldsErrorHandler";
import { useToast } from "app/hooks/Toast/useToast";
import { TaskfieldOptionsError } from "app/models/02-TAR/Task/EditTask";
import { OptionsSearch } from "app/models/FormComponentsModel";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { t } from "i18next";
import { FormEvent, useState } from "react";
import { INITIAL_BASE_TASK_VALIDATIONS } from "../constants/editBaseTaskConstants";
import { BaseTaskFormModel, BaseTaskValidationModel } from "../models/EditBaseTaskFormModels";

export const useEditBaseTaskErrors = (
    dataTask: BaseTaskFormModel,
    selectedBaseTaskType: OptionsSearch,
    selectedPlanAPPCC: OptionsSearch,
    requiredPlanAPPCC: boolean
) => {
    const { handleToast } = useToast();
    const { filterTaskFieldsWithoutErrorMessage, filterTaskCheckListWithoutErrorMessage, taskfieldOptionsDropdown } =
        useDynamicFieldsErrorHandler();

    const [validations, setValidations] = useState<BaseTaskValidationModel>(INITIAL_BASE_TASK_VALIDATIONS);

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;
        const aux = { ...validations };

        aux.taskTitle = "";
        if (!dataTask.taskTitle.length) {
            aux.taskTitle = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        aux.baseTaskType = "";
        if (!selectedBaseTaskType.label.length) {
            aux.baseTaskType = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        aux.planAPPCC = "";
        if (requiredPlanAPPCC && !selectedPlanAPPCC.label.length) {
            aux.planAPPCC = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        aux.reportData = "";
        if (dataTask.reportType === "DYNAMIC_FIELD") {
            const dynamicFields = dataTask.dynamicFields.filter(({ isDeleted }) => !isDeleted);
            if (!dynamicFields.length) {
                aux.reportData = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
                check = false;
            }

            aux.dynamicFields = dynamicFields
                .map(({ id, label, dynamicFieldsType, taskFieldOptions }) => ({
                    dynamicFieldsType: dynamicFieldsType,
                    errorMessage: !label.length ? t(TranslationCommon.INPUT_NOT_EMPTY) : "",
                    id: id,
                    taskfieldOptions: isDropdownDynamicField(dynamicFieldsType)
                        ? taskfieldOptionsDropdown(taskFieldOptions)
                        : [],
                }))
                .filter(filterTaskFieldsWithoutErrorMessage);
            if (aux.dynamicFields.length) check = false;
        }

        aux.checklist = [];
        if (dataTask.reportType === "CHECKLIST") {
            const checklist = dataTask.checkList.filter(({ isDeleted }) => !isDeleted);

            const errorChecklist: TaskfieldOptionsError[] = checklist
                .map(({ id, name }) => ({
                    errorMessage: !name.length ? t(TranslationKeys.CHECKLIST_CANNOT_BE_EMPTY) : "",
                    id,
                }))
                .filter(filterTaskCheckListWithoutErrorMessage);

            aux.checklist = errorChecklist;
            if (aux.checklist.length) check = false;
        }

        setValidations({ ...aux });
        if (!check) {
            handleToast({
                title: t(TranslationModals.FAILED_SAVE_TASK_TITLE),
                subtitle: t(TranslationModals.FAILED_SAVE_TASK_DESCRIPTION),
                variant: "danger",
                type: "alert",
            });
            return false;
        }

        return true;
    };

    return {
        validations,
        validate,
    };
};
