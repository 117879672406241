import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { useGoBack, useSession } from "app/hooks";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { useLogOut } from "app/hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { PrivatePaths } from "app/shared/Constants";

export type ErrorTypeModel =
    | "404"
    | "500"
    | "no company"
    | "AppFailedToken"
    | "accessDenied"
    | "no permisions"
    | "underMainteniance"
    | string;

const WentWrong: FC<{ errorType?: ErrorTypeModel }> = ({ errorType }) => {
    const { t } = useTranslation();
    const session = useSession();
    const nav = useNavigate();
    const { handleParentGoBack } = useGoBack();
    const handleLoogout = useLogOut();

    const actionButton = () => {
        if (errorType === "404") {
            handleParentGoBack();
            return;
        }
        if (errorType === "no permisions" && session?.appSource === undefined) {
            handleLoogout();
            return;
        }
        if (errorType === "accessDenied") {
            nav(`/${session?.user.initialScreenCode?.toLocaleLowerCase() || PrivatePaths.DASHBOARD}`);
            return;
        }
        window.location.reload();
    };

    const getStatus = () => {
        return errorType === "404" || errorType === "500" ? `${errorType} error` : "error";
    };

    const getSubTitle = () => {
        return errorType === "404"
            ? t(TranslationCommon.NOT_FOUND)
            : errorType === "500"
            ? t(TranslationCommon.SOMETHING_WENT_WRONG)
            : errorType === "no company"
            ? t(TranslationErrors.NO_COMPANY_ASSIGNED)
            : errorType === "AppFailedToken"
            ? t(TranslationErrors.APP_FAILED_TOKEN)
            : errorType === "accessDenied"
            ? t(TranslationCommon.ACCESS_DENIED)
            : errorType === "no permisions"
            ? t(TranslationErrors.NO_PERMISSIONS_SUBTITLE)
            : errorType === "underMainteniance"
            ? t(TranslationErrors.ERROR_UNDER_MAINTENIANCE_SUBTITLE)
            : t(TranslationCommon.RELOAD);
    };

    const getButtonLabel = () => {
        return errorType === "404" || errorType === "accessDenied"
            ? t(TranslationCommon.GO_BACK)
            : errorType === "no permisions" && session?.appSource === undefined
            ? t(TranslationCommon.LOGOUT)
            : t(TranslationCommon.RELOAD);
    };

    const getTitle = () => {
        return errorType === "404"
            ? t(TranslationErrors.PAGE_NOT_FOUND_TITLE)
            : errorType === "no company"
            ? t(TranslationErrors.NO_COMPANY_ASSIGNED_TITLE)
            : errorType === "no permisions"
            ? t(TranslationErrors.NO_PERMISSIONS_TITLE)
            : errorType === "underMainteniance"
            ? t(TranslationErrors.ERROR_UNDER_MAINTENIANCE_TITLE)
            : t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE);
    };

    return (
        <div className="errorPage">
            <div className="errorPage__container">
                <div className="errorPage__heading">
                    <div className="errorPage__heading__subHeading">
                        <p className="errorPage__heading__subHeading__errorCode">{getStatus()}</p>
                        <h1 className="errorPage__heading__subHeading__title">{getTitle()}</h1>
                    </div>
                    <p className="errorPage__heading__description">{getSubTitle()}</p>
                </div>
                {!session?.appSource && (
                    <div className="errorPage__actions">
                        <NewButton buttonType="primary" text={getButtonLabel()} handleClickButton={actionButton} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default WentWrong;
