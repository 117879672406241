import { FC } from "react";
import { HeaderModalProps } from "../GenericModal";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "app/components_v2/Icon/Icon";

export const GenericModalHeader: FC<HeaderModalProps> = ({
    title,
    headerChildren,
    headerImage,
    onClose,
    actionButton,
    radius,
    variant = "primary",
}) => {
    return (
        <div
            className={`genericModalHeader genericModalHeader--${variant} ${
                radius ? "genericModalHeader--radius" : ""
            }`}
            style={{ backgroundImage: headerImage ? `url(${headerImage})` : "none" }}
        >
            <div className="genericModalHeader__container">
                <div className="flex--center gap--20">
                    <p className={`genericModalHeader__title${actionButton ? "--withIcon" : ""}`}>{title}</p>

                    {actionButton && (
                        <Icon
                            dataTestId="genericModalSecondaryButton"
                            icon={actionButton.icon}
                            onClick={actionButton.onClick}
                            size="sm"
                        />
                    )}
                </div>
                <Icon icon={faXmark} onClick={onClose} dataTestId="genericModalCloseButton" size="sm" />
            </div>
            {headerChildren}
        </div>
    );
};
