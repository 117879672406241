import { FC } from "react";
import { MobileTaskRowHeader } from "./MobileTaskRowHeader/MobileTaskRowHeader";
import { MobileTaskBody } from "./MobileTaskBody/MobileTaskBody";
import { selectBadgeTitle } from "app/helpers/task/selectBadgeTitle";
import { useTranslation } from "react-i18next";
import { convertUTCtoLocaleDate, getHourSecWithString } from "app/helpers";
import { TableTaskPillProps } from "./type";

export const MobileTaskRow: FC<TableTaskPillProps> = ({
    data,
    isChecked,
    onChangeCheckbox,
    selectedTab,
    rowPosition,
}) => {
    const { t } = useTranslation();

    const {
        taskRangeHours,
        fK_User,
        fK_Department,
        fK_Subdepartment,
        user,
        department,
        subdepartment,
        profilePictureURL,
        taskScheduler,
        name,
        isPhotoRequired,
        isCritical,
        hasCheckList,
        hasDataToReport,
        workingPosition,
        fK_WorkingPosition,
    } = data;

    const handleClickTask = () => {
        isChecked !== undefined && onChangeCheckbox && onChangeCheckbox(!isChecked);
    };

    const formatHours = () => {
        if (!taskRangeHours) return "-";
        let title = "";
        taskRangeHours.forEach(({ hour, maxHour }) => {
            title += `${getHourSecWithString(convertUTCtoLocaleDate(new Date(hour)), "h")}${
                maxHour ? ` - ${getHourSecWithString(convertUTCtoLocaleDate(new Date(maxHour)), "h")}` : ""
            } / `;
        });
        title = title.slice(0, -2);

        return title;
    };

    const getColorId = () =>
        selectedTab === "user"
            ? fK_User
            : selectedTab === "department"
            ? fK_Department
            : selectedTab === "workingPosition"
            ? fK_WorkingPosition
            : fK_Subdepartment;

    const rowHeaderName: Record<string, string | null> = {
        user: user,
        department: department,
        workingPosition: workingPosition,
        subdepartment: subdepartment,
    };

    const getImage = () => (selectedTab === "user" ? profilePictureURL : "");

    return (
        <div
            className={`mobileTaskRow--${rowPosition} ${isChecked ? "mobileTaskRow--focused" : ""}`}
            onClick={handleClickTask}
        >
            <div className="mobileTaskRow__container">
                <MobileTaskRowHeader
                    data={data}
                    colorId={getColorId() || 0}
                    name={rowHeaderName[selectedTab] || ""}
                    img={getImage() || undefined}
                    isChecked={isChecked}
                    onChangeCheckbox={onChangeCheckbox}
                    badgeTitle={selectBadgeTitle(taskScheduler?.typeCode, t)}
                    badgeVariant={taskScheduler?.typeCode === "ONE_TIME" ? "blue" : "grey"}
                    selectedTab={selectedTab}
                />
                <MobileTaskBody
                    hours={formatHours()}
                    name={name || ""}
                    hasCheckList={hasCheckList}
                    isPhotoRequired={isPhotoRequired}
                    isCritical={isCritical}
                    hasTaskFields={hasDataToReport}
                />
            </div>
        </div>
    );
};
