import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewButton } from "app/components_v2/__buttons/NewButton/NewButton";
import { Input } from "app/components_v2/__inputs/Input/Input";
import {
    getUsersLogin,
    setTokenAndRefreshTokenExpiration,
    setUserLogin,
} from "app/helpers/BrowserStorage/LocalStorageHandler";
import { isUserTypeAdmin } from "app/helpers/userTypeCheck";
import { useToast } from "app/hooks/Toast/useToast";
import { LoginRequestModel } from "app/models/00-LOGIN/Auth/LoginRequestModel";
import AuthService from "app/services/00-LOGIN/AuthService";
import { CookieKey, PrivatePaths, PublicPaths } from "app/shared/Constants";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

type LoginFormValues = {
    username: string;
    password: string;
};

type LoginErrorFormValues = {
    username: string;
    password: string;
};

const initialFormValues: LoginFormValues = {
    username: "",
    password: "",
};

const initialErrorFormValues: LoginErrorFormValues = {
    username: "",
    password: "",
};

export const Login: FC = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const nav = useNavigate();
    const [searchParams] = useSearchParams();
    const usersIds: number[] = getUsersLogin();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<LoginFormValues>({
        username: searchParams.get("username") || initialFormValues.username,
        password: initialFormValues.password,
    });

    const [erroFormValues, setErroFormValues] = useState<LoginErrorFormValues>(initialErrorFormValues);
    const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);

    const handleChange = (values: Partial<LoginFormValues>) => setFormValues((prev) => ({ ...prev, ...values }));

    const handleErrorsChange = (values: Partial<LoginErrorFormValues>) =>
        setErroFormValues((prev) => ({ ...prev, ...values }));

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) return;

        setIsLoading(true);
        const values: LoginRequestModel = { ...formValues, browserFingerprint: "web" };

        const sr = await AuthService.Login(values);
        if (sr.status()) {
            document.cookie = `${CookieKey.REFRESH_TOKEN}=${sr.data.refreshToken}; path=/; expires=${new Date(
                sr.data.refreshTokenExpiration
            )}`;
            setInvalidCredentials(false);

            setUserLogin(sr.data.userId);
            setTokenAndRefreshTokenExpiration({
                refreshTokenExpiration: new Date(sr.data.refreshTokenExpiration).toISOString(),
                tokenExpiration: new Date(sr.data.tokenExpiration).toISOString(),
            });

            const qr = localStorage.getItem("qrCode");
            const goToQr = `/${PrivatePaths.DASHBOARD}/qr/${qr}`;
            const goToSelectDepartment = `/${PrivatePaths.SELECT_DEPARTMENT}`;
            const goToLicenses = `/${PrivatePaths.LICENSE_PAGE}`;
            window.location.replace(
                isUserTypeAdmin(sr.data.userType) ? goToLicenses : qr ? goToQr : goToSelectDepartment
            );
            return;
        } else {
            if (sr.getParsedError() === "LOGIN_INVALID_CREDENTIALS") {
                handleToast({
                    title: t(TranslationModals.TOAST_ERROR_LOGIN),
                    variant: "danger",
                });
                setInvalidCredentials(true);
            } else if (sr.getParsedError() === "USER_NOT_EXIST") {
                handleToast({
                    title: t(TranslationModals.TOAST_ERROR_USER_NOT_FOUND_LOGIN),
                    variant: "danger",
                });
                setInvalidCredentials(true);
            } else {
                console.error(sr.getParsedError());
                handleToast({ title: t(TranslationModals.TOAST_GENERIC_ERROR), variant: "danger" });
            }
        }

        setIsLoading(false);
    };

    const validateForm = () => {
        let error = false;
        handleErrorsChange({ ...initialErrorFormValues });
        if (formValues.username.length < 1) {
            handleErrorsChange({ username: t(TranslationKeys.EMPTY_LOGIN_USERNAME) });
            setInvalidCredentials(false);
            error = true;
        }
        if (formValues.password.length < 1) {
            handleErrorsChange({ password: t(TranslationKeys.EMPTY_LOGIN_PASSWORD) });
            setInvalidCredentials(false);
            error = true;
        }

        return error;
    };

    return (
        <form autoComplete="off" className="loginInputForm" onSubmit={onSubmit}>
            {!!usersIds.length && (
                <Link to={`${PublicPaths.SELECT_PROFILE}`} className="selectProfiles__addUser">
                    <FontAwesomeIcon icon={faArrowLeft} className="selectProfiles__addUser__icon" />
                    {t(TranslationKeys.LOGIN_CHANGE_PROFILE)}
                </Link>
            )}
            <h1 className="loginInputForm__title">{t(TranslationKeys.LOGIN_TITLE)}</h1>
            <div className="loginInputForm__container">
                <div className="loginInputForm__container__inputs">
                    <Input
                        label={t(TranslationKeys.LOGGIN_USERNAME)}
                        placeholder={t(TranslationKeys.LOGGIN_PLACEHOLDER_USERNAME)}
                        onChange={(newValue) => handleChange({ username: newValue })}
                        errorMessage={erroFormValues.username}
                        showBorderColor={invalidCredentials}
                        value={formValues.username}
                        focus={!searchParams.get("username")}
                    />
                    <div className="loginInputForm__passwordItem">
                        <Input
                            type="password"
                            label={t(TranslationKeys.LOGGIN_PASSWORD)}
                            placeholder={t(TranslationKeys.LOGGIN_PLACEHOLDER_PASSWORD)}
                            onChange={(newValue) => handleChange({ password: newValue })}
                            errorMessage={erroFormValues.password}
                            showBorderColor={invalidCredentials}
                            value={formValues.password}
                            focus={!!searchParams.get("username")}
                        />
                        <p
                            className="loginInputForm__passwordItem__recoverPassword"
                            onClick={() => {
                                nav(`${PublicPaths.RECOVER_PASSWORD}`);
                            }}
                        >
                            {t(TranslationKeys.FORGOT_PASSWORD)}
                        </p>
                    </div>
                </div>

                <NewButton text={t(TranslationKeys.LOGIN)} type="submit" fullWidth loading={isLoading} />
            </div>
        </form>
    );
};
