import { FC } from "react";
import { TaskPillAvatar } from "../TaskPillAvatar/TaskPillAvatar";
import { TaskPillDate } from "../TaskPillDate/TaskPillDate";
import { TaskPillAvatar as TaskPillAvatarType } from "../type";

export type TaskPillBodyDesktopProps = {
    user: TaskPillAvatarType;
    title: string;
    nameInitials?: string;
    description?: string;
    isDisabledRange?: boolean;
    date?: string;
    completedDate?: string;
    startHour?: string;
};

export const TaskPillBodyDesktop: FC<TaskPillBodyDesktopProps> = ({
    user,
    nameInitials,
    title,
    description,
    date,
    completedDate,
    isDisabledRange,
    startHour,
}) => {
    return (
        <div className="taskPill__section__wrapper">
            <div className="taskPill__section">
                <TaskPillAvatar user={user} nameInitials={nameInitials} />
            </div>
            <div className="taskpillBodyDesktop__body">
                <p className="taskPill__section__title text_clamp">{title}</p>
                {description && <p className="taskPill__section__taskDescription">{description}</p>}
            </div>
            {(date || completedDate || startHour) && (
                <div className="taskPill__section--last">
                    <TaskPillDate
                        isDisabledRange={isDisabledRange}
                        date={date}
                        completedDate={completedDate}
                        startHour={startHour}
                    />
                </div>
            )}
        </div>
    );
};
