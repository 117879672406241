import { TypeCodeTask } from "app/models/02-TAR/Task/TaskBody";
import { TaskSchedulerModel } from "app/models/02-TAR/Task/TaskSchedulerModel";
import { RecurrentTaskValuesModel, RecurentTaskCustomValues, CustomDays } from "app/models/02-TAR/TaskWizard";
import i18n from "app/translation/i18n";
import { TranslationKeys } from "app/translation/translationKeys";

// Te dice si la tarea recurrente es custom o no
export const selectRecurringTaskValue = (typeCode: TypeCodeTask): RecurrentTaskValuesModel => {
    switch (typeCode) {
        case "MONTHLY":
            return "everyMonth";
        default:
            return "custom";
    }
};

// Te dice de que tipo es la tarea recurrente custom
export const selectRecurringTaskCustomValue = (typeCode: TypeCodeTask): RecurentTaskCustomValues => {
    switch (typeCode) {
        case "DAILY":
            return "day";
        case "WEEKLY":
            return "week";
        case "MONTHLY":
            return "everyMonth";
        case "ANNUALY":
            return "everyYear";
        default:
            return "day";
    }
};

export const setDays = (taskSchedule: TaskSchedulerModel): CustomDays[] => {
    const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = taskSchedule;
    return [
        {
            isActive: monday,
            value: i18n.t(TranslationKeys.DAY_WEEK_MONDAY_FIRST_LETTER),
            id: 0,
        },

        {
            isActive: tuesday,
            value: i18n.t(TranslationKeys.DAY_WEEK_TUESDAY_FIRST_LETTER),
            id: 1,
        },
        {
            isActive: wednesday,
            value: i18n.t(TranslationKeys.DAY_WEEK_WEDNESDAY_FIRST_LETTER),
            id: 2,
        },
        {
            isActive: thursday,
            value: i18n.t(TranslationKeys.DAY_WEEK_THURSDAY_FIRST_LETTER),
            id: 3,
        },
        {
            isActive: friday,
            value: i18n.t(TranslationKeys.DAY_WEEK_FRIDAY_FIRST_LETTER),
            id: 4,
        },
        {
            isActive: saturday,
            value: i18n.t(TranslationKeys.DAY_WEEK_SATURDAY_FIRST_LETTER),
            id: 5,
        },
        {
            isActive: sunday,
            value: i18n.t(TranslationKeys.DAY_WEEK_SUNDAY_FIRST_LETTER),
            id: 6,
        },
    ];
};
